import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core/";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Logout extends Component {
  state = {
    isLoggingOut: false,
  };

  // logout function
  logout = () => {
    // change isLoggingOut to true
    this.setState({
      isLoggingOut: true,
    });
    // destroy the cookie
    cookies.remove("AGU-ANAGHI-ERI-AGU", { path: "/" });
    // change isLoggingOut to false
    this.setState({
      isLoggingOut: false,
    });
    // direct the user to the landing page
    window.location.href = "/";
  };

  render() {
    const { isLoggingOut } = this.state;
    return (
      <Button type="submit" onClick={() => this.logout()}>
        {isLoggingOut ? "Working" : "Logout"}
      </Button>
    );
  }
}

export default withRouter(Logout);
