import React, { useContext, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core/";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import SelectMTHeader from "./SelectMTHeader";
import MenuItemWithCheckBox from "./MenuItemWithCheckBox";
import { MenuItemContext } from "../../../../../ContextAPI/MenuItemContext";
import LoadingFeedback from "../../../../helpers/LoadingFeedback";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function SelectMT({ useMenuItem }) {
  // context
  const {
    MPSelectedMenuItem,
    setMPSelectedMenuItem,
    searchResult,
    setSearchResult,
  } = useContext(HelpersContext);
  const { menuItems } = useContext(MenuItemContext);

  // states
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <section id="select_MT">
      {useMenuItem ? (
        <Button onClick={handleClickOpen()}>
          <ModalButton useMenuItem={useMenuItem} />
        </Button>
      ) : (
        <Button onClick={handleClickOpen()}>
          <ModalButton />
        </Button>
      )}

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"md"}
        scroll={scroll}
        disableBackdropClick={true}
        TransitionComponent={Transition}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <div id="select_MT_header">
            <aside>
              <p>
                <span onClick={() => setMPSelectedMenuItem([])}>X</span>{" "}
                <span>{MPSelectedMenuItem.length} Selected</span>
              </p>
            </aside>

            <aside>
              <Button
                onClick={() => {
                  searchResult?.length
                    ? setMPSelectedMenuItem([
                        ...MPSelectedMenuItem,
                        ...searchResult,
                      ])
                    : setMPSelectedMenuItem([
                        ...MPSelectedMenuItem,
                        ...menuItems,
                      ]);
                }}
              >
                Select All
              </Button>
              <Button onClick={() => handleClose()}>Close</Button>
            </aside>
          </div>
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <SelectMTForm
              closeAddUI={handleClose}
              searchResult={searchResult}
              setSearchResult={setSearchResult}
              menuItems={menuItems}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </section>
  );
}

function SelectMTForm(props) {
  // props
  const setSearchResult = props.setSearchResult;
  const searchResult = props.searchResult;
  const menuItems = props.menuItems;

  // context
  const { menuItemLoading, getMenuItems } = useContext(MenuItemContext);
  const { search, page } = useContext(HelpersContext);

  // effect
  useEffect(() => {
    getMenuItems(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <main id="select_mt_body">
      {/* header */}
      <SelectMTHeader
        setSearchResult={setSearchResult}
        menuItems={menuItems}
        closeAddUI={props.closeAddUI}
      />

      {/* menu items */}
      <Grid container id="select_menu_item_checkbox">
        {/* display menu items here */}
        {searchResult && searchResult.length > 0 ? (
          searchResult.map((result) => (
            <MenuItemWithCheckBox menuItem={result} />
          ))
        ) : search && searchResult && !searchResult.length ? (
          "No Result Found!"
        ) : menuItemLoading === "processing" ? (
          <LoadingFeedback message="Searching For Menu Items..." />
        ) : menuItemLoading === "found" && menuItems.length > 0 ? (
          menuItems.map((result) => <MenuItemWithCheckBox menuItem={result} />)
        ) : menuItemLoading === "found" && menuItems.length <= 0 ? (
          <LoadingFeedback message="Search Completed But No Menu Item Found" />
        ) : menuItemLoading === "failed" ? (
          <LoadingFeedback message="An Error Occured. There might be no items available. Alse, Check your Connection" />
        ) : (
          ""
        )}
      </Grid>
    </main>
  );
}

function ModalButton({ useMenuItem }) {
  return (
    <>
      {useMenuItem ? (
        ""
      ) : (
        <span>
          <AiOutlinePlus />
        </span>
      )}
      Add Meals
    </>
  );
}
