// external imports
import React, { useState, useContext } from "react";
import { TextField, Button } from "@material-ui/core/";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { StaffContext } from "../../../ContextAPI/StaffContext";
import FeedbackPopup from "../../helpers/FeedbackPopup";

export default function AdminLoginForm() {
  // context
  const { login, adminLogin } = useContext(StaffContext);

  // setup form fields
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // yup schema for validation
  const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    adminLogin(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Enter Staff ID */}
        <TextField
          label="Enter Staff ID"
          fullWidth
          name="username"
          error={!!errors.username}
          helperText={errors.username ? errors.username.message : ""}
          inputRef={register}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Staff ID is your phone number"
        />

        {/* Password */}
        <TextField
          label="Password"
          fullWidth
          type="password"
          name="password"
          error={!!errors.password}
          helperText={errors.password ? errors.password.message : ""}
          inputRef={register}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password not less than 6 characters"
        />

        {/* feedback popup */}
        <FeedbackPopup
          condition={login}
          additionalMessage="Redirecting To Dashboard..."
        />

        {/* button */}
        <section className="form-btn">
          <Button type="submit">Login</Button>
        </section>
      </form>
    </>
  );
}
