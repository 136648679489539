import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import HalfRowLayout from "../../../helpers/HalfRowLayout";
import NumberFormaterSuffix from "../../../helpers/NumberFormaterSuffix";
import { RecipeContext } from "../../../../ContextAPI/RecipeContext";

export default function PaymentAndDelivery({ isRecipeOrder }) {
  // context
  const { recipeDetails } = useContext(RecipeContext);
  const recipeOrder = isRecipeOrder ? recipeDetails.RecipeOrder : recipeDetails;
  const payment = isRecipeOrder
    ? recipeDetails.RecipeOrder.Payments[0]
    : recipeDetails.Payments[0];

  return (
    <>
      <Grid container id="general_info">
        <Grid item xs={5} sm={5} md={5} lg={5} id="">
          <Grid container>
            <HalfRowLayout className="text_key" text="Total Amount:" />
            <HalfRowLayout
              text={<NumberFormaterSuffix value={recipeOrder.amount} />}
            />

            <HalfRowLayout className="text_key" text="Sub-Total:" />
            <HalfRowLayout
              text={<NumberFormaterSuffix value={recipeOrder.subTotal} />}
            />

            <HalfRowLayout className="text_key" text="Delivery fee:" />
            <HalfRowLayout
              text={<NumberFormaterSuffix value={recipeOrder.deliveryAmount} />}
            />

            <HalfRowLayout className="text_key" text="Payment status:" />
            <HalfRowLayout text={payment ? payment.status : ""} />

            <HalfRowLayout className="text_key" text="Payment method:" />
            <HalfRowLayout text={payment ? payment.paymentMethod : ""} />

            <HalfRowLayout className="text_key" text="PAYMENT GATEWAY:" />
            <HalfRowLayout text={payment ? payment.gateWayType : ""} />
          </Grid>
        </Grid>

        <div className="vertical_rule"></div>

        <Grid item xs={5} sm={5} md={5} lg={5} id="">
          <Grid container>
            <HalfRowLayout className="text_key" text="COLLECTION METHOD:" />
            <HalfRowLayout text={recipeOrder.deliveryOption} />

            <HalfRowLayout className="text_key" text="Area:" />
            <HalfRowLayout text={recipeOrder.deliveryArea} />

            <HalfRowLayout className="text_key" text="Address:" />
            <HalfRowLayout text={recipeOrder.deliveryAddress} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
