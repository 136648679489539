import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import LoadingFeedback from "../../helpers/LoadingFeedback";
import { MenuPlanCategoryContext } from "../../../ContextAPI/MenuPlanCategoryContext";
import Category from "./Category";
import SearchItems from "./SearchItems";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";

export default function ViewCategoriesContent() {
  // context
  const { menuPlanCategoryLoading, menuPlanCategories } = useContext(
    MenuPlanCategoryContext
  );
  const { searchResult, search } = useContext(HelpersContext);

  return (
    <main id="view_mwnu_items">
      {/* filter */}
      <Grid container className="top_filter">
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{menuPlanCategories.length}</span>
          </p>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

        {/* filter section */}
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          className="filter_forms"
          id="pos_filter"
        >
          {/* searsch field */}
          <SearchItems item={menuPlanCategories} isName={true} />
        </Grid>
      </Grid>

      {/* Categories */}
      <Grid container id="meal_items">
        {/* display Categories here */}
        {searchResult && searchResult.length > 0 ? (
          searchResult.map((result) => <Category category={result} />)
        ) : search && searchResult && !searchResult.length ? (
          "No Result Found!"
        ) : menuPlanCategoryLoading === "processing" ? (
          <LoadingFeedback message="Searching For Categories..." />
        ) : menuPlanCategoryLoading === "found" &&
          menuPlanCategories.length > 0 ? (
          menuPlanCategories.map((result) => <Category category={result} />)
        ) : menuPlanCategoryLoading === "found" &&
          menuPlanCategories.length <= 0 ? (
          <LoadingFeedback message="Search Completed But No Category Found" />
        ) : menuPlanCategoryLoading === "failed" ? (
          <LoadingFeedback message="An Error Occured. There might be no Category available. Also, Check your Connection" />
        ) : (
          ""
        )}
      </Grid>
    </main>
  );
}
