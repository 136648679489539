import { Button, Grid, InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { useContext } from "react";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import { UtilsContext } from "../../../../ContextAPI/UtilsContext";

export default function Search() {
  const { setSearch, search } = useContext(HelpersContext);
  const { getPaymentByRef } = useContext(UtilsContext);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="filter_forms"
        id="pos_filter"
      >
        <form>
          <TextField
            variant="outlined"
            placeholder="transaction reference"
            name="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button
            disabled={!search ? true : false}
            onClick={() => getPaymentByRef(search.trim())}
          >
            Search
          </Button>
        </form>
      </Grid>
    </>
  );
}
