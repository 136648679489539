import React, { useContext, useState } from "react";
import { Grid, Button, ButtonGroup } from "@material-ui/core/";
import {
  RiArrowDownSLine,
  RiDeleteBin2Fill,
  RiArrowUpSLine,
} from "react-icons/ri";
import { IconContext } from "react-icons";
import moment from "moment";
import EditCartItem from "./EditCartItem";
import { toggleShowItems } from "../../helpers/MenuItemHelper";
import { CartContext } from "../../../ContextAPI/CartContext";
import { getAddonsDetails, getNames } from "../../helpers/POSOrderHelper";

export default function EachCartMeal(props) {
  // context
  const { removeCartItem } = useContext(CartContext);

  // props
  const cartItem = props.cartItem;
  const cartItems = props.cartItems;
  const setCartItems = props.setCartItems;

  // state
  const [showDetails, setshowDetails] = useState(false);

  return (
    <>
      {cartItem.data.id ? (
        <>
          <main id="each_cart_meal" key={cartItem.data.id}>
            <Grid container>
              <Grid item xs={2} sm={2} md={2} lg={2} id="qty">
                <span>
                  <p>{cartItem.data.quantity}</p>
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <p>{cartItem.menuItem.itemName}</p>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} id="price" align="right">
                <p>{parseFloat(cartItem.data.amount).toFixed(2)} NGN</p>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <p>
                  {moment(cartItem.data.createdAt).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )}
                </p>
              </Grid>

              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                align="right"
                id="view_more_btn"
              >
                <Button
                  onClick={() => {
                    toggleShowItems(showDetails, setshowDetails);
                  }}
                >
                  {showDetails ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                </Button>
              </Grid>
            </Grid>
          </main>

          {showDetails ? (
            <section id="each_cart_meal_details">
              {/* display the addons and preferences */}
              <p>
                {getAddonsDetails(cartItem.addons).split(",").join(", ") +
                  ", " +
                  getNames(cartItem.preferences).join(", ")}
              </p>

              {/* show the special instructions */}
              <details>
                <summary>Special Instruction</summary>
                <blockquote>
                  {cartItem.data.specialInstruction
                    ? cartItem.data.specialInstruction
                    : "No Special Instructions For This Item"}
                </blockquote>
              </details>

              <div id="each_cart_meal_btns">
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  <EditCartItem
                    cartItem={cartItem}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                  />

                  {/* remove this item from cart */}
                  <Button
                    onClick={() =>
                      removeCartItem(
                        cartItem.data.id,
                        cartItems,
                        setCartItems,
                        cartItem.data.createdBy
                      )
                    }
                  >
                    <IconContext.Provider
                      value={{ className: "add_meal_manage_icons" }}
                    >
                      <RiDeleteBin2Fill />
                    </IconContext.Provider>{" "}
                    <span>Delete</span>
                  </Button>
                </ButtonGroup>
              </div>
            </section>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}
