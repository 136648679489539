// external imports
import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import moment from "moment";
import { getBaseUrl } from "../Configurations/ContextConfig";
import { HelpersContext } from "./HelpersContext";
import {
  getIds,
  getPlantypes,
  getUniqueItems,
} from "../components/helpers/MenuPlanHelper";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};
let branchId = null;

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
  branchId = staff.branchId;
}

const BASE_URL = getBaseUrl();

// create MenuPlan context to manage MenuPlan here
export const MenuPlanContext = createContext();

export default function MenuPlanContextProvider(props) {
  // context
  const {
    savedMPItems,
    setSavedMPItems,
    MPBasicInfo,
    setMPBasicInfo,
    morningRanges,
    afternoonRanges,
    eveningRanges,
    setMorningRanges,
    setAfternoonRanges,
    setEveningRanges,
    setMPSelectedMenuItem,
    setSelectedPOD,
    dateRange,
  } = useContext(HelpersContext);

  // set initial state
  const [menuPlanLoading, setLoading] = useState(false);
  const [fetchingMP, setFetchingMP] = useState(false);
  const [menuPlans, setMenuPlans] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [updatingMP, setUpdatingMP] = useState(false);
  const [addingPlanDetails, setAddingPlanDetails] = useState(false);

  // make API call to get all the MenuPlans
  const getMealPlans = (data) => {
    // clear all filter
    data.setCategory(null);
    data.setSearch("");
    data.setSearchResult(null);

    setFetchingMP("processing");

    const method = "get",
      url = `${BASE_URL}menuplans/plan/branch?branchId=${branchId}&page=1`;

    // get MenuPlans
    axios({ url, method, headers })
      .then((result) => {
        setMenuPlans(result.data.data.data.items);
        setFetchingMP("found");
      })
      .catch((error) => {
        error = new Error();
        setFetchingMP("failed");
      });
  };

  // addMenuPlan
  const addMenuPlan = (inputs, imageUrl, dateRange, isActive, history) => {
    setLoading("processing");
    // validate image
    if (!imageUrl) return alert("No Image Selected");

    // get MenuPlan category
    // config for MenuPlan category API call
    const method = "get",
      url = `${BASE_URL}menuplans/categories/${staff.branchId}`;

    // make API call to get all MenuPlan category
    axios({ url, method, headers })
      .then((result) => {
        const allMenuPlanCategories = result.data.data.data.rows;
        let selectedCategoryId = null;

        // get the id of the selected category
        for (const category of allMenuPlanCategories) {
          if (inputs.category.toLowerCase() === category.name.toLowerCase()) {
            selectedCategoryId = category.id;
          }
        }

        const options = {
          method: "post",
          url: `${BASE_URL}menuplans/`,
          headers,
          data: {
            name: inputs.planName,
            description: inputs.description,
            branchId: staff.branchId,
            imageurl: imageUrl,
            startDate: moment(dateRange[0].startDate).format().split("T")[0],
            endDate: moment(dateRange[0].endDate).format().split("T")[0],
            isactivated: isActive,
            categoryId: selectedCategoryId,
          },
        };

        const menuPlanDetailsData = {
          planName: inputs.planName,
          dateRange,
        };

        // make API call
        axios(options)
          .then((result) => {
            setLoading("successful");

            menuPlanDetailsData.menuPlan = result.data.data.data;
            setMPBasicInfo(menuPlanDetailsData);

            setTimeout(() => {
              history.push("/add-menu-plan-info", menuPlanDetailsData);
              setLoading(false);
            }, 1000);
          })
          .catch((error) => {
            error = new Error();
            setLoading("failed");

            setTimeout(() => {
              setLoading(false);
            }, 1000);
          });
      })
      .catch((error) => {
        error = new Error();
        alert(
          "Matching Category Failed! It could be your network. Try Submitting Again."
        );
      });
  };

  // AddMenuPlanDetail
  const AddMenuPlanDetail = (
    selectedTimeRange,
    menuItem,
    periodOfDay,
    row,
    day,
    planData,
    submissionDate,
    allDetails,
    setAllDetails
  ) => {
    // assign the new item added an id
    const itemId = periodOfDay + row + day;

    // terminate the process if there is already an item in that location
    for (const item of allDetails) {
      if (item.id === itemId) return alert("Item Already Exist here!");
    }

    // start processing input
    setLoading("processing");

    // get config for API call
    const method = "post",
      url = `${BASE_URL}menuplans/detail/`,
      headers = {
        Authorization: `Bearer ${staff.token}`,
      },
      data = {
        menuplanid: planData.menuPlan.id,
        plantype: periodOfDay,
        menuitemid: menuItem.id,
        plandate: moment(submissionDate).format().split("T")[0],
        deliveryTimes: selectedTimeRange.toString(),
        branchId: staff.branchId,
      };

    // create details for the new meal being added
    // this will be used to display data on the FE.
    const addedMeal = {
      id: itemId,
      itemName: menuItem.itemName,
      itemPrice: menuItem.amount,
      data,
    };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setLoading("successful");
        setAllDetails([...allDetails, addedMeal]);
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  // AddMenuPlanDetail
  const AddMenuPlanContent = (MPSelectedMenuItem, closePreview, closeAddUI) => {
    if (!MPSelectedMenuItem.length) {
      return setAddingPlanDetails("Please Select Item(s)!");
    }

    if (
      !morningRanges.length &&
      !afternoonRanges.length &&
      !eveningRanges.length
    ) {
      return setAddingPlanDetails("Please Select Time Range(s)!");
    }

    // start processing input
    setAddingPlanDetails("processing");

    // remove duplicates if any
    const filteredMenuItems = getUniqueItems(
      getIds(MPSelectedMenuItem),
      MPSelectedMenuItem
    );

    // generate start and end date
    // we reduce the start date by 1 and increase end date by 1
    // this is because the backend is skiping the days when included
    const start = moment(
      moment(dateRange[0].startDate).subtract(1, "d")
    ).format("YYYY-MM-DD");
    const end = moment(moment(dateRange[0].endDate).subtract(1, "d")).format(
      "YYYY-MM-DD"
    );

    // get config for API call
    const method = "post",
      url = `${BASE_URL}menuplans/detailV1/`,
      headers = {
        Authorization: `Bearer ${staff.token}`,
      },
      data = {
        menuplanid: MPBasicInfo.menuPlan.id,
        plantypes: getPlantypes(morningRanges, afternoonRanges, eveningRanges),
        menuItems: getIds(MPSelectedMenuItem).toString(),
        start,
        end,
        branchId,
      };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setSavedMPItems([...savedMPItems, ...filteredMenuItems]);

        setMorningRanges([]);
        setAfternoonRanges([]);
        setEveningRanges([]);
        setMPSelectedMenuItem([]);
        setSelectedPOD([]);
        closePreview(false);
        closeAddUI(false);
        setAddingPlanDetails("successful");

        if (window.location.pathname === "/retrieving-meal-plan-details") {
          window.location.reload();
        }

        setTimeout(() => {
          setAddingPlanDetails(null);
        }, 1000);
      })
      .catch((error) => {
        error = new Error();
        setAddingPlanDetails("failed");

        setTimeout(() => {
          setAddingPlanDetails(null);
        }, 1000);
      });
  };

  // filter by category
  const filterMPByCategory = (data) => {
    // clear the search field and seaerch result if any
    // this is so that the filter result can be applied
    data.setSearch("");
    data.setSearchResult(null);

    setFetchingMP("processing");

    const method = "get",
      url = `${BASE_URL}menuplans/plan/branch/category?branchId=${branchId}&page=1&categoryid=${data.category}`;

    // get MenuPlans
    axios({ url, method, headers })
      .then((result) => {
        setMenuPlans(result.data.data.data.items);
        setFetchingMP("found");
      })
      .catch((error) => {
        error = new Error();
        setFetchingMP("failed");
      });
  };

  // edit MP general details
  // edit menu item
  const editMenuPlan = (
    name,
    description,
    imageUrl,
    isActive,
    mealPlan,
    btn,
    history,
  ) => {
    setUpdatingMP("processing");

    console.log(mealPlan);

    // set meal plan id
    const id = mealPlan.id;

    // configuration for Axios API call
    let options = {
      method: "put",
      url: `${BASE_URL}menuplans/`,
      headers,
      data: {
        id,
        description,
        branchId,
        isactivated: isActive,
      }
    };

    if (mealPlan.name !== name) {
      options.data.name = name
    }

    // process the image if an image was selected
    if (imageUrl) {
      options.data.imageurl = imageUrl;

      editMPAPICall(options, btn, history, mealPlan);
    } else {

      editMPAPICall(options, btn, history, mealPlan);
    }
  };

  // API call for edit menu item
  const editMPAPICall = (options, btn, history, mealPlan) => {
    axios(options)
      .then(() => {
        setUpdatingMP("successful");

        setTimeout(() => {
          btn === "Update"
            ? (window.location.href = "/view-menu-plans")
            : history.push("/retrieving-meal-plan-details", mealPlan);
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setUploadPercentage(0);
        error = new Error();
        setUpdatingMP("failed");
      });
  };

  return (
    <MenuPlanContext.Provider
      value={{
        menuPlanLoading,
        menuPlans,
        addMenuPlan,
        uploadPercentage,
        AddMenuPlanDetail,
        fetchingMP,
        filterMPByCategory,
        getMealPlans,
        editMenuPlan,
        updatingMP,
        AddMenuPlanContent,
        addingPlanDetails,
      }}
    >
      {props.children}
    </MenuPlanContext.Provider>
  );
}
