import React from "react";
import {
  Button,
  Grid,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core/";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function CreateRoleForm() {
  return (
    <form id="create_role" className="form_frame">
      <h1>CREATE ROLE</h1>

      <Grid container id="role_description">
        {/* Role Discription */}
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <h4>Role Discription</h4>
          <TextField multiline rows={2} variant="outlined" fullWidth />
        </Grid>
      </Grid>

      <h1>Assign Permissions</h1>

      {/* permissions body */}
      <CreateRolePermissions />
      <CreateRolePermissions />
      <CreateRolePermissions />

      {/* button */}
      <section className="form-btn">
        <Button>Create</Button>
      </section>
    </form>
  );
}

function CreateRolePermissions() {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />

          <Accordion>
            {/* accordion title */}
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="accordionTitle"
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControlLabel
                    value="makeSale"
                    control={<Checkbox color="primary" />}
                    label="Make Sale"
                    labelPlacement=""
                  />

                  <span id="permissions_given">4/5</span>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <p>
                    This permission allows the user to manage the staff: create,
                    set roles, etc.
                  </p>
                </Grid>
              </Grid>
            </AccordionSummary>

            <AccordionDetails id="inner_permissions">
              <FormGroup aria-label="position" column>
                <FormControlLabel
                  value="top"
                  control={<Checkbox color="primary" />}
                  label="Give discount"
                  labelPlacement=""
                />

                <FormControlLabel
                  value="start"
                  control={<Checkbox color="primary" />}
                  label="Edit Price at Point of Sale"
                  labelPlacement=""
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
}
