import React, { useState } from "react";
import { Button, Grid, Hidden, Badge } from "@material-ui/core/";
import { HiBadgeCheck } from "react-icons/hi";
import POSFilter from "./POSFilter";
import NewOrder from "./NewOrder";
import { AiOutlineArrowRight } from "react-icons/ai";
import POSFilterDrawer from "./POSFilterDrawer";
import { toggleShow } from "../../helpers/OrderHelpers";
import { getIds } from "../../helpers/POSOrderHelper";

export default function POSHeader(props) {
  // states
  const [showControls, setShowControls] = useState(true);

  // props
  const setShowCart = props.setShowCart;
  const showCart = props.showCart;
  const cartItems = props.cartItems;
  const menuItems = props.menuItems;

  return (
    <>
      <header>
        <Grid container className="top_filter">
          {/* control buttons */}
          <Grid item xs={10} sm={10} md={10} lg={10} id="top_pos_btns">
            {showControls && !props.showCart ? (
              <>
                <Button>
                  Quick Sale{" "}
                  <span>
                    <HiBadgeCheck />
                  </span>
                </Button>
                <Button>Schedule</Button>

                {/* start a new order */}
                <NewOrder cartItems={cartItems} />
              </>
            ) : (
              <Button
                onClick={() =>
                  toggleShow(showControls, setShowControls, setShowCart)
                }
                id="show_controls_btn"
              >
                <span>Show Controls</span> <AiOutlineArrowRight />
              </Button>
            )}
          </Grid>

          {/* show cart button */}
          {showCart ? (
            ""
          ) : (
            <Grid item xs={2} sm={2} md={2} lg={2} id="show_cart_btn">
              <Badge
                onClick={() =>
                  toggleShow(showCart, setShowCart, setShowControls)
                }
                color="secondary"
                badgeContent={getIds(cartItems).length}
                showZero
              >
                Show Cart
              </Badge>
            </Grid>
          )}

          {/* filter section */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {/* filter options for xs and sm */}
            <Hidden mdUp>
              <POSFilterDrawer menuItems={menuItems} />
            </Hidden>

            {/* filter options for md and lg */}
            <Hidden smDown>
              <POSFilter menuItems={menuItems} />
            </Hidden>
          </Grid>
        </Grid>
      </header>
    </>
  );
}
