import React, { useContext, useEffect } from "react";
import { MenuPlanContext } from "../../../../ContextAPI/MenuPlanContext";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import MealPlans from "../ViewMealPlans/MealPlans";
import { filterPlans } from "../../../helpers/MenuPlanHelper";

export default function ViewInactiveMealPlans() {
  // context
  const { menuPlans, fetchingMP, getMealPlans } = useContext(MenuPlanContext);
  const { searchResult, setSearchResult, setSearch, search, setCategory } =
    useContext(HelpersContext);

  const plans = filterPlans(menuPlans, false);

  useEffect(() => {
    getMealPlans({ setCategory, setSearch, setSearchResult });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MealPlans
      plans={plans}
      searchResult={searchResult}
      search={search}
      fetchingMP={fetchingMP}
    />
  );
}
