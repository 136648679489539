// change the retrieved inventories to the format desired
const processInventory = (inventories) => {
  if (!inventories) return;

  let selectedInventoriesArray = [];

  for (const inventory of inventories) {
    const newInventory = {
      id: inventory.Inventory.id,
      name: inventory.Inventory.itemName,
      price: inventory.Inventory.unitPrice,
      totalPrice: inventory.price,
      quantity: inventory.quantity,
      extra: inventory.extra,
    };

    selectedInventoriesArray.push(newInventory);
  }

  return selectedInventoriesArray;
};

// change the retrieved addons to the format desired
const processAddons = (addons) => {
  if (!addons) return;

  let selectedAddons = [];

  for (const addon of addons) {
    const newInventory = {
      id: addon.id,
      name: addon.Inventory.itemName,
      price: parseFloat(addon.price) / parseFloat(addon.quantity),
      totalPrice: addon.price,
      quantity: addon.quantity,
      extra: addon.isExtra,
    };

    selectedAddons.push(newInventory);
  }

  return selectedAddons;
};

// extract preferences that are not part of the retrieved preferences
const extractPreferences = (menuItemPreferences, retrievedPreferences) => {
  if (!menuItemPreferences || !retrievedPreferences) return;

  // get the names of the retrievedPreferences
  let preferenceNames = [];
  for (const item of retrievedPreferences) {
    preferenceNames = [...preferenceNames, item.Preference.name];
  }

  // compare the menuItemPreferences with the preferenceNames and remove any match
  let extracted = [];
  for (const item of menuItemPreferences) {
    if (!preferenceNames.includes(item.name)) {
      extracted = [...extracted, item];
    }
  }

  return extracted;
};

// extract preferences that are not part of the retrieved preferences
const extractCategories = (menuItemCategories, retrievedCategories) => {
  if (!menuItemCategories || !retrievedCategories) return;

  // get the names of the retrievedCategories
  let categoriesNames = [];
  for (const item of retrievedCategories) {
    categoriesNames = [...categoriesNames, item.ItemCategory.name];
  }

  // compare the menuItemCategories with the categoriesNames and remove any match
  let extracted = [];
  for (const item of menuItemCategories) {
    if (!categoriesNames.includes(item.name)) {
      extracted = [...extracted, item];
    }
  }

  return extracted;
};


export {
  processInventory,
  processAddons,
  extractPreferences,
  extractCategories,
};
