import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { HiPlusSm } from "react-icons/hi";
import { startNewOrder } from "../../helpers/POSOrderHelper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function NewOrder(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewOrder = () => {
    if (props.cartItems.length) {
      handleClickOpen();
    } else {
      startNewOrder();
    }
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleNewOrder}>
        <span>
          <HiPlusSm />
        </span>
        New Order
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="new_order_title">
          {"Start New Order?"}
        </DialogTitle>
        <DialogContent id="new_order_body">
          <DialogContentText id="alert-dialog-slide-description">
            Do you want to begin a New Order without saving the items in Cart
            Already?
          </DialogContentText>
        </DialogContent>
        <DialogActions id="new_order_btns">
          <Button onClick={handleClose}>
            No
          </Button>
          <Button onClick={() => startNewOrder()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
