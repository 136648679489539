import React from "react";
import Feedback from "./Feedback";

export default function FeedbackPopup(props) {
  if (props.condition === "successful") {
    return (
      <Feedback
        severity="success"
        message={`Successful! ${
          props.additionalMessage ? props.additionalMessage : ""
        }`}
      />
    );
  }
  
  if (props.condition === "failed") {
    return (
      <Feedback
        severity="error"
        message="Failure! Check your network and the details you entered"
      />
    );
  }

  if (props.condition === "processing") {
    return <Feedback severity="warning" message="Working..." />;
  }

  return null;
}
