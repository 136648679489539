import React from "react";
import Content from "../../../components/adminComponents/PageNotFound/Content";

export default function Permission404() {
  return (
    <>
      <Content
        message="Page Not Returned"
        reason="You do not have Permission to view this page"
      />
    </>
  );
}
