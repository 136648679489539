import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { CustomerContext } from "../../../../ContextAPI/CustomerContext";
import HalfRowLayout from "../../../helpers/HalfRowLayout";

export default function Details({ payment }) {
  const { getCustomer, customer, gettingCustomer } =
    useContext(CustomerContext);

  useEffect(() => {
    getCustomer(payment.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Grid container>
        {gettingCustomer === "found" && customer ? (
          <>
            <HalfRowLayout className="text_key" text="Customer Name:" />
            <HalfRowLayout text={customer.fullName} />

            <HalfRowLayout className="text_key" text="Customer Phone No:" />
            <HalfRowLayout text={customer.phoneNumber} />

            <HalfRowLayout className="text_key" text="Customer Email:" />
            <HalfRowLayout text={customer.email} />
          </>
        ) : (
          "Customer Not Found"
        )}

        <HalfRowLayout className="text_key" text="PAYMENT GATEWAY:" />
        <HalfRowLayout text={payment.gateWayType} />

        <HalfRowLayout className="text_key" text="Status:" />
        <HalfRowLayout text={payment.status} />

        <HalfRowLayout className="text_key" text="Transaction ID:" />
        <HalfRowLayout text={payment.id} />

        <HalfRowLayout className="text_key" text="Transaction Ref:" />
        <HalfRowLayout text={payment.paymentRef} />

        <HalfRowLayout className="text_key" text="Time:" />
        <HalfRowLayout text={moment(payment.createdAt).format("HH:mm:ss")} />

        <HalfRowLayout className="text_key" text="Date:" />
        <HalfRowLayout text={moment(payment.createdAt).format("L")} />
      </Grid>
    </>
  );
}
