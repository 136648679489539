import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@material-ui/core/";
import { AiOutlinePlus } from "react-icons/ai";
import { UtilsContext } from "../../../ContextAPI/UtilsContext";

export default function AddBankDetails(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  console.log(props.searchData, props.selectedBank)

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div id="add_bank_details">
      {/* popup button */}
      <Button onClick={handleClickOpen()}>
        <span>
          <AiOutlinePlus />
        </span>
        Add Bank
      </Button>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>Enter banking details</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <AddBankDetailsForm
              searchData={props.searchData}
              setSearch={props.setSearch}
              setSelectedBank={props.setSelectedBank}
              setShowAllBank={props.setShowAllBank}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function AddBankDetailsForm(props) {
  // context
  const { addBank } = useContext(UtilsContext);

  // props
  const searchData = props.searchData;
  const setSearch = props.setSearch;
  const setSelectedBank = props.setSelectedBank;

  // initial state
  const [bankName, setBankName] = useState(searchData);
  const [accountName, setAccountName] = useState(searchData);
  const [accountNumber, setAccountNumber] = useState(searchData);
  const [accountType, setAccountType] = useState(searchData);

  // yup schema for validation
  const schema = yup.object().shape({
    bankName: yup.string().required(),
    accountName: yup.string().required(),
    accountNumber: yup.number().required(),
    accountType: yup.string().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    addBank(data, setSearch, setSelectedBank, props.setShowAllBank);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* name */}
      <section>
        <h4>Bank Name</h4>
        <TextField
          fullWidth
          variant="outlined"
          name="bankName"
          error={!!errors.bankName}
          helperText={errors.bankName ? errors.bankName.message : ""}
          inputRef={register}
          value={bankName}
          onChange={(e) => setBankName(e.target.value)}
        />
      </section>

      {/* Account name */}
      <section>
        <h4>Account Name</h4>
        <TextField
          fullWidth
          variant="outlined"
          error={!!errors.accountName}
          helperText={errors.accountName ? errors.accountName.message : ""}
          inputRef={register}
          name="accountName"
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
        />
      </section>

      {/* Account Number */}
      <section>
        <h4>Account Number</h4>
        <TextField
          fullWidth
          variant="outlined"
          error={!!errors.accountNumber}
          helperText={errors.accountNumber ? errors.accountNumber.message : ""}
          inputRef={register}
          name="accountNumber"
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
        />
      </section>

      {/* Account Type */}
      <section>
        <h4>Account Type</h4>
        <TextField
          fullWidth
          variant="outlined"
          error={!!errors.accountType}
          helperText={errors.accountType ? errors.accountType.message : ""}
          inputRef={register}
          name="accountType"
          value={accountType}
          onChange={(e) => setAccountType(e.target.value)}
        />
      </section>

      {/* button */}
      <section className="form-btn">
        <Button type="submit">Add</Button>
      </section>
    </form>
  );
}
