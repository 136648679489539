import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import ViewCustomersTable from "../../../components/adminComponents/Customers/ViewCustomers/ViewCustomersTable";
import SettingsFrame from "../../../components/adminComponents/Settings/SettingsFrame";

export default function IncomingOrder() {
  return (
    <main className="table_page_frame">
      <DashboardFrame
        pageHeading="Customers"
        dashboardContent={
          <SettingsFrame
            firstLabel="Find Customers"
            firstContent={<ViewCustomersTable />}
          />
        }
      />
    </main>
  );
}
