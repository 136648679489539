import React, { useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
} from "@material-ui/core/";
import HalfRowLayout from "../../../helpers/HalfRowLayout";
import { OrderContext } from "../../../../ContextAPI/OrderContext";
import { MPManagementContext } from "../../../../ContextAPI/MPManagementContext";
import LoadingFeedback from "../../../helpers/LoadingFeedback";
import { sortMPOrders, sortOrders } from "../../../helpers/OrderHelpers";

export default function CustomerSummary(props) {
  // context
  const { getCustomersMTOrders } = useContext(OrderContext);
  const { getMenuPlanByUser } = useContext(MPManagementContext);

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const customer = props.customer;

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
    getCustomersMTOrders(customer.id);
    getMenuPlanByUser(customer.id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      {/* popup button */}
      <Button id="menu_plan_time_limit_btn" onClick={handleClickOpen()}>
        Summary
      </Button>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{customer.fullName}</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <CustomerSummaryBody />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function CustomerSummaryBody(props) {
  // context
  const { customerOrders, loadingCustomerOrder } = useContext(OrderContext);
  const { userMPOrders, userOrdersLoading } = useContext(MPManagementContext);

  const instantOrders = customerOrders.length;
  const completedInstantOrder = sortOrders(customerOrders, "Delivered");
  const cancelledInstantOrder = sortOrders(customerOrders, "Cancelled");

  const MPOrders = userMPOrders.length;
  const completedMPOrder = sortMPOrders(userMPOrders, "Delivered");
  const cancelledMPOrder = sortMPOrders(userMPOrders, "Cancelled");

  return (
    <Grid container>
      {/* Instant Orders */}
      <h3>Instant Orders ({instantOrders})</h3>
      <EachSummary
        loader={loadingCustomerOrder}
        data={customerOrders}
        completed={completedInstantOrder}
        cancelled={cancelledInstantOrder}
      />

      {/* Meal Plan orders */}
      <h3>Meal Plan orders ({MPOrders})</h3>
      <EachSummary
        loader={userOrdersLoading}
        data={userMPOrders}
        completed={completedMPOrder}
        cancelled={cancelledMPOrder}
      />

      {/* Total orders */}
      <h3>Total orders ({instantOrders + MPOrders})</h3>
      <Grid container>
        <HalfRowLayout className="text_key" text="Completed:" />
        <HalfRowLayout
          text={completedInstantOrder.length + completedMPOrder.length}
        />

        <HalfRowLayout className="text_key" text="Cancelled:" />
        <HalfRowLayout
          text={cancelledInstantOrder.length + cancelledMPOrder.length}
        />
      </Grid>
    </Grid>
  );
}

function EachSummary(props) {
  const loader = props.loader;
  const data = props.data;

  return (
    <>
      {loader === "processing" ? (
        <LoadingFeedback message="Searching For orders..." />
      ) : loader === "found" && data.length > 0 ? (
        <>
          <Grid container>
            <HalfRowLayout className="text_key" text="Completed:" />
            <HalfRowLayout text={props.completed.length} />

            <HalfRowLayout className="text_key" text="Cancelled:" />
            <HalfRowLayout text={props.cancelled.length} />
          </Grid>
        </>
      ) : loader === "found" && data.length <= 0 ? (
        <LoadingFeedback message="Search Completed - No Orders Found" />
      ) : loader === "failed" ? (
        <LoadingFeedback message="An Error Occured. There may be no orders for this Customer" />
      ) : (
        ""
      )}
    </>
  );
}
