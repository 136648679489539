import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Receipt from "./IncomingOrder/Receipt";
import { CgMoreVertical } from "react-icons/cg";
import { useHistory } from "react-router-dom";
import CheckoutPopup from "../CreatePOSOrder/CheckoutPopup";
import { OrderContext } from "../../../ContextAPI/OrderContext";
import CancelOrder from "./CancelOrder";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";

export default function OrderActions(props) {
  // context
  const { changeOrderStatus, changing } = useContext(OrderContext);
  const { roles } = useContext(HelpersContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const orderReceived = props.order;
  const status = orderReceived.menuitemorders.status;
  const deliveryOption = orderReceived.menuitemorders.deliveryOption;
  const history = useHistory();
  const orderTable = props.orderTable;
  const customer = props.customer;
  const orderedBy = orderReceived.orderBy;

  const orderDetails = {
    id: orderReceived.id,
    isMenuPlan: orderReceived.isMenuPlan,
    orderedBy: orderedBy ? orderedBy : customer,
    area: orderReceived.menuitemorders.DeliveryAddress,
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <CgMoreVertical />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* see all details of an order */}
        <MenuItem
          onClick={() =>
            history.push(
              orderTable === "Active"
                ? "/view-an-active-order"
                : "/view-an-incoming-order",
              orderDetails
            )
          }
        >
          View Details
        </MenuItem>

        {/* Receipt slip */}
        <Receipt order={orderReceived} customer={customer} />

        {/* hide this button if the order has beed Delivered or Cancelled */}
        {status === "Delivered" ||
        status === "Cancelled" ||
        status === "CANCELLED" ? (
          ""
        ) : (
          <>
            {roles.includes("Chef 2") ||
            roles.includes("Rider 2") ||
            roles.includes("Dispatch 1") ? (
              <>
                {/* change order status depending on the initial status */}
                <MenuItem
                  className="success-btn"
                  onClick={() =>
                    changeOrderStatus(
                      orderReceived.id,
                      status === "Pending" && roles.includes("Chef 2")
                        ? "Accepted"
                        : status === "Accepted" && roles.includes("Chef 2")
                        ? "Processing"
                        : status === "Processing" && roles.includes("Chef 2")
                        ? "Ready"
                        : status === "Ready" &&
                          roles.includes(
                            deliveryOption === "Delivery"
                              ? "Rider 2"
                              : "Dispatch 1"
                          )
                        ? "Delivered"
                        : "",
                      setAnchorEl
                    )
                  }
                >
                  {status === "Pending" && roles.includes("Chef 2")
                    ? "Accept"
                    : status === "Accepted" && roles.includes("Chef 2")
                    ? "Begin Processing"
                    : status === "Processing" && roles.includes("Chef 2")
                    ? "Ready"
                    : status === "Ready" &&
                      roles.includes(
                        deliveryOption === "Delivery" ? "Rider 2" : "Dispatch 1"
                      )
                    ? "Deliver"
                    : ""}
                </MenuItem>
              </>
            ) : (
              ""
            )}

            <>
              {/* cancel order here */}
              {roles.includes("Manager 1") ? (
                <CancelOrder order={orderReceived} />
              ) : (
                ""
              )}
            </>
          </>
        )}

        {/* make payment here */}
        {roles.includes("Cashier 2") ? (
          <CheckoutPopup order={orderReceived} />
        ) : (
          ""
        )}
      </Menu>

      {/* feedback popup */}
      <FeedbackPopup
        condition={changing}
        additionalMessage="Refresh To See Update..."
      />
    </div>
  );
}
