import { Button, Grid, TextField } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";

export default function StartAndEndDates(props) {
  const filterFunction = props.filterFunction;
  const executeOnce = props.executeOnce;
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    page,
    presentDay,
    nextDay,
  } = useContext(HelpersContext);

  // execute filterFunction if executeOnce is true
  if (executeOnce) {
    filterFunction(presentDay, nextDay, page);
  }

  // execute filterFunction if executeOnce is false and page changes
  useEffect(() => {
      filterFunction(startDate, endDate, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <Grid item xs={5} sm={5} md={5} lg={5} className="filter_forms">
        {/* start date */}
        <p>Start:</p>
        <TextField
          variant="outlined"
          type="date"
          name="startDate"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />

        {/* end date */}
        <p>End:</p>
        <TextField
          variant="outlined"
          type="date"
          name="endDate"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />

        {/* apply filter */}
        <Button onClick={() => filterFunction(startDate, endDate, page)}>
          Apply
        </Button>
      </Grid>
    </>
  );
}
