import React from "react";
import { Grid, Badge } from "@material-ui/core";

export default function HalfRowLayout(props) {
  return (
    <Grid
      item
      xs={6}
      sm={6}
      md={6}
      lg={6}
      id={props.id}
      className={props.className}
    >
      {props.children}

      {props.color ? (
        <Badge color={props.color} variant="dot">
          {props.text}
        </Badge>
      ) : (
        <p>{props.text}</p>
      )}
    </Grid>
  );
}
