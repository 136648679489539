import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { OrderContext } from "../../../ContextAPI/OrderContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CancelOrder(props) {
  // context
  const { changeOrderStatus } = useContext(OrderContext);

  const order = props.order;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MenuItem
        variant="outlined"
        color="primary"
        className="danger-btn"
        onClick={handleClickOpen}
      >
        Cancel
      </MenuItem>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="new_order_title">{"Cancel Order?"}</DialogTitle>
        <DialogContent id="new_order_body">
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to cancel this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions id="new_order_btns">
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => changeOrderStatus(order.id, "Cancelled")}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
