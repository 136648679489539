// external imports
import React, { createContext, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  removeFromCart,
  returnNull,
} from "../components/helpers/POSOrderHelper";
import { getBaseUrl } from "../Configurations/ContextConfig";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};
let branchId = null;
let staffId = null;

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
  branchId = staff.branchId;
  staffId = staff.id;
}

const BASE_URL = getBaseUrl();

// create Cart context to manage Cart here
export const CartContext = createContext();

export default function CartContextProvider(props) {
  // set initial state
  const [cartLoading, setLoading] = useState(false);

  // add Cart
  const addCartItem = (cartData) => {
    setLoading("processing");

    // get config for creating Cart
    const method = "post",
      url = `${BASE_URL}orders/cart/`,
      data = {
        branchId,
        menuitemid: cartData.menuItem.id,
        quantity: parseFloat(cartData.overallQty),
        amount: parseFloat(cartData.totalPrice),
        addons: JSON.stringify(cartData.selectedAddons),
        preferences: JSON.stringify(cartData.selectedPreference),
        specialInstruction: cartData.instructions,
        createdBy: staffId,
      };

    // make API call to create Cart
    axios({ url, method, headers, data })
      .then((result) => {
        // create details for this item
        const newCartItem = {
          menuItem: cartData.menuItem,
          addons: cartData.selectedAddons,
          preferences: cartData.selectedPreference,
          data: result.data.data,
        };

        // add item to the cart by adding to the cart array
        cartData.setCartItems([...cartData.cartItems, newCartItem]);

        setLoading("successful");

        setLoading(false);
        cartData.closePopup(false);
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  // edit Cart Item
  const editCartItem = (cartData) => {
    setLoading("processing");

    const item = cartData.cartItemData;
    const cartItems = cartData.cartItems;
    const setCartItems = cartData.setCartItems;

    // get config for creating Cart
    const method = "put",
      url = `${BASE_URL}orders/cart/`,
      data = {
        cartId: item.id,
        quantity: parseFloat(cartData.overallQty),
        amount: parseFloat(cartData.totalPrice),
        addons: returnNull(JSON.stringify(cartData.selectedAddons)),
        preferences: returnNull(JSON.stringify(cartData.selectedPreference)),
        specialInstruction: returnNull(cartData.instructions),
        orderForFriend: item.orderForFriend,
        friendName: returnNull(item.friendName),
        friendPhoneNumber: returnNull(item.friendPhoneNumber),
        deliveryOption: returnNull(item.deliveryOption),
        deliveryTime: returnNull(item.deliveryTime),
        deliveryAddress: returnNull(item.deliveryAddress),
        userId: item.createdBy,
      };

    // create details for this item
    const newCartItem = {
      menuItem: cartData.menuItem,
      addons: cartData.selectedAddons,
      preferences: cartData.selectedPreference,
      data: {
        id: item.id,
        quantity: parseFloat(cartData.overallQty),
        amount: parseFloat(cartData.totalPrice),
        addons: returnNull(JSON.stringify(cartData.selectedAddons)),
        preferences: returnNull(JSON.stringify(cartData.selectedPreference)),
        specialInstruction: returnNull(cartData.instructions),
        orderForFriend: item.orderForFriend,
        friendName: returnNull(item.friendName),
        friendPhoneNumber: returnNull(item.friendPhoneNumber),
        deliveryOption: returnNull(item.deliveryOption),
        deliveryTime: returnNull(item.deliveryTime),
        deliveryAddress: returnNull(item.deliveryAddress),
        createdBy: item.createdBy,
        branchId: item.branchId,
        createdAt: item.createdAt,
        menuitemid: item.menuitemid,
        status: item.status,
        updatedAt: item.updatedAt,
      },
    };

    // make the id of the current item null
    for (let cartItem of cartItems) {
      if (cartItem.data.id === item.id) {
        cartItem.data.id = null;
      }
    }

    // make API call to create Cart
    axios({ url, method, headers, data })
      .then(() => {
        // add item to the cart by adding to the cart array
        setCartItems([...cartItems, newCartItem]);

        setLoading("successful");

        setLoading(false);
        cartData.closePopup(false);
      })
      .catch((error) => {
        // return the id to the initial id if the update was not successful
        for (let cartItem of cartItems) {
          if (cartItem.data.id === null) {
            cartItem.data.id = data.cartId;
          }
        }
        error = new Error();
        setLoading("failed");
      });
  };

  const removeCartItem = (cartId, cartItems, setCartItems, userId) => {
    setLoading("processing");

    // get config for creating Cart
    const method = "delete",
      url = `${BASE_URL}orders/cart/`,
      data = { cartId, userId };

    // make API call to create Cart
    axios({ url, method, headers, data })
      .then(() => {
        removeFromCart(cartId, cartItems, setCartItems);
        setLoading("successful");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  return (
    <CartContext.Provider
      value={{
        cartLoading,
        addCartItem,
        editCartItem,
        removeCartItem,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}
