import React, { useContext } from "react";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import LoadingFeedback from "../../../helpers/LoadingFeedback";
import { getDatesSelected } from "../../../helpers/MenuPlanHelper";
import EachDay from "./EachDay/EachDay";
import SelectMT from "./SelectMT/SelectMT";

export default function AddMPDetailsForm(props) {
  // context
  const { MPBasicInfo } = useContext(HelpersContext);
  const planDetails = MPBasicInfo.menuPlan;

  const allDays = getDatesSelected(planDetails.startDate, planDetails.endDate);

  return (
    <main id="add_mp_details">
      {MPBasicInfo ? (
        <>
          {/* top */}
          <header id="add_mp_details_header">
            <h1>{MPBasicInfo.planName.toUpperCase()}</h1>

            <aside>
              <SelectMT />
            </aside>
          </header>

          {allDays.map((day) => (
            <EachDay day={day} />
          ))}
        </>
      ) : (
        <LoadingFeedback message="Retrieving Meal Plan Details. Please Wait or Try Returning Later..." />
      )}
    </main>
  );
}
