import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextField, Button, Dialog, DialogTitle } from "@material-ui/core/";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { MenuPlanCategoryContext } from "../../../ContextAPI/MenuPlanCategoryContext";
import ImageInput from "../MPCategoryMGT/ImageInput";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";

export default function AddMenuPlanCategory(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div id="add_menu_plan_category_popup">
      {/* popup button */}
      <Button onClick={handleClickOpen()}>Add</Button>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Add Menu Plan Category
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <AddMenuPlanCategoryForm
              category={props.category}
              setCategory={props.setCategory}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function AddMenuPlanCategoryForm(props) {
  const { addMenuPlanCategory } = useContext(MenuPlanCategoryContext);
  const { imgBase64 } = useContext(HelpersContext);

  //   initial state
  const [name, setName] = useState(props.category);

  // yup schema for validation
  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    addMenuPlanCategory(data, imgBase64, props.setCategory);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* menu Image */}
      <section>
        <ImageInput />
      </section>

      {/* name */}
      <section>
        <h4>Name</h4>
        <TextField
          fullWidth
          variant="outlined"
          name="name"
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ""}
          inputRef={register}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </section>

      {/* button */}
      <section className="form-btn">
        <Button type="submit">Add</Button>
      </section>
    </form>
  );
}
