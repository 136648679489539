import React, { useContext, useEffect } from "react";
import { FormGroup, Grid } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import LoadingFeedback from "../../../../../helpers/LoadingFeedback";
import { HelpersContext } from "../../../../../../ContextAPI/HelpersContext";
import {
  addAddonsQty,
  getCustomerQtyAndPrice,
  setupSelectedAddons,
  subtractAddonsQty,
} from "../../../HelperFunctions";
import NumberFormaterPrefix from "../../../../../helpers/NumberFormaterPrefix";

export default function AddonsPreferences({ content, items, loading }) {
  return (
    <>
      {loading === "processing" ? (
        <LoadingFeedback message={`Searching For ${content}...`} />
      ) : loading === "found" && items.length > 0 ? (
        items.map((item) => (
          <>
            <Content content={content} itemRetrieved={item} />
          </>
        ))
      ) : loading === "found" && items.length <= 0 ? (
        <LoadingFeedback message={`Search Completed But No ${content} Found`} />
      ) : loading === "failed" ? (
        <LoadingFeedback message="An Error Occured. Check your Connection" />
      ) : (
        ""
      )}
    </>
  );
}

function Content({ content, itemRetrieved }) {
  const {
    checked,
    setChecked,
    checkedItemValue,
    setCheckedItemValue,
    customerMPAddons,
    setCustomerMPAddons,
    addonOrPreference,
    setAddonOrPreference,
    customerMPPreferences,
    setCustomerMPPreferences,
  } = useContext(HelpersContext);

  useEffect(() => {
    if (addonOrPreference === "PREFERENCE") {
      setupSelectedAddons(
        checked,
        checkedItemValue,
        customerMPPreferences,
        setCustomerMPPreferences
      );
    }

    if (addonOrPreference === "ADDON") {
      setupSelectedAddons(
        checked,
        checkedItemValue,
        customerMPAddons,
        setCustomerMPAddons
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, checkedItemValue]);

  // get the price and quantity of an addon or preference that has been selected
  // this will update the UI with the selected item quantity not the item returned from the DB
  const priceAndQty = getCustomerQtyAndPrice(itemRetrieved, [
    ...customerMPAddons,
    ...customerMPPreferences,
  ]);

  // name of item
  const itemName =
    content === "PREFERENCE"
      ? itemRetrieved.Preference.name
      : itemRetrieved.Inventory.itemName;

  // price of item
  const price =
    content === "PREFERENCE"
      ? itemRetrieved.Preference.unitPrice
      : priceAndQty
      ? priceAndQty.price
      : "";

  return (
    <>
      <Grid
        item
        xs={content === "PREFERENCE" ? 6 : 4}
        sm={content === "PREFERENCE" ? 6 : 4}
        md={content === "PREFERENCE" ? 6 : 4}
        lg={content === "PREFERENCE" ? 6 : 4}
      >
        <FormGroup aria-label="position">
          <FormControlLabel
            value={itemRetrieved}
            control={
              <Checkbox
                color="primary"
                onClick={(e) => {
                  setChecked(e.target.checked);
                  setCheckedItemValue(itemRetrieved);
                  setAddonOrPreference(
                    content === "PREFERENCE" ? "PREFERENCE" : "ADDON"
                  );
                }}
              />
            }
            label={itemName}
            labelPlacement="end"
          />
        </FormGroup>
      </Grid>

      {content === "PREFERENCE" ? (
        ""
      ) : (
        <Grid item xs={4} sm={4} md={4} lg={4} align="center" id="qty">
          {priceAndQty && priceAndQty.isSelected ? (
            <p>
              <b
                onClick={() =>
                  subtractAddonsQty(
                    itemRetrieved.id,
                    customerMPAddons,
                    setCustomerMPAddons
                  )
                }
              >
                <FaMinusCircle />
              </b>
              <span>{priceAndQty ? priceAndQty.qty : ""}</span>
              <b
                onClick={() =>
                  addAddonsQty(
                    itemRetrieved.id,
                    customerMPAddons,
                    setCustomerMPAddons
                  )
                }
              >
                <FaPlusCircle />
              </b>
            </p>
          ) : (
            ""
          )}
        </Grid>
      )}

      <Grid
        item
        xs={content === "PREFERENCE" ? 6 : 4}
        sm={content === "PREFERENCE" ? 6 : 4}
        md={content === "PREFERENCE" ? 6 : 4}
        lg={content === "PREFERENCE" ? 6 : 4}
        align="right"
      >
        {priceAndQty && priceAndQty.isSelected ? (
          <p>{price ? <NumberFormaterPrefix value={price} /> : "₦0.00"}</p>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
}
