import React from "react";
import CreateInventoryForm from "../../../components/adminComponents/Inventory/CreateInventory/CreateInventoryForm";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";

export default function CreateInventory() {
  return (
    <>
      <DashboardFrame
        pageHeading="Inventory"
        dashboardContent={<CreateInventoryForm />}
      />
    </>
  );
}
