import React, { useContext, useEffect } from "react";
import { TextField, CircularProgress, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CustomerContext } from "../../../../ContextAPI/CustomerContext";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";

export default function CustomerSearch(props) {
  // props
  const getOrders = props.getOrders;

  // context
  const { searchCustomers, customerLoading, customers } =
    useContext(CustomerContext);
  const { setCustomer, page, customerId, setCustomerId } =
    useContext(HelpersContext);

  // show what is typed in the input field and make search
  const filterCustomer = (value) => {
    setCustomer(value);
    searchCustomers(value);
  };

  const searchMPOrder = (value) => {
    if (value) {
      setCustomerId(value.id);
      setCustomer(value.fullName + " (" + value.phoneNumber + ")");
      getOrders(value.id, page);
    }
  };

  // execute filterFunction page changes
  useEffect(() => {
    getOrders(customerId, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Grid
      item
      xs={4}
      sm={4}
      md={4}
      lg={4}
      className="filter_forms"
      id="search_customer"
    >
      <Autocomplete
        id="filter_customer"
        options={customers}
        getOptionLabel={(option) =>
          option.fullName + " (" + option.phoneNumber + ")"
        }
        onChange={(event, value) => searchMPOrder(value)}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Enter Customer Name"
            variant="outlined"
            onChange={(e) => filterCustomer(e.target.value)}
            autoFocus
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {customerLoading === "processing" ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Grid>
  );
}
