import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion, Grid } from "@material-ui/core/";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddonsPreferences from "./AddonsPreferences";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function ItemPopupAccordion({ content, items, loading }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        {/* header */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{content}</Typography>
        </AccordionSummary>

        {/* body */}
        <AccordionDetails>
          <Grid container id="addons_preferences_content">
            <AddonsPreferences
              content={content}
              items={items}
              loading={loading}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
