import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import MenuPlanOrdersTable from "../../../components/adminComponents/Sales/MenuPlanOrders/MenuPlanOrdersTable";
import SettingsFrame from "../../../components/adminComponents/Settings/SettingsFrame";

export default function MenuPlanOrders() {
  return (
    <main className="table_page_frame">
      <DashboardFrame
        pageHeading="Sales"
        dashboardContent={
          <SettingsFrame
            firstLabel="Menu Plan Order"
            firstContent={<MenuPlanOrdersTable />}
          />
        }
      />
    </main>
  );
}
