import React from "react";
import CreatePromotionForm from "../../components/adminComponents/CreatePromotion/CreatePromotionForm";
import DashboardFrame from "../../components/adminComponents/DashboardFrame";

export default function CreatePromotion() {
  return (
    <>
      <DashboardFrame pageHeading="Promotion" dashboardContent={<CreatePromotionForm />} />
    </>
  );
}
