// external imports
import React, { useState, useContext } from "react";
import { Grid, TextField, Button } from "@material-ui/core/";
import InputAdornment from "@material-ui/core/InputAdornment";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// internal imports
import { InventoryCategoryContext } from "../../../../ContextAPI/InventoryCategoryContext";
import { InventoryContext } from "../../../../ContextAPI/InventoryContext";
import FeedbackPopup from "../../../helpers/FeedbackPopup";
import {
  toggleShowCategories,
  updateCategory,
} from "../../../helpers/InventoryCategoryHelpers";
import { searchItems } from "../../MPCategoryMGT/HelperFunction";

export default function CreateInventoryForm() {
  // context
  const {
    inventoryCategories,
    addInventoryCategory,
    showCategories,
    setShowCategories,
  } = useContext(InventoryCategoryContext);
  const { addInventory, inventoryLoading } = useContext(InventoryContext);

  // setup form fields
  const [itemName, setItemName] = useState(null);
  const [unitPrice, setUnitPrice] = useState(null);
  const [holding, setHolding] = useState(null);
  const [description, setDescription] = useState(null);
  const [inventoryCategory, setInventoryCategory] = useState(null);
  const [restockLevel, setRestockLevel] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [filteredCategory, setFilteredCategory] = useState([]);

  // yup schema for validation
  const schema = yup.object().shape({
    itemName: yup.string().required(),
    unitPrice: yup.number().required(),
    holding: yup.string().required(),
    description: yup.string().required(),
    inventoryCategory: yup.string().required(),
    quantity: yup.number().required(),
    restockLevel: yup.number().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    addInventory(data);
  };

  // createInventoryCategory
  const createInventoryCategory = (newCategory) => {
    addInventoryCategory(newCategory.toLowerCase());
  };

  return (
    <main id="create_inventory">
      <form onSubmit={handleSubmit(onSubmit)} className="form_frame">
        <h1>Create Inventory</h1>

        <Grid container>
          {/* holding unit ID */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h4>Holding Unit</h4>
            <TextField
              fullWidth
              variant="outlined"
              name="holding"
              error={!!errors.holding}
              helperText={errors.holding ? errors.holding.message : ""}
              inputRef={register}
              value={holding}
              onChange={(e) => setHolding(e.target.value)}
            />
          </Grid>

          {/* Item Name */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h4>Item Name</h4>
            <TextField
              fullWidth
              variant="outlined"
              name="itemName"
              error={!!errors.itemName}
              helperText={errors.itemName ? errors.itemName.message : ""}
              inputRef={register}
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h4>Description</h4>
            <TextField
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              name="description"
              error={!!errors.description}
              helperText={errors.description ? errors.description.message : ""}
              inputRef={register}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>

          {/* Price Per Unit */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h4>Price Per Unit</h4>
            <TextField
              fullWidth
              variant="outlined"
              name="unitPrice"
              error={!!errors.unitPrice}
              helperText={errors.unitPrice ? errors.unitPrice.message : ""}
              inputRef={register}
              value={unitPrice}
              onChange={(e) => setUnitPrice(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">NGN</InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* Add Category */}
          <Grid item xs={12} sm={12} md={6} lg={6} className="category-input">
            <h4>Add Category</h4>
            <TextField
              variant="outlined"
              placeholder="Search Category"
              name="inventoryCategory"
              error={!!errors.inventoryCategory}
              helperText={
                errors.inventoryCategory ? errors.inventoryCategory.message : ""
              }
              inputRef={register}
              value={inventoryCategory}
              onChange={(e) => setInventoryCategory(e.target.value)}
              onKeyUp={(e) =>
                searchItems(
                  e.target.value,
                  inventoryCategories,
                  setFilteredCategory,
                  true
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() =>
                      toggleShowCategories(showCategories, setShowCategories)
                    }
                  >
                    <KeyboardArrowDownIcon />
                  </InputAdornment>
                ),
              }}
            />

            {/* add button */}
            <span className="category-add-btn">
              <Button
                onClick={() => createInventoryCategory(inventoryCategory)}
              >
                Add
              </Button>
            </span>

            {/* category dropdown */}
            {/* show all categories */}
            <div className="form-dropdown">
              {showCategories ? (
                inventoryCategories &&
                inventoryCategories.length > 0 &&
                inventoryCategories.map((result) => (
                  <p
                    key={result.id}
                    onClick={() =>
                      updateCategory(
                        result.name,
                        setInventoryCategory,
                        showCategories,
                        setShowCategories,
                        setFilteredCategory
                      )
                    }
                  >
                    {result.name.toUpperCase()}
                  </p>
                ))
              ) : (
                <>
                  {/* show only search text */}
                  {filteredCategory &&
                    filteredCategory.length > 0 &&
                    filteredCategory.map((result) => (
                      <p
                        key={result}
                        onClick={() => setInventoryCategory(result.name)}
                      >
                        {result.name.toUpperCase()}
                      </p>
                    ))}
                </>
              )}
            </div>
          </Grid>

          {/* Quantity */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h4>Quantity (Unit)</h4>
            <TextField
              fullWidth
              variant="outlined"
              name="quantity"
              error={!!errors.quantity}
              helperText={errors.quantity ? errors.quantity.message : ""}
              inputRef={register}
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">No. of unit</InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* Set Restock Level */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h4>Restock Level</h4>
            <TextField
              fullWidth
              variant="outlined"
              name="restockLevel"
              error={!!errors.restockLevel}
              helperText={
                errors.restockLevel ? errors.restockLevel.message : ""
              }
              inputRef={register}
              value={restockLevel}
              onChange={(e) => setRestockLevel(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">No. of unit</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {/* feedback popup */}
        <FeedbackPopup
          condition={inventoryLoading}
          additionalMessage="Page Reloads in a moment..."
        />

        {/* button */}
        <section className="form-btn">
          <Button type="submit">Create</Button>
        </section>
      </form>
    </main>
  );
}
