import React, { useContext, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core/";
import { UtilsContext } from "../../../../ContextAPI/UtilsContext";
import moment from "moment";
import FeedbackPopup from "../../../helpers/FeedbackPopup";
import VerifyPaymentBtn from "./VerifyPaymentBtn";

export default function PaymentDetails({ order }) {
  // context
  const { getPayment, payment, loadingPay } = useContext(UtilsContext);

  useEffect(() => {
    getPayment(order.orderId, "ORDER");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid id="dish_details">
      <Grid>
        <h2>Payment Details</h2>
      </Grid>

      {payment && payment.length
        ? payment.map((details, index) => (
            <PaymentDetailsBody details={details} index={index} />
          ))
        : "No Payments Found!"}

      <FeedbackPopup
        condition={loadingPay}
        message={"Payments retrieved successfully!"}
      />
    </Grid>
  );
}

function PaymentDetailsBody({ details, index }) {
  return (
    <>
      <Grid>
        <h3>ATTEMPT {index + 1}</h3>
      </Grid>

      <Grid container>
        <Grid item xs={10} sm={10} md={10} lg={10} className="dish_table">
          <TableContainer key={index}>
            <Table aria-label="a dense table">
              {/* table head */}
              <TableHead>
                <TableRow>
                  <TableCell>No Of Attempts</TableCell>
                  <TableCell>No Of Errors</TableCell>
                  <TableCell>PAYMENT GATEWAY</TableCell>
                  <TableCell>Status:</TableCell>
                  <TableCell>Transaction ID</TableCell>
                  <TableCell>Transaction Ref</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>

              {/* table body */}
              <TableBody>
                <TableRow key={index}>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>{details.gateWayType}</TableCell>
                  <TableCell>{details.status}</TableCell>
                  <TableCell>{details.id}</TableCell>
                  <TableCell>{details.paymentRef}</TableCell>
                  <TableCell>
                    {moment(details.createdAt).format("HH:mm:ss")}
                  </TableCell>
                  <TableCell>{moment(details.createdAt).format("L")}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={2} sm={2} md={2} lg={2} className="verify_btn">
          <VerifyPaymentBtn paymentRef={details.paymentRef} />
        </Grid>
      </Grid>
    </>
  );
}
