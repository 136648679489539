import React, { useContext, useEffect } from "react";
import { MenuItemContext } from "../../../ContextAPI/MenuItemContext";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import MealItems from "./ViewMenuItems/MealItems";
import { filterItems } from "../../helpers/MenuItemHelper";

export default function ViewMenuItemsContent() {
  // context
  const { allMenuItems, gettingAllMT, getAllMenuItems } =
    useContext(MenuItemContext);
  const { searchResult, search, page } = useContext(HelpersContext);

  const items = filterItems(allMenuItems, true);

  useEffect(() => {
    getAllMenuItems(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <MealItems
      items={items}
      gettingAllMT={gettingAllMT}
      searchResult={searchResult}
      search={search}
    />
  );
}
