import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { timeLimitConvertion } from "../../../../helpers/MenuPlanHelper";

const DeliveryDateTime = ({ detail }) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={4} id="delivery_time">
        <h3>{moment(detail.deliveryDate).format("ddd, MMM Do YYYY")}</h3>
        
        <section>
          <p>
            {timeLimitConvertion(detail.deliveryTime)}
            <span> ({detail.quantity}x)</span>
          </p>
        </section>
      </Grid>
    </>
  );
};

export default DeliveryDateTime;
