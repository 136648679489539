import React, { useContext, useEffect } from "react";
import { MenuItemContext } from "../../../../ContextAPI/MenuItemContext";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import MealItems from "./MealItems";
import { filterItems } from "../../../helpers/MenuItemHelper";

export default function InActiveItems() {
  // context
  const { allMenuItems, gettingAllMT, getAllMenuItems } =
    useContext(MenuItemContext);
  const { searchResult, search, page } = useContext(HelpersContext);
  console.log(allMenuItems);

  const items = filterItems(allMenuItems, false);

  useEffect(() => {
    getAllMenuItems(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <MealItems
      items={items}
      gettingAllMT={gettingAllMT}
      searchResult={searchResult}
      search={search}
    />
  );
}
