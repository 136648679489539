import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import EditMPCategory from "./EditMPCategory";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function EditForm(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        {/* toggle buttons */}
        <AccordionSummary
          expandIcon={<>&#9997;</>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {props.headerComponent}
        </AccordionSummary>

        {/* edit form */}
        <AccordionDetails>
          <EditMPCategory item={props.item} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
