import React from "react";
import RecipeTable from "../../../pages/admin/CustomerRecipe/RicepeTable";
import GeneralInfo from "./ManageDetails/GeneralInfo";
import Body from "./ManageDetails/Body";
import { useContext } from "react";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import { useEffect } from "react";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import { CreateAndEarnContext } from "../../../ContextAPI/CreateAndEarn";

function Content() {
  const { currentCreateAndEarnRecipe, setCurrentCreateAndEarnRecipe } =
    useContext(HelpersContext);

  useEffect(() => {
    setCurrentCreateAndEarnRecipe(
      JSON.parse(localStorage.getItem("createAndEarnRecipe"))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {currentCreateAndEarnRecipe ? (
        <>
          <div id="view_update_customer_recipe_details">
            <GeneralInfo recipe={currentCreateAndEarnRecipe} />
            <Body recipe={currentCreateAndEarnRecipe} />
          </div>
        </>
      ) : (
        "Details Not Set!"
      )}
    </>
  );
}

const ManageDetails = () => {
  const { updating } = useContext(CreateAndEarnContext);

  return (
    <>
      <RecipeTable table={<Content />} title="Details" />
      <FeedbackPopup condition={updating} additionalMessage="" />
    </>
  );
};

export default ManageDetails;
