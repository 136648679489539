import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextField, Button } from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { MenuItemCategoryContext } from "../../../../ContextAPI/MenuItemCategoryContext";
import { validateImage } from "../../../helpers/MenuItemHelper";
import ImageUploadProgress from "../../../helpers/ImageUploadProgress";

export default function AddCategory(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div id="add_category_popup">
      {/* popup button */}
      <Button onClick={handleClickOpen()}>Add</Button>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <AddCategoryForm
              itemCategoryName={props.itemCategoryName}
              selectedItemCategory={props.selectedItemCategory}
              setSelectedItemCategory={props.setSelectedItemCategory}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function AddCategoryForm(props) {
  const { addCategory, uploadPercentage } = useContext(MenuItemCategoryContext);

  const selectedItemCategory = props.selectedItemCategory;
  const setSelectedItemCategory = props.setSelectedItemCategory;

  const [name, setName] = useState(props.itemCategoryName);
  const [description, setDescription] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageCheck, setImageCheck] = useState(false);

  // yup schema for validation
  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    addCategory(
      data,
      selectedItemCategory,
      setSelectedItemCategory,
      imageUrl,
      imageCheck
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* menu Image */}
      <section>
        <h4>Add Image</h4>
        <TextField
          fullWidth
          variant="outlined"
          type="file"
          name="imageUrl"
          error={
            imageCheck === "setType" ||
            imageCheck === "setSize" ||
            imageCheck === "empty"
          }
          helperText={
            imageCheck === "setType"
              ? "You have not selected an Image"
              : imageCheck === "setSize"
              ? "Image Size cannot be more than 100kb"
              : imageCheck === "empty"
              ? "No Image Selected"
              : ""
          }
          inputRef={register}
          defaultValue={imageUrl}
          onChange={(e) => setImageUrl(e.target.files[0])}
          onBlur={() => validateImage(imageUrl, setImageCheck)}
        />
      </section>

      {/* name */}
      <section>
        <h4>Name</h4>
        <TextField
          fullWidth
          variant="outlined"
          name="name"
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ""}
          inputRef={register}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </section>

      {/* Description */}
      <section>
        <h4>Description</h4>
        <TextField
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          name="description"
          error={!!errors.description}
          helperText={errors.description ? errors.description.message : ""}
          inputRef={register}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </section>

      {/* progress bar */}
      <ImageUploadProgress progress={uploadPercentage} />

      {/* button */}
      <section className="form-btn">
        <Button type="submit">Add</Button>
      </section>
    </form>
  );
}
