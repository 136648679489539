import React from "react";
import NumberFormat from "react-number-format";

export default function NumberFormaterSuffix(props) {
  return (
    <NumberFormat
      value={props.value}
      displayType={"text"}
      thousandSeparator={true}
      suffix={" NGN"}
    />
  );
}
