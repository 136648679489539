import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { CgMoreVertical } from "react-icons/cg";
import StatusBtn from "../../RecipeOrder/StatusBtn";
import CancelOrder from "../../RecipeOrder/Cancel";
import KitchenSlip from "../../RecipeOrder/PrintSlip/KitchenSlip";

export default function Actions({
  viewDetails,
  printDetails,
  order,
  showStatusBtn,
  showKitchen,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const status = order ? order.status : "";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <CgMoreVertical />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {viewDetails}
        {printDetails}
        {showKitchen ? <KitchenSlip order={order} /> : ""}
        {showStatusBtn ? (
          <>
            <StatusBtn location={"onTable"} order={order} />
            {status === "Delivered" || status === "Cancelled" ? (
              ""
            ) : (
              <CancelOrder order={order} />
            )}
          </>
        ) : (
          ""
        )}
      </Menu>
    </div>
  );
}
