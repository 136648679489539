import React, { useContext } from "react";
import { Grid, Button } from "@material-ui/core/";
import EachCartMeal from "./EachCartMeal";
import AddCustomer from "./AddCustomer";
import LoadingFeedback from "../../helpers/LoadingFeedback";
import { getCartTotal } from "../../helpers/POSOrderHelper";
import TableDetails from "./TableDetails";
import POSContact from "./POSContact";
import { OrderContext } from "../../../ContextAPI/OrderContext";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import { AiOutlineArrowRight } from "react-icons/ai";
import { toggleShowItems } from "../../helpers/MenuItemHelper";

export default function AddToCart(props) {
  // context
  const { createOrder, isCreating } = useContext(OrderContext);

  // props
  const cartItems = props.cartItems;
  const setCartItems = props.setCartItems;
  const tableDetails = props.tableDetails;
  const setTableDetails = props.setTableDetails;
  const customer = props.customer;

  const cartTotal = getCartTotal(cartItems);

  return (
    <aside id="add_to_cart">
      {/* header */}
      <section id="add_to_cart_header">
        <h3>ADD TO CART</h3>
        <h3 onClick={() => toggleShowItems(props.showCart, props.setShowCart)}>
          <span>Hide</span> <AiOutlineArrowRight />
        </h3>
      </section>

      {/* customer details */}
      <div id="add_to_cart_customer">
        {/* customer */}
        <section id="add_customer_wrapper">
          <AddCustomer
            customer={customer}
            setCustomer={props.setCustomer}
            cartItems={cartItems}
          />

          <p>Customer</p>
        </section>

        {/* friend */}
        <section id="order_for_friend_wrapper">
          {tableDetails ? (
            <header>
              <POSContact tableDetails={tableDetails} />
              <aside
                id="remove_friend"
                onClick={() => {
                  setTableDetails(null);
                }}
              >
                <p>X</p>
              </aside>
            </header>
          ) : (
            <TableDetails setTableDetails={setTableDetails} />
          )}
          <p>Table Details</p>
        </section>
      </div>

      {/* meal details */}
      <main id="add_to_cart_meals">
        {cartItems.length > 0 ? (
          cartItems.map((item) => (
            <EachCartMeal
              cartItem={item}
              cartItems={cartItems}
              setCartItems={setCartItems}
            />
          ))
        ) : (
          <LoadingFeedback message="Add Meal to the Cart &#128522;" />
        )}
      </main>

      {/* total */}
      <section id="add_to_cart_total">
        <p>Total</p>
        <p>{cartTotal.toFixed(2)} NGN</p>
      </section>

      <FeedbackPopup
        condition={isCreating}
        additionalMessage="Page Reloads in a moment..."
      />

      {/* button */}
      <Grid container id="add_to_cart_btns">
        <Grid item xs={12} sm={12} md={12} lg={12} className="form-btn">
          <Button
            id="save_btn"
            onClick={() =>
              createOrder({ cartTotal, cartItems, tableDetails, customer })
            }
          >
            &#128525; PLACE ORDER &#128536;
          </Button>
        </Grid>
      </Grid>
    </aside>
  );
}
