import React, { useContext } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core/";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import MealPlan from "../../MealPlanMGT/ViewMealPlans/MealPlan";
import { MPManagementContext } from "../../../../ContextAPI/MPManagementContext";
import LoadingFeedback from "../../../helpers/LoadingFeedback";
import ItemPopup from "./ItemPopup";
import Heading from "./Heading";
import { IoFastFoodOutline } from "react-icons/io5";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function PlanPopup({ mealPlan }) {
  // context
  const { getUniqueMPMealsById } = useContext(MPManagementContext);

  // states
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");

    // get the meals in the plan
    getUniqueMPMealsById(mealPlan.id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={2} className="">
      <section onClick={handleClickOpen()}>
        <MealPlan mealPlan={mealPlan} isEdit={false} />
      </section>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"md"}
        scroll={scroll}
        disableBackdropClick={true}
        TransitionComponent={Transition}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <Heading
            mealPlan={mealPlan}
            closePopup={handleClose}
            icon={<IoFastFoodOutline />}
          />
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Content />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

function Content() {
  // context
  const { MPDetails, gettingMPDetails } = useContext(MPManagementContext);

  return (
    <>
      {/* menu items */}
      <Grid container id="view_mwnu_items">
        {/* display menu items here */}
        {gettingMPDetails === "processing" ? (
          <LoadingFeedback message="Searching For Menu Items..." />
        ) : gettingMPDetails === "found" && MPDetails.length > 0 ? (
          MPDetails.map((result) => <ItemPopup menuItem={result} />)
        ) : gettingMPDetails === "found" && MPDetails.length <= 0 ? (
          <LoadingFeedback message="Search Completed But No Menu Item Found" />
        ) : gettingMPDetails === "failed" ? (
          <LoadingFeedback message="An Error Occured. There might be no items available. Alse, Check your Connection" />
        ) : (
          ""
        )}
      </Grid>
    </>
  );
}
