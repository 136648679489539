import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import EditMenuItemForm from "../../../components/adminComponents/MenuItemsMGT/EditMenuItemForm";

export default function EditMenuItem(props) {
  const menuItem = props.location.state;

  return (
    <>
      <DashboardFrame
        pageHeading="Manage Menu Items"
        dashboardContent={<EditMenuItemForm menuItem={menuItem} />}
      />
    </>
  );
}
