import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core/";
import { AiOutlinePlus } from "react-icons/ai";
import moment from "moment";
import MenuPlanDays from "./MenuPlanDays";

export default function MenuPlanEachDay(props) {
  let [numberOfMoreMeal, setNumberOfMoreMeal] = useState(1);

  // add more meal space for a day
  const moreMealArray = [];
  for (let moreMeal = 1; moreMeal <= numberOfMoreMeal; moreMeal++) {
    moreMealArray.push(
      <MenuPlanDays
        row={moreMeal}
        day={moment(props.day).format("(Do MMMM, YYYY)")}
        planData={props.planData}
        submissionDate={props.day}
        allDetails={props.allDetails}
        setAllDetails={props.setAllDetails}
      />
    );
  }

  return (
    <>
      <section id="each-day-menu">
        {/* date */}
        <header>
          <h3>{moment(props.day).format("dddd")}</h3>
          <p>{moment(props.day).format("(Do MMMM, YYYY)")}</p>
        </header>

        <main>
          {/* time of days headings */}
          <Grid container id="time_of_day">
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <section>
                <h3>Morning</h3>
              </section>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <section>
                <h3>Afternoon</h3>
              </section>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <section>
                <h3>Evening</h3>
              </section>
            </Grid>
          </Grid>

          {/* spaces to add meal for each time of day */}
          <Grid container align="center" id="each_time_day">
            {moreMealArray}
          </Grid>

          <Grid container>
            {/* add more meal button section */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="add_more_meal_btn_container"
            >
              <Button onClick={() => setNumberOfMoreMeal(numberOfMoreMeal++)}>
                <span>
                  <AiOutlinePlus />
                </span>
                Add More Meal
              </Button>
            </Grid>
          </Grid>
        </main>
      </section>
    </>
  );
}
