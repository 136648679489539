// external imports
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  InputAdornment,
} from "@material-ui/core/";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  toggleShowCategories,
  updateCategory,
} from "../../helpers/InventoryCategoryHelpers";

// local imports
import AddMenuPlanCategory from "./AddMenuPlanCategory";
import { MenuPlanCategoryContext } from "../../../ContextAPI/MenuPlanCategoryContext";
import { MenuPlanContext } from "../../../ContextAPI/MenuPlanContext";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import ImageInput from "../MPCategoryMGT/ImageInput";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import { searchItems } from "../MPCategoryMGT/HelperFunction";

export default function CreateMenuPlanForm() {
  const history = useHistory();

  // context
  const { menuPlanCategories } = useContext(MenuPlanCategoryContext);
  const { addMenuPlan, menuPlanLoading } = useContext(MenuPlanContext);
  const { imgBase64 } = useContext(HelpersContext);

  // set initial states
  const [isActive, setIsActive] = useState(true);
  const [planName, setPlanName] = useState(null);
  const [category, setCategory] = useState(null);
  const [description, setDescription] = useState(null);
  const [showCategories, setShowCategories] = useState(null);
  const [filteredCategory, setFilteredCategory] = useState([]);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  // yup schema for validation
  const schema = yup.object().shape({
    planName: yup.string().required(),
    description: yup.string().required(),
    category: yup.string().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    addMenuPlan(data, imgBase64, state, isActive, history);
  };

  return (
    <form
      id="create_menu_plan_form"
      className="form_frame"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h1>Create Menu Plan</h1>

      <Grid container>
        {/* Upload photo */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ImageInput />
        </Grid>

        {/* menu plan name */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <h4>Menu plan name</h4>
          <TextField
            fullWidth
            variant="outlined"
            name="planName"
            error={!!errors.planName}
            helperText={errors.planName ? errors.planName.message : ""}
            inputRef={register}
            value={planName}
            onChange={(e) => setPlanName(e.target.value)}
          />
        </Grid>

        {/* Description */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <h4>Description</h4>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            name="description"
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ""}
            inputRef={register}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>

        {/* Add catgory */}
        <Grid item xs={12} sm={12} md={6} lg={6} id="menu_category_input">
          <h4>Add Category</h4>
          <Grid container>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <TextField
                variant="outlined"
                placeholder="Search Item Category"
                name="category"
                fullWidth
                error={!!errors.category}
                helperText={errors.category ? errors.category.message : ""}
                inputRef={register}
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                onKeyUp={(e) =>
                  searchItems(
                    e.target.value,
                    menuPlanCategories,
                    setFilteredCategory,
                    true
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() =>
                        toggleShowCategories(showCategories, setShowCategories)
                      }
                    >
                      <KeyboardArrowDownIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* add button */}
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <AddMenuPlanCategory
                category={category}
                setCategory={setCategory}
              />
            </Grid>
          </Grid>

          {/* category dropdown */}
          {/* show all categories */}
          <div className="form-dropdown">
            {showCategories ? (
              menuPlanCategories &&
              menuPlanCategories.length > 0 &&
              menuPlanCategories.map((result) => (
                <p
                  key={result.id}
                  onClick={() =>
                    updateCategory(
                      result.name,
                      setCategory,
                      showCategories,
                      setShowCategories,
                      setFilteredCategory
                    )
                  }
                >
                  {result.name.toUpperCase()}
                </p>
              ))
            ) : (
              <>
                {/* show only search text */}
                {filteredCategory &&
                  filteredCategory.length > 0 &&
                  filteredCategory.map((result) => (
                    <p key={result} onClick={() => setCategory(result.name)}>
                      {result.name.toUpperCase()}
                    </p>
                  ))}
              </>
            )}
          </div>
        </Grid>

        {/* calendar */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          id="menu_plan_calender"
        >
          <h4>Select A Range of Days</h4>
          <DateRangePicker
            onChange={(item) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
          />
        </Grid>

        {/* set isActive */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl component="fieldset" className="switch_input">
            <FormGroup aria-label="DisplayOnApp" row>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive}
                    color="primary"
                    onChange={(e) => setIsActive(e.target.checked)}
                  />
                }
                label="Active"
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      {/* feedback popup */}
      <FeedbackPopup
        condition={menuPlanLoading}
        additionalMessage="Redirects in a moment..."
      />

      {/* button */}
      <section className="form-btn">
        <Button type="submit">Create</Button>
      </section>
    </form>
  );
}
