// external imports
import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";
import { HelpersContext } from "./HelpersContext";
import { getUserIds } from "../components/adminComponents/Notification/Helper";
import { clearStatusTracker } from "./HelperFunctions";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let branchId = null;
let headers = {};

if (staff) {
  branchId = staff.branchId;
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
}

const BASE_URL = getBaseUrl();
const BASE_URL_2 = getBaseUrl(2);

// create Notification context to manage Notification here
export const NotificationContext = createContext();

export default function NotificationContextProvider(props) {
  // get details from session storage
  const createAndEarn = JSON.parse(sessionStorage.getItem("CreateAndEarn"));

  const {
    presentDay,
    nextDay,
    selectedCustomers,
    description,
    setDescription,
    setTitle,
    title,
    topic,
    setTopic,
  } = useContext(HelpersContext);

  // set initial state
  // const [checkNotifications, setCheckNotifications] = useState(0);

  // instant
  const [instantOrders, setInstantOrders] = useState(0);
  const [instantOrdersPage, setInstantOrdersPage] = useState(0);
  const [instantOrderNotify, setInstantOrderNotify] = useState(false);

  // meal plan
  const [MPOrders, setMPOrders] = useState(0);
  const [MPOrdersPage, setMPOrdersPage] = useState(0);
  const [MPOrderNotify, setMPOrderNotify] = useState(false);

  // recipe order
  const [recipesOrder, setRecipesOrder] = useState(0);
  const [recipesOrderPage, setRecipesOrderPage] = useState(0);
  const [recipeOrderNotify, setRecipeOrderNotify] = useState(false);

  // survey
  const [survey, setSurvey] = useState(0);
  const [surveyPage, setSurveyPage] = useState(0);
  const [surveyNotify, setSurveyNotify] = useState(false);

  // recipe
  const [recipes, setRecipes] = useState(0);
  const [recipesPage, setRecipesPage] = useState(0);
  const [recipeNotify, setRecipeNotify] = useState(false);

  const [sending, setSending] = useState(false);

  // setInterval(() => {
  //   const notificationRepeat = localStorage.getItem("notificationRepeat");

  //   if (!notificationRepeat) {
  //     localStorage.setItem("notificationRepeat", 0);
  //   }
  //   localStorage.setItem(
  //     "notificationRepeat",
  //     parseFloat(notificationRepeat === "NaN" ? 0 : notificationRepeat) + 1
  //   );
  //   setCheckNotifications(localStorage.getItem("notificationRepeat"));
  //   console.log(checkNotifications);
  // }, 180000);

  // check if there are updates
  const checkUpdate = (
    itemTotal,
    pageTotal,
    setOrderNotify,
    setOrdersCount,
    setOrdersPageCount,
    ordersCount,
    ordersPage
  ) => {
    // check if there are new orders
    if ("checkNotifications" > 1) {
      if (itemTotal > ordersCount || pageTotal > ordersPage) {
        setOrderNotify(true);
      } else {
        setOrderNotify(false);
      }
    }

    // update with the new order and page count
    setOrdersCount(itemTotal);
    setOrdersPageCount(pageTotal);
  };

  // get all orders for order management
  const getOrders = (
    url,
    setOrderNotify,
    setOrdersCount,
    setOrdersPageCount,
    ordersCount,
    ordersPage,
    count
  ) => {
    const method = "get";

    // get Orders
    axios({ url, method, headers })
      .then((result) => {
        const itemTotal =
          count === "count"
            ? result.data.data.count
            : result.data.data.totalItems;
        const pageTotal = count ? null : result.data.data.totalPages;

        checkUpdate(
          itemTotal,
          pageTotal,
          setOrderNotify,
          setOrdersCount,
          setOrdersPageCount,
          ordersCount,
          ordersPage
        );
      })
      .catch((error) => {
        error = new Error();
      });
  };

  // make API call to get all the Notifications
  useEffect(() => {
    const instantOrderUrl = `${BASE_URL}orders/menuitem/getOrdersByDate/?dateFrom=${presentDay}&dateTo=${nextDay}&page=1`;
    const MPOrderUrl = `${BASE_URL}orders/menuplan/getOrdersByDateV1/?dateFrom=${
      presentDay.split("T")[0]
    }&dateTo=${nextDay.split("T")[0]}&page=1`;
    const recipesOrderUrl = `${BASE_URL_2}recipes/deliverytime?branchId=${branchId}`;
    const surveyUrl = `${BASE_URL_2}mealsurvey?branchId=${branchId}`;
    const recipeUrl = `${BASE_URL_2}recipes/orders?branchId=${branchId}`;

    // instant
    getOrders(
      instantOrderUrl,
      setInstantOrderNotify,
      setInstantOrders,
      setInstantOrdersPage,
      instantOrders,
      instantOrdersPage
    );

    // mp
    getOrders(
      MPOrderUrl,
      setMPOrderNotify,
      setMPOrders,
      setMPOrdersPage,
      MPOrders,
      MPOrdersPage
    );

    // recipe order
    getOrders(
      recipesOrderUrl,
      setRecipeOrderNotify,
      setRecipesOrder,
      setRecipesOrderPage,
      recipesOrder,
      recipesOrderPage,
      "count"
    );

    // survey
    getOrders(
      surveyUrl,
      setSurveyNotify,
      setSurvey,
      setSurveyPage,
      survey,
      surveyPage
    );

    // recipe order
    getOrders(
      recipeUrl,
      setRecipeNotify,
      setRecipes,
      setRecipesPage,
      recipes,
      recipesPage,
      "count"
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ["checkNotifications"]);

  // send
  const sendNotification = () => {
    setSending("processing");

    // get config for API call
    const method = "post",
      url = `${BASE_URL_2}notification/many`,
      data = {
        userIds: createAndEarn
          ? createAndEarn.user.id
          : getUserIds(selectedCustomers),
        messageContent: {
          title,
          body: description,
        },
      };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setSending("successful");

        setTitle("");
        setDescription("");

        sessionStorage.clear();
        clearStatusTracker(setSending);
      })
      .catch((error) => {
        error = new Error();
        setSending("failed");

        clearStatusTracker(setSending);
      });
  };

  // send to all
  const sendToAll = () => {
    setSending("processing");

    // get config for API call
    const method = "post",
      url = `${BASE_URL_2}notification/topic`,
      data = {
        topic,
        messageContent: {
          title,
          body: description,
        },
      };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setSending("successful");

        setTitle("");
        setTopic("");
        setDescription("");

        clearStatusTracker(setSending);
      })
      .catch((error) => {
        error = new Error();
        setSending("failed");

        clearStatusTracker(setSending);
      });
  };

  return (
    <NotificationContext.Provider
      value={{
        instantOrderNotify,
        MPOrderNotify,
        recipeOrderNotify,
        surveyNotify,
        recipeNotify,
        sendNotification,
        sending,
        sendToAll,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
}
