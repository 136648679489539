import Completed from "../../../components/adminComponents/Survey/Completed";
import PendingTable from "../../../components/adminComponents/Survey/Pending";
import RecipeTable from "../CustomerRecipe/RicepeTable";

const Survey = () => {
  return (
    <>
      <RecipeTable
        table={<PendingTable />}
        title="Pending"
        pageHeading="Survey Management"
        title2="Completed"
        table2={<Completed />}
      />
    </>
  );
};

export default Survey;
