import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import EditInventoryForm from "../../../components/adminComponents/Inventory/EditInventory/EditInventoryForm";

export default function EditInventory(props) {
  const inventoryData = props.location.state;

  return (
    <>
      <DashboardFrame
        pageHeading="Inventory"
        dashboardContent={<EditInventoryForm inventoryData={inventoryData} />}
      />
    </>
  );
}
