import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import RetrievingMPDetailsContent from "../../../components/adminComponents/MPDetails/EditMPDetails/RetrievingMPDetailsContent";

export default function RetrievingMPDetails(props) {
  const planDetails = props.location.state;

  return (
    <>
      <DashboardFrame
        pageHeading="Menu Plan Details"
        dashboardContent={
          <RetrievingMPDetailsContent planDetails={planDetails} />
        }
      />
    </>
  );
}
