import React from "react";
import { selectBank } from "../../helpers/OrderHelpers";

export default function EachBankDetail(props) {
  const bank = props.bank;

  return (
    <div
      id="bank_options"
      onClick={() =>
        selectBank(
          bank,
          props.setSearch,
          props.setSelectedBank,
          props.setShowAllBank
        )
      }
    >
      <p>{bank.bankName}</p>
      <h3>{bank.accountName}</h3>
      <p>{bank.accountNumber}</p>
    </div>
  );
}
