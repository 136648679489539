import React from "react";

export default function PrintoutHeaderWithIMG() {
  return (
    <div className="printout_header_with_img">
      <img
        src="https://res.cloudinary.com/dw47bg27h/image/upload/v1627914561/OOMA_MAIN_copy_2_1_nwo7dy.png"
        alt="ooma logo"
        width="25px"
        height="25px"
      />
      <h2>OOMA KITCHEN</h2>
    </div>
  );
}
