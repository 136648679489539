import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import SpaceInBetweenLayout from "../../../helpers/SpaceInBetweenLayout";

export default function DeliveryDetails({ order }) {
  const recipeOrder = order.RecipeOrder;

  return (
    <>
      {" "}
      <Grid container className="order_details_down">
        <SpaceInBetweenLayout
          textA="Delivery area:"
          textB={recipeOrder.deliveryArea}
        />

        <SpaceInBetweenLayout
          textA="Date of delivery:"
          textB={moment(order.deliveryDate).format("Do MMM, YYYY")}
        />

        <SpaceInBetweenLayout
          textA="Time of delivery:"
          textB={order.deliveryTime}
        />

        <SpaceInBetweenLayout
          textA="Delivery method:"
          textB={recipeOrder.deliveryOption}
        />
      </Grid>
    </>
  );
}
