const getDeliveryDays = (data) => {
  let collected = [];

  for (const item of data) {
    collected.push(item.deliveryDate);
  }

  return [...new Set(collected)];
};

const getDeliveryTimes = (times, day) => {
  let collected = [];

  for (const time of times) {
    if (time.deliveryDate === day) {
      collected.push(time.deliveryTime);
    }
  }

  return collected
};

export { getDeliveryTimes, getDeliveryDays };
