import React from "react";
import ViewMenuItemsContent from "../../../components/adminComponents/MenuItemsMGT/ViewMenuItemsContent";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";

export default function ViewMenuItems() {
  return (
    <>
      <DashboardFrame
        pageHeading="Manage Menu Items"
        dashboardContent={<ViewMenuItemsContent />}
      />
    </>
  );
}
