import React from "react";
import { BiBell } from "react-icons/bi";
import SubmitButton from "../../CustomerMP/Create/itemPopup/SubmitButton";

export const Notify = ({recipe}) => {
  return (
    <>
      {" "}
      <SubmitButton
        icon={<BiBell />}
        label="Send notification"
        altClass={"alt_class"}
        submitFunction={() => {
          sessionStorage.setItem(
            "CreateAndEarn",
            JSON.stringify({
              fromCreateAndEarn: true,
              user: recipe.createdByDetail,
              notificationTitle: "NOT APPROVED",
            })
          );
          window.location.href = "/send-notification";
        }}
      />
    </>
  );
};
