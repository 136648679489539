// external imports
import React, { useState, useContext } from "react";
import { TextField, Button, Grid } from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";
import { InventoryContext } from "../../../../ContextAPI/InventoryContext";
import FeedbackPopup from "../../../helpers/FeedbackPopup";

export default function AddOrEditInventory(props) {
  // get data from manageInventoryTable component
  const inventoryData = {
    inventoryId: props.inventoryId,
    itemName: props.itemName,
    holding: props.holding,
    inventoryCategory: props.InventoryCategory,
    unitPrice: props.unitPrice,
    quantity: props.quantity,
    restockLevel: props.restockLevel,
  };

  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Grid container id="add_edit_inventory">
        {/* add */}
        <Grid item xs={6} sm={6} md={6} lg={6} id="add_inventory_qty">
          {/* popup button */}
          <button onClick={handleClickOpen()}>Add</button>

          {/* popup body */}
          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              {inventoryData.itemName}
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              {/* content */}
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <AddInventoryQty inventoryData={inventoryData} />
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Grid>

        {/* edit */}
        <Grid item xs={6} sm={6} md={6} lg={6} id="edit_inventory">
          <button
            onClick={() => history.push("/edit-inventory", inventoryData)}
          >
            Edit
          </button>
        </Grid>
      </Grid>
    </>
  );
}

function AddInventoryQty(props) {
  // context
  const { inventoryLoading, updateQuantity } = useContext(InventoryContext);

  const inventoryData = props.inventoryData;

  // initial state
  const oldQuantity = parseFloat(props.inventoryData.quantity);
  const [quantity, setQuantity] = useState(0);
  const [newQuantity, setNewQuantity] = useState(oldQuantity);

  // submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    updateQuantity(inventoryData, newQuantity, inventoryData.inventoryId);
  };

  return (
    <form id="add_inventory_qty_form" onSubmit={handleSubmit}>
      <h4>Current quantity</h4>
      <p>{oldQuantity}</p>

      <h4>Add extra quantity</h4>
      <TextField
        name="quantity"
        value={quantity}
        onChange={(e) => setQuantity(e.target.value)}
        onKeyUp={(e) => setNewQuantity(parseFloat(e.target.value) + oldQuantity)}
      />

      <h4>New quantity</h4>
      <p>{newQuantity}</p>

      {/* feedback popup */}
      <FeedbackPopup condition={inventoryLoading} />

      {/* button */}
      <section className="form-btn">
        <Button type="submit">Proceed</Button>
      </section>
    </form>
  );
}
