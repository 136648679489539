import React, { useState } from "react";
import { Button, Grid, Avatar, TextField } from "@material-ui/core/";
import Cookies from "universal-cookie";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");

export default function ManageProfile() {
  // initial states
  const [switchPasswordView, setSwitchPasswordView] = useState(false);

  return (
    <>
      <form id="profile_settings">
        {/* profile details */}
        <Grid container id="profile_details">
          {/* user AVI */}
          <Grid item xs={1} sm={1} md={1} lg={1} id="profile_image">
            <Avatar
              alt="Chiamaka Uzochukwu"
              src="https://www.pinclipart.com/picdir/middle/164-1642558_shamim-amiri-blank-female-avatar-icon-clipart.png"
            />
          </Grid>

          {/* user name */}
          <Grid item xs={11} sm={11} md={11} lg={11} id="user_name">
            <h3>{staff.fullName}</h3>
          </Grid>
        </Grid>

        {/* phone and role */}
        <Grid container id="phone_role">
          {/* phone number */}
          <Grid item xs={6} sm={6} md={6} lg={6} id="user_phone">
            <h4>Phone Number</h4>
            <p>{staff.phoneNumber}</p>
          </Grid>

          {/* role */}
          <Grid item xs={6} sm={6} md={6} lg={6} id="user_role">
            <h4>Role</h4>
            <p>{staff.roles.split(",").join(", ")}</p>
          </Grid>
        </Grid>

        {/* password */}
        {!switchPasswordView ? (
          <Grid container id="user_password">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h4>Password</h4>
              <p onClick={() => setSwitchPasswordView(true)}>Change Password</p>
            </Grid>
          </Grid>
        ) : (
          <Grid container id="change_password">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {/* old password */}
              <section>
                <h4>Enter Old Password</h4>
                <TextField fullWidth variant="outlined" type="password" />
              </section>

              {/* new password */}
              <section>
                <h4>Enter New Password</h4>
                <TextField fullWidth variant="outlined" type="password" />
              </section>
            </Grid>

            {/* re enter password */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <section>
                <h4>Re - Enter New Password</h4>
                <TextField fullWidth variant="outlined" type="password" />
              </section>
            </Grid>
          </Grid>
        )}

        {/* permissions */}
        <Grid container id="permissions">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h4>Permissions</h4>
            {/* <p>Give discount</p>
            <p>Edit Price at Point of Sale</p> */}
          </Grid>
        </Grid>

        {switchPasswordView ? (
          <Grid container id="profile_buttons">
            {/* cancel */}
            <Grid item xs={6} sm={6} md={6} lg={6} id="profile_cancel_btn">
              <Button onClick={() => setSwitchPasswordView(false)}>
                Cancel
              </Button>
            </Grid>

            {/* update */}
            <Grid item xs={6} sm={6} md={6} lg={6} id="profile_update_btn">
              <Button>Update</Button>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </form>
    </>
  );
}
