import React from "react";
import MenuPlanEachTimeOfDay from "./MenuPlanEachTimeOfDay";

export default function MenuPlanDays(props) {
  return (
    <>
      {/* morning */}
      <MenuPlanEachTimeOfDay
        planData={props.planData}
        periodOfDay={"Morning"}
        row={props.row}
        day={props.day}
        submissionDate={props.submissionDate}
        allDetails={props.allDetails}
        setAllDetails={props.setAllDetails}
      />

      {/* afternoon */}
      <MenuPlanEachTimeOfDay
        planData={props.planData}
        periodOfDay={"Afternoon"}
        row={props.row}
        day={props.day}
        submissionDate={props.submissionDate}
        allDetails={props.allDetails}
        setAllDetails={props.setAllDetails}
      />

      {/* evening */}
      <MenuPlanEachTimeOfDay
        planData={props.planData}
        periodOfDay={"Evening"}
        row={props.row}
        day={props.day}
        submissionDate={props.submissionDate}
        allDetails={props.allDetails}
        setAllDetails={props.setAllDetails}
      />
    </>
  );
}
