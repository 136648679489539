// external imports
import React, { useContext } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, FormControl, Select } from "@material-ui/core";

// local imports
import AddOrEditInventory from "./AddOrEditInventory";
import InventoryDescription from "./InventoryDescription";
import { InventoryContext } from "../../../../ContextAPI/InventoryContext";
import LoadingFeedback from "../../../helpers/LoadingFeedback";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";

export default function ManageInventoryTable() {
  const { inventories, inventoryLoading } = useContext(InventoryContext);
  const { roles } = useContext(HelpersContext);

  const columns = [
    "",
    "S/N",
    "HOLDING UNIT",
    "ITEM NAME",
    "CATEGORY",
    "PRICE (NGN)",
    "QUANTITY (Unit)",
    "RESTOCK LEVEL (Unit)",
    "TOTAL ADDED (Unit)",
    "TOTAL SOLD (Unit)",
    "OPTIONS",
  ];

  // extract data for the table rows
  let extractedInventories = [];
  let serialNumber = 1;

  for (const inventory of inventories) {
    let inventoryData = [
      roles.includes("Holding 2") ? (
        <InventoryDescription
          itemName={inventory.itemName}
          description={inventory.description}
        />
      ) : (
        "NONE"
      ),
      serialNumber++,
      inventory.holding,
      inventory.itemName.toUpperCase(),
      inventory.InventoryCategory.name,
      inventory.unitPrice,
      inventory.quantity,
      inventory.restockLevel,
      "total added",
      "total sold",
      <AddOrEditInventory
        inventoryId={inventory.id}
        holding={inventory.holding}
        itemName={inventory.itemName}
        InventoryCategory={inventory.InventoryCategory.name}
        unitPrice={inventory.unitPrice}
        quantity={inventory.quantity}
        restockLevel={inventory.restockLevel}
      />,
    ];

    extractedInventories.push(inventoryData);
  }

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "100%",
    tableBodyMaxHeight: "400px",
    jumpToPage: true,
  };

  const data = extractedInventories;

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter">
        {/* today */}
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            Today <span>{inventories.length}</span>
          </p>
        </Grid>

        <Grid item xs={2} sm={2} md={2} lg={2} className="filter_text">
          <p>Filter by:</p>
        </Grid>

        {/* sort options */}
        <Grid item xs={9} sm={9} md={9} lg={9} className="filter_forms">
          {/* Date Range */}
          <FormControl variant="outlined">
            <Select native>
              <option value={5}>Date Range</option>
              <option value={10}>Ten</option>
              <option value={20}>Twenty</option>
              <option value={30}>Thirty</option>
            </Select>
          </FormControl>

          {/* Time Range */}
          <FormControl variant="outlined">
            <Select native>
              <option value={5}>Time Range</option>
              <option value={10}>Ten</option>
              <option value={20}>Twenty</option>
              <option value={30}>Thirty</option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* set table */}
      {inventoryLoading === "processing" ? (
        <LoadingFeedback message="Searching For Inventories..." />
      ) : inventoryLoading === "found" && inventories.length > 0 ? (
        <MUIDataTable
          title={"Item"}
          data={data}
          columns={columns}
          options={options}
        />
      ) : inventoryLoading === "found" && inventories.length <= 0 ? (
        <LoadingFeedback message="Search Completed But No Inventory Found" />
      ) : inventoryLoading === "failed" ? (
        <LoadingFeedback message="An Error Occured. Check your Connection" />
      ) : (
        ""
      )}
    </main>
  );
}
