import React, { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core/";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import { getAllDates } from "../../../helpers/MenuPlanHelper";
import { MenuPlanContext } from "../../../../ContextAPI/MenuPlanContext";
import EachDayPreview from "./EachDay/EachDayPreview";
import FeedbackPopup from "../../../helpers/FeedbackPopup";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewItems(props) {
  // states
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // context
  const {
    dateRange,
    setMorningRanges,
    setAfternoonRanges,
    setEveningRanges,
    setMPSelectedMenuItem,
    setSelectedPOD,
    MPSelectedMenuItem,
    MPBasicInfo,
  } = useContext(HelpersContext);
  const { AddMenuPlanContent, addingPlanDetails } = useContext(MenuPlanContext);

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <section id="select_MT">
      <Button onClick={handleClickOpen()}>Preview</Button>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"md"}
        scroll={scroll}
        disableBackdropClick={true}
        TransitionComponent={Transition}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <div id="select_MT_header">
            <aside>{MPBasicInfo.planName.toUpperCase()} Preview</aside>

            <aside>{addingPlanDetails}</aside>

            <aside>
              <Button
                onClick={() => {
                  AddMenuPlanContent(
                    MPSelectedMenuItem,
                    handleClose,
                    props.closeAddUI
                  );
                }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setMorningRanges([]);
                  setAfternoonRanges([]);
                  setEveningRanges([]);
                  setMPSelectedMenuItem([]);
                  setSelectedPOD([]);
                  handleClose();
                }}
              >
                Clear
              </Button>
              <Button onClick={() => handleClose()}>Close</Button>
            </aside>
          </div>
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* feedback popup */}
            <FeedbackPopup
              condition={addingPlanDetails}
              additionalMessage="Returning..."
            />

            {/* preview content */}
            <PreviewItemsContent dateRange={dateRange} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </section>
  );
}

function PreviewItemsContent(props) {
  const allDaysPreview = getAllDates(props.dateRange);

  return (
    <main id="add_mp_details">
      <section style={{ textAlign: "center", fontWeight: "bold" }}>
        <p>
          Don't Worry! Duplicates Will Be Removed Before Submission.
        </p>
      </section>

      {allDaysPreview.map((day) => (
        <EachDayPreview day={day} view="" />
      ))}
    </main>
  );
}
