const getTopingsDetails = (toppings) => {
  let collected = [];

  for (const item of toppings) {
    collected.push(item.name + " (" + item.quantity + ")");
  }

  return collected.toString();
};

export {
    getTopingsDetails
}