import React, { useContext } from "react";
import { FormGroup, Grid } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import LoadingFeedback from "../../../../../helpers/LoadingFeedback";
import { timeLimitConvertion } from "../../../../../helpers/MenuPlanHelper";
import {
  addQty,
  addTimeRangeOfDay,
  getASelectedTimeRange,
  isTRSelected,
  subtractQty,
} from "../../../HelperFunctions";
import { HelpersContext } from "../../../../../../ContextAPI/HelpersContext";

export default function TimeRanges({ content, items, loading, day }) {
  return (
    <>
      {loading === "processing" ? (
        <LoadingFeedback message={`Searching For ${content}...`} />
      ) : loading === "found" && items.length > 0 ? (
        items.map((item) => (
          <>
            <Content itemRetrieved={item} day={day} />
          </>
        ))
      ) : loading === "found" && items.length <= 0 ? (
        <LoadingFeedback message={`Search Completed But No ${content} Found`} />
      ) : loading === "failed" ? (
        <LoadingFeedback message="An Error Occured. Check your Connection" />
      ) : (
        ""
      )}
    </>
  );
}

function Content({ itemRetrieved, day }) {
  const {
    selectedTimeRanges,
    setSelectedTimeRanges,
    selectedMonths,
    setSelectedMonths,
    selectedDays,
    setSelectedDays,
  } = useContext(HelpersContext);

  const isChecked = isTRSelected(itemRetrieved, day, selectedTimeRanges);

  // get the quantity of a delivery time that has been selected
  // this will update the UI with the selected delivery time quantity 
  const itemSelectedDetails = getASelectedTimeRange(
    itemRetrieved.id,
    selectedTimeRanges
  );

  return (
    <>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <FormGroup aria-label="position" key={itemRetrieved.id}>
          <FormControlLabel
            value={itemRetrieved}
            control={
              <Checkbox
                checked={isChecked}
                color="primary"
                onChange={(e) =>
                  addTimeRangeOfDay(
                    e.target.checked,
                    itemRetrieved,
                    day,
                    selectedTimeRanges,
                    setSelectedTimeRanges,
                    selectedMonths,
                    setSelectedMonths,
                    selectedDays,
                    setSelectedDays
                  )
                }
              />
            }
            label={timeLimitConvertion(itemRetrieved.range)}
            labelPlacement="end"
          />
        </FormGroup>
      </Grid>

      <Grid item xs={6} sm={6} md={6} lg={6} align="center" id="qty">
        {isChecked ? (
          <p>
            {/* decrease quantity */}
            <b
              onClick={() =>
                subtractQty(
                  itemRetrieved.id,
                  selectedTimeRanges,
                  setSelectedTimeRanges
                )
              }
            >
              <FaMinusCircle />
            </b>

            {/* show quantity */}
            <span>{itemSelectedDetails.quantity}</span>

            {/* increase quantity */}
            <b
              onClick={() =>
                addQty(
                  itemRetrieved.id,
                  selectedTimeRanges,
                  setSelectedTimeRanges
                )
              }
            >
              <FaPlusCircle />
            </b>
          </p>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
}
