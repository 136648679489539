import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { RecipeContext } from "../../../../ContextAPI/RecipeContext";
import NumberFormaterSuffix from "../../../helpers/NumberFormaterSuffix";
import Input from "./Form/Input";
import { Detail } from "./RecipeDetails";

export const Price = () => {
  const { amount, setAmount, recipeTableType, recipeDetails } =
    useContext(RecipeContext);

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} id="price">
        {recipeTableType === "COMPLETED" ? (
          <Detail
            id="update_price"
            title="Updated price"
            body={<NumberFormaterSuffix value={recipeDetails.amount} />}
          />
        ) : (
          <Input
            title="Update price"
            placeholder={"₦0.00"}
            width={false}
            value={amount}
            inputSetter={setAmount}
          />
        )}
      </Grid>
    </>
  );
};
