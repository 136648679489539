// get the total amount for the order being made ie addons + preferences + menuItem price
const getOrderTotal = (selectedAddons, selectedPreference, amount) => {
  // get selectedAddons total
  let addonsTotal = 0;
  for (const addon of selectedAddons) {
    if (addon.totalPrice) {
      addonsTotal = parseFloat(addonsTotal) + parseFloat(addon.totalPrice);
    }
  }

  // get selectedPreference total
  let preferenceTotal = 0;
  for (const preference of selectedPreference) {
    if (preference.Preference.unitPrice) {
      preferenceTotal =
        parseFloat(preferenceTotal) + parseFloat(preference.Preference.unitPrice);
    }
  }

  return (
    parseFloat(preferenceTotal) + parseFloat(addonsTotal) + parseFloat(amount)
  );
};

// decrease the overal quantity
const reduceOverallQty = (e, overallQty, setOverallQty) => {
  e.preventDefault();

  if (overallQty === 1 || overallQty === "1")
    return alert("Quantity cannot be less than 1");

  setOverallQty(parseFloat(overallQty) - 1);
};

// increase the overal quantity
const increaseOverallQty = (e, overallQty, setOverallQty) => {
  e.preventDefault();
  setOverallQty(parseFloat(overallQty) + 1);
};

// cart total
const getCartTotal = (cartItems) => {
  let cartTotal = 0;

  for (const item of cartItems) {
    if (item.data.id) {
      cartTotal = parseFloat(cartTotal) + parseFloat(item.data.amount);
    }
  }

  return cartTotal;
};

// get all the preferences names
const getNames = (data) => {
  if(!data) return;

  let allNames = [];
  for (const item of data) {
    allNames.push(item.Preference.name);
  }

  return allNames;
};

// remove item from cart
const removeFromCart = (id, cartItems, setCartItems) => {
  setCartItems(cartItems.filter((item) => id !== item.data.id));
};

// check if an item was already selected
const isSelected = (itemArray, itemName) => {
  for (const item of itemArray) {
    if (item.name === itemName) {
      return true;
    }
  }
};

// check if an item was already selected
const isPreferencesSelected = (itemArray, itemName) => {
  for (const item of itemArray) {
    if (item.Preference.name === itemName) {
      return true;
    }
  }
};

// return null for empty strings
const returnNull = (field) => {
  if (field === "" || field === [] || field === {}) {
    return null;
  } else {
    return field;
  }
};

// get the customer id for whom is an order is placed for
const getOrderedFor = (customer) => {
  if (customer) {
    return customer.id;
  } else {
    return null;
  }
};

// get table options
const getTableOptions = (numberOfOptions) => {
  let tableOptions = [];
  for (let index = 1; index <= numberOfOptions; index++) {
    const tbl = `TABLE ${index}`;
    tableOptions.push(<option value={tbl}>{tbl}</option>);
  }

  return tableOptions;
};

// get addons Names and quantity
const getAddonsDetails = (addons) => {
  if (!addons) return;

  let processedAddons = [];
  for (const addon of addons) {
    processedAddons.push(addon.name + " (" + addon.quantity + ")");
  }

  return processedAddons.toString();
};

// refresh the page to give way for a new order if the cart is empty
const startNewOrder = () => {
  window.location.href = window.location.href.split("#")[0];
};

// get ids of an array of object
const getIds = (data) => {
  let extractedIds = [];
  for (const item of data) {
    if (item.data.id) {
      extractedIds.push(item.data.id);
    }
  }

  return extractedIds;
};

// search orders
const searchOrders = (searchData, allData, setter) => {
  // discontinue if there is no search yet
  if (searchData === null || allData === []) return;

  // empty the previous search array if any
  const searchResult = [];
  const data = searchData.toLowerCase();

  // loop through all data
  for (const item of allData) {
    let itemName;
    //   convert to lower case
    // in a case where there is no order name, we will set it to a string to avoid errors
    if (item.orderName) {
      itemName = item.orderName.toLowerCase();
    }else{
      itemName = "nothing"
    }

    const itemRef = item.orderRef.toLowerCase();

    // check if the search word or character matches
    if (
      [...itemName].includes(data) ||
      itemName === data ||
      itemName.split(" ").includes(data) ||
      [...itemRef].includes(data) ||
      itemRef === data ||
      itemRef.split("-").includes(data)
    ) {
      // add any match to the searchResult
      if (!searchResult.includes(item)) {
        searchResult.push(item);
      }
    }
  }
  
  setter(searchResult);
};

export {
  getOrderTotal,
  reduceOverallQty,
  increaseOverallQty,
  getCartTotal,
  removeFromCart,
  getNames,
  isSelected,
  returnNull,
  getOrderedFor,
  getTableOptions,
  getAddonsDetails,
  startNewOrder,
  getIds,
  searchOrders,
  isPreferencesSelected
};
