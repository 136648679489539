import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import InActiveItems from "../../../components/adminComponents/MenuItemsMGT/ViewMenuItems/InActiveItems";

export default function ViewInactiveMenuItems() {
  return (
    <>
      <DashboardFrame
        pageHeading="Manage Inactive Menu Items"
        dashboardContent={<InActiveItems />}
      />
    </>
  );
}
