import React, { useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import LoadingFeedback from "../../../helpers/LoadingFeedback";
import CustomerSearch from "../../MenuPlanManagement/CustomerMPOrder/CustomerSearch";
import { doNothing } from "../HelperFunctions";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import { MenuPlanContext } from "../../../../ContextAPI/MenuPlanContext";
import SearchItems from "../../MPCategoryMGT/SearchItems";
import FilterByCategory from "../../MealPlanMGT/ViewMealPlans/FilterByCategory";
import PlanPopup from "./PlanPopup";
import PreviewPopup from "./PreviewPopup";

export default function SelectMP() {
  // context
  const { menuPlans, fetchingMP, getMealPlans } = useContext(MenuPlanContext);
  const {
    searchResult,
    setSearchResult,
    setSearch,
    search,
    setCategory,
    setCustomerId,
    setCustomer,
    customer,
  } = useContext(HelpersContext);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (user) {
      setCustomer(user.fullName + " (" + user.phoneNumber + ")");
      setCustomerId(user.id);
    }

    getMealPlans({ setCategory, setSearch, setSearchResult });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main id="view_mwnu_items" className="customer_mp">
      {/* filter */}
      <Grid container className="top_filter">
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{menuPlans.length}</span>
          </p>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

        {/* filter section */}
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          className="filter_forms"
          id="pos_filter"
        >
          {/* search */}
          {user ? (
            <h3>{customer} </h3>
          ) : (
            <CustomerSearch getOrders={doNothing} />
          )}

          {/* searsch field */}
          <SearchItems item={menuPlans} isName={true} />

          {/* categories */}
          <FilterByCategory />
        </Grid>

        {/* preview button */}
        <Grid item xs={1} sm={1} md={1} lg={1} id="preview_btn">
          <PreviewPopup />
        </Grid>
      </Grid>

      {/* menu plans */}
      <Grid container id="meal_items">
        {/* display menu plans here */}
        {searchResult && searchResult.length > 0 ? (
          searchResult.map((result) => (
            <PlanPopup mealPlan={result} isEdit={false} />
          ))
        ) : search && searchResult && searchResult.length <= 0 ? (
          "No Result Found!"
        ) : fetchingMP === "processing" ? (
          <LoadingFeedback message="Searching For Meal Plans..." />
        ) : fetchingMP === "found" && menuPlans.length > 0 ? (
          menuPlans.map((result) => (
            <PlanPopup mealPlan={result} isEdit={false} />
          ))
        ) : fetchingMP === "found" && menuPlans.length <= 0 ? (
          <LoadingFeedback message="Search Completed But No Meal Plan Found" />
        ) : fetchingMP === "failed" ? (
          <LoadingFeedback message="An Error Occured. There might be no items available. Also, Check your Connection" />
        ) : (
          ""
        )}
      </Grid>
    </main>
  );
}
