import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { AppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AdminSideMenu from "./DashboardFrame/AdminSideMenu";
import Logout from "../helpers/Logout";
import { NotificationContext } from "../../ContextAPI/NotificationContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function DashboardFrame(props) {
  // context
  const {
    instantOrderNotify,
    MPOrderNotify,
    recipeOrderNotify,
    surveyNotify,
    recipeNotify,
  } = useContext(NotificationContext);

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root} id="admin_dashboard">
      <CssBaseline />
      {/* app bar*/}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        {/* handburger Icon to open side menu */}
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>

          <section id="page_heading">
            <a href="/dashboard">
              {" "}
              <h1>{props.pageHeading}</h1>
            </a>
          </section>
        </Toolbar>

        {/* notification bar */}
        <aside id="notify">
          <p>
            {instantOrderNotify ? "New Instant Order!!! " : ""}
            {MPOrderNotify ? "New Meal Plan Order!!! " : ""}
            {recipeOrderNotify ? "New Recipe Order!!! " : ""}
            {surveyNotify ? "New Survey!!! " : ""}
            {recipeNotify ? "New Recipe!!! " : ""}
          </p>
        </aside>

        {/* notification icon */}
        <IconButton color="inherit" edge="end" id="notification_icon">
          <NotificationsIcon />
        </IconButton>
      </AppBar>

      {/* side menu */}
      <Drawer
        id="admin_side_menu"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        {/* title and navigation icon */}
        <div className={classes.toolbar} id="admin_side_menu_header">
          {/* title */}
          <a href="/dashboard">
            <h2>ooma kitchen</h2>
          </a>

          {/* nav icon to close the side menu */}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>

        {/* nav items */}
        <AdminSideMenu />
      </Drawer>

      {/* body content */}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <section id="logout-btn">
          <Logout />
        </section>

        {props.dashboardContent}
      </main>
    </div>
  );
}
