const getMPPreferences = (data) => {
    if(!data) return;

    let allNames = [];
    for (const item of data) {
      allNames.push(item.name);
    }
  
    return allNames;
};

const getAdminMPPreferences = (data) => {
  if(!data) return;

  let allNames = [];
  for (const item of data) {
    allNames.push(item.Preference.name);
  }

  return allNames;
};

export { getMPPreferences, getAdminMPPreferences };
