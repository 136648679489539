import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import EditMealPlanForm from "../../../components/adminComponents/MealPlanMGT/EditMealPlanForm";

export default function EditMealPlan(props) {
  const mealPlan = props.location.state;

  return (
    <>
      <DashboardFrame
        pageHeading="Manage Meal Plan"
        dashboardContent={<EditMealPlanForm mealPlan={mealPlan} />}
      />
    </>
  );
}
