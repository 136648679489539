import { Button, FormControl, Select } from "@material-ui/core";
import React, { useContext } from "react";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import { MenuPlanCategoryContext } from "../../../../ContextAPI/MenuPlanCategoryContext";
import { MenuPlanContext } from "../../../../ContextAPI/MenuPlanContext";

export default function FilterByCategory() {
  // context
  const { filterMPByCategory, getMealPlans } = useContext(MenuPlanContext);
  const { menuPlanCategories } = useContext(MenuPlanCategoryContext);
  const { category, setCategory, setSearchResult, setSearch } =
    useContext(HelpersContext);

  return (
    <>
      <FormControl variant="outlined">
        <Select
          native
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Categories</option>
          {menuPlanCategories &&
            menuPlanCategories.length > 0 &&
            menuPlanCategories.map((category) => (
              <option
                key={category.id}
                title={category.description}
                value={category.id}
              >
                {category.name.toUpperCase()}
              </option>
            ))}
        </Select>
      </FormControl>
      {/* Buttons */}
      <Button
        onClick={() =>
          filterMPByCategory({ category, setSearch, setSearchResult })
        }
      >
        Apply
      </Button>
      <Button
        onClick={() =>
          getMealPlans({ setCategory, setSearch, setSearchResult })
        }
      >
        Clear
      </Button>
    </>
  );
}
