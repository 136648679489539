import { TextField } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import ImageUploadProgress from "../../helpers/ImageUploadProgress";
import { validateImage } from "../../helpers/MenuItemHelper";
import { imgToBase64 } from "./HelperFunction";

export default function ImageInput(props) {
  const {
    setImageUrl,
    imageCheck,
    setImageCheck,
    uploadPercentage,
    imageUrl,
    setUploadPercentage,
    setImgBase64,
    imgBase64,
  } = useContext(HelpersContext);

  // props
  const initialImage = props.initialImage;

  // get what we need from useform()
  const { register } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    // convert image to base64
    if (!imageCheck && imageUrl) {
      imgToBase64(imageUrl, setUploadPercentage, setImgBase64);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageCheck, imageUrl]);

  return (
    <>
      <h4>Image</h4>
      {/* View Initial Image */}
      {initialImage ? (
        <a href={initialImage} target="_blank" rel="noreferrer">
          View Initial Image
        </a>
      ) : (
        ""
      )}

      {/* Use Initial Image */}
      <span id="use_initial_img" onClick={() => setImgBase64(null)}>
        {imgBase64 && props.isEdit ? "Use Initial Image?" : null}
      </span>

      <TextField
        fullWidth
        variant="outlined"
        type="file"
        name="imageUrl"
        error={
          imageCheck === "setType" ||
          imageCheck === "setSize" ||
          imageCheck === "empty"
        }
        helperText={
          imageCheck === "setType"
            ? "You have not selected an Image"
            : imageCheck === "setSize"
            ? "Image Size cannot be more than 70kb"
            : imageCheck === "empty"
            ? "No Image Selected"
            : ""
        }
        inputRef={register}
        defaultValue={imageUrl}
        onChange={(e) => setImageUrl(e.target.files[0])}
        onBlur={() => validateImage(imageUrl, setImageCheck)}
      />

      {/* progress bar */}
      <ImageUploadProgress progress={uploadPercentage} />
    </>
  );
}
