import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";
import HalfRowLayout from "../../../../helpers/HalfRowLayout";
import NumberFormaterPrefix from "../../../../helpers/NumberFormaterPrefix";
import {
  getTotalPriceOfItemsSelected,
  getTotalQuantityOfItemsSelected,
} from "../../HelperFunctions";

export default function GeneralInfo() {
  const {
    customerMPPreview,
    customer,
    subscriptionDuration,
    deliveryTimesSelected,
  } = useContext(HelpersContext);

  const user = customer.split("(");

  return (
    <>
      <Grid container id="general_info">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container>
            <HalfRowLayout className="text_key" text="Name of customer:" />
            <HalfRowLayout text={customer ? user[0] : "Not Selected"} />

            <HalfRowLayout className="text_key" text="Custumer’s number:" />
            <HalfRowLayout
              text={customer ? user[1].split(")")[0] : "Not Selected"}
            />

            <HalfRowLayout className="text_key" text="Subscription duration:" />
            <HalfRowLayout text={subscriptionDuration.length + "days"} />

            <HalfRowLayout className="text_key" text="Total No. of meals:" />
            <HalfRowLayout
              text={getTotalQuantityOfItemsSelected(customerMPPreview)}
            />

            <HalfRowLayout className="text_key" text="Total deliveries:" />
            <HalfRowLayout text={deliveryTimesSelected.length} />

            <HalfRowLayout className="text_key" text="Total price:" />
            <HalfRowLayout
            className="total_price"
              text={
                <NumberFormaterPrefix
                  value={getTotalPriceOfItemsSelected(customerMPPreview)}
                />
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
      </Grid>
    </>
  );
}
