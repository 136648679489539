import React, { useContext, useState } from "react";
import { TextField, Button, Grid } from "@material-ui/core/";
import { AiOutlinePlus } from "react-icons/ai";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Dialog, DialogTitle } from "@material-ui/core/";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import MenuPlanTimeLimits from "./MenuPlanTimeLimits";
import { MenuItemContext } from "../../../ContextAPI/MenuItemContext";
import { MenuItemCategoryContext } from "../../../ContextAPI/MenuItemCategoryContext";
import { toggleShowItems } from "../../helpers/MenuItemHelper";

export default function MenuPlanAddMeal(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [selectedCategory, setSelectedCategory] = useState("");

  // context
  const { menuItemCategories } = useContext(MenuItemCategoryContext);
  const { filterByCategory } = useContext(MenuItemContext);

  // initial states
  const [showCategories, setShowCategories] = useState(false);
  const [search, setSearch] = useState("");

  const searchedItems = {};

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div id="menu_plan_add_meal">
      <Button onClick={handleClickOpen()}>
        <AiOutlinePlus />
        <span>Add Meal</span>
      </Button>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {/* search form */}
          <main id="add_meal_input_popup">
            <Grid container>
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <TextField
                  variant="outlined"
                  placeholder="Search Meal"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyUp={(e) => {}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                {/* view category button */}
                <Button
                  onClick={() =>
                    toggleShowItems(showCategories, setShowCategories)
                  }
                >
                  <FormatListBulletedIcon style={{ fontSize: 45 }} />
                </Button>
              </Grid>
            </Grid>

            {/* category list */}
            {showCategories ? (
              <div className="form-dropdown">
                {menuItemCategories &&
                  menuItemCategories.length > 0 &&
                  menuItemCategories.map((result) => (
                    <p
                      onClick={() =>
                        filterByCategory(
                          result.id,
                          result.name,
                          setSelectedCategory,
                          setShowCategories,
                          setSearch
                        )
                      }
                    >
                      {result.name}
                    </p>
                  ))}
              </div>
            ) : (
              ""
            )}
          </main>
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <MenuPlanAddMealSearchResult
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
              searchedItems={searchedItems}
              search={search}
              periodOfDay={props.periodOfDay}
              row={props.row}
              day={props.day}
              planData={props.planData}
              submissionDate={props.submissionDate}
              allDetails={props.allDetails}
              setAllDetails={props.setAllDetails}
              toggleAddMeal={handleClose}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function MenuPlanAddMealSearchResult(props) {
  const { menuItems, filteredMenuItems, menuItemLoading } =
    useContext(MenuItemContext);
  const setSelectedCategory = props.setSelectedCategory;
  const selectedCategory = props.selectedCategory;
  const searchedItems = props.searchedItems;
  const search = props.search;

  const periodOfDay = props.periodOfDay;
  const row = props.row;
  const day = props.day;
  const planData = props.planData;
  const submissionDate = props.submissionDate;
  const allDetails = props.allDetails;
  const setAllDetails = props.setAllDetails;
  const toggleAddMeal = props.toggleAddMeal;

  // clear category filter at the point of making a search
  if (search !== "") {
    setSelectedCategory("");
  }

  return (
    <section id="search_options_list">
      {/* render the category section if a category is selected */}
      {selectedCategory ? (
        <p id="selected_category">
          <span>Category:</span> {selectedCategory}
        </p>
      ) : search !== "" && searchedItems.length <= 0 ? (
        <p>
          <i>
            <b>No Match Found for Search...</b>
          </i>
        </p>
      ) : (
        ""
      )}

      {/* render the menuItems */}
      {search !== null && searchedItems.length > 0 ? (
        <>
          <h3>Search Result(s)</h3>
          {searchedItems.map((result) => (
            <MenuPlanTimeLimits
              menuItem={result}
              periodOfDay={periodOfDay}
              row={row}
              day={day}
              planData={planData}
              submissionDate={submissionDate}
              allDetails={allDetails}
              setAllDetails={setAllDetails}
              toggleAddMeal={toggleAddMeal}
            />
          ))}
        </>
      ) : menuItemLoading === "searching" ? (
        <p>
          <i>
            <b>Searching...</b>
          </i>
        </p>
      ) : menuItemLoading === "found" && filteredMenuItems.length <= 0 ? (
        <p>
          <i>
            <b>No Match Found...</b>
          </i>
        </p>
      ) : menuItemLoading === "found" && filteredMenuItems.length > 0 ? (
        <>
          <h3>Category Filter Result(s)</h3>
          {filteredMenuItems.map((result) => (
            <MenuPlanTimeLimits
              menuItem={result}
              periodOfDay={periodOfDay}
              row={row}
              day={day}
              planData={planData}
              submissionDate={submissionDate}
              allDetails={allDetails}
              setAllDetails={setAllDetails}
              toggleAddMeal={toggleAddMeal}
            />
          ))}
        </>
      ) : selectedCategory === "" && search === "" ? (
        <>
          <h3>All Menu Items</h3>
          {menuItems &&
            menuItems.length > 0 &&
            menuItems.map((result) => (
              <MenuPlanTimeLimits
                menuItem={result}
                periodOfDay={periodOfDay}
                row={row}
                day={day}
                planData={planData}
                submissionDate={submissionDate}
                allDetails={allDetails}
                setAllDetails={setAllDetails}
                toggleAddMeal={toggleAddMeal}
              />
            ))}
        </>
      ) : (
        ""
      )}
    </section>
  );
}
