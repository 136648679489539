import MUIDataTable from "mui-datatables";
import React from "react";
import LoadingFeedback from "../LoadingFeedback";

export default function TableContent(props) {
  const loading = props.loading;
  const receivedData = props.receivedData;
  const heading = props.heading;

  return (
    <>
      {loading === "processing" ? (
        <LoadingFeedback message={`Searching For ${heading}...`} />
      ) : loading === "found" && receivedData.length > 0 ? (
        <MUIDataTable
          title={`${heading}`}
          data={props.tableData}
          columns={props.columns}
          options={props.tableOptions}
        />
      ) : loading === "found" && receivedData.length <= 0 ? (
        <LoadingFeedback message={`Search Completed - No ${heading} Found`} />
      ) : loading === "failed" ? (
        <LoadingFeedback
          message={`An Error Occured. There May Be no ${heading} for this DATE RANGE.`}
        />
      ) : !loading ? (
        <LoadingFeedback message={`Orders will appear here`} />
      ) : (
        ""
      )}
    </>
  );
}
