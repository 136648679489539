import React from "react";
import { Detail } from "../../CustomerRecipe/ViewAndUpdateDetails/RecipeDetails";

export default function Body({ recipe }) {
  return (
    <div id="recipe_details">
      <Detail title="Time for preparation" body={recipe.preparationDuration} />
      <Detail title="Recipe title" body={recipe.title} />
      <Detail title="Key ingredients" body={recipe.ingredients} />
      <Detail title="Recipe description" body={recipe.description} />
      <Detail title="Special instructions" body={recipe.specialInstructions} />
    </div>
  );
}
