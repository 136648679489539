import React, { useContext, useEffect } from "react";
import { OrderContext } from "../../../../ContextAPI/OrderContext";
import LoadingFeedback from "../../../helpers/LoadingFeedback";
import ViewAnOrderFrame from "../ViewAnOrderFrame";

export default function ViewAnActiveOrderContent(props) {
  const { getOrderById, order, orderLoading } = useContext(OrderContext);
  const orderDetails = props.orderDetails;

  useEffect(() => {
    getOrderById(orderDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {orderLoading === "processing" ? (
        <LoadingFeedback message="Searching..." />
      ) : orderLoading === "found" && order.length <= 0 ? (
        <LoadingFeedback message="Searching..." />
      ) : orderLoading === "failed" ? (
        <LoadingFeedback message="An Error Occured. Check your Connection" />
      ) : orderLoading === "found" &&
        order &&
        order.menuitemorders.status === "Pending" ? (
        <ViewAnOrderFrame
          firstBtn="Accept"
          order={order}
          orderDetails={orderDetails}
        />
      ) : orderLoading === "found" &&
        order &&
        order.menuitemorders.status === "Accepted" ? (
        <ViewAnOrderFrame
          firstBtn="Begin Processing"
          order={order}
          orderDetails={orderDetails}
        />
      ) : orderLoading === "found" &&
        order &&
        order.menuitemorders.status === "Processing" ? (
        <ViewAnOrderFrame
          firstBtn="Ready"
          order={order}
          orderDetails={orderDetails}
        />
      ) : orderLoading === "found" &&
        order &&
        order.menuitemorders.status === "Ready" ? (
        <ViewAnOrderFrame
          firstBtn="Deliver"
          order={order}
          orderDetails={orderDetails}
        />
      ) : orderLoading === "found" &&
        order &&
        (order.menuitemorders.status === "Deliver" ||
          order.menuitemorders.status === "Delivered") ? (
        <ViewAnOrderFrame
          firstBtn=""
          order={order}
          orderDetails={orderDetails}
        />
      ) : orderLoading === "found" &&
        order &&
        (order.menuitemorders.status === "Cancel" ||
          order.menuitemorders.status === "Cancelled" ||
          order.menuitemorders.status === "CANCELLED") ? (
        <ViewAnOrderFrame
          firstBtn=""
          order={order}
          orderDetails={orderDetails}
        />
      ) : (
        ""
      )}
    </>
  );
}
