import React from "react";
import ViewAnIncomingOrderDishTable from "./ViewAnOrderFrame/ViewAnIncomingOrderDishTable";
import ViewAnIncomingOrderTimeDetails from "./ViewAnOrderFrame/ViewAnIncomingOrderTimeDetails";
import IncomingOrderPaymentAndDelivery from "./ViewAnOrderFrame/IncomingOrderPaymentAndDelivery";
import LoadingFeedback from "../../helpers/LoadingFeedback";

export default function ViewAnOrderFrame(props) {
  const order = props.order;
  const menuItems = order.menuitemorders.MenuItemOrderDetails;
  const orderDetails = props.orderDetails;

  return (
    <main id="view_an_order">
      {/* first row */}
      <ViewAnIncomingOrderTimeDetails
        firstBtn={props.firstBtn}
        order={order}
        customer={orderDetails.orderedBy}
        area={orderDetails.area}
      />

      {/* second row */}
      {menuItems && menuItems.length ? (
        menuItems.map((menuItem) => (
          <ViewAnIncomingOrderDishTable
            channel={order.orderChannel}
            menuItem={menuItem}
          />
        ))
      ) : (
        <LoadingFeedback message="No Menu Item Found" />
      )}

      {/* third section */}
      <IncomingOrderPaymentAndDelivery order={order} area={orderDetails.area} />
    </main>
  );
}
