import React, { useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import HalfRowLayout from "../../../helpers/HalfRowLayout";
import moment from "moment";
import CheckoutPopup from "../../CreatePOSOrder/CheckoutPopup";
import { OrderContext } from "../../../../ContextAPI/OrderContext";
import CancelOrder from "../CancelOrder";
import KitchenSlip from "../IncomingOrder/KitchenSlip";
import Receipt from "../IncomingOrder/Receipt";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";

export default function ViewAnIncomingOrderTimeDetails(props) {
  const { changeOrderStatus } = useContext(OrderContext);
  const { roles } = useContext(HelpersContext);

  const area = props.area;

  const order = props.order;
  const customer = props.customer;
  const menuItems = order.menuitemorders;
  const status = menuItems.status;
  const deliveryOption = menuItems.deliveryOption;

  const firstBtn = props.firstBtn;

  return (
    <>
      <Grid container id="order_and_time_details">
        <Grid item xs={5} sm={5} md={5} lg={5} id="order_details">
          <Grid container>
            <HalfRowLayout className="text_key" text="ORDER NAME:" />
            <HalfRowLayout text={order.orderName} />

            <HalfRowLayout className="text_key" text="ORDER ID:" />
            <HalfRowLayout text={order.orderRef} />

            <HalfRowLayout className="text_key" text="CUSTOMER NAME:" />
            <HalfRowLayout text={customer.fullName} />

            <HalfRowLayout className="text_key" text="CUSTOMER NUMBER:" />
            <HalfRowLayout text={customer.phoneNumber} />

            <HalfRowLayout className="text_key" text="ITEM:" />
            <HalfRowLayout text={menuItems.MenuItemOrderDetails.length} />

            <HalfRowLayout className="text_key" text="DATE OF DELIVERY:" />
            <HalfRowLayout text="" />

            <HalfRowLayout className="text_key" text="TIME OF DELIVERY:" />
            <HalfRowLayout text={menuItems.deliveryTime} />

            <HalfRowLayout className="text_key" text="TIME DELIVERED:" />
            <HalfRowLayout text="" />

            <HalfRowLayout className="text_key" text="RECIPIENT’S NAME:" />
            <HalfRowLayout text={menuItems.friendName} />

            <HalfRowLayout className="text_key" text="RECIPIENT’S NUMBER:" />
            <HalfRowLayout text={menuItems.friendPhoneNumber} />

            <HalfRowLayout className="text_key" text="STATUS:" />
            <HalfRowLayout text={status} />

            <HalfRowLayout className="text_key" text="CHANNEL:" />
            <HalfRowLayout text={order.orderChannel} />
          </Grid>
        </Grid>

        <div className="vertical_rule"></div>

        {/* timing details */}
        <Grid item xs={5} sm={5} md={5} lg={5} id="time_details">
          <Grid container>
            {/* date_time_container */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="date_time_container"
            >
              <span>{moment(order.createdAt).format("L")}</span>
              <span>{moment(order.createdAt).format("LTS")}</span>
            </Grid>

            {/* buttons */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="date_time_container"
            >
              {/* Receipt slip */}
              <Receipt order={order} customer={customer} area={area} />

              {/* Kitchen Slip */}
              {!order ? (
                <p>Kitchen Slip Loading</p>
              ) : (
                <KitchenSlip
                  order={order}
                  menuItems={order.menuitemorders.MenuItemOrderDetails}
                  customer={customer}
                  area={area}
                />
              )}

              {/* checkout */}
              {roles.includes("Cashier 2") ? (
                <CheckoutPopup order={order} />
              ) : (
                ""
              )}
            </Grid>

            {/* time_until_delivery */}
            <Grid container id="until_delivery">
              <Grid item xs={6} sm={6} md={6} lg={6} id="until_delivery_time">
                <p>00:00:00</p>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} id="until_delivery_text">
                <p>(Until Delivery)</p>
              </Grid>
            </Grid>

            {/* status button */}
            <Grid container>
              {status === "Cancel" || status === "Cancelled" ? (
                ""
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12} id="delivery_btn">
                  {roles.includes("Chef 2") ||
                  roles.includes("Rider 2") ||
                  roles.includes("Dispatch 1") ? (
                    <section className="btn_blue">
                      <Button
                        onClick={() =>
                          firstBtn === "Accept" && roles.includes("Chef 2")
                            ? changeOrderStatus(order.id, "Accepted")
                            : firstBtn === "Begin Processing" &&
                              roles.includes("Chef 2")
                            ? changeOrderStatus(order.id, "Processing")
                            : firstBtn === "Ready" && roles.includes("Chef 2")
                            ? changeOrderStatus(order.id, "Ready")
                            : firstBtn === "Deliver" &&
                              roles.includes(
                                deliveryOption === "Delivery"
                                  ? "Rider 2"
                                  : "Dispatch 1"
                              )
                            ? changeOrderStatus(order.id, "Delivered")
                            : ""
                        }
                      >
                        {firstBtn}
                      </Button>
                    </section>
                  ) : (
                    ""
                  )}

                  {/* cancel order here */}
                  {roles.includes("Manager 1") && firstBtn ? (
                    <CancelOrder order={order} />
                  ) : (
                    ""
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
