import React, { useRef } from "react";
import SpaceInBetweenLayout from "../../../helpers/SpaceInBetweenLayout";
import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ReactToPrint from "react-to-print";
import EachCartItemDetail from "./EachCartItemDetail";
import moment from "moment";
import PrintoutHeaderWithIMG from "./PrintoutHeaderWithIMG";
import PriceAndDate from "./PriceAndDate";

export default function KitchenSlip(props) {
  const componentRef = useRef();
  const order = props.order;
  const menuItems = props.menuItems;

  return (
    <div>
      {/* button to trigger the printing */}
      <ReactToPrint
        trigger={() => <MenuItem>Kitchen Slip</MenuItem>}
        content={() => componentRef.current}
      />

      {/* component to be printed hidden from users */}
      <div style={{ display: "none" }}>
        {order.orderChannel === "POS" ? (
          <POSKitchenSlipContent
            order={order}
            menuItems={menuItems}
            customer={props.customer}
            ref={componentRef}
          />
        ) : (
          <AppKitchenSlipContent
            order={order}
            menuItems={menuItems}
            customer={props.customer}
            ref={componentRef}
            area={props.area}
          />
        )}
      </div>
    </div>
  );
}

class POSKitchenSlipContent extends React.PureComponent {
  render() {
    const order = this.props.order;
    const menuItems = this.props.menuItems;
    const otherDetails = order.menuitemorders;
    const deliveryTime = otherDetails.deliveryTime;

    return (
      <>
        <main className="kitchen_slips">
          <PrintoutHeaderWithIMG />

          {/* order details */}
          <Grid container className="order_details">
            <SpaceInBetweenLayout textA={order.orderName} textB="" />

            <SpaceInBetweenLayout textA="Order ID:" textB={order.orderRef} />

            <SpaceInBetweenLayout
              textA="No. of items:"
              textB={menuItems.length}
            />

            <SpaceInBetweenLayout
              textA="Time of delivery:"
              textB={deliveryTime ? moment(deliveryTime).format("LTS") : ""}
            />

            <SpaceInBetweenLayout
              textA="Order status:"
              textB={order.menuitemorders.status}
            />

            <SpaceInBetweenLayout
              textA="Waiter"
              textB={this.props.customer.fullName}
            />
          </Grid>

          {/* cart details */}
          {menuItems && menuItems.length
            ? menuItems.map((menuItem) => (
                <EachCartItemDetail
                  channel={order.orderChannel}
                  menuItem={menuItem}
                />
              ))
            : ""}

          {/* price and date */}
          <PriceAndDate total={otherDetails.total} />
        </main>
      </>
    );
  }
}

class AppKitchenSlipContent extends React.PureComponent {
  render() {
    const order = this.props.order;
    const menuItems = this.props.menuItems;
    const customer = this.props.customer;
    const otherDetails = order.menuitemorders;

    return (
      <>
        <main className="kitchen_slips">
          <PrintoutHeaderWithIMG />

          {/* order details */}
          <Grid container className="order_details dashed_bottom">
            <Grid container className="order_details_top dashed_bottom">
              {/* basic details */}
              <SpaceInBetweenLayout
                textA="Recipent’s name:"
                textB={
                  otherDetails.friendName
                    ? otherDetails.friendName
                    : customer.fullName
                }
              />

              <SpaceInBetweenLayout
                textA="Recipent’s number:"
                textB={
                  otherDetails.friendPhoneNumber
                    ? otherDetails.friendPhoneNumber
                    : customer.phoneNumber
                }
              />

              <SpaceInBetweenLayout textA="Order ID:" textB={order.orderRef} />

              <SpaceInBetweenLayout
                textA="No. of items:"
                textB={menuItems.length}
              />

              <SpaceInBetweenLayout
                textA="Date of order:"
                textB={moment(order.createdAt).format("Do MMM, YYYY")}
              />

              <SpaceInBetweenLayout
                textA="Time of order:"
                textB={moment(order.createdAt).format("LTS")}
              />

              <SpaceInBetweenLayout
                textA="Order status:"
                textB={otherDetails.status}
              />
            </Grid>

            {/* delivery details */}
            <Grid container className="order_details_down">
              <SpaceInBetweenLayout
                textA="Delivery area:"
                textB={this.props.area ? this.props.area.address : ""}
              />

              <SpaceInBetweenLayout textA="Date of delivery:" textB="" />

              <SpaceInBetweenLayout
                textA="Time of delivery:"
                textB={otherDetails.deliveryTime}
              />

              <SpaceInBetweenLayout
                textA="Delivery method:"
                textB={otherDetails.deliveryOption}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              id="delivery_address"
              align="center"
            >
              <h3>
                <b>Delivery Address</b>
              </h3>
              <p>{otherDetails.deliveryAddress}</p>
            </Grid>
          </Grid>

          {/* cart details */}
          {menuItems && menuItems.length
            ? menuItems.map((menuItem) => (
                <EachCartItemDetail
                  channel={order.orderChannel}
                  menuItem={menuItem}
                />
              ))
            : ""}

          {/* price and date */}
          <PriceAndDate total={otherDetails.total} />
        </main>
      </>
    );
  }
}
