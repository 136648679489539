import React from "react";
import { timeLimitConvertion } from "../../../../../helpers/MenuPlanHelper";

export default function RangesInDay({ day, timeRanges, menuItem }) {
  return (
    <>
      <section>
        <p>
          {timeRanges && timeRanges.length
            ? timeRanges.map((time) => (
                <>
                  {timeLimitConvertion(time.range)}
                  <span> ({time.quantity}x), </span>
                </>
              ))
            : ""}
        </p>
      </section>
    </>
  );
}
