import React from "react";
import { Button, Grid, TextField } from "@material-ui/core/";

export default function ManageBranch() {
  return (
    <form id="branch_set_up">
      <main>
        {/* region and city */}
        <Grid container id="region_city">
          {/* region */}
          <Grid item xs={12} sm={6} md={6} lg={6} id="region">
            <h4>Region</h4>
            <TextField fullWidth variant="outlined" />
          </Grid>

          {/* city */}
          <Grid item xs={12} sm={6} md={6} lg={6} id="city">
            <h4>City</h4>
            <TextField fullWidth variant="outlined" />
          </Grid>
        </Grid>

        {/* address and area */}
        <Grid container id="address">
          {/* address */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h4>Address</h4>
            <TextField fullWidth variant="outlined" />
          </Grid>
        </Grid>
      </main>

      {/* set work hours */}
      <Grid container id="set_work_hours">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h2>Set work hours</h2>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          <h4>Opening Hours</h4>
          <TextField variant="outlined" type="time" />
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          <h4>Closing Hours</h4>
          <TextField variant="outlined" type="time" />
        </Grid>
      </Grid>

      {/* button */}
      <section className="form-btn">
        <Button>Update</Button>
      </section>
    </form>
  );
}