import React from "react";
import DashboardFrame from "../../../../components/adminComponents/DashboardFrame";
import CustomerOrdersTable from "../../../../components/adminComponents/MenuItemsMGT/Customers/CustomerOrdersTable";
import SettingsFrame from "../../../../components/adminComponents/Settings/SettingsFrame";

export default function CustomerOrders() {
  return (
    <main className="table_page_frame">
      <DashboardFrame
        pageHeading="Order Management"
        dashboardContent={
          <SettingsFrame
            firstLabel="Customer's Orders"
            firstContent={<CustomerOrdersTable />}
          />
        }
      />
    </main>
  );
}
