import React, { useContext } from "react";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";
import EachDayHeader from "../../../MPDetails/AddMPDetails/EachDay/EachDayHeader";
import { getDeliveryTimeForDay } from "../../HelperFunctions";
import EachTimeRangMeals from "./EachTimeRangMeals";

export default function PreviewContentBody({ day }) {
  const { deliveryTimesSelected } = useContext(HelpersContext);

  const dayDeliveryTime = getDeliveryTimeForDay(deliveryTimesSelected, day);

  return (
    <div id="preview_content">
      <div id="each_date">
        <header>
          <EachDayHeader day={day} />
        </header>

        {dayDeliveryTime && dayDeliveryTime.length
          ? dayDeliveryTime.map((time) => (
              <EachTimeRangMeals time={time} day={day} />
            ))
          : ""}
      </div>
    </div>
  );
}
