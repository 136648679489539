import React, { useContext, useState } from "react";
import { TextField, Button } from "@material-ui/core/";
import { HiPlusSm } from "react-icons/hi";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Dialog, DialogTitle } from "@material-ui/core/";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import POSContact from "./POSContact";
import { CustomerContext } from "../../../ContextAPI/CustomerContext";
import LoadingFeedback from "../../helpers/LoadingFeedback";

export default function AddCustomer(props) {
  // context
  const { searchCustomers } = useContext(CustomerContext);

  // props
  const customer = props.customer;

  // states
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [search, setSearch] = useState("");

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <span onClick={handleClickOpen()}>
        {customer ? (
          <POSContact customer={customer} />
        ) : (
          <Button>
            <HiPlusSm />
            <span>Add Customer</span>
          </Button>
        )}
      </span>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <main className="popup_search">
            {/* search form */}
            <TextField
              variant="outlined"
              placeholder="Type at least 3 letters to search"
              name="search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => {
                      searchCustomers(search);
                    }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </main>
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* display customers */}
            <SearchResult
              setCustomer={props.setCustomer}
              closePopup={handleClose}
              cartItems={props.cartItems}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function SearchResult(props) {
  // context
  const { customerLoading, customers } = useContext(CustomerContext);

  // props
  const setCustomer = props.setCustomer;

  // update the cutomer and close the modal
  const setUpCustomer = (customer) => {
    if(props.cartItems.length) return alert("Save order or Empty Cart First!");
    
    setCustomer(customer);
    props.closePopup(false);
  };

  return (
    <>
      {customerLoading === "processing" ? (
        <LoadingFeedback message="Searching For Customers..." />
      ) : customerLoading === "found" && customers.length > 0 ? (
        customers.map((customer) => (
          <main
            className="pending_request_body"
            id="customer_details"
            key={customer.id}
            onClick={() => setUpCustomer(customer)}
          >
            <p>{customer.fullName}</p>
            <p id="phone">{customer.phoneNumber}</p>
            <p id="debt">-0.00 NGN</p>
          </main>
        ))
      ) : customerLoading === "found" && customers.length <= 0 ? (
        <LoadingFeedback message="Search Completed But No Customer Found" />
      ) : customerLoading === "failed" ? (
        <LoadingFeedback message="An Error Occured. Check your Connection" />
      ) : (
        ""
      )}
    </>
  );
}
