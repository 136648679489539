import React, { useContext, useEffect } from "react";
import TimeDetails from "./ViewAMPOrderFrame/TimeDetails";
import DishTable from "./ViewAMPOrderFrame/DishTable";
import PaymentAndDelivery from "./ViewAMPOrderFrame/PaymentAndDelivery";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import PaymentDetails from "./ViewAMPOrderFrame/PaymentDetails";

export default function ViewAMPOrderFrame() {
  const { setDeliveryDetails } = useContext(HelpersContext);

  const order = JSON.parse(localStorage.getItem("MenuPlanOrder"));

  useEffect(() => {
    setDeliveryDetails(JSON.parse(order.deliveryTimeJson));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main id="view_an_order">
      {/* first row */}
      <TimeDetails order={order} />

      {/* second row */}
      <DishTable order={order} />

      {/* third section */}
      <PaymentAndDelivery order={order} />

      <PaymentDetails order={order} />
    </main>
  );
}
