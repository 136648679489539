import React, { Component } from "react";
import * as THREE from "three";
import OrbitControls from "three-orbitcontrols";

export default class LogoAnimation extends Component {
  componentDidMount() {
    const imgSRC = this.props.imgSRC;

    // scene
    this.scene = new THREE.Scene();

    // renderer
    this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    this.renderer.setSize(
      window.innerWidth / this.props.widthDivider,
      window.innerHeight / this.props.heightDivider
    );

    this.mount.appendChild(this.renderer.domElement);

    // camera
    this.camera = new THREE.PerspectiveCamera(
      50,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    this.camera.position.z = 5;

    // create box
    let geometry = new THREE.BoxGeometry(2, 2, 2);

    let texture = new THREE.TextureLoader().load(imgSRC);
    let material = new THREE.MeshBasicMaterial({
      map: imgSRC ? texture : null,
      wireframe: this.props.wireframe,
      color: this.props.color,
    });
    this.cube = new THREE.Mesh(geometry, material);

    // add the cube to the scene
    this.scene.add(this.cube);

    this.animation();

    // orbit controls
    new OrbitControls(this.camera, this.renderer.domElement);

    // when the window size changes
    window.addEventListener("resize", this.handleWindowResize);
  }

  animation = () => {
    requestAnimationFrame(this.animation);
    this.cube.rotation.x += this.props.speed;
    this.cube.rotation.y += this.props.speed;
    this.renderer.render(this.scene, this.camera);
  };

  handleWindowResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.render(this.scene, this.camera);
  };

  render() {
    return (
      <div
        ref={(mount) => {
          this.mount = mount;
        }}
      />
    );
  }
}
