// external imports
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  Select,
} from "@material-ui/core/";

// internal imports
import { RegionContext } from "../../../../ContextAPI/RegionContext";
import { BranchContext } from "../../../../ContextAPI/BranchContext";
import FeedbackPopup from "../../../helpers/FeedbackPopup";

export default function CreateBranchForm() {
  // context
  const { regionLoading, regions } = useContext(RegionContext);
  const { branchLoading, addBranch } = useContext(BranchContext);

  // setup form fields
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [regionId, setRegionId] = useState(null);
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");

  // yup schema for validation
  const schema = yup.object().shape({
    name: yup.string().required(),
    address: yup.string().required(),
    regionId: yup.string().required(),
    openingTime: yup.string().required(),
    closingTime: yup.string().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    addBranch(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="branch_set_up">
      <main>
        {/* region and name */}
        <Grid container id="region_city">
          {/* region */}
          <Grid item xs={12} sm={6} md={6} lg={6} id="region">
            <h4>Region</h4>
            <FormControl variant="outlined" fullWidth>
              <Select
                native
                name="regionId"
                error={!!errors.regionId}
                helperText={errors.regionId ? errors.regionId.message : ""}
                inputRef={register}
                value={regionId}
                onChange={(e) => setRegionId(e.target.value)}
              >
                {regionLoading ? (
                  <option value={null}>Fetching Regions</option>
                ) : (
                  <>
                    {regions &&
                      regions.length > 0 &&
                      regions.map((region) => (
                        <option value={region.id}>{region.name}</option>
                      ))}
                  </>
                )}
              </Select>
            </FormControl>
          </Grid>

          {/* Branch Name */}
          <Grid item xs={12} sm={6} md={6} lg={6} id="city">
            <h4>Branch Name</h4>
            <TextField
              fullWidth
              variant="outlined"
              name="name"
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ""}
              inputRef={register}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
        </Grid>

        {/* address and area */}
        <Grid container id="address">
          {/* address */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h4>Address</h4>
            <TextField
              fullWidth
              variant="outlined"
              name="address"
              error={!!errors.address}
              helperText={errors.address ? errors.address.message : ""}
              inputRef={register}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
        </Grid>
      </main>

      {/* set work hours */}
      <Grid container id="set_work_hours">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h2>Set work hours</h2>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          <h4>Opening Hours</h4>
          <TextField
            variant="outlined"
            type="time"
            name="openingTime"
            error={!!errors.openingTime}
            helperText={errors.openingTime ? errors.openingTime.message : ""}
            inputRef={register}
            value={openingTime}
            onChange={(e) => setOpeningTime(e.target.value)}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          <h4>Closing Hours</h4>
          <TextField
            variant="outlined"
            type="time"
            name="closingTime"
            error={!!errors.closingTime}
            helperText={errors.closingTime ? errors.closingTime.message : ""}
            inputRef={register}
            value={closingTime}
            onChange={(e) => setClosingTime(e.target.value)}
          />
        </Grid>
      </Grid>

      {/* feedback popup */}
      <FeedbackPopup
        condition={branchLoading}
        additionalMessage="Page Reloads in a moment..."
      />

      {/* button */}
      <section className="form-btn">
        <Button type="submit">Create</Button>
      </section>
    </form>
  );
}
