import React, { useContext } from "react";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import { IoCloseSharp } from "react-icons/io5";
import SubmitMenuItem from "../../CreateMenuItem/SubmitMenuItem";

export default function Heading({
  mealPlan,
  closePopup,
  title,
  icon,
  isBtn,
  submitMT,
}) {
  const { customer } = useContext(HelpersContext);

  return (
    <>
      <div className="cart_item_header">
        <section id="plan_title_name">
          {icon} {mealPlan ? mealPlan.name : title} -
          {customer ? <span> - {customer}</span> : ""}
        </section>

        <section id="plan_modal_close">
          {isBtn ? <SubmitMenuItem label="Submit" submitMT={submitMT} /> : ""}
          <p onClick={() => closePopup()}>
            <IoCloseSharp />
          </p>
        </section>
      </div>
    </>
  );
}
