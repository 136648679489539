import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  MenuItem,
  DialogTitle,
} from "@material-ui/core/";
import InputAdornment from "@material-ui/core/InputAdornment";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import AddBankDetails from "./AddBankDetails";
import { UtilsContext } from "../../../ContextAPI/UtilsContext";
import LoadingFeedback from "../../helpers/LoadingFeedback";
import { toggleShowItems } from "../../helpers/MenuItemHelper";
import EachBankDetail from "./EachBankDetail";
import { searchBank } from "../../helpers/OrderHelpers";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import { IoBagCheckOutline, IoCloseSharp } from "react-icons/io5";

export default function CheckoutPopup(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // context
  const { submissionError } = useContext(UtilsContext);

  return (
    <div id="checkout_modal">
      <MenuItem onClick={handleClickOpen()}>CHECKOUT</MenuItem>
      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"md"}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <div className="cart_item_header">
            <section>
              <IoBagCheckOutline /> Checkout
            </section>
            {/* error message */}
            <section>{submissionError}</section>
            <section onClick={() => handleClose()}>
              <IoCloseSharp />
            </section>
          </div>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <CheckoutPopupForm order={props.order} closePopup={handleClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function CheckoutPopupForm(props) {
  // context
  const { utilsLoading, banks, getBanks, makePayment, isSubmitting } =
    useContext(UtilsContext);

  // props
  const order = props.order;
  const menuitemorders = order.menuitemorders;
  const closePopup = props.closePopup;

  // states
  const [showAllBank, setShowAllBank] = useState(false);
  const [search, setSearch] = useState(null);
  const [filteredBanks, setfilteredBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("CASH");
  const [POSRef, setPOSRef] = useState(null);

  useEffect(() => {
    getBanks();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectedBank, paymentMethod, POSRef)
    makePayment({ selectedBank, paymentMethod, POSRef, order, closePopup });
  };

  return (
    <form id="pos_checkout_popup">
      {/* prices */}
      <Grid container>
        <Grid
          item
          xs={5}
          sm={5}
          md={5}
          lg={5}
          className="price_section checkout_prices"
        >
          {/* old */}
          <section>
            <p>Old price</p>
            <p>{menuitemorders.total} NGN</p>
          </section>

          {/* new */}
          <section>
            <p>New price</p>
            <p>{menuitemorders.total} NGN</p>
          </section>

          {/* total */}
          <section>
            <p>Total price</p>
            <p>{menuitemorders.total} NGN</p>
          </section>
        </Grid>

        <div className="pos_vertical_rule"></div>

        {/* payment details */}
        <Grid item xs={6} sm={6} md={6} lg={6} className="price_section">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="checkout_prices"
          >
            <section>
              <h4>Amount Due</h4>
              <p>{menuitemorders.total} NGN</p>
            </section>

            <section>
              <h4>Amount Recieved</h4>
              <p>{menuitemorders.total} NGN</p>
            </section>

            <section>
              <p>{paymentMethod}</p>
              <h4>{menuitemorders.total} NGN</h4>
            </section>

            <section>
              <h4>Balance</h4>
              <p>000 NGN</p>
            </section>
          </Grid>

          {/* payment methods */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="payment_methods"
          >
            <p>Select payment method</p>

            <div>
              {/* Cash */}
              <Button
                onClick={() => setPaymentMethod("CASH")}
                className={
                  paymentMethod === "CASH" ? "selected_payment_method" : ""
                }
              >
                CASH
              </Button>

              {/* POS */}
              <Button
                onClick={() => setPaymentMethod("POS")}
                className={
                  paymentMethod === "POS" ? "selected_payment_method" : ""
                }
              >
                POS
              </Button>

              {/* Transfer */}
              <Button
                onClick={() => setPaymentMethod("TRANSFER")}
                className={
                  paymentMethod === "TRANSFER" ? "selected_payment_method" : ""
                }
              >
                TRANSFER
              </Button>
            </div>
          </Grid>

          {/* POS Ref */}
          {paymentMethod === "POS" ? (
            <Grid item xs={12} sm={12} md={12} lg={12} id="pos_ref">
              {/* input box */}
              <h4>Please enter Ref. No</h4>
              <TextField
                variant="outlined"
                fullWidth
                name="POSRef"
                value={POSRef}
                onChange={(e) => setPOSRef(e.target.value)}
              />
            </Grid>
          ) : (
            ""
          )}

          {/* bank details */}
          {paymentMethod === "TRANSFER" ? (
            <Grid item xs={12} sm={12} md={12} lg={12} id="bank_details">
              {/* input box */}
              <h4>Select banking detail</h4>
              <TextField
                variant="outlined"
                fullWidth
                name="search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyUp={(e) =>
                  searchBank(e.target.value, banks, setfilteredBanks)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() =>
                        toggleShowItems(showAllBank, setShowAllBank)
                      }
                    >
                      {showAllBank ? <MdExpandLess /> : <MdExpandMore />}
                    </InputAdornment>
                  ),
                }}
              />

              {/* options */}
              {showAllBank ? (
                <main>
                  {utilsLoading === "processing" ? (
                    <LoadingFeedback message="Searching For Bankss..." />
                  ) : utilsLoading === "found" && banks.length > 0 ? (
                    banks.map((bank) => (
                      <EachBankDetail
                        bank={bank}
                        setSearch={setSearch}
                        setSelectedBank={setSelectedBank}
                        setShowAllBank={setShowAllBank}
                      />
                    ))
                  ) : utilsLoading === "found" && banks.length <= 0 ? (
                    <LoadingFeedback message="Search Completed But No Bank Found" />
                  ) : utilsLoading === "failed" ? (
                    <LoadingFeedback message="An Error Occured. Check your Connection" />
                  ) : (
                    ""
                  )}
                </main>
              ) : (
                filteredBanks &&
                filteredBanks.length > 0 &&
                filteredBanks.map((bank) => (
                  <EachBankDetail
                    bank={bank}
                    setSearch={setSearch}
                    setSelectedBank={setSelectedBank}
                    setShowAllBank={setShowAllBank}
                  />
                ))
              )}

              <AddBankDetails
                searchData={search}
                setSearch={setSearch}
                setSelectedBank={setSelectedBank}
                setShowAllBank={setShowAllBank}
              />
            </Grid>
          ) : (
            ""
          )}

          {/* feedback message  */}
          <FeedbackPopup
            condition={isSubmitting}
            additionalMessage="Popup closes in a moment..."
          />

          {/* button */}
          <div className="form-btn">
            <Button type="" onClick={(e) => handleSubmit(e)}>Proceed</Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
