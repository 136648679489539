// external imports
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

// local imports
import { returnUniqueInventories } from "../components/helpers/MenuItemHelper";
import { getBaseUrl } from "../Configurations/ContextConfig";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
}

const BASE_URL = getBaseUrl();

// create MenuItemCategory context to manage MenuItemCategory here
export const MenuItemCategoryContext = createContext();

export default function MenuItemCategoryContextProvider(props) {
  // set initial state
  const [menuItemCategoryLoading, setLoading] = useState(false);
  const [menuItemCategories, setMenuItemCategories] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  // make API call to get all the MenuItemCategorys
  useEffect(() => {
    setLoading(true);

    const method = "get",
      url = `${BASE_URL}menuitems/categories`;

    // get MenuItemCategorys
    axios({ url, method, headers })
      .then((result) => {
        setMenuItemCategories(result.data.data.rows);
        setLoading(false);
      })
      .catch((error) => {
        error = new Error();
      });
  }, []);

  // Add Category
  const addCategory = (
    data,
    selectedItemCategory,
    setSelectedItemCategory,
    imageUrl,
    imageCheck
  ) => {
    setLoading("processing");
    // validate image
    if (!imageUrl) return alert("No Image Selected");
    if (
      imageCheck === "setType" ||
      imageCheck === "setSize" ||
      imageCheck === "empty"
    )
      return alert("Wrong Image");

    // process the image
    // instantiate new FIleReader Class
    let imageData = new FileReader();

    // set the path of the image
    imageData.readAsDataURL(imageUrl);

    // when the path is set, make the API call
    imageData.onload = (e) => {
      // set the data
      const options = {
        method: "post",
        url: `${BASE_URL}menuitems/categories`,
        headers,
        data: {
          name: data.name,
          imageUrl: e.target.result,
          description: data.description,
        },
        // do this as image is uploaded to get the progress percentage
        onUploadProgress: (progressEvent) => {
          // destructure progressEvent
          const { loaded, total } = progressEvent;

          // get the percentage by multiplying the loaded by 100
          let percent = Math.floor((loaded * 100) / total);

          // keep showing progress report while it is not completed yet
          if (percent < 100) {
            setUploadPercentage(percent);
          }
        },
      };

      // make API call
      axios(options)
        .then((result) => {
          // get the newly added category details
          const item = result.data.data;
          const newCategory = {
            id: item.id,
            name: item.name,
            imageUrl: item.imageUrl,
            description: item.description,
          };

          // add the newCategory to the table
          selectedItemCategory.push(newCategory);
          // this ensures that there are no duplicates and forces the newPreference to update the UI
          returnUniqueInventories(
            selectedItemCategory,
            setSelectedItemCategory
          );

          setLoading("successful");
          alert("Successful!");
          setUploadPercentage(0);
        })
        .catch((error) => {
          setUploadPercentage(0);
          error = new Error();
          setLoading("failed");
          alert("Failed! There may be a duplicate or a network lag");
        });
    };
  };

  return (
    <MenuItemCategoryContext.Provider
      value={{
        menuItemCategoryLoading,
        menuItemCategories,
        addCategory,
        uploadPercentage,
      }}
    >
      {props.children}
    </MenuItemCategoryContext.Provider>
  );
}
