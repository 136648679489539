import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useContext } from "react";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import { RecipeContext } from "../../../../ContextAPI/RecipeContext";
import CancelOrder from "../Cancel";
import KitchenSlip from "../PrintSlip/KitchenSlip";
import StatusBtn from "../StatusBtn";

export default function TimingAndStatus() {
  // context
  const { recipeDetails } = useContext(RecipeContext);
  const { roles } = useContext(HelpersContext);

  // const
  const dateCreated = recipeDetails.createdAt;
  const status = recipeDetails.status;

  return (
    <>
      <Grid container>
        {/* date_time_container */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="date_time_container"
        >
          <span>{moment(dateCreated).format("L")}</span>
          <span> {moment(dateCreated).format("LTS")}</span>
        </Grid>

        {/* buttons */}
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          className="receipt_kitchen_container"
        >
          {/* Kitchen Slip */}
          <KitchenSlip order={recipeDetails} />
        </Grid>

        {/* time_until_delivery */}
        <Grid container id="until_delivery">
          <Grid item xs={6} sm={6} md={6} lg={6} id="until_delivery_time">
            <p>00:00:00</p>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} id="until_delivery_text">
            <p>(Until Delivery)</p>
          </Grid>

          {/* status button */}
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} id="delivery_btn">
              <StatusBtn order={recipeDetails} />

              {/* cancel order here */}
              {roles.includes("Chef 3") ? (
                status === "Delivered" || status === "Cancelled" ? (
                  ""
                ) : (
                  <CancelOrder order={recipeDetails} />
                )
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
