// external imports
import moment from "moment";
import React, { createContext, useState } from "react";
import { addDays } from "date-fns";
import Cookies from "universal-cookie";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let roles = [];

if (staff) {
  roles = staff.roles.split(",");
}

// create Helpers context to manage Helpers here
export const HelpersContext = createContext();

export default function HelpersContextProvider(props) {
  // get details from session storage
  const createAndEarn = JSON.parse(sessionStorage.getItem("CreateAndEarn"));

  // const
  const presentDay = moment(new Date()).format().split("T")[0];
  const nextDay = moment(moment(presentDay).add(1, "d")._d)
    .format()
    .split("T")[0];

  // set initial state
  const [menuPlanOrder, setMenuPlanOrder] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [morningRanges, setMorningRanges] = useState([]);
  const [afternoonRanges, setAfternoonRanges] = useState([]);
  const [eveningRanges, setEveningRanges] = useState([]);
  const [MPSelectedMenuItem, setMPSelectedMenuItem] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [selectedPOD, setSelectedPOD] = useState([]);
  const [savedMPItems, setSavedMPItems] = useState([]);
  const [MPBasicInfo, setMPBasicInfo] = useState(null);
  const [MPAddMealRedirecting, setMPAddMealRedirecting] = useState(false);
  const [customer, setCustomer] = useState("");

  const [filteredResult, setFilteredResult] = useState(null);
  const [filteredTotalPrice, setFilteredTotalPrice] = useState(null);
  const [page, setPage] = useState(1);

  const [startDate, setStartDate] = useState(presentDay);
  const [endDate, setEndDate] = useState(nextDay);

  const [searchResult, setSearchResult] = useState([]);
  const [search, setSearch] = useState("");
  const [customerId, setCustomerId] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [imgBase64, setImgBase64] = useState(null);
  const [imageCheck, setImageCheck] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [category, setCategory] = useState(null);
  const [agreed, setAgreed] = useState(false);
  const [monthsInItem, setMonthsInItem] = useState([]);
  const [selectedTimeRanges, setSelectedTimeRanges] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [customerMPPreview, setCustomerMPPreview] = useState([]);
  const [modifyDescription, setModifyDescription] = useState("");
  const [modifiedPrice, setModifiedPrice] = useState(700.0);
  const [customerMPTotalPrice, setCustomerMPTotalPrice] = useState(0);
  const [itemsBeforePreview, setItemsBeforePreview] = useState([]);

  const [subscriptionDuration, setSubscriptionDuration] = useState([]);
  const [deliveryTimesSelected, setDeliveryTimesSelected] = useState([]);
  const [customerMPAddons, setCustomerMPAddons] = useState([]);
  const [customerMPPreferences, setCustomerMPPreferences] = useState([]);
  const [checked, setChecked] = useState(false);
  const [checkedItemValue, setCheckedItemValue] = useState(null);
  const [addonOrPreference, setAddonOrPreference] = useState(null);
  const [surveyId, setSurveyId] = useState(null);

  const [deliveryDetails, setDeliveryDetails] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [description, setDescription] = useState("");

  const [toggle, setToggle] = useState(true);
  const [show, setShow] = useState(false);

  const [title, setTitle] = useState(
    createAndEarn && createAndEarn.fromCreateAndEarn ? "NOT APPROVED" : ""
  );
  const [topic, setTopic] = useState("");
  const [currentCreateAndEarnRecipe, setCurrentCreateAndEarnRecipe] =
    useState(null);

  const [type, setType] = useState(null);

  return (
    <HelpersContext.Provider
      value={{
        menuPlanOrder,
        setMenuPlanOrder,
        selectedMeal,
        setSelectedMeal,
        MPSelectedMenuItem,
        setMPSelectedMenuItem,
        dateRange,
        setDateRange,
        selectedPOD,
        setSelectedPOD,
        morningRanges,
        afternoonRanges,
        eveningRanges,
        setMorningRanges,
        setAfternoonRanges,
        setEveningRanges,
        savedMPItems,
        setSavedMPItems,
        MPBasicInfo,
        setMPBasicInfo,
        presentDay,
        nextDay,
        MPAddMealRedirecting,
        setMPAddMealRedirecting,
        roles,
        customer,
        setCustomer,
        filteredResult,
        setFilteredResult,
        filteredTotalPrice,
        setFilteredTotalPrice,
        page,
        setPage,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        searchResult,
        setSearchResult,
        search,
        setSearch,
        imageUrl,
        setImageUrl,
        imgBase64,
        setImgBase64,
        imageCheck,
        setImageCheck,
        uploadPercentage,
        setUploadPercentage,
        category,
        setCategory,
        agreed,
        setAgreed,
        monthsInItem,
        setMonthsInItem,
        selectedTimeRanges,
        setSelectedTimeRanges,
        selectedMonths,
        setSelectedMonths,
        selectedDays,
        setSelectedDays,
        customerMPPreview,
        setCustomerMPPreview,
        modifyDescription,
        setModifyDescription,
        modifiedPrice,
        setModifiedPrice,
        customerMPTotalPrice,
        setCustomerMPTotalPrice,
        subscriptionDuration,
        setSubscriptionDuration,
        deliveryTimesSelected,
        setDeliveryTimesSelected,
        customerMPAddons,
        setCustomerMPAddons,
        checked,
        setChecked,
        checkedItemValue,
        setCheckedItemValue,
        addonOrPreference,
        setAddonOrPreference,
        customerMPPreferences,
        setCustomerMPPreferences,
        customerId,
        setCustomerId,
        itemsBeforePreview,
        setItemsBeforePreview,
        surveyId,
        setSurveyId,
        deliveryDetails,
        setDeliveryDetails,
        orderStatus,
        setOrderStatus,
        selectedCustomers,
        setSelectedCustomers,
        description,
        setDescription,
        toggle,
        setToggle,
        title,
        setTitle,
        setTopic,
        topic,
        show,
        setShow,
        currentCreateAndEarnRecipe,
        setCurrentCreateAndEarnRecipe,
        type,
        setType,
      }}
    >
      {props.children}
    </HelpersContext.Provider>
  );
}
