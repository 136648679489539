import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Button } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import NumberFormaterSuffix from "../../helpers/NumberFormaterSuffix";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

export default function SingleMenuItem(props) {
  const classes = useStyles();
  const menuItem = props.menuItem;

  return (
    <Card className={classes.root}>
      <CardActionArea>
        {/* item image */}
        <CardMedia
          component="img"
          alt={menuItem.itemName}
          height="100"
          image={menuItem.imageUrl}
          title={menuItem.description}
        />

        {/* item name */}
        <CardContent title={menuItem.description}>
          <Typography variant="body2" color="textSecondary" component="p">
            {menuItem.itemName}
          </Typography>
        </CardContent>
      </CardActionArea>

      {/* price */}
      <CardActions title={menuItem.description}>
        <Button size="small" color="primary" align="center">
        <NumberFormaterSuffix value={menuItem.amount} />
        </Button>
      </CardActions>
    </Card>
  );
}
