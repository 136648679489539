// external imports
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};
let branchId = null;

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
  branchId = staff.branchId
}

const BASE_URL = getBaseUrl();

// create Schedule context to manage Schedule here
export const ScheduleContext = createContext();

export default function ScheduleContextProvider(props) {
  // set initial state
  const [scheduleLoading, setLoading] = useState(null);
  const [schedules, setSchedules] = useState([]);

  // make API call to get all the Schedules
  useEffect(() => {
    setLoading(true);

    const method = "get",
    url = `${BASE_URL}menuplans/time/branch/${branchId}`;

    // get Schedules
    axios({ url, method, headers })
      .then((result) => {
        setSchedules(result.data.data.data.rows);
        setLoading(false);
      })
      .catch((error) => {
        error = new Error();
      });
  }, []);

  // add Schedule
  const addSchedule = (inputs, isActive) => {
    setLoading("processing");

    // get config for API call
    const method = "post",
      url = `${BASE_URL}menuplans/time/`,
      data = {
        timeRange: inputs.lowerTimeLimit + " - " + inputs.upperTimeLimit,
        isActive: isActive,
        planType: inputs.periodOfDay,
        branchId: staff.branchId,
      };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setLoading("successful");

        setTimeout(() => {
          window.location.href = window.location.href.split( '#' )[0];
        }, 3000);
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  return (
    <ScheduleContext.Provider
      value={{ scheduleLoading, schedules, addSchedule }}
    >
      {props.children}
    </ScheduleContext.Provider>
  );
}
