import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { RecipeContext } from "../../../../../ContextAPI/RecipeContext";
import DeliveryDateTime from "./DeliveryDateTime";

const SelectedDT = () => {
  const { recipeDetails } = useContext(RecipeContext);
  const deliveryTimes = recipeDetails.RecipeOrderDeliveryDates;

  return (
    <>
      <Grid container id="select_delivery_time">
        <Grid item xs={12} sm={12} md={12} lg={12} id="heading">
          <h2>SELECTED TIME OF DELIVERY</h2>
        </Grid>

        {deliveryTimes && deliveryTimes.length ? (
          deliveryTimes.map((time) => <DeliveryDateTime detail={time} />)
        ) : (
          <p>No Delivery Time Found</p>
        )}
      </Grid>
    </>
  );
};

export default SelectedDT;
