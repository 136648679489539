import React from "react";
import { Grid } from "@material-ui/core";
import SpaceInBetweenLayout from "../../../helpers/SpaceInBetweenLayout";
import moment from "moment";
import NumberFormaterPrefix from "../../../helpers/NumberFormaterPrefix";

export default function PriceAndDate({ total }) {
  return (
    <section className="price_and_date">
      <Grid container key="1">
        {/* Total Price */}
        <SpaceInBetweenLayout
          mainClass="price"
          textA="Price"
          textB={<NumberFormaterPrefix value={total} />}
        />
      </Grid>
      <h5>Printed: {moment().format("Do MMMM, YYYY; h:mm a")}.</h5>
    </section>
  );
}
