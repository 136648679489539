import React from "react";
import CreateMenuPlanDetailsForm from "../../../components/adminComponents/CreateMenuPlan/CreateMenuPlanDetailsForm";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";

export default function CreateMenuPlanDetails(props) {
  const dateRange = props.location.state;
  return (
    <>
      <DashboardFrame
        pageHeading="Menu Plan Details"
        dashboardContent={<CreateMenuPlanDetailsForm dateRange={dateRange} />}
      />
    </>
  );
}
