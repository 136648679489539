import ViewAll from "../../../components/adminComponents/CreateAndEarn/ViewAll";
import RecipeTable from "../CustomerRecipe/RicepeTable";

const CreateAndEarn = () => {
  return (
    <>
      <RecipeTable
        table={<ViewAll />}
        title="View Table"
        pageHeading="View create and earn requests"
      />
    </>
  );
};

export default CreateAndEarn;
