import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import DeliveryArea from "../../../components/adminComponents/Settings/Branch/DeliveryArea";
import ManageBranch from "../../../components/adminComponents/Settings/Branch/ManageBranch";
import SettingsFrame from "../../../components/adminComponents/Settings/SettingsFrame";

export default function BranchSetUp() {
  return (
    <>
      <DashboardFrame
        pageHeading="Settings"
        dashboardContent={
          <SettingsFrame
            firstLabel="Delivery Area"
            firstContent={<DeliveryArea />}
            secondLabel="Update Branch"
            secondContent={<ManageBranch />}
          />
        }
      />
    </>
  );
}
