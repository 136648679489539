import React from "react";
import CreateVoucherForm from "../../components/adminComponents/CreateVoucher/CreateVoucherForm";
import DashboardFrame from "../../components/adminComponents/DashboardFrame";

export default function CreateVoucher() {
  return (
    <>
      <DashboardFrame
        pageHeading="Voucher"
        dashboardContent={<CreateVoucherForm />}
      />
    </>
  );
}
