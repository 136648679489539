import React from "react";
import { Grid } from "@material-ui/core";
import SpaceInBetweenLayout from "../../../helpers/SpaceInBetweenLayout";
import moment from "moment";
import NumberFormaterPrefix from "../../../helpers/NumberFormaterPrefix";

export default function PriceAndDate({ total, order }) {
  return (
    <section className="price_and_date">
      <Grid container key="1">
        {/* unit price */}
        <SpaceInBetweenLayout
          mainClass="unit_price"
          textA="Unit Price"
          textB={<NumberFormaterPrefix value={total} />}
        />

        {/* Total Price */}
        <SpaceInBetweenLayout
          mainClass="price"
          textA="Total Price"
          textB={<NumberFormaterPrefix value={order ? order.total : ""} />}
        />
      </Grid>
      <h5>Printed: {moment().format("Do MMMM, YYYY; h:mm a")}.</h5>
    </section>
  );
}
