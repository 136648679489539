import { MenuItem } from "@material-ui/core";
import React, { useContext } from "react";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import { MPManagementContext } from "../../../ContextAPI/MPManagementContext";
import { getMPStatus } from "../../helpers/MenuPlanHelper";

export default function StatusBtn({ order }) {
  // context
  const { changeMealDTstatus } = useContext(MPManagementContext);
  const { deliveryDetails } = useContext(HelpersContext);

  const status = getMPStatus(deliveryDetails, order.deliveryTime);

  return (
    <>
      <MenuItem
        onClick={() =>
          changeMealDTstatus(
            order.orderedBy,
            order.id,
            order.basketId,
            deliveryDetails,
            order.deliveryTime.time,
            status === "Pending"
              ? "Accepted"
              : status === "Accepted"
              ? "Processing"
              : status === "Processing"
              ? "Ready"
              : status === "Ready"
              ? "Delivered"
              : ""
          )
        }
      >
        {status === "Pending"
          ? "Accept"
          : status === "Accepted"
          ? "Begin Processing"
          : status === "Processing"
          ? "Ready"
          : status === "Ready"
          ? "Deliver"
          : ""}
      </MenuItem>
    </>
  );
}
