import { Checkbox, CircularProgress, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext } from "react";
import { CustomerContext } from "../../../ContextAPI/CustomerContext";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import { getFilteredCustomers } from "./Helper";

export default function CustomerSearchCheckbox() {
  // context
  const { searchCustomers, customerLoading, customers } =
    useContext(CustomerContext);
  const { setCustomer, setSelectedCustomers } = useContext(HelpersContext);

  // show what is typed in the input field and make search
  const filterCustomer = (value) => {
    setCustomer(value);
    searchCustomers(value);
  };

  return (
    <>
      <Grid item xs={9} sm={9} md={9} lg={9} id="search_customer">
        <Autocomplete
          multiple
          id="filter_customer"
          options={getFilteredCustomers(customers)}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          onChange={(event, value) => setSelectedCustomers(value)}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox checked={selected} />
              {option}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              onChange={(e) => filterCustomer(e.target.value)}
              autoFocus
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {customerLoading === "processing" ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </>
  );
}
