import React from "react";
import { Grid } from "@material-ui/core";
import MealPlan from "../ViewMealPlans/MealPlan";
import LoadingFeedback from "../../../helpers/LoadingFeedback";
import SearchItems from "../../MPCategoryMGT/SearchItems";
import FilterByCategory from "../ViewMealPlans/FilterByCategory";

export default function MealPlans({ plans, searchResult, search, fetchingMP }) {
  return (
    <main id="view_mwnu_items">
      {/* filter */}
      <Grid container className="top_filter">
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{plans.length}</span>
          </p>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

        {/* filter section */}
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          className="filter_forms"
          id="pos_filter"
        >
          {/* search field */}
          <SearchItems item={plans} isName={true} />

          {/* categories */}
          <FilterByCategory />
        </Grid>
      </Grid>

      {/* menu plans */}
      <Grid container id="meal_items">
        {/* display menu plans here */}
        {searchResult && searchResult.length > 0 ? (
          searchResult.map((result) => (
            <MealPlan mealPlan={result} isEdit={true} />
          ))
        ) : search && searchResult && searchResult.length <= 0 ? (
          "No Result Found!"
        ) : fetchingMP === "processing" ? (
          <LoadingFeedback message="Searching For Meal Plans..." />
        ) : fetchingMP === "found" && plans.length > 0 ? (
          plans.map((result) => <MealPlan mealPlan={result} isEdit={true} />)
        ) : fetchingMP === "found" && plans.length <= 0 ? (
          <LoadingFeedback message="Search Completed But No Meal Plan Found" />
        ) : fetchingMP === "failed" ? (
          <LoadingFeedback message="An Error Occurred. There might be no items available. Also, Check your Connection" />
        ) : (
          ""
        )}
      </Grid>
    </main>
  );
}
