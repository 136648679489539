import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Grid } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import AvailabilityAndDisplay from "../MenuItemsMGT/ViewMenuItems/AvailabilityAndDisplay";
import EditForm from "./EditForm";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

export default function Category(props) {
  const classes = useStyles();
  const category = props.category;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card className={classes.root} key={category.id}>
        <CardActionArea>
          {/* item image */}
          <CardMedia
            component="img"
            alt={category.name}
            height="100"
            image={category.imageurl}
            title={category.description}
          />

          {/* item name */}
          <CardContent title={category.name}>
            <Typography variant="body2" color="textSecondary" component="p">
              {category.name}
            </Typography>
          </CardContent>
        </CardActionArea>

        {/* price */}
        <CardActions title={category.description}>
          <EditForm
            item={category}
            headerComponent={
              <AvailabilityAndDisplay
                item={category}
                url={`/menuplans/category`}
                isCategory={true}
              />
            }
          />
        </CardActions>
      </Card>
    </Grid>
  );
}
