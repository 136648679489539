import { Grid } from "@material-ui/core";
import React from "react";
import RecipeDetails from "../RecipeDetails";

const UpdateForm = ({ isRecipeOrder }) => {
  return (
    <>
      <Grid container id="update_form">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {<RecipeDetails isRecipeOrder={isRecipeOrder} />}
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateForm;
