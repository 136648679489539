import React, { useContext } from "react";
import { MPManagementContext } from "../../../../ContextAPI/MPManagementContext";
import { sortByStatus } from "../../../helpers/MenuPlanHelper";
import MPOrdersMGT from "../MPOrdersMGT";

export default function HistoryMPOrdersTable() {
  // context
  const { MPOrders } = useContext(MPManagementContext);

  let deliveredOrders = sortByStatus(MPOrders, "Delivered");
  let cancelledOrders = sortByStatus(MPOrders, "Cancelled");

  const orders = [...deliveredOrders, ...cancelledOrders];
  return (
    <>
      <MPOrdersMGT orders={orders} group={"History"} />
    </>
  );
}
