import { Grid } from "@material-ui/core";
import React from "react";

export default function DeliveryAddress({ order }) {
  const recipeOrder = order.RecipeOrder;

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        id="delivery_address"
        align="center"
      >
        <h3>
          <b>Delivery Address</b>
        </h3>
        <p>{recipeOrder.deliveryAddress}</p>
      </Grid>
    </>
  );
}
