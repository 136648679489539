// external imports
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core/";
import { useHistory } from "react-router-dom";

// local imports
import { MenuPlanContext } from "../../../ContextAPI/MenuPlanContext";
import ImageUploadProgress from "../../helpers/ImageUploadProgress";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import ImageInput from "../MPCategoryMGT/ImageInput";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";

export default function EditMealPlanForm(props) {
  const history = useHistory();
  const mealPlan = props.mealPlan;

  // context
  const { editMenuPlan, uploadPercentage, updatingMP } =
    useContext(MenuPlanContext);
  const { imgBase64 } = useContext(HelpersContext);

  const [isActive, setIsActive] = useState(mealPlan.isactivated);
  const [description, setDescription] = useState(mealPlan.description);
  const [name, setName] = useState(mealPlan.name);

  // yup schema for validation
  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
  });

  // get what we need from useform()
  const { register, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <form id="create_menu_plan_form" className="form_frame">
      <h1>Edit Menu Plan</h1>

      <Grid container>
        {/* Upload photo */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ImageInput isEdit={true} initialImage={mealPlan.imageurl} />
        </Grid>

        {/* menu plan name */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <h4>Menu plan name</h4>
          <TextField
            fullWidth
            variant="outlined"
            name="name"
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ""}
            inputRef={register}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>

        {/* Description */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <h4>Description</h4>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            name="description"
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ""}
            inputRef={register}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>

        {/* Add category */}
        <Grid item xs={12} sm={12} md={6} lg={6} id="menu_category_input">
          <h4>Category</h4>
          <TextField
            variant="outlined"
            fullWidth
            disabled
            value={mealPlan.MenuPlanCategory.name}
          />
        </Grid>

        {/* Start Date */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <h4>Start Date</h4>
          <TextField
            fullWidth
            disabled
            variant="outlined"
            value={mealPlan.startDate.split("T")[0]}
          />
        </Grid>

        {/* End date */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <h4>End Date</h4>
          <TextField
            fullWidth
            disabled
            variant="outlined"
            value={mealPlan.endDate.split("T")[0]}
          />
        </Grid>

        {/* set isActive */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl component="fieldset" className="switch_input">
            <FormGroup aria-label="DisplayOnApp" row>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive}
                    color="primary"
                    onChange={(e) => setIsActive(e.target.checked)}
                  />
                }
                label="Active"
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      {/* progress bar */}
      <ImageUploadProgress progress={uploadPercentage} />

      {/* feedback popup */}
      <FeedbackPopup
        condition={updatingMP}
        additionalMessage="Redirecting..."
      />

      {/* button */}
      <section className="form-btn" id="mp_update_buttons">
        {/* update */}
        <Button
          onClick={(e) => {
            editMenuPlan(
              name,
              description,
              imgBase64,
              isActive,
              mealPlan,
              e.target.innerHTML,
              history
            );
          }}
        >
          Update
        </Button>

        {/* update and AddMeals */}
        <Button
          onClick={(e) => {
            editMenuPlan(
              name,
              description,
              imgBase64,
              isActive,
              mealPlan,
              e.target.innerHTML,
              history
            );
          }}
        >
          Update And Add Meals
        </Button>
      </section>
    </form>
  );
}
