import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useContext } from "react";
import { HelpersContext } from "../../../../../../ContextAPI/HelpersContext";
import { isDaySelected } from "../../../HelperFunctions";
import { BiEdit } from "react-icons/bi";

export default function Day({ day, isEdit }) {
  const { itemsBeforePreview } = useContext(HelpersContext);

  const isSelected = isDaySelected(itemsBeforePreview, day);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        id="day"
        className={isSelected ? "selectedFrom" : ""}
      >
        {isEdit ? (
          <BiEdit />
        ) : (
          <>
            <p>{moment(day).format("ddd")}</p>
            <p>{moment(day).format("l").split("/")[1]}</p>
          </>
        )}
      </Grid>
    </>
  );
}
