// external imports
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { getBaseUrl } from "../Configurations/ContextConfig";

const BASE_URL = getBaseUrl();

// create Region context to manage Region here
export const RegionContext = createContext();

export default function RegionContextProvider(props) {
  // set initial state
  const [regionLoading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);

  // make API call to get all the regions
  useEffect(() => {
    setLoading(true);

    const method = "get",
      url = `${BASE_URL}utils/region/`;

    // get regions
    axios({ url, method })
      .then((result) => {
        setRegions(result.data.data.data.rows);
        setLoading(false);
      })
      .catch((error) => {
        error = new Error();
      });
  }, []);

  return (
    <RegionContext.Provider value={{ regionLoading, regions }}>
      {props.children}
    </RegionContext.Provider>
  );
}
