import React, { useContext } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core/";
import Slide from "@material-ui/core/Slide";
import Heading from "../Heading";
import Day from "./Day";
import { MPManagementContext } from "../../../../../../ContextAPI/MPManagementContext";
import moment from "moment";
import TimeRanges from "./TimeRanges";
import { HelpersContext } from "../../../../../../ContextAPI/HelpersContext";
import { getItemRangesArray, isDaySelected } from "../../../HelperFunctions";
import { CustomerContext } from "../../../../../../ContextAPI/CustomerContext";
import SubmitButton from "../SubmitButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DayPopup({ day, menuItem, isEdit }) {
  // context
  const { getMPItemsTimes } = useContext(MPManagementContext);
  const { itemsBeforePreview } = useContext(HelpersContext);

  const isSelected = isDaySelected(itemsBeforePreview, day);

  // states
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => () => {
    if (isSelected) return;

    setOpen(true);
    setScroll("paper");

    // get the delivery times available for this day
    getMPItemsTimes(
      menuItem.menuplanId,
      menuItem.menuitemid,
      moment(day).format("YYYY-MM-DD")
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Grid item xs={1} sm={1} md={1} lg={1} className="item_popup">
      <section onClick={handleClickOpen()}>
        <Day day={day} isEdit={isEdit} />
      </section>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        disableBackdropClick={true}
        TransitionComponent={Transition}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <Heading closePopup={handleClose} />
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Content day={day} closePopup={handleClose} menuItem={menuItem} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

function Content({ day, closePopup, menuItem }) {
  // context
  const { availableTimes, gettingTime } = useContext(MPManagementContext);
  const { clearDateAndTime } = useContext(CustomerContext);

  // context
  const {
    selectedTimeRanges,
    selectedMonths,
    selectedDays,
    agreed,
    modifyDescription,
    customerMPTotalPrice,
    customerMPAddons,
    customerMPPreferences,
    itemsBeforePreview,
    setItemsBeforePreview,
  } = useContext(HelpersContext);

  // add to current items before preview
  const addItem = () => {
    setItemsBeforePreview([
      ...itemsBeforePreview,
      {
        menuItem,
        selectedTimeRanges,
        timeRangeArray: getItemRangesArray(selectedTimeRanges),
        selectedMonths,
        selectedDays,
        price: customerMPTotalPrice,
        modifyDescription: agreed ? modifyDescription : "",
        addons: customerMPAddons,
        preferences: customerMPPreferences,
      },
    ]);

    clearDateAndTime();

    closePopup();
  };

  return (
    <div id="time_qty_adjustment">
      <header>
        <p>{moment(day).format("dddd, MMMM Do YYYY")}</p>
      </header>

      <Grid container id="time_qty">
        <TimeRanges
          content={"TIME"}
          items={availableTimes}
          loading={gettingTime}
          day={day}
        />
      </Grid>

      <section className="form-btn">
        <SubmitButton label={"Proceed"} submitFunction={addItem} />
      </section>
    </div>
  );
}
