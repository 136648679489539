import React from "react";
import { Button, Grid, TextField } from "@material-ui/core/";

export default function Order() {
  return (
    <form id="order_settings">
      <Grid container id="upper_section">
        {/* Processing and Dispatch Ready time */}
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          lg={5}
          id="processing_dispatch_ready_time"
        >
          {/* Processing time */}
          <section>
            <h3>Processing time</h3>

            <p>
              Time to start preparing food. It is advised that the processing
              time is set, few hours before delivery time.
            </p>

            <h5>Set Time</h5>

            {/* hour and minute input fields */}
            <Grid container>
              <Grid item xs={3} sm={3} md={3} lg={3} className="hour">
                <TextField label="Hour" variant="outlined" type="number" />
              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} className="minute">
                <TextField label="Minute" variant="outlined" type="number" />
              </Grid>
            </Grid>
          </section>

          {/* Dispatch ready time */}
          <section id="dispatch_ready_time">
            <h3>Dispatch ready time</h3>

            <p>
              Time to start preparing food. It is advised that the processing
              time is set, few hours before delivery time.
            </p>

            <h5>Set Time</h5>

            {/* hour and minute input fields */}
            <Grid container>
              <Grid item xs={3} sm={3} md={3} lg={3} className="hour">
                <TextField label="Hour" variant="outlined" type="number" />
              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} className="minute">
                <TextField label="Minute" variant="outlined" type="number" />
              </Grid>
            </Grid>
          </section>
        </Grid>

        <div id="vertical_rule"></div>

        <Grid item xs={12} sm={5} md={5} lg={5} id="waiting_time">
          <section>
            <h3>Waiting time</h3>

            <p>
              Time to start preparing food. It is advised that the processing
              time is set, few hours before delivery time.
            </p>

            <h5>Set Time</h5>

            {/* hour and minute input fields */}
            <Grid container>
              <Grid item xs={3} sm={3} md={3} lg={3} className="hour">
                <TextField label="Hour" variant="outlined" type="number" />
              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} className="minute">
                <TextField label="Minute" variant="outlined" type="number" />
              </Grid>
            </Grid>
          </section>
        </Grid>
      </Grid>

      {/* button */}
      <section className="form-btn">
        <Button>Update</Button>
      </section>
    </form>
  );
}
