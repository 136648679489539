import React from "react";
import { BiPrinter } from "react-icons/bi";
import SubmitButton from "../../CustomerMP/Create/itemPopup/SubmitButton";

const Print = () => {
  return (
    <>
      <SubmitButton
        icon={<BiPrinter />}
        label="Print page"
        altClass={"alt_class"}
        submitFunction={() => window.print()}
      />
    </>
  );
};

export default Print;
