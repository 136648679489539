// external imports
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// assets
import "./assets/index.css";

// internal imports
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import StaffContextProvider from "./ContextAPI/StaffContext";
import RegionContextProvider from "./ContextAPI/RegionContext";
import BranchContextProvider from "./ContextAPI/BranchContext";
import InventoryCategoryContextProvider from "./ContextAPI/InventoryCategoryContext";
import InventoryContextProvider from "./ContextAPI/InventoryContext";
import MenuItemPreferenceContextProvider from "./ContextAPI/MenuItemPreferenceContext";
import MenuItemCategoryContextProvider from "./ContextAPI/MenuItemCategoryContext";
import MenuItemContextProvider from "./ContextAPI/MenuItemContext";
import ScheduleContextProvider from "./ContextAPI/ScheduleContext";
import MenuPlanCategoryContextProvider from "./ContextAPI/MenuPlanCategoryContext";
import MenuPlanContextProvider from "./ContextAPI/MenuPlanContext";
import CustomerContextProvider from "./ContextAPI/CustomerContext";
import CartContextProvider from "./ContextAPI/CartContext";
import OrderContextProvider from "./ContextAPI/OrderContext";
import UtilsContextProvider from "./ContextAPI/UtilsContext";
import MPManagementContextProvider from "./ContextAPI/MPManagementContext";
import HelpersContextProvider from "./ContextAPI/HelpersContext";
import EditMenuItemContextProvider from "./ContextAPI/EditMenuItemContext";
import RecipeContextProvider from "./ContextAPI/RecipeContext";
import SurveyContextProvider from "./ContextAPI/SurveyContext";
import NotificationContextProvider from "./ContextAPI/NotificationContext";
import CreateAndEarnContextProvider from "./ContextAPI/CreateAndEarn";

ReactDOM.render(
  <HelpersContextProvider>
    <CreateAndEarnContextProvider>
      <NotificationContextProvider>
        <SurveyContextProvider>
          <RecipeContextProvider>
            <EditMenuItemContextProvider>
              <MPManagementContextProvider>
                <UtilsContextProvider>
                  <OrderContextProvider>
                    <CartContextProvider>
                      <CustomerContextProvider>
                        <MenuPlanContextProvider>
                          <MenuPlanCategoryContextProvider>
                            <ScheduleContextProvider>
                              <MenuItemContextProvider>
                                <MenuItemCategoryContextProvider>
                                  <MenuItemPreferenceContextProvider>
                                    <InventoryContextProvider>
                                      <InventoryCategoryContextProvider>
                                        <BranchContextProvider>
                                          <RegionContextProvider>
                                            <StaffContextProvider>
                                              <BrowserRouter>
                                                <React.StrictMode>
                                                  <App />
                                                </React.StrictMode>
                                              </BrowserRouter>
                                            </StaffContextProvider>
                                          </RegionContextProvider>
                                        </BranchContextProvider>
                                      </InventoryCategoryContextProvider>
                                    </InventoryContextProvider>
                                  </MenuItemPreferenceContextProvider>
                                </MenuItemCategoryContextProvider>
                              </MenuItemContextProvider>
                            </ScheduleContextProvider>
                          </MenuPlanCategoryContextProvider>
                        </MenuPlanContextProvider>
                      </CustomerContextProvider>
                    </CartContextProvider>
                  </OrderContextProvider>
                </UtilsContextProvider>
              </MPManagementContextProvider>
            </EditMenuItemContextProvider>
          </RecipeContextProvider>
        </SurveyContextProvider>
      </NotificationContextProvider>
    </CreateAndEarnContextProvider>
  </HelpersContextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
