import React, { useContext, useEffect } from "react";
import SpaceInBetweenLayout from "../../../helpers/SpaceInBetweenLayout";
import { Grid } from "@material-ui/core";
import { MenuItemContext } from "../../../../ContextAPI/MenuItemContext";
import {
  getAdminMPPreferences,
  getMPPreferences,
} from "../../../helpers/MPOrderHelpers";

export default function MPItemDetail({ quantity, order }) {
  // context
  const { getMenuItemInventories, menuItemInventories } =
    useContext(MenuItemContext);
  const preferences = JSON.parse(order.preferences);

  // addons
  let addons = [];

  if (order.addedByAdmin) {
    addons = JSON.parse(order.addons);
  } else {
    addons = JSON.parse(JSON.parse(order.addons));
  }

  useEffect(() => {
    getMenuItemInventories(order.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container className="cart_item_details" key={order.id}>
        {/* menu item name */}
        <Grid item xs={12} sm={12} md={12} lg={12} id="cart_item_heading">
          <>
            <h4>({quantity} plate[s])</h4> <h4>{order.itemName}</h4>
          </>
        </Grid>

        {/* inventory */}
        <SpaceInBetweenLayout
          mainClass="dashed_bottom"
          idA="text_bold"
          idB="text_bold"
          textA="Item"
          textB="Unit"
        />

        {/* main items */}
        {menuItemInventories && menuItemInventories.length
          ? menuItemInventories.map((item) => (
              <SpaceInBetweenLayout
                textA={item.Inventory.itemName.toUpperCase()}
                textB={parseFloat(item.quantity)}
              />
            ))
          : ""}

        {/* admin addons */}
        {addons && addons.length && order.addedByAdmin
          ? addons.map((addon) => (
              <SpaceInBetweenLayout
                textA={
                  addon.isExtra
                    ? "Extra " + addon.Inventory.itemName.toUpperCase()
                    : addon.Inventory.itemName.toUpperCase()
                }
                textB={
                  parseFloat(addon.quantity) *
                  parseFloat(addon.customerQuantity)
                }
              />
            ))
          : ""}

        {/* user addons */}
        {addons && addons.length && !order.addedByAdmin
          ? addons.map((addon) => (
              <SpaceInBetweenLayout
                textA={
                  addon.isExtra
                    ? "Extra " + addon.name.toUpperCase()
                    : addon.name.toUpperCase()
                }
                textB={
                  parseFloat(addon.originalQuantity) *
                  parseFloat(addon.quantity)
                }
              />
            ))
          : ""}

        <Grid container id="preference_instructions">
          {/* Preference */}
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <p>Preference:</p>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <p>
              {preferences && !order.addedByAdmin
                ? getMPPreferences(preferences).join(", ")
                : ""}

              {preferences && order.addedByAdmin
                ? getAdminMPPreferences(preferences).join(", ")
                : ""}
            </p>
          </Grid>

          {/* Special Instructions: */}
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <p>Special Instructions:</p>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <p>{order.specialInstruction}</p>
          </Grid>

          {/* Note: */}
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <p>Note:</p>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <p>{order.note}</p>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
