import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core/";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { toggleShowItems } from "../../helpers/MenuItemHelper";
import { getAddonsDetails, getNames } from "../../helpers/POSOrderHelper";

export default function EachRequestItem(props) {
  // props
  const cartItem = props.cartItem;

  // state
  const [showDetails, setshowDetails] = useState(false);

  return (
    <>
      <main id="each_cart_meal" key={cartItem.Cart.id}>
        <Grid container>
          <Grid item xs={2} sm={2} md={2} lg={2} id="qty">
            <span>
              <p>{cartItem.Cart.quantity}</p>
            </span>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <p>{cartItem.Cart.MenuItem.itemName}</p>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} id="price" align="right">
            <p>{parseFloat(cartItem.Cart.amount).toFixed(2)} NGN</p>
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            align="center"
            id="view_more_btn"
          >
            <Button
              onClick={() => {
                toggleShowItems(showDetails, setshowDetails);
              }}
            >
              {showDetails ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
            </Button>
          </Grid>
        </Grid>
      </main>

      {showDetails ? (
        <section id="each_cart_meal_details">
          {/* display the addons and preferences */}
          <p>
            {getAddonsDetails(JSON.parse(cartItem.Cart.addons)).split(",").join(", ") +
              ", " +
             getNames(JSON.parse(cartItem.Cart.preferences)).join(", ")}
          </p>

          {/* show the special instructions */}
          <details>
            <summary>Special Instruction</summary>
            <blockquote>
              {cartItem.Cart.specialInstruction
                ? cartItem.Cart.specialInstruction
                : "No Special Instructions For This Item"}
            </blockquote>
          </details>
        </section>
      ) : (
        ""
      )}
    </>
  );
}
