import React from "react";
import Content from "../../../components/adminComponents/PageNotFound/Content";

export default function NoPermissionsFound() {
  return (
    <>
      <Content
        message="You are not permitted to be here"
        reason="You have not been assigned any permissions yet. Please contact your superior!"
      />
    </>
  );
}
