// external imports
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
}

const BASE_URL = getBaseUrl();

// create Inventory context to manage Inventory here
export const InventoryContext = createContext();

export default function InventoryContextProvider(props) {
  // set initial state
  const [inventoryLoading, setLoading] = useState(false);
  const [inventories, setInventory] = useState([]);

  // make API call to get all the Inventory
  useEffect(() => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}inventories?page=1`;

    // get Inventories
    axios({ url, method, headers })
      .then((result) => {
        setInventory(result.data.data.items);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  }, []);

  // get inventory category id
  const inventoryCategoryId = (categoryName) => {
    // get config for inventory category API call
    const method = "get",
      url = `${BASE_URL}inventorycategories`;

    // make API call to get all inventory category
    axios({ url, method, headers })
      .then((result) => {
        const AllInventoryCategories = result.data.data;
        let selectedCategoryId = null;

        // get the id of the selected category
        for (const category of AllInventoryCategories) {
          if (categoryName === category.name) {
            selectedCategoryId = category.id;
          }
        }

        return selectedCategoryId;
      })
      .catch((error) => {
        error = new Error();
        alert("Matching Category Failed!");
      });
  };

  // add inventory
  const addInventory = (inventoryData) => {
    setLoading("processing");

    // get config for inventory category API call
    const method = "get",
      url = `${BASE_URL}inventorycategories`;

    // make API call to get all inventory category
    axios({ url, method, headers })
      .then((result) => {
        const AllInventoryCategories = result.data.data;
        let selectedCategoryId = null;

        // get the id of the selected category
        for (const category of AllInventoryCategories) {
          if (inventoryData.inventoryCategory === category.name) {
            selectedCategoryId = category.id;
          }
        }

        // get config for creating inventory
        const method = "post",
          url = `${BASE_URL}inventories`,
          data = {
            itemName: inventoryData.itemName.toLowerCase(),
            unitPrice: inventoryData.unitPrice,
            holding: inventoryData.holding,
            description: inventoryData.description,
            inventoryCategoryId: selectedCategoryId,
            quantity: inventoryData.quantity,
            restockLevel: inventoryData.restockLevel,
          };

        // make API call to create inventory
        axios({ url, method, headers, data })
          .then(() => {
            setLoading("successful");

            setTimeout(() => {
              window.location.href = window.location.href.split("#")[0];
            }, 3000);
          })
          .catch((error) => {
            error = new Error();
            setLoading("failed");
          });
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  const editInventory = (inventoryData, id) => {
    setLoading("processing");

    // get the id of the selectedCategoryId
    const selectedCategoryId = inventoryCategoryId(
      inventoryData.inventoryCategory
    );

    // get config for creating inventory
    const method = "put",
      url = `${BASE_URL}inventories/${id}`,
      data = {
        itemName: inventoryData.itemName.toLowerCase(),
        unitPrice: inventoryData.unitPrice,
        holding: inventoryData.holding,
        description: inventoryData.description,
        inventoryCategoryId: selectedCategoryId,
        quantity: inventoryData.quantity,
        restockLevel: inventoryData.restockLevel,
      };

    // make API call to create inventory
    axios({ url, method, headers, data })
      .then(() => {
        setLoading("successful");

        // redirect user to the manage-inventory page
        window.location.href = "/manage-inventory";
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  const updateQuantity = (inventoryData, newQuantity, id) => {
    setLoading("processing");

    // get the id of the selectedCategoryId
    const selectedCategoryId = inventoryCategoryId(
      inventoryData.inventoryCategory
    );

    // get config for creating inventory
    const method = "put",
      url = `${BASE_URL}inventories/${id}`,
      data = {
        itemName: inventoryData.itemName.toLowerCase(),
        unitPrice: inventoryData.unitPrice,
        holding: inventoryData.holding,
        description: inventoryData.description,
        inventoryCategoryId: selectedCategoryId,
        quantity: newQuantity,
        restockLevel: inventoryData.restockLevel,
      };

    // make API call to create inventory
    axios({ url, method, headers, data })
      .then(() => {
        setLoading("successful");

        // redirect user to the manage-inventory page
        window.location.href = "/manage-inventory";
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  return (
    <InventoryContext.Provider
      value={{
        inventoryLoading,
        inventories,
        addInventory,
        editInventory,
        updateQuantity,
        inventoryCategoryId
      }}
    >
      {props.children}
    </InventoryContext.Provider>
  );
}
