import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { sortOrders, tableOptions } from "../../helpers/OrderHelpers";
import TableContent from "../../helpers/Table/TableContent";
import { SurveyContext } from "../../../ContextAPI/SurveyContext";
import Actions from "../CustomerRecipe/ActionsMenu/Actions";
import Menu from "../CustomerRecipe/ActionsMenu/Menu";

export default function PendingTable() {
  // context
  const { surveyLoading, surveys } = useContext(SurveyContext);

  // seperate order according to status
  let pendingSurveys = sortOrders(surveys, "PENDING", "SURVEY");

  // table headings
  const columns = [
    "",
    "S/N",
    "CUSTOMER NAME",
    "CUSTOMER’S NUMBER",
    "SURVEY ID",
    "SERVICE TYPE",
    "SPECIAL ATTENTION",
    "DATE OF REQUEST",
    "TIME OF REQUEST",
    "MEAL PLAN FEE",
    "PAYMENT STATUS",
    "PAYMENT GATEWAY",
    "STATUS",
  ];

  // extract data for the table rows
  const extractedOrders = pendingSurveys.map((survey, index) => {
    const customer = survey.User;

    return [
      <Actions viewDetails={<Menu link={"/view-survey"} details={survey} />} />,
      index + 1,
      customer.fullName,
      customer.phoneNumber,
      survey.id,
      survey.chefType,
      survey.privateAttention === "TRUE" ? "YES" : "NO",
      moment(survey.createdAt).format("L"),
      moment(survey.createdAt).format("HH:mm:ss"),
      "",
      "",
      "",
      survey.status,
    ];
  });

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter" id="pos_filter">
        {/* today */}
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{pendingSurveys.length}</span>
          </p>
        </Grid>
      </Grid>

      {/* set table */}
      <TableContent
        loading={surveyLoading}
        receivedData={pendingSurveys}
        heading="Pending Survey"
        tableData={extractedOrders}
        columns={columns}
        tableOptions={tableOptions}
      />
    </main>
  );
}
