// get the appropriate base url based on if the app is in development or otherwise
const getBaseUrl = (versionNumber) => {
  let version = 1;

  if (versionNumber) {
    version = versionNumber;
  }

  // local host
  if (window.location.hostname === "localhost") {
    // return `https://api.ooma.kitchen/api/v${version}/`;
    return `https://api.staging.ooma.kitchen/api/v${version}/`;
    // return `https://api.live.ooma.kitchen/api/v${version}/`;
    // staging
  } else if (window.location.hostname === "admin.staging.ooma.kitchen") {
    return `https://api.staging.ooma.kitchen/api/v${version}/`;
    // development
  } else if (window.location.hostname === "admin.dev.ooma.kitchen") {
    return `https://api.ooma.kitchen/api/v${version}/`;
    // production
  } else if (window.location.hostname === "admin.ooma.kitchen") {
    return `https://api.live.ooma.kitchen/api/v${version}/`;
    // no match
  } else {
    return "";
  }
};

export { getBaseUrl };
