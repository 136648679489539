import React from "react";
import PrintoutHeaderWithIMG from "./PrintoutHeaderWithIMG";
import NumberFormaterPrefix from "../../../helpers/NumberFormaterPrefix";

export default function PrintoutHeader({ quantity, total }) {
  return (
    <>
      <PrintoutHeaderWithIMG />

      <h3>
        50 Chief Ejikeme Egbuna street;
        <br /> Independence Layout, Enugu
      </h3>

      <h1>
        {
          <NumberFormaterPrefix
            value={parseFloat(quantity) * parseFloat(total)}
          />
        }
      </h1>
    </>
  );
}
