import React, { useState } from "react";
import { Button, FormControl, Select } from "@material-ui/core/";
import { FaTable } from "react-icons/fa";
import { Dialog, DialogTitle } from "@material-ui/core/";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getTableOptions } from "../../helpers/POSOrderHelper";

export default function TableDetails(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button onClick={handleClickOpen()}>
        <FaTable />

        <span>Add Table Details</span>
      </Button>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Add Table Details</DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <TableDetailsForm setTableDetails={props.setTableDetails} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function TableDetailsForm(props) {
  // props
  const setTableDetails = props.setTableDetails;

  // inital state
  const [tableName, setTableName] = useState("");
  const [tableSide, setTableSide] = useState("");

  // yup schema for validation
  const schema = yup.object().shape({
    tableName: yup.string().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    setTableDetails(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* tableName */}
      <section>
        <h4>Table tableName</h4>
        <FormControl variant="outlined" fullWidth>
          <Select
            native
            fullWidth
            variant="outlined"
            name="tableName"
            error={!!errors.tableName}
            helperText={errors.tableName ? errors.tableName.message : ""}
            inputRef={register}
            value={tableName}
            onChange={(e) => setTableName(e.target.value)}
          >
            <option value="">Select Table</option>
            <option value="Take Out">Take Out</option>
            {getTableOptions(10)}
          </Select>
        </FormControl>
      </section>

      {/* tableSide */}
      <section>
        <h4>Table Side</h4>
        <FormControl variant="outlined" fullWidth>
          <Select
            native
            variant="outlined"
            fullWidth
            name="tableSide"
            inputRef={register}
            value={tableSide}
            onChange={(e) => setTableSide(e.target.value)}
            required={tableName === "Take Out" ? false : true}
            disabled={tableName === "Take Out" ? true : false}
          >
            <option value="">Select Side</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </Select>
        </FormControl>
      </section>

      {/* button */}
      <section className="form-btn">
        <Button type="submit">Save</Button>
      </section>
    </form>
  );
}
