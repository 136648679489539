import React, { useContext, useState } from "react";
import { Button, Grid } from "@material-ui/core/";
import SelectedTimeRanges from "./SelectedTimeRanges";
import Calender from "./Calender";
import PeriodOfDay from "./PeriodOfDay";
import { toggleShowContent } from "../../../../helpers/MenuPlanHelper";
import PreviewItems from "../PreviewItems";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";
import { Badge } from "@material-ui/core";
import SearchItems from "../../../MPCategoryMGT/SearchItems";
import NextAndPrevButtons from "../../../../helpers/Table/NextAndPrevButtons";

export default function SelectMTHeader(props) {
  // props
  const menuItems = props.menuItems;

  // state
  const [showTimeLimits, setShowTimeLimits] = useState(false);

  // context
  const {
    morningRanges,
    afternoonRanges,
    eveningRanges,
    setSearch,
    setSearchResult,
  } = useContext(HelpersContext);

  return (
    <>
      <header>
        <Grid container id="">
          {/* search */}
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            id="add_mp_search"
            align="left"
          >
            {/* search field */}
            <SearchItems item={menuItems} />

            <Button
              onClick={() => {
                setSearchResult([]);
                setSearch("");
              }}
            >
              Clear Search
            </Button>
          </Grid>

          {/* View Selected Time Limits */}
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            id="view_selected_btn"
            align="right"
          >
            <Button>
              <Badge
                onClick={() =>
                  toggleShowContent(showTimeLimits, setShowTimeLimits)
                }
                color="secondary"
                badgeContent={
                  [...morningRanges, ...afternoonRanges, ...eveningRanges]
                    .length
                }
                showZero
              >
                {showTimeLimits
                  ? "Hide Selected Time Limits"
                  : "View Selected Time Limits"}
              </Badge>
            </Button>
          </Grid>

          {/* selected time ranges */}
          <Grid item xs={12} sm={12} md={12} lg={12} id="selected_date_ranges">
            {showTimeLimits ? <SelectedTimeRanges /> : ""}
          </Grid>

          {/* buttons */}
          <Grid container id="btns">
            {/* Select Date Range */}
            <Grid item xs={6} sm={6} md={6} lg={6} id="first_btn">
              <Calender />

              <span>
                <PeriodOfDay />
              </span>
            </Grid>

            <NextAndPrevButtons />

            {/* create */}
            <Grid item xs={3} sm={3} md={3} lg={3} id="last_btn" align="right">
              <PreviewItems closeAddUI={props.closeAddUI} />
            </Grid>
          </Grid>
        </Grid>
      </header>
    </>
  );
}
