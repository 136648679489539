// external imports
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");

const BASE_URL = getBaseUrl();

// create Branch context to manage Branch here
export const BranchContext = createContext();

export default function BranchContextProvider(props) {
  // set initial state
  const [branchLoading, setLoading] = useState(null);
  const [branches, setBranches] = useState([]);

  // make API call to get all the Branches
  useEffect(() => {
    setLoading(true);

    const method = "get",
      url = "";

    // get Branchs
    axios({ url, method })
      .then((result) => {
        setBranches(result.data.data.data.rows);
        setLoading(false);
      })
      .catch((error) => {
        error = new Error();
      });
  }, []);

  //   add branch
  const addBranch = (data) => {
    setLoading("processing");

    // get config for API call
    const method = "post",
      url = `${BASE_URL}branches/`,
      headers = {
        Authorization: `Bearer ${staff.token}`,
      };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setLoading("successful");

        setTimeout(() => {
          window.location.href = window.location.href.split( '#' )[0];
        }, 3000);
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });

  };

  return (
    <BranchContext.Provider value={{ branchLoading, branches, addBranch }}>
      {props.children}
    </BranchContext.Provider>
  );
}
