const getFilteredCustomers = (customers) => {
  let filtered = [];

  for (const customer of customers) {
    filtered.push(customer.fullName + " : " + customer.phoneNumber + " : " + customer.id);
  }

  return filtered;
};

const getUserIds = (customers) => {
  let filtered = [];

  for (const customer of customers) {
    filtered.push(customer.split(": ")[2]);
  }

  return filtered;
}

export { getFilteredCustomers, getUserIds };
