import React, { useContext } from "react";
import { MPManagementContext } from "../../../../ContextAPI/MPManagementContext";
import { sortByStatus } from "../../../helpers/MenuPlanHelper";
import MPOrdersMGT from "../MPOrdersMGT";

export default function ActiveMPOrdersTable() {
  // context
  const { MPOrders } = useContext(MPManagementContext);

  let acceptedOrders = sortByStatus(MPOrders, "Accepted");
  let processingOrders = sortByStatus(MPOrders, "Processing");
  let readyOrders = sortByStatus(MPOrders, "Ready");

  const orders = [...acceptedOrders, ...processingOrders, ...readyOrders];

  return (
    <>
      <MPOrdersMGT orders={orders} group={"Active"} />
    </>
  );
}
