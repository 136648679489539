import React, { useContext } from "react";
import {
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core/";
import {
  addTimeRange,
  timeLimitConvertion,
} from "../../../../helpers/MenuPlanHelper";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";

export default function TimeLimitList(props) {
  const period = props.period;
  const timeOfDay = props.timeOfDay;

  const {
    morningRanges,
    afternoonRanges,
    eveningRanges,
    setMorningRanges,
    setAfternoonRanges,
    setEveningRanges,
  } = useContext(HelpersContext);

  let ranges;
  let setRanges;

  switch (period) {
    case "Morning":
      ranges = morningRanges;
      setRanges = setMorningRanges;
      break;

    case "Afternoon":
      ranges = afternoonRanges;
      setRanges = setAfternoonRanges;
      break;

    case "Evening":
      ranges = eveningRanges;
      setRanges = setEveningRanges;

      break;

    default:
      break;
  }

  return (
    <>
      <FormLabel component="legend">{period}</FormLabel>
      {timeOfDay &&
        timeOfDay.length > 0 &&
        timeOfDay.map((result) => (
          <FormGroup aria-label="position">
            <FormControlLabel
              value={result.timeRange}
              control={
                <Checkbox
                  checked={ranges.includes(result.timeRange) ? true : false}
                  color="primary"
                  onChange={(e) =>
                    addTimeRange(
                      e.target.checked,
                      e.target.value,
                      setRanges,
                      ranges
                    )
                  }
                />
              }
              label={timeLimitConvertion(result.timeRange)}
              labelPlacement="end"
            />
          </FormGroup>
        ))}
    </>
  );
}
