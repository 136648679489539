// external imports
import React, { createContext, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};
let branchId = null;
let staffId = null;

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
  branchId = staff.branchId;
  staffId = staff.id;
}

const BASE_URL = getBaseUrl();

// create staff context to manage staff here
export const StaffContext = createContext();

export default function StaffContextProvider(props) {
  // set initial state
  const [login, setLogin] = useState(false);
  const [staffLoading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  // login
  const adminLogin = (data) => {
    setLogin("processing");

    const method = "post",
      url = `${BASE_URL}users/loginStaff`,
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

    // login
    axios({ url, method, headers, data })
      .then((result) => {
        // create cookie with the token returned
        cookies.set("AGU-ANAGHI-ERI-AGU", result.data.data, {
          path: "/",
        });

        setLogin("successful");

        // redirect user to the dashboard page
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        error = new Error();
        setLogin("failed");

        setTimeout(() => {
          setLogin(null);
        }, 1000);
      });
  };

  // create staff
  const addStaff = (input, roles) => {
    setLoading("processing");

    // get config for API call
    const method = "post",
      url = `${BASE_URL}staffs`,
      data = {
        username: input.userName,
        lastName: input.lastName,
        firstName: input.firstName,
        phoneNumber: parseInt(input.phone),
        password: input.password,
        branchId,
        createdBy: staffId,
        staffTypeId: 1,
        roles: roles.toString(),
        email: input.email,
      };

    // make API call to create inventory
    axios({ url, method, headers, data })
      .then(() => {
        setLoading("successful");

        setTimeout(() => {
          window.location.href = window.location.href.split("#")[0];
        }, 3000);
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  // retrieve user
  const getUser = (userId) => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}user?user_id=${userId}`;

    // get Utilss
    axios({ url, method, headers })
      .then((result) => {
        console.log(result);
        setUser(result.data.data.rows);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  return (
    <StaffContext.Provider
      value={{ login, adminLogin, addStaff, staffLoading, getUser, user }}
    >
      {props.children}
    </StaffContext.Provider>
  );
}
