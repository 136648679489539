import React, { useContext, useState } from "react";
import { Button, Grid, TextField, Checkbox } from "@material-ui/core/";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { StaffContext } from "../../../ContextAPI/StaffContext";
import FeedbackPopup from "../../helpers/FeedbackPopup";

export default function CreateStaffForm() {
  // context
  const { staffLoading, addStaff } = useContext(StaffContext);
  // states
  const [roles, setRoles] = useState("");
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  // yup schema for validation
  const schema = yup.object().shape({
    userName: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    password: yup.string().required(),
    email: yup.string().required(),
    phone: yup.string().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    // terminate if no role is selected
    if (!roles.length) {
      return alert("Please assign at least one role to the staff");
    }

    addStaff(data, roles);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form_frame"
        id="create_staff"
      >
        <h1>Create Staff</h1>

        <Grid container>
          {/* User Name */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <h4>UserName</h4>
            <TextField
              variant="outlined"
              fullWidth
              name="userName"
              error={!!errors.userName}
              helperText={errors.userName ? errors.userName.message : ""}
              inputRef={register}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Grid>

          {/* Phone */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <h4>Phone Number</h4>
            <TextField
              variant="outlined"
              fullWidth
              type="tel"
              name="phone"
              error={!!errors.phone}
              helperText={errors.phone ? errors.phone.message : ""}
              inputRef={register}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>

          {/* First Name */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <h4>First Name</h4>
            <TextField
              variant="outlined"
              fullWidth
              name="firstName"
              error={!!errors.firstName}
              helperText={errors.firstName ? errors.firstName.message : ""}
              inputRef={register}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>

          {/* Last Name */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <h4>Last Name</h4>
            <TextField
              variant="outlined"
              fullWidth
              name="lastName"
              error={!!errors.lastName}
              helperText={errors.lastName ? errors.lastName.message : ""}
              inputRef={register}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <h4>Email</h4>
            <TextField
              variant="outlined"
              fullWidth
              type="email"
              name="email"
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
              inputRef={register}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          {/* Password */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <h4>Password</h4>
            <TextField
              variant="outlined"
              type="password"
              fullWidth
              name="password"
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ""}
              inputRef={register}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>

          {/* Role */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h4>Role</h4>

            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={staffRoles}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              onChange={(event, value) => setRoles(value)}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox checked={selected} />
                  {option}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
        </Grid>

        {/* feedback popup */}
        <FeedbackPopup
          condition={staffLoading}
          additionalMessage="Page Reloads in a moment..."
        />

        {/* button */}
        <section className="form-btn">
          <Button type="submit">Create</Button>
        </section>
      </form>
    </>
  );
}

const staffRoles = [
  "Waiter",
  "Cashier 1",
  "Cashier 2",
  "Dispatch 1",
  "Dispatch 2",
  "Rider 1",
  "Rider 2",
  "Chef 1",
  "Chef 2",
  "Chef 3",
  "Holding 1",
  "Holding 2",
  "Holding 3",
  "Order management 1",
  "Order management 2",
  "Order Management 3",
  "Manager 1",
  "Manager 2",
  "Admin 1",
  "Admin 2",
  "Customer Service 1",
  "Recipe Request Management 1",
  "Recipe Request Management 2",
  "Survey Management",
  "Create Plan for Customer"
];
