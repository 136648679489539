import moment from "moment";

// funtion does nothing
const doNothing = () => {};

// get the months in an item in a plan
const getMonthsInPlan = (dates, setter) => {
  let months = [];

  for (const day of dates) {
    const month = moment(day).format("MMMM YYYY");

    if (!months.includes(month)) {
      months.push(month);
    }
  }

  setter(months);
};

// add selected time range to the selected time ranges
const addTimeRangeOfDay = (
  checked,
  value,
  day,
  selectedTimeRanges,
  setSelectedTimeRanges,
  selectedMonths,
  setSelectedMonths,
  selectedDays,
  setSelectedDays
) => {
  const monthYear = moment(day).format("MMMM YYYY");
  value.monthYear = monthYear;

  if (!selectedDays.includes(value.date)) {
    setSelectedDays([...selectedDays, value.date]);
  }

  if (!selectedMonths.includes(monthYear)) {
    setSelectedMonths([...selectedMonths, monthYear]);
  }

  if (checked) {
    setSelectedTimeRanges([...selectedTimeRanges, value]);
  }

  if (!checked) {
    setSelectedTimeRanges(
      selectedTimeRanges.filter((time) => time.id !== value.id)
    );
  }
};

// check if a time range has been selected
const isTRSelected = (range, day, selectedTimeRanges) => {
  if (!selectedTimeRanges.length) return;

  for (const item of selectedTimeRanges) {
    if (item.id === range.range + moment(day).format("YYYY-MM-DD")) return true;
  }
};

// reduce the qty an item in a selected time range
const subtractQty = (id, ranges, setter) => {
  for (const range of ranges) {
    if (range.id === id && range.quantity > 1) {
      range.quantity = parseFloat(range.quantity) - 1;
    }
  }

  // force an update of the DOM
  setter([...ranges]);
};

// increase the qty an item in a selected time range
const addQty = (id, ranges, setter) => {
  for (const range of ranges) {
    if (range.id === id) {
      range.quantity = parseFloat(range.quantity) + 1;
    }
  }

  // force an update of the DOM
  setter([...ranges]);
};

const isDaySelected = (items, day) => {
  for (const item of items) {
    if (item.selectedDays.includes(moment(day).format("YYYY-MM-DD")))
      return true;
  }
};

// get a range already selected
const getASelectedTimeRange = (id, ranges) => {
  for (const range of ranges) {
    if (range.id === id) return range;
  }
};

// removeDateAndTimeRanges
const removeDateAndTimeRanges = (
  day,
  itemsBeforePreview,
  setItemsBeforePreview
) => {
  // remove the day
  setItemsBeforePreview(
    itemsBeforePreview.filter((item) => day !== item.selectedDays[0])
  );
};

// getAllDaysSelected
const getAllDaysSelected = (data, setter) => {
  let daysArr = [];

  for (const item of data) {
    daysArr = [...daysArr, ...item.selectedDays];
  }

  setter([...new Set(daysArr)]);
};

// getAllTimeRangeSelected
const getAllTimeRangeSelected = (data, setter) => {
  let daysArr = [];

  for (const item of data) {
    daysArr = [...daysArr, ...item.selectedTimeRanges];
  }

  setter(daysArr);
};

// getItemRangesArray
const getItemRangesArray = (ranges) => {
  const rangesArray = [];
  for (const range of ranges) {
    rangesArray.push(range.range);
  }

  return rangesArray;
};

// getQuantityOfItemRange
const getQuantityOfItemRange = (item, time, day) => {
  for (const range of item.selectedTimeRanges) {
    if (range.date === day && range.range === time) {
      return range.quantity;
    }
  }
};

// removeFromPreview
const removeFromPreview = (
  items,
  itemsSetter,
  day,
  time,
  menuItem,
  setSubscriptionDuration,
  setDeliveryTimesSelected
) => {
  // loop through the items for preview
  for (const item of items) {
    if (
      item.menuItem.itemName === menuItem.menuItem.itemName &&
      item.selectedDays[0] === day
    ) {
      for (let index = 0; index < item.selectedTimeRanges.length; index++) {
        if (time === item.selectedTimeRanges[index].range) {
          item.selectedTimeRanges.splice(index, 1);
          item.timeRangeArray.splice(index, 1);
        }
      }
    }
  }

  // update the duration and delivery times
  getAllTimeRangeSelected(items, setDeliveryTimesSelected);
  getItemsRemaingForPreview(items, itemsSetter);
  getAllDaysSelected(items, setSubscriptionDuration);
};

// get items remaining for preview
const getItemsRemaingForPreview = (items, itemsSetter) => {
  itemsSetter(items.filter((item) => item.selectedTimeRanges.length));
};

// add or remove selected addons
const setupSelectedAddons = (checked, item, data, setter) => {
  if (checked) {
    item.customerQuantity = 1;
    item.customerPrice = item.price;

    setter([...data, item]);
  }

  if (!checked) {
    setter(data.filter((element) => element.id !== item.id));
  }
};

// getCustomerQtyAndPrice
const getCustomerQtyAndPrice = (item, data) => {
  for (const element of data) {
    if (item.id === element.id) {
      return {
        price: element.customerPrice,
        qty: element.customerQuantity,
        isSelected: true,
      };
    }
  }
};

// reduce the qty an addons in a selected addons
const subtractAddonsQty = (id, items, setter) => {
  for (const item of items) {
    if (item.id === id && item.customerQuantity > 1) {
      item.customerQuantity = parseFloat(item.customerQuantity) - 1;
      item.customerPrice =
        parseFloat(item.price) * parseFloat(item.customerQuantity);
    }
  }

  // force an update of the DOM
  setter([...items]);
};

// increase the qty an addons in a selected addons
const addAddonsQty = (id, items, setter) => {
  for (const item of items) {
    if (item.id === id) {
      item.customerQuantity = parseFloat(item.customerQuantity) + 1;
      item.customerPrice =
        parseFloat(item.price) * parseFloat(item.customerQuantity);
    }
  }

  // force an update of the DOM
  setter([...items]);
};

// calculate total price for each item ie addons + preferences + menuItem price
const getItemTotal = (selectedAddons, selectedPreference, amount) => {
  // get selectedAddons total
  let addonsTotal = 0;
  for (const addon of selectedAddons) {
    if (addon.customerPrice) {
      addonsTotal = parseFloat(addonsTotal) + parseFloat(addon.customerPrice);
    }
  }

  // get selectedPreference total
  let preferenceTotal = 0;
  for (const preference of selectedPreference) {
    if (preference.Preference.unitPrice) {
      preferenceTotal =
        parseFloat(preferenceTotal) +
        parseFloat(preference.Preference.unitPrice);
    }
  }

  return (
    parseFloat(preferenceTotal) + parseFloat(addonsTotal) + parseFloat(amount)
  );
};

// get deliveryTime and their quantity
const mapDeliveryTimeQuantity = (ranges) => {
  let collected = [];

  for (const range of ranges) {
    collected.push({
      time: range.range,
      quantity: range.quantity,
      status: "Pending",
    });
  }

  return JSON.stringify(collected);
};

// get the addons name and quantity
const getAddonsNameAndQuantity = (addons) => {
  if (!addons) return;

  let processedAddons = [];
  for (const addon of addons) {
    processedAddons.push(
      addon.Inventory.itemName + " (" + addon.customerQuantity + ")"
    );
  }

  return processedAddons;
};

// get the all delivery time for a particular day
const getDeliveryTimeForDay = (deliveryTimesSelected, day) => {
  let collected = [];
  for (const time of deliveryTimesSelected) {
    if (time.date === day && !collected.includes(time.range)) {
      collected.push(time.range);
    }
  }

  return collected;
};

// setup basket items to be submitted for customer meal plan schedulling
const getBasketitems = (items, branchId, staffId, customerId, surveyId) => {
  const collected = [];

  items.forEach((item) => {
    collected.push({
      menuplanid: item.menuItem.menuplanId,
      branchId,
      menuitemid: item.menuItem.menuitemid,
      quantity: 1,
      addedByAdmin: true,
      adminUserId: staffId,
      addons: JSON.stringify(item.addons),
      preferences: JSON.stringify(item.preferences),
      specialInstruction: item.modifyDescription,
      amount: item.price,
      deliveryDate: item.selectedDays[0] + "T00:00:00.000Z",
      deliveryTime: mapDeliveryTimeQuantity(item.selectedTimeRanges),
      createdBy: customerId,
      surveyId,
    });
  });

  return collected;
};

// get number of meals selected for preview
const getMealsNamesSelectedForPreview = (items) => {
  let collected = [];

  for (const item of items) {
    collected.push(item.menuItem.itemName);
  }

  return [...new Set(collected)];
};

// get the total number of meals or quantity of items selected
const getTotalQuantityOfItemsSelected = (items) => {
  let total = 0;

  // loop through the items selected
  for (const item of items) {
    // loop through the selectedTimeRanges to get and add the quantities
    for (const element of item.selectedTimeRanges) {
      total = parseFloat(total) + parseFloat(element.quantity);
    }
  }

  return total;
};

// get the total price
const getTotalPriceOfItemsSelected = (items) => {
  let total = 0;

  // loop through the items selected
  for (const item of items) {
    // get the total quantity for this item
    let totalQty = 0;
    for (const time of item.selectedTimeRanges) {
      totalQty = parseFloat(totalQty) + time.quantity;
    }

    // get the total price by multiplying the item price by the total quantity
    const price = parseFloat(item.price) * totalQty;
    total = parseFloat(total) + parseFloat(price);
  }

  return total;
};

export {
  doNothing,
  getMonthsInPlan,
  addTimeRangeOfDay,
  isTRSelected,
  subtractQty,
  addQty,
  isDaySelected,
  getASelectedTimeRange,
  removeDateAndTimeRanges,
  getAllDaysSelected,
  getAllTimeRangeSelected,
  getItemRangesArray,
  getQuantityOfItemRange,
  removeFromPreview,
  setupSelectedAddons,
  getCustomerQtyAndPrice,
  subtractAddonsQty,
  addAddonsQty,
  getItemTotal,
  mapDeliveryTimeQuantity,
  getAddonsNameAndQuantity,
  getDeliveryTimeForDay,
  getBasketitems,
  getMealsNamesSelectedForPreview,
  getTotalQuantityOfItemsSelected,
  getTotalPriceOfItemsSelected,
};
