import React, { useContext } from "react";
import { HelpersContext } from "../../../../../../ContextAPI/HelpersContext";
import Months from "./Months";

export default function SelectDays({ availableDays, menuItem }) {
  const { monthsInItem, page } = useContext(HelpersContext);

  return (
    <div id="select_days">
      <h3>Select days</h3>

      {/* render one month/time according to the numbering of the page */}
      <Months
        month={monthsInItem[page - 1]}
        availableDays={availableDays}
        menuItem={menuItem}
      />
    </div>
  );
}
