import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import ViewCategoriesContent from "../../../components/adminComponents/MPCategoryMGT/ViewCategoriesContent";

export default function ViewCategories() {
  return (
    <>
      <DashboardFrame
        pageHeading="Manage Meal Plans Categories"
        dashboardContent={<ViewCategoriesContent />}
      />
    </>
  );
}
