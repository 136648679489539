import React, { useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  FormControl,
} from "@material-ui/core/";
import { ScheduleContext } from "../../../../../ContextAPI/ScheduleContext";
import { filterSchedulesByTimeOfDay } from "../../../../helpers/MenuPlanHelper";
import TimeLimitList from "./TimeLimitList";

export default function TimeLimits(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      {/* popup button */}
      <Button id="menu_plan_time_limit_btn" onClick={handleClickOpen()}>
        Time Limits
      </Button>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Select all Time Limits{" "}
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <TimeLimitsBody
              periodOfDay={props.periodOfDay}
              closeModal={handleClose}
              closePODModal={props.closePODModal}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function TimeLimitsBody(props) {
  // context
  const { schedules } = useContext(ScheduleContext);

  //  props
  const periodOfDay = props.periodOfDay;

  // seperate the schedules according to period of the day
  const filteredSchedules = filterSchedulesByTimeOfDay(schedules);
  const morningTime = filteredSchedules.morningTime;
  const afternoonTime = filteredSchedules.afternoonTime;
  const eveningTime = filteredSchedules.eveningTime;

  // submit
  const handleSubmit = () => {
    props.closePODModal();
    props.closeModal();
  };

  return (
    <form>
      <FormControl component="fieldset">
        {/* display time according to the period of day being worked upon */}

        {/* Morning */}
        {periodOfDay.includes("Morning") ? (
          <TimeLimitList period="Morning" timeOfDay={morningTime} />
        ) : (
          ""
        )}

        {/* Afternoon */}
        {periodOfDay.includes("Afternoon") ? (
          <TimeLimitList period="Afternoon" timeOfDay={afternoonTime} />
        ) : (
          ""
        )}

        {/* Evening */}
        {periodOfDay.includes("Evening") ? (
          <TimeLimitList period="Evening" timeOfDay={eveningTime} />
        ) : (
          ""
        )}
      </FormControl>

      <section className="form-btn">
        <Button onClick={() => handleSubmit()}>Save</Button>
      </section>
    </form>
  );
}
