import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core/";
import { collectPOD } from "../../../../helpers/MenuPlanHelper";
import TimeLimits from "./TimeLimits";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";

export default function PeriodOfDay(props) {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");

  // context
  const { selectedPOD, setSelectedPOD } = useContext(HelpersContext);

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      {/* popup button */}
      <Button id="menu_plan_time_limit_btn" onClick={handleClickOpen()}>
        {selectedPOD.length
          ? selectedPOD.toString().split(",").join(" | ")
          : "Select period of the day"}
      </Button>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Select period of the day
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <PeriodOfDayContent
              selectedPOD={selectedPOD}
              setSelectedPOD={setSelectedPOD}
              closePODModal={handleClose}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function PeriodOfDayContent(props) {
  const periods = ["Morning", "Afternoon", "Evening"];

  const selectedPOD = props.selectedPOD;
  const setSelectedPOD = props.setSelectedPOD;

  return (
    <form>
      <FormControl component="fieldset">
        {periods.map((period) => (
          <FormGroup aria-label="position" key={period}>
            <FormControlLabel
              value={period}
              control={
                <Checkbox
                  checked={selectedPOD.includes(period) ? true : false}
                  color="primary"
                  onChange={(e) =>
                    collectPOD(
                      e.target.checked,
                      period,
                      selectedPOD,
                      setSelectedPOD
                    )
                  }
                />
              }
              label={period}
              labelPlacement="end"
            />
          </FormGroup>
        ))}
      </FormControl>

      <section className="form-btn">
        <TimeLimits
          periodOfDay={selectedPOD}
          closePODModal={props.closePODModal}
        />
      </section>
    </form>
  );
}
