import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import InstantOrderTable from "../../../components/adminComponents/Sales/InstantOrder/InstantOrderTable";
import SettingsFrame from "../../../components/adminComponents/Settings/SettingsFrame";

export default function InstantOrders() {
  return (
    <main className="table_page_frame">
      <DashboardFrame
        pageHeading="Sales"
        dashboardContent={
          <SettingsFrame
            firstLabel="Instant Order"
            firstContent={<InstantOrderTable />}
          />
        }
      />
    </main>
  );
}
