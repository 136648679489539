import React, { useContext, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core/";
import { MenuItemContext } from "../../../../ContextAPI/MenuItemContext";

export default function DishTable({ order }) {
  // context
  const { getMenuItemInventories, menuItemInventories } =
    useContext(MenuItemContext);

  const preferences = JSON.parse(order.preferences);

  // addons
  let addons = [];

  if (order.addedByAdmin) {
    addons = JSON.parse(order.addons);
  } else {
    addons = JSON.parse(JSON.parse(order.addons));
  }

  useEffect(() => {
    getMenuItemInventories(order.itemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container id="dish_details">
      <h2>
        {order.itemName} <span>(x{order.deliveryTime.quantity})</span>
      </h2>

      <Grid item xs={12} sm={12} md={12} lg={12} className="dish_table">
        <TableContainer key={order.id}>
          <Table aria-label="a dense table">
            {/* table head */}
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Unit(s)</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Preference</TableCell>
                <TableCell>Special Instructions</TableCell>
                <TableCell>Note</TableCell>
              </TableRow>
            </TableHead>

            {/* table body */}
            <TableBody>
              <TableRow key={order.id}>
                <TableCell>
                  {/* main items */}
                  {menuItemInventories && menuItemInventories.length
                    ? menuItemInventories.map((item) => (
                        <li>{item.Inventory.itemName}</li>
                      ))
                    : ""}

                  {/* admin addons */}
                  {addons && addons.length && order.addedByAdmin
                    ? addons.map((addon) => (
                        <li>
                          {addon.isExtra
                            ? "Extra " + addon.Inventory.itemName
                            : addon.Inventory.itemName}{" "}
                        </li>
                      ))
                    : ""}

                  {/* user addons */}
                  {addons && addons.length && !order.addedByAdmin
                    ? addons.map((addon) => (
                        <li>
                          {addon.isExtra ? "Extra " + addon.name : addon.name}{" "}
                        </li>
                      ))
                    : ""}
                </TableCell>

                <TableCell id="qty">
                  {/* main quantity */}
                  {menuItemInventories && menuItemInventories.length
                    ? menuItemInventories.map((item) => (
                        <li>{parseFloat(item.quantity)} unit(s)</li>
                      ))
                    : ""}

                  {/* admin addons quantity */}
                  {addons && addons.length && order.addedByAdmin
                    ? addons.map((addon) => (
                        <li>
                          {parseFloat(addon.quantity) *
                            parseFloat(addon.customerQuantity)}{" "}
                          unit(s)
                        </li>
                      ))
                    : ""}

                  {/* user addons quantity */}
                  {addons && addons.length && !order.addedByAdmin
                    ? addons.map((addon) => (
                        <li>
                          {parseFloat(addon.originalQuantity) *
                            parseFloat(addon.quantity)}{" "}
                          unit(s)
                        </li>
                      ))
                    : ""}
                </TableCell>

                <TableCell>
                  {/* main items price */}
                  {menuItemInventories && menuItemInventories.length
                    ? menuItemInventories.map((item) => (
                        <li>{parseFloat(item.price).toFixed(2)} NGN </li>
                      ))
                    : ""}

                  {/* admin addons price */}
                  {addons && addons.length && order.addedByAdmin
                    ? addons.map((addon) => (
                        <li>
                          {parseFloat(addon.customerPrice).toFixed(2)} NGN{" "}
                        </li>
                      ))
                    : ""}

                  {/* user addons price */}
                  {addons && addons.length && !order.addedByAdmin
                    ? addons.map((addon) => (
                        <li>{parseFloat(addon.price).toFixed(2)} NGN </li>
                      ))
                    : ""}
                </TableCell>

                <TableCell>
                  {preferences && preferences.length && order.addedByAdmin
                    ? preferences.map((preference) => (
                        <li>
                          {preference.Preference.name}{" "}
                          <span>
                            (
                            {parseFloat(
                              preference.Preference.unitPrice
                            ).toFixed(2)}
                            ) NGN
                          </span>{" "}
                        </li>
                      ))
                    : ""}

                  {preferences && preferences.length && !order.addedByAdmin
                    ? preferences.map((preference) => (
                        <li>
                          {preference.name}{" "}
                          <span>
                            ({parseFloat(preference.unitPrice).toFixed(2)}) NGN
                          </span>{" "}
                        </li>
                      ))
                    : ""}
                </TableCell>

                {/* specialInstruction */}
                <TableCell align="center" style={{ width: 300 }}>
                  {order.specialInstruction}
                </TableCell>

                {/* note */}
                <TableCell align="center" style={{ width: 300 }}>
                  {order.note}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
