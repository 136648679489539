import React, { useContext } from "react";
import MenuItem from "@material-ui/core/MenuItem";

import { useHistory } from "react-router-dom";
import { RecipeContext } from "../../../../ContextAPI/RecipeContext";

export default function Menu({ link, details, tableType }) {
  // context
  const { setRecipeDetails, setRecipeTableType } = useContext(RecipeContext);
  const history = useHistory();

  return (
    <>
      <MenuItem
        onClick={() => {
          setRecipeDetails(details);
          setRecipeTableType(tableType)
          history.push(link);
        }}
      >
        View Details
      </MenuItem>
    </>
  );
}
