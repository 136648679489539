import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextField, Button } from "@material-ui/core/";
import { MenuPlanCategoryContext } from "../../../ContextAPI/MenuPlanCategoryContext";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import ImageInput from "./ImageInput";

export default function EditMPCategory(props) {
  // props
  const item = props.item;
  const id = item.id;

  // context
  const { updatingCategory, editMPCategory } = useContext(
    MenuPlanCategoryContext
  );
  const { imgBase64 } = useContext(HelpersContext);

  // initial states
  const [name, setName] = useState(item.name);
  const [description, setDescription] = useState(item.description);

  // yup schema for validation
  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    editMPCategory({ id, name, imgBase64, description });
  };

  return (
    <form>
      {/* menu Image */}
      <section>
        <ImageInput isEdit={true} initialImage={item.imageurl} />
      </section>

      {/* name */}
      <section>
        <h4>Name</h4>
        <TextField
          fullWidth
          variant="outlined"
          name="name"
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ""}
          inputRef={register}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </section>

      {/* description */}
      <section>
        <h4>Description</h4>
        <TextField
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          name="description"
          error={!!errors.description}
          helperText={errors.description ? errors.description.message : ""}
          inputRef={register}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </section>

      {/* feedback popup */}
      <FeedbackPopup
        condition={updatingCategory}
        additionalMessage="Reloading..."
      />

      {/* button */}
      <section className="form-btn" id="category_btn">
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
          Add
        </Button>
      </section>
    </form>
  );
}
