import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Grid } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import EditMenu from "./EditMenu";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

export default function MealPlan(props) {
  const classes = useStyles();
  const mealPlan = props.mealPlan;
  const isEdit = props.isEdit;

  return (
    <Grid
      item
      xs={isEdit ? 6 : 12}
      sm={isEdit ? 4 : 12}
      md={isEdit ? 3 : 12}
      lg={isEdit ? 2 : 12}
    >
      <Card className={classes.root} key={mealPlan.id}>
        <CardActionArea>
          {/* item image */}
          <CardMedia
            component="img"
            alt={mealPlan.name}
            height="100"
            image={mealPlan.imageurl}
            title={mealPlan.description}
          />

          {/* item name */}
          <CardContent title={mealPlan.name}>
            <Typography variant="body2" color="textSecondary" component="p">
              {mealPlan.name}
            </Typography>
          </CardContent>
        </CardActionArea>

        {/* Action button */}
        {isEdit === false ? (
          ""
        ) : (
          <CardActions title={mealPlan.description}>
            <EditMenu mealPlan={mealPlan} />
          </CardActions>
        )}
      </Card>
    </Grid>
  );
}
