import React, { useRef } from "react";
import SpaceInBetweenLayout from "../../../helpers/SpaceInBetweenLayout";
import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ReactToPrint from "react-to-print";
import MPPricePaymentDetails from "./MPPricePaymentDetails";
import PrintoutHeader from "../../Order/IncomingOrder/PrintoutHeader";
import NumberFormaterPrefix from "../../../helpers/NumberFormaterPrefix";

export default function MPOrderReceipt({ order }) {
  const componentRef = useRef();

  return (
    <div>
      {/* button to trigger the printing */}
      <ReactToPrint
        trigger={() => <MenuItem>Receipt</MenuItem>}
        content={() => componentRef.current}
      />

      {/* component to be printed hidden from users */}
      <div style={{ display: "none" }}>
        <ReceiptContent order={order} ref={componentRef} />
      </div>
    </div>
  );
}

class ReceiptContent extends React.PureComponent {
  render() {
    const order = this.props.order;
    const quantity = order.deliveryTime.quantity;

    return (
      <>
        <main className="receipt">
          <PrintoutHeader
            total={order.amount}
            quantity={quantity}
          />

          {/* order details */}
          <Grid container className="order_details">
            <SpaceInBetweenLayout
              textA="Customer’s name:"
              textB={order.fullName}
            />

            <SpaceInBetweenLayout
              textA="Customer’s number:"
              textB={order.phoneNumber}
            />

            <SpaceInBetweenLayout
              textA="Recipent’s name:"
              textB={order.friendName}
            />

            <SpaceInBetweenLayout
              textA="Recipent’s number:"
              textB={order.friendPhoneNumber}
            />
            <SpaceInBetweenLayout textA="Order ID:" textB={order.orderRef} />

            <SpaceInBetweenLayout
              textA="Delivery area:"
              textB={order.deliveryAddId}
            />
          </Grid>

          <Grid container id="delivery_address">
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <h3>
                <span>Delivery Address</span>
              </h3>
              <h3>{order.deliveryAddress}</h3>
            </Grid>
          </Grid>

          {/* cart details */}
          <Grid container className="cart_item_details">
            <>
              <Grid item xs={1} sm={1} md={1} lg={1} id="qty">
                <p>({quantity}x)</p>
              </Grid>
              <Grid item xs={7} sm={7} md={7} lg={7}>
                <p>{order.itemName.toUpperCase()}</p>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} align="right">
                <p>
                  <NumberFormaterPrefix value={order.amount} />
                </p>
              </Grid>
            </>
          </Grid>

          {/* price and payment*/}
          <MPPricePaymentDetails order={order} quantity={quantity} />
        </main>
      </>
    );
  }
}
