import React, { useContext } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core/";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Heading from "./Heading";
import { VscOpenPreview } from "react-icons/vsc";
import GeneralInfo from "./Preview/GeneralInfo";
import PreviewContentBody from "./Preview/PreviewContentBody";
import { Badge } from "@material-ui/core";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import {
  getAllDaysSelected,
  getAllTimeRangeSelected,
  getTotalQuantityOfItemsSelected,
} from "../HelperFunctions";
import { CustomerContext } from "../../../../ContextAPI/CustomerContext";
import FeedbackPopup from "../../../helpers/FeedbackPopup";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function PreviewPopup() {
  // context
  const {
    customerMPPreview,
    setSubscriptionDuration,
    setDeliveryTimesSelected,
  } = useContext(HelpersContext);
  const { createCustomerMP, creatingCustomerMP } = useContext(CustomerContext);

  // states
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");

    getAllDaysSelected(customerMPPreview, setSubscriptionDuration);
    getAllTimeRangeSelected(customerMPPreview, setDeliveryTimesSelected);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // submit the items selected
  const submitPreviewItems = () => {
    createCustomerMP(handleClose);
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={2} className="preview_popup">
      <section id="popup_btn" onClick={handleClickOpen()}>
        <Badge
          onClick={handleClickOpen()}
          color="secondary"
          badgeContent={getTotalQuantityOfItemsSelected(customerMPPreview)}
          showZero
        >
          Preview
        </Badge>
      </section>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"md"}
        scroll={scroll}
        disableBackdropClick={true}
        TransitionComponent={Transition}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <Heading
            title="Preview"
            isBtn={true}
            closePopup={handleClose}
            icon={<VscOpenPreview />}
            submitMT={submitPreviewItems}
          />
        </DialogTitle>

        <FeedbackPopup
          condition={creatingCustomerMP}
          additionalMessage="Popup closes in a moment..."
        />

        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Content />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

function Content() {
  const { subscriptionDuration, customerId } = useContext(HelpersContext);

  return (
    <div id="preview_customer_mp">
      <GeneralInfo />

      {subscriptionDuration && subscriptionDuration.length ? (
        subscriptionDuration
          .sort()
          .map((day) => <PreviewContentBody day={day} />)
      ) : (
        <section id="no_item_selected">
          <p>{`You haven’t added any meal ${
            customerId ? "for this customer" : ""
          } yet`}</p>
        </section>
      )}
    </div>
  );
}
