import React from "react";
import Content from "../../../components/adminComponents/PageNotFound/Content";

export default function NotFound(props) {
  return (
    <>
      <Content
        message="Page Not Found"
        reason="We don not have the page that you are trying to access yet"
      />
    </>
  );
}
