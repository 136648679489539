import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { MPManagementContext } from "../../../../ContextAPI/MPManagementContext";
import moment from "moment";
import MPOrderActions from "../MPOrderActions";
import { tableOptions } from "../../../helpers/OrderHelpers";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import CustomerSearch from "./CustomerSearch";
import TableContent from "../../../helpers/Table/TableContent";
import NextAndPrevButtons from "../../../helpers/Table/NextAndPrevButtons";
import NumberFormaterPrefix from "../../../helpers/NumberFormaterPrefix";

export default function CustomerMPOrderTable() {
  const { userMPOrders, userOrdersLoading, getMenuPlanByUser } =
    useContext(MPManagementContext);
  const { customer } = useContext(HelpersContext);

  const columns = [
    "S/N",
    "MORE ACTIONS",
    "RECIPIANT’S NAME",
    "RECIPIANT’S NUMBER",
    "CUSTOMIZED NAME",
    "PLAN NAME",
    "ITEM NAME",
    "Quantity",
    "DATE OF SUBSCRIPTION",
    "TIME OF SUBSCRIPTION",
    "ORDER STATUS",
    "AMOUNT",
    "PAYMENT METHOD",
    "Payment Status",
    "DELIVERY METHOD",
  ];

  // extract data for the table rows
  let extractedOrders;
  if (userMPOrders.length > 0) {
    extractedOrders = userMPOrders.map((eachOrder, index) => {
      const order = eachOrder.orderInfo;
      const menuPlan = order.MenuPlan;
      const menuItem = order.MenuPlanDetail.MenuItem;

      return [
        index + 1,
        <MPOrderActions order={eachOrder} />,
        order.friendName,
        order.friendPhoneNumber,
        order.orderName,
        menuPlan.name,
        menuItem.itemName,
        order.quantity,
        moment(order.createdAt).format("L"),
        moment(order.createdAt).format("HH:mm:ss"),
        order.status,
        <NumberFormaterPrefix value={order.total} />,
        order.paymentMethod,
        order.paymentStatus,
        order.deliveryOption,
      ];
    });
  }

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter">
        {/* today */}
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{userMPOrders.length}</span>
          </p>
        </Grid>

        <Grid item xs={2} sm={2} md={2} lg={2} className="filter_text"></Grid>

        {/* search */}
        <CustomerSearch getOrders={getMenuPlanByUser} />

        {/* pagination */}
        <NextAndPrevButtons />
      </Grid>

      {/* set table */}
      <TableContent
        loading={userOrdersLoading}
        receivedData={userMPOrders}
        heading={customer}
        tableData={extractedOrders}
        columns={columns}
        tableOptions={tableOptions}
      />
    </main>
  );
}
