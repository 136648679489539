import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingFeedback(props) {
  return (
    <div id="loading_feedback">
      <CircularProgress />
      <h3><b>{props.message}</b></h3>
    </div>
  );
}
