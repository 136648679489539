// external imports
import { Switch, Route } from "react-router-dom";
import { useContext } from "react";

// assets
import "./assets/App.scss";

// internal imports
import AdminLogin from "./pages/admin/AdminLogin";
import CreateBranch from "./pages/admin/settings/CreateBranch";
import CreateMenuItem from "./pages/admin/CreateMenuItem";
import CreateMenuPlan from "./pages/admin/MenuPlan/CreateMenuPlan";
import DashboardLP from "./pages/admin/DashboardLP";
import ProfileSettings from "./pages/admin/settings/ProfileSettings";
import BranchSetUp from "./pages/admin/settings/BranchSetUp";
import OrderAndSchedule from "./pages/admin/settings/OrderAndSchedule";
import CreateStaff from "./pages/admin/CreateStaff";
import CreateRole from "./pages/admin/CreateRole";
import CreatePromotion from "./pages/admin/CreatePromotion";
import CreateVoucher from "./pages/admin/CreateVoucher";
import CreateInventory from "./pages/admin/Inventory/CreateInventory";
import ManageInventory from "./pages/admin/Inventory/ManageInventory";
import EditInventory from "./pages/admin/Inventory/EditInventory";
import IncomingOrder from "./pages/admin/Order/IncomingOrder/IncomingOrder";
import ViewAnIncomingOrder from "./pages/admin/Order/IncomingOrder/ViewAnIncomingOrder";
import ActiveOrder from "./pages/admin/Order/ActiveOrder/ActiveOrder";
import ViewAnActiveOrder from "./pages/admin/Order/ActiveOrder/ViewAnActiveOrder";
import ProtectedRoute from "./components/helpers/ProtectedRoute";
import CreateMenuPlanDetails from "./pages/admin/MenuPlan/CreateMenuPlanDetails";
import CreatePOSOrder from "./pages/admin/CreatePOSOrder";
import ActiveMPOrders from "./pages/admin/MenuPlanManagement/ActiveMPOrders/ActiveMPOrders";
import CustomerMPOrder from "./pages/admin/MenuPlanManagement/CustomerMPOrder/CustomerMPOrder";
import ViewACustomerMPOrder from "./pages/admin/MenuPlanManagement/CustomerMPOrder/ViewACustomerMPOrder";
import ViewMenuItems from "./pages/admin/MenuItemsMGT/ViewMenuItems";
import EditMenuItem from "./pages/admin/MenuItemsMGT/EditMenuItem";
import ViewMealPlans from "./pages/admin/MealPlanMGT/ViewMealPlans";
import EditMealPlan from "./pages/admin/MealPlanMGT/EditMealPlan";
import AddMPDetails from "./pages/admin/MenuPlan/AddMPDetails";
import ViewCustomers from "./pages/admin/Customer/ViewCustomers";
import RetrievingMPDetails from "./pages/admin/MenuPlan/RetrievingMPDetails";
import { HelpersContext } from "./ContextAPI/HelpersContext";
import Permission404 from "./pages/admin/PageNotFound/Permission404";
import InstantOrders from "./pages/admin/Sales/InstantOrders";
import MenuPlanOrders from "./pages/admin/Sales/MenuPlanOrders";
import CustomerOrders from "./pages/admin/Order/Customers/CustomerOrders";
import ViewCategories from "./pages/admin/MPCategoryMGT/ViewCategories";
import NotFound from "./pages/admin/PageNotFound/NotFound";
import Create from "./pages/admin/CustomerMP/Create";
import Pending from "./components/adminComponents/CustomerRecipe/Pending";
import ViewAndUpdateDetails from "./components/adminComponents/CustomerRecipe/ViewAndUpdateDetails";
import Completed from "./components/adminComponents/CustomerRecipe/Completed";
import CreatedMP from "./components/adminComponents/CustomerMP/CreatedMP";
import Survey from "./pages/admin/Survey/Survey";
import ViewSurvey from "./components/adminComponents/Survey/ViewSurvey";
import ActiveRecipeOrder from "./components/adminComponents/RecipeOrder/Active";
import ViewRecipeOrder from "./components/adminComponents/RecipeOrder/View";
import PendingMPOrders from "./pages/admin/MenuPlanManagement/PendingMPOrders/PendingMPOrders";
import HistoryMPOrders from "./pages/admin/MenuPlanManagement/HistoryMPOrders/HistoryMPOrders";
import PendingRecipeOrder from "./components/adminComponents/RecipeOrder/Pending";
import HistoryRecipeOrder from "./components/adminComponents/RecipeOrder/History";
import Send from "./components/adminComponents/Notification/Send";
import CreateAndEarn from "./pages/admin/CreateAndEarn/CreateAndEarn";
import ManageDetails from "./components/adminComponents/CreateAndEarn/ManageDetails";
import ViewInactiveMenuItems from "./pages/admin/MenuItemsMGT/ViewInactiveMenuItems";
import ViewInactiveMP from "./pages/admin/MealPlanMGT/ViewInactiveMP";
import PaymentVerification from "./pages/admin/Payment/PaymentVerification";

function App() {
  const { roles } = useContext(HelpersContext);

  return (
    <>
      <Switch>
        <Route exact path="/" component={AdminLogin} />
        <ProtectedRoute exact path="/dashboard" component={DashboardLP} />

        {/* customers */}
        <ProtectedRoute
          exact
          path="/view-customers"
          component={
            roles.includes("Customer Service 1") ? ViewCustomers : Permission404
          }
        />

        {/* settings */}
        <ProtectedRoute
          exact
          path="/profile-setting"
          component={ProfileSettings}
        />
        <ProtectedRoute exact path="/branch-setup" component={BranchSetUp} />
        <ProtectedRoute
          exact
          path="/order-and-schedule"
          component={OrderAndSchedule}
        />
        <ProtectedRoute
          exact
          path="/create-branch"
          component={roles.includes("Admin 1") ? CreateBranch : Permission404}
        />

        {/* inventory routes */}
        <ProtectedRoute
          exact
          path="/create-inventory"
          component={
            roles.includes("Holding 1") ? CreateInventory : Permission404
          }
        />
        <ProtectedRoute
          exact
          path="/manage-inventory"
          component={
            roles.includes("Holding 3") ? ManageInventory : Permission404
          }
        />
        <ProtectedRoute
          exact
          path="/edit-inventory"
          component={
            roles.includes("Holding 2") ? EditInventory : Permission404
          }
        />

        {/* menu-item */}
        <ProtectedRoute
          exact
          path="/create-menu-item"
          component={roles.includes("Chef 1") ? CreateMenuItem : Permission404}
        />

        {/* menu-item mgt */}
        <ProtectedRoute
          exact
          path="/menu-items-management"
          component={roles.includes("Chef 1") ? ViewMenuItems : Permission404}
        />

        <ProtectedRoute
          exact
          path="/inactive-menu-items-management"
          component={
            roles.includes("Chef 1") ? ViewInactiveMenuItems : Permission404
          }
        />

        <ProtectedRoute
          exact
          path="/edit-menu-item"
          component={roles.includes("Chef 1") ? EditMenuItem : Permission404}
        />

        {/* menu-plan */}
        <ProtectedRoute
          exact
          path="/create-menu-plan"
          component={roles.includes("Chef 1") ? CreateMenuPlan : Permission404}
        />

        <ProtectedRoute
          exact
          path="/add-menu-plan-details"
          component={
            roles.includes("Chef 1") ? CreateMenuPlanDetails : Permission404
          }
        />

        <ProtectedRoute
          exact
          path="/add-menu-plan-info"
          component={roles.includes("Chef 1") ? AddMPDetails : Permission404}
        />

        {/* meal plan mgt */}
        <ProtectedRoute
          exact
          path="/view-menu-plans"
          component={roles.includes("Chef 1") ? ViewMealPlans : Permission404}
        />

        <ProtectedRoute
          exact
          path="/inactive-view-menu-plans"
          component={roles.includes("Chef 1") ? ViewInactiveMP : Permission404}
        />

        <ProtectedRoute
          exact
          path="/edit-meal-plan"
          component={roles.includes("Chef 1") ? EditMealPlan : Permission404}
        />

        <ProtectedRoute
          exact
          path="/retrieving-meal-plan-details"
          component={
            roles.includes("Chef 1") ? RetrievingMPDetails : Permission404
          }
        />

        {/* staff */}
        <ProtectedRoute
          exact
          path="/create-staff"
          component={roles.includes("Admin 2") ? CreateStaff : Permission404}
        />

        <ProtectedRoute exact path="/create-role" component={CreateRole} />
        <ProtectedRoute
          exact
          path="/create-promotion"
          component={CreatePromotion}
        />
        <ProtectedRoute
          exact
          path="/create-voucher"
          component={CreateVoucher}
        />

        {/* Meal item Order */}
        {/* incoming */}
        <ProtectedRoute
          exact
          path="/incoming-order"
          component={
            roles.includes("Order management 1") ? IncomingOrder : Permission404
          }
        />
        <ProtectedRoute
          exact
          path="/view-an-incoming-order"
          component={
            roles.includes("Order management 1")
              ? ViewAnIncomingOrder
              : Permission404
          }
        />

        {/* active */}
        <ProtectedRoute exact path="/active-order" component={ActiveOrder} />
        <ProtectedRoute
          exact
          path="/view-an-active-order"
          component={
            roles.includes("Order management 1")
              ? ViewAnActiveOrder
              : Permission404
          }
        />

        {/* pos */}
        <ProtectedRoute exact path="/POS" component={CreatePOSOrder} />

        {/* Active MP Orders */}
        <ProtectedRoute
          exact
          path="/menu-plan-active-orders"
          component={
            roles.includes("Order management 2")
              ? ActiveMPOrders
              : Permission404
          }
        />
        <ProtectedRoute
          exact
          path="/view-a-mp-active-order"
          component={ActiveMPOrders}
        />

        {/* Pending MP Orders */}
        <ProtectedRoute
          exact
          path="/menu-plan-pending-orders"
          component={
            roles.includes("Order management 2")
              ? PendingMPOrders
              : Permission404
          }
        />

        {/* Pending MP Orders */}
        <ProtectedRoute
          exact
          path="/menu-plan-history-orders"
          component={
            roles.includes("Order management 2")
              ? HistoryMPOrders
              : Permission404
          }
        />

        {/* Customer Orders */}
        <ProtectedRoute
          exact
          path="/customer-orders"
          component={
            roles.includes("Order management 1")
              ? CustomerOrders
              : Permission404
          }
        />

        {/* Customer MP Orders */}
        <ProtectedRoute
          exact
          path="/menu-plan-customer-orders"
          component={
            roles.includes("Order management 2")
              ? CustomerMPOrder
              : Permission404
          }
        />
        <ProtectedRoute
          exact
          path="/view-a-customer-mp-order"
          component={
            roles.includes("Order management 2")
              ? ViewACustomerMPOrder
              : Permission404
          }
        />

        {/* sales */}
        <ProtectedRoute
          exact
          path="/instant-order-sales"
          component={
            roles.includes("Cashier 1") ? InstantOrders : Permission404
          }
        />
        <ProtectedRoute
          exact
          path="/meal-plan-order-sales"
          component={
            roles.includes("Cashier 1") ? MenuPlanOrders : Permission404
          }
        />

        {/* Menu Plan categories */}
        <ProtectedRoute
          exact
          path="/view-menu-plans-categories"
          component={roles.includes("Chef 1") ? ViewCategories : Permission404}
        />

        {/* create customer MP */}
        <ProtectedRoute
          exact
          path="/create-customer-meal-plan"
          component={
            roles.includes("Create Plan for Customer") ? Create : Permission404
          }
        />
        <ProtectedRoute
          exact
          path="/created-customer-meal-plan"
          component={roles.includes("Chef 1") ? CreatedMP : Permission404}
        />

        {/* Recipe Mgt */}
        <ProtectedRoute
          exact
          path="/pending-recipe"
          component={
            roles.includes("Recipe Request Management 1")
              ? Pending
              : Permission404
          }
        />

        <ProtectedRoute
          exact
          path="/view-recipe-details"
          component={
            roles.includes("Recipe Request Management 1")
              ? ViewAndUpdateDetails
              : Permission404
          }
        />

        <ProtectedRoute
          exact
          path="/completed-recipe"
          component={
            roles.includes("Recipe Request Management 1")
              ? Completed
              : Permission404
          }
        />

        {/* Recipe Order Mgt */}
        <ProtectedRoute
          exact
          path="/recipe-order-management"
          component={
            roles.includes("Order Management 3")
              ? ActiveRecipeOrder
              : Permission404
          }
        />

        <ProtectedRoute
          exact
          path="/pending-recipe-order-management"
          component={
            roles.includes("Order Management 3")
              ? PendingRecipeOrder
              : Permission404
          }
        />

        <ProtectedRoute
          exact
          path="/history-recipe-order-management"
          component={
            roles.includes("Order Management 3")
              ? HistoryRecipeOrder
              : Permission404
          }
        />

        <ProtectedRoute
          exact
          path="/view-recipe-order"
          component={
            roles.includes("Order Management 3")
              ? ViewRecipeOrder
              : Permission404
          }
        />

        {/* survey */}
        <ProtectedRoute
          exact
          path="/survey-management"
          component={
            roles.includes("Survey Management") ||
            roles.includes("Create Plan for Customer")
              ? Survey
              : Permission404
          }
        />

        <ProtectedRoute
          exact
          path="/view-survey"
          component={
            roles.includes("Survey Management") ||
            roles.includes("Create Plan for Customer")
              ? ViewSurvey
              : Permission404
          }
        />

        {/* notification */}
        <ProtectedRoute
          exact
          path="/send-notification"
          component={roles.includes("Admin 2") ? Send : Permission404}
        />

        {/* Create And Earn */}
        <ProtectedRoute
          exact
          path="/create-and-earn"
          component={roles.includes("Admin 2") ? CreateAndEarn : Permission404}
        />

        <ProtectedRoute
          exact
          path="/view-create-and-earn-details"
          component={roles.includes("Admin 2") ? ManageDetails : Permission404}
        />

        {/* payment */}
        <ProtectedRoute
          exact
          path="/payment-verification"
          component={
            roles.includes("Admin 2") ? PaymentVerification : Permission404
          }
        />

        {/* page not found */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
