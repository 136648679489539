import { Button } from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import { UtilsContext } from "../../../../ContextAPI/UtilsContext";
import FeedbackPopup from "../../../helpers/FeedbackPopup";

export default function VerifyPaymentBtn({paymentRef}) {
  // context
  const { verifyPayment, verifying } = useContext(UtilsContext);

  return (
    <>
      <Button
        disabled={!verifying ? false : true}
        onClick={() => verifyPayment(paymentRef)}
      >
        {!verifying ? "Verify" : verifying}
      </Button>

      <FeedbackPopup
        condition={verifying}
        message={"Payments retrieved successfully!"}
      />
    </>
  );
}
