import React from "react";
import DashboardFrame from "../../../../components/adminComponents/DashboardFrame";
import PendingMPOrdersTable from "../../../../components/adminComponents/MenuPlanManagement/PendingMPOrders/PendingMPOrdersTable";
import SettingsFrame from "../../../../components/adminComponents/Settings/SettingsFrame";

export default function PendingMPOrders() {
  return (
    <main className="table_page_frame">
      <DashboardFrame
        pageHeading="Menu Plan Order"
        dashboardContent={
          <SettingsFrame
            firstLabel="Pending Orders"
            firstContent={<PendingMPOrdersTable />}
          />
        }
      />
    </main>
  );
}
