import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import ManageInventoryTable from "../../../components/adminComponents/Inventory/ManageInventory/ManageInventoryTable";
import SettingsFrame from "../../../components/adminComponents/Settings/SettingsFrame";

export default function ManageInventory() {
  return (
    <main className="table_page_frame">
      <DashboardFrame
        pageHeading="Inventory"
        dashboardContent={
            <SettingsFrame
              firstLabel="Manage"
              firstContent={<ManageInventoryTable />}
            />
          }
      />
    </main>
  );
}
