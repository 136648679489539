import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  DialogTitle, Hidden
} from "@material-ui/core/";
import SingleMenuItem from "./SingleMenuItem";
import { HiPlus, HiMinus } from "react-icons/hi";
import { IconContext } from "react-icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { CartContext } from "../../../ContextAPI/CartContext";
import LoadingFeedback from "../../helpers/LoadingFeedback";
import {
  increaseQty,
  reduceQty,
  toggleItems,
} from "../../helpers/MenuItemHelper";
import {
  getOrderTotal,
  reduceOverallQty,
  increaseOverallQty,
} from "../../helpers/POSOrderHelper";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import Slide from "@material-ui/core/Slide";
import { IoFastFoodOutline, IoCloseSharp } from "react-icons/io5";
import { MenuItemContext } from "../../../ContextAPI/MenuItemContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function MenuItemPopup(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const menuItem = props.menuItem;
  const customer = props.customer;
  const showCart = props.showCart;

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Grid
      item
      xs={showCart ? 12 : 6}
      sm={showCart ? 12 : 6}
      md={showCart ? 2 : 1}
      lg={showCart ? 2 : 1}
      className="card_grid"
    >
      <section onClick={handleClickOpen()}>
        <SingleMenuItem menuItem={menuItem} />
      </section>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"md"}
        scroll={scroll}
        disableBackdropClick={true}
        TransitionComponent={Transition}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <div className="cart_item_header">
            <section>
              <IoFastFoodOutline /> {menuItem.itemName}
            </section>
            <section onClick={() => handleClose()}>
              <IoCloseSharp />
            </section>
          </div>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <MenuItemPopupForm
              menuItem={menuItem}
              cartItems={props.cartItems}
              setCartItems={props.setCartItems}
              closePopup={handleClose}
              customer={customer}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

function MenuItemPopupForm(props) {
  // props
  const menuItem = props.menuItem;
  const customer = props.customer;
  const setCartItems = props.setCartItems;
  const cartItems = props.cartItems;
  const closePopup = props.closePopup;

  // context
  const { addCartItem, cartLoading } = useContext(CartContext);
  const {
    addons,
    getMenuItemAddons,
    addonsLoading,
    getMenuItemPreferences,
    preferences,
    preferencesLoading,
  } = useContext(MenuItemContext);

  // get all addons here
  useEffect(() => {
    getMenuItemAddons(menuItem.id);
    getMenuItemPreferences(menuItem.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // initial states
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [selectedPreference, setSelectedPreference] = useState([]);
  const [overallQty, setOverallQty] = useState(1);
  const [instructions, setInstructions] = useState("");

  // get total price after manipulations
  const totalPrice =
    getOrderTotal(selectedAddons, selectedPreference, menuItem.amount) *
    parseFloat(overallQty);

  // submit menu item
  const handleSubmit = (e) => {
    e.preventDefault();

    addCartItem({
      menuItem,
      overallQty,
      totalPrice,
      instructions,
      selectedAddons,
      selectedPreference,
      customer,
      setCartItems,
      cartItems,
      closePopup,
    });
  };

  return (
    <form id="pos_menu_item_popup" onSubmit={(e) => handleSubmit(e)}>
      {/* addons section */}
      <Grid container id="menu_item_addons">
        {/* menu_item_addons_list */}
        <Grid item xs={12} sm={5} md={5} lg={5}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h3>Add-Ons ({addons.length})</h3>
          </Grid>

          {/* addons list */}
          <FormControl component="fieldset" id="menu_item_addons_list">
            <FormGroup aria-label="position" column>
              {addonsLoading === "processing" ? (
                <LoadingFeedback message="Searching For Addons..." />
              ) : addonsLoading === "found" && addons.length > 0 ? (
                addons.map((addon) => (
                  <>
                    <FormControlLabel
                      key={addon.id}
                      value={addon.Inventory.itemName}
                      title={addon.Inventory.description}
                      control={
                        <>
                          <Checkbox
                            color="primary"
                            onChange={(e) =>
                              toggleItems(
                                e.target.checked,
                                addon,
                                selectedAddons,
                                setSelectedAddons,
                                "order"
                              )
                            }
                          />

                          <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              <pre id="label">
                                {addon.isExtra
                                  ? "Extra " + addon.Inventory.itemName
                                  : addon.Inventory.itemName}
                              </pre>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              <pre id="price">{addon.price} NGN</pre>
                            </Grid>
                          </Grid>
                        </>
                      }
                      labelPlacement="end"
                    />
                    <Divider />
                  </>
                ))
              ) : addonsLoading === "found" && addons.length <= 0 ? (
                <LoadingFeedback message="Search Completed But No Addon Found" />
              ) : addonsLoading === "failed" ? (
                <LoadingFeedback message="An Error Occured. Check your Connection" />
              ) : (
                ""
              )}
            </FormGroup>
          </FormControl>
        </Grid>

        <Hidden xsDown>
          <div className="pos_vertical_rule"></div>
        </Hidden>

        {/* menu_item_selected_addons */}
        <Grid item xs={12} sm={5} md={6} lg={6} id="menu_item_selected_addons">
          {/* placeholder when no addons is selected */}
          {selectedAddons.length > 0 ? (
            <aside>
              {/* selected addons appears here */}
              {selectedAddons.map((addon, index) => (
                <div className="adjust_menu_item_qty" key={addon.id}>
                  <p>{addon.name}</p>

                  <section id="qty_btns">
                    {/* subtract */}
                    <button
                      onClick={(e) =>
                        reduceQty(e, index, selectedAddons, setSelectedAddons)
                      }
                    >
                      <HiMinus />
                    </button>

                    <p>{addon.quantity}</p>
                    {/* add */}
                    <button
                      onClick={(e) =>
                        increaseQty(e, index, selectedAddons, setSelectedAddons)
                      }
                    >
                      <HiPlus />
                    </button>
                  </section>

                  <p>{addon.totalPrice} NGN</p>
                </div>
              ))}
            </aside>
          ) : (
            <>
              {/* placeholder when no addons is selected yet */}
              <section id="view_selected_addons">
                <IconContext.Provider
                  value={{ className: "selected_addons_placeholder_icon" }}
                >
                  <HiPlus />
                </IconContext.Provider>

                <p>Items you select will appear here</p>
              </section>
            </>
          )}

          {/* adjust quantity generally from here */}
          <main className="adjust_menu_item_qty" id="main_adjust_qty">
            <h4>Adjust Quantity</h4>

            <section id="qty_btns">
              {/* subtract */}
              <button
                onClick={(e) => reduceOverallQty(e, overallQty, setOverallQty)}
              >
                <HiMinus />
              </button>
              <p>{overallQty}</p>

              {/* add */}
              <button
                onClick={(e) =>
                  increaseOverallQty(e, overallQty, setOverallQty)
                }
              >
                <HiPlus />
              </button>
            </section>
          </main>
        </Grid>
      </Grid>

      {/* preference section */}
      <Grid container id="menu_item_preference">
        {/* menu_item_preference_list */}
        <Grid item xs={12} sm={5} md={5} lg={5} id="menu_item_preference_list">
          {/* preference list */}
          <FormControl component="fieldset" id="menu_item_addons_list">
            <FormLabel component="legend">Preference ({preferences.length})</FormLabel>
            <FormGroup aria-label="position" column>
              {preferencesLoading === "processing" ? (
                <LoadingFeedback message="Searching For Preferences..." />
              ) : preferencesLoading === "found" && preferences.length > 0 ? (
                preferences.map((preference) => (
                  <>
                    <FormControlLabel
                      key={preference.Preference.id}
                      value={preference.Preference.name}
                      control={
                        <>
                          <Checkbox
                            color="primary"
                            onChange={(e) =>
                              toggleItems(
                                e.target.checked,
                                preference,
                                selectedPreference,
                                setSelectedPreference
                              )
                            }
                          />

                          <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              <pre id="label">{preference.Preference.name}</pre>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              <pre id="price">
                                {preference.Preference.unitPrice} NGN
                              </pre>
                            </Grid>
                          </Grid>
                        </>
                      }
                      labelPlacement="end"
                    />
                    <Divider />
                  </>
                ))
              ) : preferencesLoading === "found" && preferences.length <= 0 ? (
                <LoadingFeedback message="Search Completed But No Preference Found" />
              ) : preferencesLoading === "failed" ? (
                <LoadingFeedback message="An Error Occured. Check your Connection" />
              ) : (
                ""
              )}
            </FormGroup>
          </FormControl>
        </Grid>

        {/* special_instruction */}
        <Grid item xs={12} sm={5} md={5} lg={5} id="special_instruction">
          <h4>Special Instructions</h4>
          <TextField
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            name="instructions"
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
          />
        </Grid>
      </Grid>

      {/* price */}
      <main className="price_section">
        {/* old */}
        <section>
          <p>Old price</p>
          <p>
            {menuItem.discount
              ? parseFloat(menuItem.amount) + parseFloat(menuItem.discount)
              : menuItem.amount}{" "}
            NGN
          </p>
        </section>

        {/* new */}
        <section>
          <p>New price</p>
          <p>{menuItem.amount} NGN</p>
        </section>

        {/* total */}
        <section>
          <p>Total price</p>
          <p>{totalPrice} NGN</p>
        </section>
      </main>

      <FeedbackPopup
        condition={cartLoading}
        additionalMessage="Popup closes in a moment..."
      />

      {/* button */}
      <section className="form-btn">
        <Button type="submit">Add to cart</Button>
      </section>
    </form>
  );
}
