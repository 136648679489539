import { Grid } from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import { UtilsContext } from "../../../ContextAPI/UtilsContext";
import VerifyPaymentBtn from "../MenuPlanManagement/ViewAMPOrderFrame/VerifyPaymentBtn";
import Details from "./Verification/Details";
import Search from "./Verification/Search";

export default function Verification() {
  const { payment, loadingPay } = useContext(UtilsContext);

  return (
    <main>
      <Grid container className="top_filter">
        <Search />
      </Grid>

      <Grid container id={"payment_ref_details"}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          {!loadingPay ? (
            "Search Result Will Appear Here..."
          ) : loadingPay === "processing" ? (
            "Searching For Payment"
          ) : loadingPay === "found" ? (
            <Details payment={payment} />
          ) : (
            "No Details For This Payment Reference"
          )}
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6} className="verify_btn">
          {payment ? <VerifyPaymentBtn paymentRef={payment.paymentRef} /> : ""}
        </Grid>
      </Grid>
    </main>
  );
}
