import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Button, Grid } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";
import { collectMenuItems, getIds, getTimeRanges } from "../../../../helpers/MenuPlanHelper";
import NumberFormaterSuffix from "../../../../helpers/NumberFormaterSuffix";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

export default function MenuItemWithCheckBox(props) {
  const classes = useStyles();
  const menuItem = props.menuItem;

  // context
  const { MPSelectedMenuItem, setMPSelectedMenuItem, savedMPItems } =
    useContext(HelpersContext);

  return (
    <Grid item xs={2} sm={2} md={2} lg={2} id="menu_item">
      {props.mode === "view" ? (
        ""
      ) : (
        <section>
          <label className="container">
            <input
              type="checkbox"
              checked={getIds(MPSelectedMenuItem).includes(menuItem.id)}
              onChange={(e) =>
                collectMenuItems(
                  e.target.checked,
                  menuItem,
                  MPSelectedMenuItem,
                  setMPSelectedMenuItem,
                  menuItem.id
                )
              }
            />
            <span className="checkmark"></span>
          </label>
        </section>
      )}

      <section>
        <Card className={classes.root}>
          <CardActionArea>
            {/* item image */}
            <CardMedia
              component="img"
              alt={menuItem.itemName}
              height="100"
              image={menuItem.imageUrl}
              title={getTimeRanges(menuItem.id, savedMPItems)}
            />

            {/* item name */}
            <CardContent title={menuItem.description}>
              <Typography variant="body2" color="textSecondary" component="p">
                {menuItem.itemName}
              </Typography>
            </CardContent>
          </CardActionArea>

          {/* price */}
          <CardActions title={menuItem.description}>
            <Button size="small" color="primary" align="center">
            <NumberFormaterSuffix value={menuItem.amount} />
            </Button>
          </CardActions>
        </Card>
      </section>
    </Grid>
  );
}
