import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { EditMenuItemContext } from "../../../../ContextAPI/EditMenuItemContext";
import FeedbackPopup from "../../../helpers/FeedbackPopup";

export default function AvailabilityAndDisplay(props) {
  // props
  const item = props.item;
  const id = item.id;
  const url = props.url;
  const isCategory = props.isCategory;

  // context
  const { updateAvailability, updatingAvailability } =
    useContext(EditMenuItemContext);

  // states
  const [availability, setAvailability] = useState(item.available);
  const [displayOnApp, setDisplayOnApp] = useState(item.activated);

  return (
    <>
      <FormControl component="fieldset" className="switch_input">
        <FormGroup aria-label="Availability" row>
          <FormControlLabel
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Switch
                checked={availability}
                color="primary"
                onChange={(e) =>
                  updateAvailability(
                    e.target.checked,
                    id,
                    setAvailability,
                    "availability",
                    url,
                    isCategory
                  )
                }
              />
            }
            label="AV"
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>

      {/* display on app */}
      <FormControl component="fieldset" className="switch_input">
        <FormGroup aria-label="DisplayOnApp" row>
          <FormControlLabel
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Switch
                checked={displayOnApp}
                color="primary"
                onChange={(e) =>
                  updateAvailability(
                    e.target.checked,
                    id,
                    setDisplayOnApp,
                    "displayOnApp",
                    url,
                    isCategory
                  )
                }
              />
            }
            label="DOA"
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>

      {/* feedback popup */}
      <FeedbackPopup condition={updatingAvailability} additionalMessage="" />
    </>
  );
}
