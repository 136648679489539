import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useContext } from "react";
import { RecipeContext } from "../../../../ContextAPI/RecipeContext";
import HalfRowLayout from "../../../helpers/HalfRowLayout";
import SubmitButton from "../../CustomerMP/Create/itemPopup/SubmitButton";
import { BiPrinter } from "react-icons/bi";

export default function GeneralInfo() {
  const { recipeDetails, recipeTableType } = useContext(RecipeContext);
  const customer = recipeDetails.User;

  return (
    <>
      <Grid container id="general_info">
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Grid container>
            <HalfRowLayout className="text_key" text="COMPLETED BY:" />
            <HalfRowLayout text="" />

            <HalfRowLayout className="text_key" text="CUSTOMER NAME:" />
            <HalfRowLayout text={customer.fullName} />

            <HalfRowLayout className="text_key" text="CUSTOMER NUMBER:" />
            <HalfRowLayout text={customer.phoneNumber} />

            <HalfRowLayout className="text_key" text="SURVEY ID:" />
            <HalfRowLayout text={recipeDetails.id} />

            <HalfRowLayout className="text_key" text="TYPE OF SERVICE:" />
            <HalfRowLayout text={recipeDetails.chefType} />

            <HalfRowLayout className="text_key" text="SPECIAL ATTENTION:" />
            <HalfRowLayout
              text={recipeDetails.privateAttention === "TRUE" ? "YES" : "NO"}
            />

            <HalfRowLayout className="text_key" text="DATE OF REQUEST:" />
            <HalfRowLayout text={moment(recipeDetails.createdAt).format("L")} />

            <HalfRowLayout className="text_key" text="TIME OF REQUEST:" />
            <HalfRowLayout
              text={moment(recipeDetails.createdAt).format("LTS")}
            />

            <HalfRowLayout className="text_key" text="DATE OF COMPLETION:" />
            <HalfRowLayout
              text={
                recipeTableType === "COMPLETED"
                  ? moment(recipeDetails.updatedAt).format("L")
                  : ""
              }
            />

            <HalfRowLayout className="text_key" text="TIME OF COMPLETION:" />
            <HalfRowLayout
              text={
                recipeTableType === "COMPLETED"
                  ? moment(recipeDetails.updatedAt).format("LTS")
                  : ""
              }
            />

            <HalfRowLayout className="text_key" text="STATUS:" />
            <HalfRowLayout text={recipeDetails.status} />
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          <div className="print_btn" style={{ textAlign: "right" }}>
            <SubmitButton
              icon={<BiPrinter />}
              label="Print page"
              altClass={"alt_class"}
              submitFunction={() => window.print()}
            />
          </div>

          <Grid container>
            <HalfRowLayout className="text_key" text="MEAL PLAN FEE:" />
            <HalfRowLayout text="" />

            <HalfRowLayout className="text_key" text="PAYMENT STATUS:" />
            <HalfRowLayout text="" />

            <HalfRowLayout className="text_key" text="PAYMENT GATEWAY:" />
            <HalfRowLayout text="" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
