import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextField, Button } from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { MenuItemPreferenceContext } from "../../../../ContextAPI/MenuItemPreferenceContext";

export default function AddPreference(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div id="add_category_popup">
      {/* popup button */}
      <Button onClick={handleClickOpen()}>Add</Button>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <AddPreferenceForm
              preferenceName={props.preferenceName}
              selectedPreference={props.selectedPreference}
              setSelectedPreference={props.setSelectedPreference}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function AddPreferenceForm(props) {
  const { addPreference } = useContext(MenuItemPreferenceContext);
  const selectedPreference = props.selectedPreference;
  const setSelectedPreference = props.setSelectedPreference;
  const [name, setName] = useState(props.preferenceName);
  const [unitPrice, setUnitPrice] = useState(0);

  // yup schema for validation
  const schema = yup.object().shape({
    name: yup.string().required(),
    unitPrice: yup.number().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    addPreference(data, selectedPreference, setSelectedPreference);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* name */}
      <section>
        <h4>Name</h4>
        <TextField
          fullWidth
          variant="outlined"
          name="name"
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ""}
          inputRef={register}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </section>

      {/* unitPrice */}
      <section>
        <h4>Unit Price</h4>
        <TextField
          fullWidth
          type="number"
          variant="outlined"
          placeholder="No price? Enter Zero (0)"
          error={!!errors.unitPrice}
          helperText={errors.unitPrice ? errors.unitPrice.message : ""}
          inputRef={register}
          name="unitPrice"
          value={unitPrice}
          onChange={(e) => setUnitPrice(e.target.value)}
        />
      </section>

      {/* button */}
      <section className="form-btn">
        <Button type="submit">Add</Button>
      </section>
    </form>
  );
}
