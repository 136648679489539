// search for a bank using either account number, account name or bank name
const searchBank = (searchData, allData, setter) => {
  // discontinue if there is no search yet
  if (searchData === null || allData === []) return;

  // empty the previous search array if any
  const searchResult = [];
  const data = searchData.toLowerCase();

  // loop through all Preferences
  for (const bank of allData) {
    //   convert to lower case
    const bankName = bank.bankName.toLowerCase();
    const accountName = bank.accountName.toLowerCase();
    const accountNumber = bank.accountNumber;

    // check if the search word or character matches
    if (
      [...bankName].includes(data) ||
      bankName === data ||
      bankName.split(" ").includes(data) ||
      [...accountName].includes(data) ||
      accountName === data ||
      accountName.split(" ").includes(data) ||
      [...accountNumber].includes(data) ||
      accountNumber === data ||
      accountNumber.split(" ").includes(data)
    ) {
      // add any match to the searchResult
      if (!searchResult.includes(bank)) {
        searchResult.push(bank);
      }
    }
  }

  setter(searchResult);
};

// setup a selected bank detials
const selectBank = (
  bankDetails,
  setSearchData,
  setSelectedBank,
  setShowAllBank
) => {
  setSearchData(bankDetails.accountName);
  setSelectedBank(bankDetails);
  setShowAllBank(false);
};

// toggle showing of item in the pos
const toggleShow = (show, setShow, showOtherItem) => {
  if (show === false) {
    showOtherItem(false);
    setShow(true);
  } else {
    setShow(false);
  }
};

// sort orders according to the status
const sortOrders = (orders, status, orderType) => {
  if (!orders.length) return [];

  let sortedOrders = [];

  for (const order of orders) {
    let statusRetrieved;

    if (orderType === "RECIPE" || orderType === "SURVEY") {
      statusRetrieved = order.status;
    } else if (orderType === "RECIPE-ORDER") {
      statusRetrieved = order.RecipeOrderDeliveryDates[0].status;
    } else {
      statusRetrieved = order.menuitemorders.status;
    }

    if (statusRetrieved === status) {
      sortedOrders.push(order);
    }
  }

  return sortedOrders;
};

// sort MP orders according to the status
const sortMPOrders = (orders, status) => {
  if (!orders.length) return [];

  let sortedOrders = [];

  for (const order of orders) {
    if (order.orderInfo.status === status) {
      sortedOrders.push(order);
    }
  }

  return sortedOrders;
};

// table options
const tableOptions = {
  filter: true,
  filterType: "dropdown",
  responsive: "standard",
  tableBodyHeight: "100%",
  tableBodyMaxHeight: "800px",
  jumpToPage: true,
  selectableRows: false,
  pagination: false
};

// get filtered total
const getFilteredTotal = (data, setter, amountCol) => {
  console.log(data);
  console.log(amountCol);
  if (!data || !data.length) return;

  let total = 0;

  for (const item of data) {
    total += parseFloat(item.data[amountCol].props.value);
  }

  setter(total);
};

// get order total
const getOrderTotal = (order) => {
  let total = 0;
  const deliveryTime = JSON.parse(order.deliveryTime);

  for (const item of deliveryTime) {
    const currentTotal = parseFloat(order.total) * parseFloat(item.quantity);

    total = parseFloat(total) + parseFloat(currentTotal);
  }

  return total;
};

// getTotalWithoutFilter
const getTotalWithoutFilter = (orders) => {
  let total = 0;

  for (const order of orders) {
    total = parseFloat(total) + parseFloat(getOrderTotal(order.orderInfo));
  }

  return total;
};

export {
  searchBank,
  selectBank,
  toggleShow,
  sortOrders,
  sortMPOrders,
  tableOptions,
  getFilteredTotal,
  getOrderTotal,
  getTotalWithoutFilter,
};
