import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { MdCancel } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { timeLimitConvertion } from "../../../../helpers/MenuPlanHelper";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";
import {
  getAddonsNameAndQuantity,
  getQuantityOfItemRange,
  removeFromPreview,
} from "../../HelperFunctions";
import { getNames } from "../../../../helpers/POSOrderHelper";
import NumberFormaterPrefix from "../../../../helpers/NumberFormaterPrefix";

export default function EachTimeRangMeals({ time, day }) {
  const { customerMPPreview } = useContext(HelpersContext);

  return (
    <>
      <main>
        <h3>{timeLimitConvertion(time)}</h3>

        <Grid container>
          {customerMPPreview && customerMPPreview.length
            ? customerMPPreview.map((item) =>
                item.timeRangeArray.includes(time) &&
                item.selectedDays.includes(day) ? (
                  <Content item={item} time={time} day={day} />
                ) : (
                  ""
                )
              )
            : ""}
        </Grid>
      </main>
    </>
  );
}

function Content({ item, time, day }) {
  const {
    customerMPPreview,
    setSubscriptionDuration,
    setDeliveryTimesSelected,
    setCustomerMPPreview,
  } = useContext(HelpersContext);

  const qty = getQuantityOfItemRange(item, time, day);

  return (
    <>
      <Grid item xs={12} sm={4} md={3} lg={3} id="each_preview_content_dates">
        <div id="preview_content_dates">
          {/* name and quantity of meal, addons, preferences */}
          <section id="title">
            <p>
              <span>({qty}x) </span>
              {item.menuItem.itemName}
              <span>
                {" "}
                {getAddonsNameAndQuantity(item.addons).join(", ")}
                {", "}
                {getNames(item.preferences).join(", ")}
              </span>
            </p>

            <p
              id="cancel_icon"
              onClick={() => {
                removeFromPreview(
                  customerMPPreview,
                  setCustomerMPPreview,
                  day,
                  time,
                  item,
                  setSubscriptionDuration,
                  setDeliveryTimesSelected
                );
              }}
            >
              <MdCancel />
            </p>
          </section>

          <section id="price">
            <h1>
              <NumberFormaterPrefix
                value={parseFloat(item.price) * parseFloat(qty)}
              />{" "}
            </h1>
          </section>

          {/* instructions */}
          <section id="instructions">
            <h4>Special instructions</h4>
            <p>{item.modifyDescription}</p>
          </section>

          {/* edit btn */}
          <section id="edit_btn">
            <BiEdit /> <p>Edit</p>
          </section>
        </div>
      </Grid>
    </>
  );
}
