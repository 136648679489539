import React from "react";
import { Grid } from "@material-ui/core";
import EachDayBodyPreview from "./EachDayBodyPreview";
import EachDayHeader from "./EachDayHeader";

export default function EachDayPreview(props) {
  return (
    <Grid container id="each_day">
      {/* date */}
      <EachDayHeader day={props.day} />

      <Grid item xs={12} sm={12} md={12} lg={12} id="each_day_body">
        <EachDayBodyPreview />
      </Grid>
    </Grid>
  );
}
