import { Grid } from "@material-ui/core";
import moment from "moment";
import HalfRowLayout from "../../../helpers/HalfRowLayout";
import Print from "./Print";
import { Notify } from "./Notify";
import Status from "./Status";
import Reject from "./Reject";

export default function GeneralInfo({ recipe }) {
  const customer = recipe.createdByDetail;
  const status = recipe.status;

  return (
    <>
      <Grid container id="general_info">
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Grid container>
            <HalfRowLayout className="text_key" text="CUSTOMER NAME:" />
            <HalfRowLayout text={customer.fullName} />

            <HalfRowLayout className="text_key" text="CUSTOMER NUMBER:" />
            <HalfRowLayout text={customer.phoneNumber} />
            <HalfRowLayout className="text_key" text="STATUS:" />
            <HalfRowLayout text={recipe.status} />

            <HalfRowLayout className="text_key" text="DATE OF REQUEST:" />
            <HalfRowLayout text={moment(recipe.createdAt).format("L")} />

            <HalfRowLayout className="text_key" text="TIME OF REQUEST:" />
            <HalfRowLayout text={moment(recipe.createdAt).format("LTS")} />
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          {/* print */}
          <div className="print_btn">
            <Print />

            {/* notification */}
            {status === "REJECTED" ? <Notify recipe={recipe} /> : ""}
          </div>

          {/* status */}
          {status === "APPROVED" || status === "REJECTED" ? (
            ""
          ) : (
            <section className="print_btn" id="status_btn">
              <Status status={status} id={recipe.id} />

              {/* reject recipe */}
              <Reject id={recipe.id} />
            </section>
          )}
        </Grid>
      </Grid>
    </>
  );
}
