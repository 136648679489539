import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import TableContent from "../../helpers/Table/TableContent";
import { tableOptions } from "../../helpers/OrderHelpers";
import { CreateAndEarnContext } from "../../../ContextAPI/CreateAndEarn";
import moment from "moment";
import Actions from "./Actions";

export default function ViewAll() {
  // context
  const { changing, createAndEarnRecipes } = useContext(CreateAndEarnContext);

  // table headings
  const columns = [
    "",
    "S/N",
    "CUSTOMER'S NAME",
    "CUSTOMER’S NUMBER",
    "ITEM TITLE",
    "ORDER ID",
    "ORDER STATUS",
    "DATE OF REQUEST",
    "TIME OF REQUEST",
  ];

  // extract data for the table rows
  const extractedOrders = createAndEarnRecipes.map((order, index) => {
    const customer = order.createdByDetail;
    return [
      <Actions recipe={order} />,
      index + 1,
      customer.fullName,
      customer.phoneNumber,
      order.title,
      order.id.split("-")[4],
      order.status,
      moment(order.createdAt).format("L"),
      moment(order.createdAt).format("HH:mm:ss"),
    ];
  });

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter" id="pos_filter">
        {/* today */}
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{createAndEarnRecipes.length}</span>
          </p>
        </Grid>
      </Grid>

      {/* set table */}
      <TableContent
        loading={changing}
        receivedData={createAndEarnRecipes}
        heading="Recipes"
        tableData={extractedOrders}
        columns={columns}
        tableOptions={tableOptions}
      />
    </main>
  );
}
