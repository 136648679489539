import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function SelectedTimeRanges(props) {
  // context
  const {
    morningRanges,
    afternoonRanges,
    eveningRanges,
    setMorningRanges,
    setAfternoonRanges,
    setEveningRanges,
  } = useContext(HelpersContext);

  const classes = useStyles();

  return (
    <section component="ul" className={classes.root}>
      {/* Morning */}
      <SelectedTimeRangesByPOD
        ranges={morningRanges}
        setRanges={setMorningRanges}
      />

      {/* afternoon */}
      <SelectedTimeRangesByPOD
        ranges={afternoonRanges}
        setRanges={setAfternoonRanges}
      />

      {/* Evening */}
      <SelectedTimeRangesByPOD
        ranges={eveningRanges}
        setRanges={setEveningRanges}
      />
    </section>
  );
}

function SelectedTimeRangesByPOD(props) {
  const classes = useStyles();
  const ranges = props.ranges;

  const handleDelete = (data) => () => {
    props.setRanges(ranges.filter((item) => item !== data));
  };

  return (
    <>
      {ranges.map((data, index) => {
        return (
          <li key={index}>
            <Chip
              label={data}
              onDelete={handleDelete(data)}
              className={classes.chip}
            />
          </li>
        );
      })}
    </>
  );
}
