import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { OrderContext } from "../../../../ContextAPI/OrderContext";
import moment from "moment";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import NumberFormaterPrefix from "../../../helpers/NumberFormaterPrefix";
import { getFilteredTotal, tableOptions } from "../../../helpers/OrderHelpers";
import NextAndPrevButtons from "../../../helpers/Table/NextAndPrevButtons";
import TotalFigures from "../../../helpers/Table/TotalFigures";
import StartAndEndDates from "../../../helpers/Table/StartAndEndDates";
import TableContent from "../../../helpers/Table/TableContent";

export default function InstantOrderTable() {
  // context
  const { allOrders, orderLoading, filterOrders, executeOnce } =
    useContext(OrderContext);
  const {
    filteredResult,
    setFilteredResult,
    filteredTotalPrice,
    setFilteredTotalPrice,
  } = useContext(HelpersContext);

  let total = 0;

  // table headings
  const columns = [
    "S/N",
    "DATE",
    "TIME",
    "ORDER ID",
    "ORDER STATUS",
    "CUSTOMER NAME",
    "CUSTOMER NUMBER",
    "NO. OF ITEMS",
    "AMOUNT",
    "Payment Method",
    "DELIVERY METHOD",
    "Payment Status",
    "CHANNEL",
    "ORDER NAME",
  ];

  // extract data for the table rows
  const extractedOrders = allOrders.map((order, index) => {
    const menuItemOrders = order.menuitemorders;
    const menuItemOrderDetails = menuItemOrders.MenuItemOrderDetails;
    const orderBy = order.orderBy;

    // get total amount
    total = total + parseFloat(menuItemOrders.total);

    return [
      index + 1,
      moment(order.createdAt).format("L"),
      moment(order.createdAt).format("HH:mm:ss"),
      order.orderRef,
      menuItemOrders.status,
      orderBy.fullName,
      orderBy.phoneNumber,
      menuItemOrderDetails.length,
      <NumberFormaterPrefix value={menuItemOrders.total} />,
      menuItemOrders.paymentMethod,
      order.menuitemorders.deliveryOption,
      order.paymentStatus,
      order.orderChannel,
      order.orderName,
    ];
  });

  // get total of filtered result
  tableOptions.onFilterChange = (
    changedColumn,
    filterList,
    type,
    changedColumnIndex,
    displayData
  ) => {
    setFilteredResult(displayData);
    getFilteredTotal(displayData, setFilteredTotalPrice, 8);
  };

  return (
    <main className="main_table_frame">
      {/* filter */}
      <Grid container className="top_filter" id="pos_filter">
        {/* total figures */}
        <TotalFigures
          filteredResult={filteredResult}
          orders={allOrders}
          filteredTotalPrice={filteredTotalPrice}
          total={total}
        />

        {/* filter options */}
        <StartAndEndDates
          filterFunction={filterOrders}
          executeOnce={executeOnce}
        />

        {/* pagination */}
        <NextAndPrevButtons />
      </Grid>

      {/* set table */}
      <TableContent
        loading={orderLoading}
        receivedData={allOrders}
        heading="Instant Order"
        tableData={extractedOrders}
        columns={columns}
        tableOptions={tableOptions}
      />
    </main>
  );
}
