import React, { useState } from "react";
import moment from "moment";
import { Grid } from "@material-ui/core/";
import { getAllDates } from "../../helpers/MenuPlanHelper";
import MenuPlanEachDay from "./MenuPlanEachDay";
import MyMenuPlanCalendar from "./MyMenuPlanCalendar";

export default function CreateMenuPlanDetailsForm(props) {
  const data = props.dateRange;

  const allDays = getAllDates(moment, data.dateRange);

  const [allDetails, setAllDetails] = useState([]);

  return (
    <>
      <main id="create_menu_plan">
        {/* navigation */}
        <Grid container id="create_menu_plan_navigation">
          {/* view calendar */}
          <Grid item xs={12} sm={12} md={12} lg={12} align="right">
            <MyMenuPlanCalendar />
          </Grid>
        </Grid>

        {/* list of days with the meals */}
        <div>
          {/* name of plan */}
          <h1>{data.planName}</h1>

          {allDays.map((day) => (
            <MenuPlanEachDay
              day={day}
              planData={data}
              allDetails={allDetails}
              setAllDetails={setAllDetails}
            />
          ))}
        </div>
      </main>
    </>
  );
}
