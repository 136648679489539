import React from "react";
import Avatar from "@material-ui/core/Avatar";

export default function POSContact(props) {
  const tableDetails = props.tableDetails;
  const customer = props.customer;

  return (
    <>
      {tableDetails ? (
        <main id="pos_contact">
          {/* image */}
          <section id="contact_img">
            <Avatar alt={tableDetails.tableName} src="" />
          </section>

          {/* Details */}
          <section id="contact_text">
            <p>{tableDetails.tableName}</p>

            <p>{tableDetails.tableSide}</p>
          </section>
        </main>
      ) : (
        <main id="pos_contact">
          {/* image */}
          <section id="contact_img">
            <Avatar alt="" src="" />
          </section>

          {/* Details */}
          <section id="contact_text">
            <p>{customer.fullName}</p>
            
            <p>{customer.phoneNumber}</p>
          </section>
        </main>
      )}
    </>
  );
}
