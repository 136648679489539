import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { GrNext, GrPrevious } from "react-icons/gr";
import DayPopup from "./DayPopup";
import moment from "moment";
import { HelpersContext } from "../../../../../../ContextAPI/HelpersContext";

export default function Months({ month, availableDays, menuItem }) {
  const { page, setPage, monthsInItem } = useContext(HelpersContext);

  return (
    <>
    {/* month name, months counter, previous and next buttons */}
      <header>
        <h3>{month}</h3>

        <p>
          {/* move to previous month */}
          <span
            onClick={() => {
              page > 1 ? setPage(page - 1) : setPage(monthsInItem.length);
            }}
          >
            <GrPrevious />
          </span>

          {/* current page number/total months available */}
          {page}/{monthsInItem.length}

          {/* move to next month */}
          <span
            onClick={() =>
              page < monthsInItem.length ? setPage(page + 1) : setPage(1)
            }
          >
            <GrNext />
          </span>
        </p>
      </header>

      {/* calender showing available days */}
      <Grid container id="days">
        {availableDays && availableDays.length
          ? availableDays.map((day) =>
              moment(day).format("MMMM YYYY") === month ? (
                <DayPopup day={day} menuItem={menuItem} />
              ) : (
                ""
              )
            )
          : "Not Available"}
      </Grid>
    </>
  );
}
