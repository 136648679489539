// external imports
import React, { useContext, useState } from "react";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core/";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";
import { getDaysCount } from "../../../../helpers/MenuPlanHelper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});

export default function Calender(props) {
  // context
  const { dateRange, setDateRange, MPBasicInfo } = useContext(HelpersContext);

  // states
  const [open, setOpen] = useState(false);
  const [dateSelected, setDateSelected] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [error, setError] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const selectedDateRange =
    moment(dateRange[0].startDate).format("L") +
    " - " +
    moment(dateRange[0].endDate).format("L");

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateSelection = () => {
    // get the amount of days selected
    const rangeDiff = getDaysCount(dateRange);
    const parentStartDate = MPBasicInfo.menuPlan.startDate.split("T")[0];
    const parentEndDate = MPBasicInfo.menuPlan.endDate.split("T")[0];

    // check if the date selected are within the parent date
    const isBeforeStart = moment(
      moment(dateRange[0].startDate).format("YYYY-MM-DD")
    ).isBefore(parentStartDate);

    const isAfterEnd = moment(
      moment(dateRange[0].endDate).format("YYYY-MM-DD")
    ).isAfter(parentEndDate);

    if (rangeDiff > 14) {
      return setError("You cannot select more than 14 days");
    } else if (isBeforeStart) {
      setError(
        `You Cannot Pick A Day Before the Parent Start Date ${parentStartDate}`
      );
    } else if (isAfterEnd) {
      setError(
        `You Cannot Pick A Day After the Parent End Date ${parentEndDate}`
      );
    } else {
      setOpen(false);
      setDateSelected(true);
      setError("");
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <section id="select_MT">
      <Button onClick={handleClickOpen()}>
        {dateSelected ? selectedDateRange : "Select Date Range"}
      </Button>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"md"}
        scroll={scroll}
        disableBackdropClick={false}
        TransitionComponent={Transition}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <div id="select_MT_header">
            <aside>{selectedDateRange}</aside>

            <aside>{error}</aside>

            <aside>
              <Button onClick={() => handleClose()}>Close</Button>
            </aside>
          </div>
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <CalenderContent
              dateRange={dateRange}
              setDateRange={setDateRange}
              handleDateSelection={handleDateSelection}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </section>
  );
}

function CalenderContent(props) {
  const dateRange = props.dateRange;
  const setDateRange = props.setDateRange;

  return (
    <Grid container id="select_mt_calender">
      {/* calendar */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        align="center"
        id="menu_plan_calender"
      >
        <h4>Pick a Day or Range of Days</h4>
        <DateRangePicker
          onChange={(item) => setDateRange([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={dateRange}
          direction="horizontal"
        />

        <section className="form-btn">
          <Button onClick={() => props.handleDateSelection()}>Done</Button>
        </section>
      </Grid>
    </Grid>
  );
}
