import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import SettingsFrame from "../../../components/adminComponents/Settings/SettingsFrame";

export default function RecipeTable({
  table,
  title,
  pageHeading,
  table2,
  title2,
}) {
  return (
    <main className="table_page_frame">
      <DashboardFrame
        pageHeading={pageHeading}
        dashboardContent={
          <SettingsFrame
            firstLabel={title}
            firstContent={table}
            secondLabel={title2}
            secondContent={table2}
          />
        }
      />
    </main>
  );
}
