import React, { useContext, useState } from "react";
import { TextField, Grid, Badge } from "@material-ui/core/";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Dialog, DialogTitle } from "@material-ui/core/";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { OrderContext } from "../../../ContextAPI/OrderContext";
import LoadingFeedback from "../../helpers/LoadingFeedback";
import moment from "moment";
import EachRequestItem from "./EachRequestItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { searchOrders } from "../../helpers/POSOrderHelper";
import { toggleShowItems } from "../../helpers/MenuItemHelper";
import { IoCloseSharp } from "react-icons/io5";

export default function SeePendingRequests(props) {
  // context
  const { orderLoading, staffOrders } = useContext(OrderContext);

  // state
  const [orderSearchResult, setOrderSearchResult] = useState(null);
  const [search, setSearch] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div id="see_pending_requests">
      <Badge
        onClick={handleClickOpen()}
        color="secondary"
        badgeContent={staffOrders.length}
        showZero
      >
        View Orders
      </Badge>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        fullWidth={true}
        fullScreen={fullScreen}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {/* search form */}
          <Grid container>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <TextField
                variant="outlined"
                placeholder="Search"
                name="search"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyUp={(e) =>
                  searchOrders(
                    e.target.value,
                    staffOrders,
                    setOrderSearchResult
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              onClick={() => handleClose()}
              className="pending_order_close"
            >
              <IoCloseSharp />
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {orderSearchResult && orderSearchResult.length > 0 ? (
              orderSearchResult.map((order) => <EachRequest order={order} />)
            ) : orderLoading === "processing" ? (
              <LoadingFeedback message="Searching For orders..." />
            ) : orderLoading === "found" && staffOrders.length > 0 ? (
              staffOrders.map((order) => <EachRequest order={order} />)
            ) : orderLoading === "found" && staffOrders.length <= 0 ? (
              <LoadingFeedback message="Search Completed But No order Found" />
            ) : orderLoading === "failed" ? (
              <LoadingFeedback message="An Error Occured. Check your Connection" />
            ) : (
              ""
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function EachRequest(props) {
  const order = props.order;
  const menuItemOrderDetails = order.menuitemorders.MenuItemOrderDetails;
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <main
        className="pending_request_body"
        key={order.id}
        title="Click to see full details"
        onClick={() => {
          toggleShowItems(showMore, setShowMore);
        }}
      >
        {/* row 1 */}
        <section>
          <p>{order.orderName}</p>
          <p>
            <b>{moment(order.createdAt).format("L")}</b>
          </p>
        </section>

        {/* row 2 */}
        <section>
          <p id="phone">{order.orderRef}</p>
          <p>{moment(order.createdAt).format("LTS")}</p>
        </section>

        {/* row 3 */}
        <section>
          <p>{menuItemOrderDetails.length} items</p>
          <p>{order.menuitemorders.status}</p>
        </section>

        {/* row 4 */}
        <section>
          <p></p>
          <h3 id="phone">
            <b>{order.menuitemorders.total} NGN</b>
          </h3>
          <p></p>
        </section>
      </main>

      {showMore ? (
        <div>
          {menuItemOrderDetails &&
            menuItemOrderDetails.length > 0 &&
            menuItemOrderDetails.map((item) => (
              <EachRequestItem cartItem={item} />
            ))}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
