import React from "react";
import { Grid } from "@material-ui/core/";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function MenuPlanAddMealSearchOption(props) {
  const classes = useStyles();
  const menuItem = props.menuItem;

  return (
    <Grid container id="single_search_option">
      {/* image */}
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
        lg={4}
        className={classes.root}
        id="search_option_img"
      >
        <Avatar
          alt={menuItem.itemName}
          src={menuItem.imageUrl}
          className={classes.large}
        />
      </Grid>

      {/* Details */}
      <Grid item xs={8} sm={8} md={8} lg={8} id="search_option_text">
        <p>{menuItem.itemName}</p>

        <p>{menuItem.amount} NGN</p>
      </Grid>
    </Grid>
  );
}
