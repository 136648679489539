import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import SelectMP from "../../../components/adminComponents/CustomerMP/Create/SelectMP";

export default function Create() {
  return (
    <>
      <DashboardFrame
        pageHeading="Customers Meal Plan Scheduling"
        dashboardContent={<SelectMP />}
      />
    </>
  );
}
