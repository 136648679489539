import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";

export default function EditMenu(props) {
  // context
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const mealPlan = props.mealPlan;
  const { setMPBasicInfo } = useContext(HelpersContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMPBasicInfo({ menuPlan: mealPlan, planName: mealPlan.name });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <span className="emoji_style">&#9997;</span>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push("/edit-meal-plan", mealPlan)}>
          Edit Details
        </MenuItem>

        <MenuItem
          onClick={() =>
            history.push("/retrieving-meal-plan-details", mealPlan)
          }
        >
          View and Add Meals
        </MenuItem>
      </Menu>
    </div>
  );
}
