import React from "react";
import { useContext } from "react";
import { CreateAndEarnContext } from "../../../../ContextAPI/CreateAndEarn";
import SubmitButton from "../../CustomerMP/Create/itemPopup/SubmitButton";

export default function Status({ status, id }) {
  const { updateCreateAndEarnRecipe } = useContext(CreateAndEarnContext);

  return (
    <>
      {" "}
      <SubmitButton
        label={
          status === "PENDING"
            ? "Review"
            : status === "IN_REVIEW"
            ? "Approve"
            : ""
        }
        altClass={"alt_class"}
        submitFunction={() =>
          updateCreateAndEarnRecipe(
            id,
            status === "PENDING"
              ? "IN_REVIEW"
              : status === "IN_REVIEW"
              ? "APPROVED"
              : ""
          )
        }
      />
    </>
  );
}
