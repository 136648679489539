import React from "react";
import SpaceInBetweenLayout from "../../../helpers/SpaceInBetweenLayout";
import { Grid } from "@material-ui/core";
import { getNames } from "../../../helpers/POSOrderHelper";
import { getMPPreferences } from "../../../helpers/MPOrderHelpers";

export default function EachCartItemDetail(props) {
  const channel = props.channel;
  const menuItem = props.menuItem;
  const cart = menuItem.Cart;
  const originalItem = menuItem.Cart.MenuItem;
  const menuItemInventories = originalItem.MenuitemInventories;
  const preferences = JSON.parse(menuItem.Cart.preferences);

  let addons = [];

  if (channel === "POS") {
    addons = JSON.parse(menuItem.Cart.addons);
  } else {
    addons = JSON.parse(JSON.parse(menuItem.Cart.addons));
  }

  return (
    <>
      <Grid container className="cart_item_details" key={cart.id}>
        {/* menu item name */}
        <Grid item xs={12} sm={12} md={12} lg={12} id="cart_item_heading">
          <>
            <h4>({cart.quantity}x)</h4> <h4>{originalItem.itemName}</h4>
          </>
        </Grid>

        {/* inventory */}
        <SpaceInBetweenLayout
          mainClass="dashed_bottom"
          idA="text_bold"
          idB="text_bold"
          textA="Item"
          textB="Unit"
        />

        {/* main items */}
        {menuItemInventories &&
          menuItemInventories.length &&
          menuItemInventories.map((item) => (
            <SpaceInBetweenLayout
              textA={item.Inventory.itemName.toUpperCase()}
              textB={parseFloat(item.quantity)}
            />
          ))}

        {/* addons */}
        {addons && addons.length
          ? addons.map((addon) => (
              <SpaceInBetweenLayout
                textA={
                  addon.isExtra
                    ? "Extra " + addon.name.toUpperCase()
                    : addon.name.toUpperCase()
                }
                textB={
                  parseFloat(addon.originalQuantity) *
                  parseFloat(addon.quantity)
                }
              />
            ))
          : ""}

        <Grid container id="preference_instructions">
          {/* Preference */}
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <p>
              <b>Preference:</b>
            </p>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <p>
              {preferences && channel === "POS"
                ? getNames(preferences).join(", ")
                : preferences && channel === "MOBILE"
                ? getMPPreferences(preferences).join(", ")
                : ""}
            </p>
          </Grid>

          {/* Special Instructions: */}
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <p>
              <b>Special Instructions:</b>
            </p>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <p>{cart.specialInstruction}</p>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
