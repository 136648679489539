import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import ManageProfile from "../../../components/adminComponents/Settings/Profile/ManageProfile";
import SettingsFrame from "../../../components/adminComponents/Settings/SettingsFrame";

export default function ProfileSettings() {
  return (
    <>
      <DashboardFrame
        pageHeading="Settings"
        dashboardContent={
          <SettingsFrame
            firstLabel="Profile"
            firstContent={<ManageProfile />}
          />
        }
      />
    </>
  );
}
