import moment from "moment";
import React, { useContext } from "react";
import { MdCancel } from "react-icons/md";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";
import { removeDateAndTimeRanges } from "../../HelperFunctions";
import RangesInDay from "./SelectedTime/RangesInDay";

export default function SelectedTime({ menuItem }) {
  const { itemsBeforePreview, setItemsBeforePreview } =
    useContext(HelpersContext);

  return (
    <div id="selected_time">
      <header>SELECTED TIME OF DELIVERY</header>

      {itemsBeforePreview && itemsBeforePreview.length ? (
        itemsBeforePreview.map((item) => (
          <main>
            <div id="selected_time_date_heading">
              <h3>
                {moment(item.selectedDays[0]).format("dddd, MMMM Do YYYY")}
              </h3>
              <h3
                onClick={() =>
                  removeDateAndTimeRanges(
                    item.selectedDays[0],
                    itemsBeforePreview,
                    setItemsBeforePreview
                  )
                }
              >
                <MdCancel />
              </h3>
            </div>

            <RangesInDay
              day={item.selectedDays[0]}
              timeRanges={item.selectedTimeRanges}
              menuItem={menuItem}
            />
          </main>
        ))
      ) : (
        <p>Selected Time Range Will Appear Here. &#128526;</p>
      )}
    </div>
  );
}
