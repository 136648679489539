import React, { useContext } from "react";
import DashboardFrame from "../../../../components/adminComponents/DashboardFrame";
import ViewAnIncomingOrderContent from "../../../../components/adminComponents/Order/IncomingOrder/ViewAnIncomingOrderContent";
import SettingsFrame from "../../../../components/adminComponents/Settings/SettingsFrame";
import FeedbackPopup from "../../../../components/helpers/FeedbackPopup";
import { OrderContext } from "../../../../ContextAPI/OrderContext";

export default function ViewAnIncomingOrder(props) {
  const { changing } = useContext(OrderContext);
  const orderDetails = props.location.state;

  return (
    <main className="">
      <DashboardFrame
        pageHeading="Order"
        dashboardContent={
          <SettingsFrame
            firstLabel="Incoming"
            firstContent={
              <ViewAnIncomingOrderContent orderDetails={orderDetails} />
            }
          />
        }
      />

      {/* feedback popup */}
      <FeedbackPopup
        condition={changing}
        additionalMessage="Refresh To See Update..."
      />
    </main>
  );
}
