import React, { useContext, useEffect } from "react";
import { OrderContext } from "../../../../ContextAPI/OrderContext";
import LoadingFeedback from "../../../helpers/LoadingFeedback";
import ViewAnOrderFrame from "../ViewAnOrderFrame";

export default function ViewAnIncomingOrderContent(props) {
  const { getOrderById, order, orderByIdLoading } = useContext(OrderContext);
  const orderDetails = props.orderDetails;

  useEffect(() => {
    getOrderById(orderDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {orderByIdLoading === "processing" ? (
        <LoadingFeedback message="Searching..." />
      ) : orderByIdLoading === "found" && order.length <= 0 ? (
        <LoadingFeedback message="Searching..." />
      ) : orderByIdLoading === "failed" ? (
        <LoadingFeedback message="An Error Occured. Check your Connection" />
      ) : orderByIdLoading === "found" && order ? (
        <ViewAnOrderFrame
          firstBtn="Accept"
          order={order}
          orderDetails={orderDetails}
        />
      ) : (
        ""
      )}
    </>
  );
}
