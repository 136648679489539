import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { tableOptions } from "../../../helpers/OrderHelpers";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import { OrderContext } from "../../../../ContextAPI/OrderContext";
import CustomerSearch from "../../MenuPlanManagement/CustomerMPOrder/CustomerSearch";
import OrderActions from "../../Order/OrderActions";
import NextAndPrevButtons from "../../../helpers/Table/NextAndPrevButtons";
import TableContent from "../../../helpers/Table/TableContent";
import NumberFormaterPrefix from "../../../helpers/NumberFormaterPrefix";

export default function CustomerOrdersTable() {
  const { getCustomersMTOrders, loadingCustomerOrder, customerOrders } =
    useContext(OrderContext);
  const { customer } = useContext(HelpersContext);

  // splitting the customer string for use on the receipt
  const selectedCustomer = customer.split("(");

  // table headings
  const columns = [
    "S/N",
    "MORE ACTIONS",
    "AREA",
    "ORDER NAME",
    "ORDER ID",
    "ORDER STATUS",
    "NO. OF ITEMS",
    "DATE OF REQUEST",
    "TIME OF REQUEST",
    "DATE OF DELIVERY",
    "EXPECTED DELIVERY TIME",
    "Amount",
    "Payment Status",
    "Payment Method",
    "CHANNEL",
    "DELIVERY METHOD",
  ];

  // extract data for the table rows
  const extractedOrders = customerOrders.map((order, index) => {
    const menuItemOrders = order.menuitemorders;
    const deliveryArea = menuItemOrders.DeliveryAddress;
    const menuItemOrderDetails = menuItemOrders.MenuItemOrderDetails;

    return [
      index + 1,
      <OrderActions
        order={order}
        orderTable="Active"
        customer={{
          fullName: selectedCustomer[0],
          phoneNumber: selectedCustomer[1].split(")")[0],
        }}
      />,
      deliveryArea ? deliveryArea.address : "",
      order.orderName,
      order.orderRef,
      menuItemOrders.status,
      menuItemOrderDetails.length,
      moment(order.createdAt).format("L"),
      moment(order.createdAt).format("HH:mm:ss"),
      "",
      moment(menuItemOrders.deliveryTime).format("HH:mm:ss") !== "Invalid date"
        ? moment(menuItemOrders.deliveryTime).format("HH:mm:ss")
        : menuItemOrders.deliveryTime,
      <NumberFormaterPrefix value={menuItemOrders.total} />,
      order.paymentStatus,
      menuItemOrders.paymentMethod,
      order.orderChannel,
      order.menuitemorders.deliveryOption,
    ];
  });

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter">
        {/* today */}
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{customerOrders.length}</span>
          </p>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1} className="filter_text"></Grid>

        {/* search */}
        <CustomerSearch getOrders={getCustomersMTOrders} />

        {/* pagination */}
        <NextAndPrevButtons />
      </Grid>

      {/* set table */}
      <TableContent
        loading={loadingCustomerOrder}
        receivedData={customerOrders}
        heading={customer}
        tableData={extractedOrders}
        columns={columns}
        tableOptions={tableOptions}
      />
    </main>
  );
}
