import React, { useContext } from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { MPManagementContext } from "../../../../ContextAPI/MPManagementContext";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import {
  getFilteredTotal,
  getOrderTotal,
  tableOptions,
} from "../../../helpers/OrderHelpers";
import NumberFormaterPrefix from "../../../helpers/NumberFormaterPrefix";
import TotalFigures from "../../../helpers/Table/TotalFigures";
import NextAndPrevButtons from "../../../helpers/Table/NextAndPrevButtons";
import TableContent from "../../../helpers/Table/TableContent";
import StartAndEndDates from "../../../helpers/Table/StartAndEndDates";

export default function MenuPlanOrdersTable() {
  // context
  const { getMenuPlanByDate, executeOnce, MPOrders, MPOrdersLoading } =
    useContext(MPManagementContext);
  const {
    filteredResult,
    setFilteredResult,
    filteredTotalPrice,
    setFilteredTotalPrice,
  } = useContext(HelpersContext);

  console.log(MPOrders);

  let total = 0;

  // table headings
  const columns = [
    "S/N",
    "DATE",
    "TIME",
    "ORDER ID",
    "ORDER STATUS",
    "CUSTOMER NAME",
    "CUSTOMER NUMBER",
    "AMOUNT",
    "ITEM NAME",
    "Payment Method",
    "DELIVERY METHOD",
    "Payment Status",
    "Plan Name",
    "Customized name",
  ];

  // extract data for the table rows
  let extractedOrders;
  if (MPOrders.length > 0) {
    extractedOrders = MPOrders.map((eachOrder, index) => {
      const order = eachOrder.orderInfo;
      const customer = order.orderedBy;

      // get total amount
      total = total + parseFloat(order.total);

      return [
        index + 1,
        moment(order.createdAt).format("L"),
        moment(order.createdAt).format("HH:mm:ss"),
        order.orderRef,
        order.status,
        customer.fullName,
        customer.phoneNumber,
        <NumberFormaterPrefix value={getOrderTotal(order)} />,
        order.MenuPlanDetail[0].MenuItem.itemName,
        order.paymentMethod,
        order.deliveryOption,
        order.paymentStatus,
        order.MenuPlan.name,
        order.orderName,
      ];
    });
  }

  // get total of filtered result
  tableOptions.onFilterChange = (
    changedColumn,
    filterList,
    type,
    changedColumnIndex,
    displayData
  ) => {
    setFilteredResult(displayData);
    getFilteredTotal(displayData, setFilteredTotalPrice, 7);
  };

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter" id="pos_filter">
        <TotalFigures
          filteredResult={filteredResult}
          orders={MPOrders}
          filteredTotalPrice={filteredTotalPrice}
          total={total}
        />

        {/* filter options */}
        <StartAndEndDates
          filterFunction={getMenuPlanByDate}
          executeOnce={executeOnce}
        />

        {/* pagination */}
        <NextAndPrevButtons />
      </Grid>

      {/* set table */}
      <TableContent
        loading={MPOrdersLoading}
        receivedData={MPOrders}
        heading="Menu Plan Orders"
        tableData={extractedOrders}
        columns={columns}
        tableOptions={tableOptions}
      />
    </main>
  );
}
