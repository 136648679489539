import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { timeLimitConvertion } from "../../../helpers/MenuPlanHelper";
import { getDeliveryTimes } from "../HelperFunction";

const SelectedDT = ({ times, days }) => {
  return (
    <>
      <Grid container id="select_delivery_time">
        <Grid item xs={12} sm={12} md={12} lg={12} id="heading">
          <h2>SELECTED DAYS OF DELIVERY</h2>
        </Grid>

        {days && days.length
          ? days.map((day) => (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} id="">
                  <h2>{moment(day).format("ddd, MMMM Do YYYY")}</h2>
                </Grid>

                <Content day={day} deliverytimes={times} />
              </>
            ))
          : "No delivery details"}
      </Grid>
    </>
  );
};

function Content({ deliverytimes, day }) {
  const times = getDeliveryTimes(deliverytimes, day);

  return (
    <>
      {times && times.length ? (
        times.map((item) => (
          <Grid item xs={12} sm={4} md={3} lg={3} id="survey_delivery_time_day">
            <p>{timeLimitConvertion(item)}</p>
          </Grid>
        ))
      ) : (
        <p>No Delivery Time Found</p>
      )}
    </>
  );
}

export default SelectedDT;
