// external imports
import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";
import { HelpersContext } from "./HelpersContext";
import { getBasketitems } from "../components/adminComponents/CustomerMP/HelperFunctions";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};
let branchId = null;
let staffId = null;

if (staff) {
  branchId = staff.branchId;
  staffId = staff.id;
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
}

const BASE_URL = getBaseUrl();
const BASE_URL_2 = getBaseUrl(2);

// create Customer context to manage Customer here
export const CustomerContext = createContext();

export default function CustomerContextProvider(props) {
  const {
    customerMPPreview,
    setCustomerMPPreview,
    customerId,
    setSelectedTimeRanges,
    setSelectedMonths,
    setSelectedDays,
    setModifyDescription,
    setCustomerMPTotalPrice,
    setAgreed,
    setCustomerMPAddons,
    setCustomerMPPreferences,
    setAddonOrPreference,
    setItemsBeforePreview,
  } = useContext(HelpersContext);

  // set initial state
  const [customerLoading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [gettingCustomer, setGettingCustomer] = useState(false);
  const [executeOnce, setExecuteOnce] = useState(true);
  const [creatingCustomerMP, setCreatingCustomerMP] = useState(false);
  const [customerMPs, setCustomerMPs] = useState([]);
  const [customerMPLoading, setCustomerMPLoading] = useState(false);

  // clear data for previous item
  const clearData = () => {
    // return all data to default
    setCustomerMPAddons([]);
    setCustomerMPPreferences([]);
    setSelectedTimeRanges([]);
    setSelectedMonths([]);
    setSelectedDays([]);
    setModifyDescription("");
    setCustomerMPTotalPrice(0);
    setAgreed(false);
    setAddonOrPreference(null);
    setItemsBeforePreview([]);
  };

  // clear date and time data
  const clearDateAndTime = () => {
    setSelectedTimeRanges([]);
    setSelectedMonths([]);
    setSelectedDays([]);
  };

  // make API call to get all the Customer
  const searchCustomers = (data) => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}users/search?search_param=${data}`;

    // get Customers
    axios({ url, method, headers })
      .then((result) => {
        setCustomers(result.data.data);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  // get customers by date
  const filterCustomers = (startDate, endDate, page) => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}users/getCustomers?dateFrom=${startDate}&dateTo=${endDate}&page=${page}`;

    // get Orders
    axios({ url, method, headers })
      .then((result) => {
        setCustomers(result.data.data.data.items);
        setLoading("found");

        // stop another execution
        setExecuteOnce(false);
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");

        // stop another execution
        setExecuteOnce(false);
      });
  };

  // get a Customer
  const getCustomer = (id) => {
    setGettingCustomer("processing");

    const method = "get",
      url = `${BASE_URL}users?user_id=${id}`;

    // get a Customer
    axios({ url, method, headers })
      .then((result) => {
        setCustomer(result.data.data);
        setGettingCustomer("found");
      })
      .catch((error) => {
        error = new Error();
        setGettingCustomer("failed");
      });
  };

  // add item to customer MP
  const createCustomerMP = (closePopup) => {
    setCreatingCustomerMP("processing");

    const surveyId = localStorage.getItem("surveyId");

    // get config for creating Cart
    const method = "post",
      url = `${BASE_URL_2}orders/basket/`,
      data = {
        userId: customerId,
        baskets: getBasketitems(
          customerMPPreview,
          branchId,
          staffId,
          customerId,
          surveyId
        ),
      };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setCreatingCustomerMP("successful");
        setCustomerMPPreview([]);
        closePopup();
        localStorage.clear();

        setTimeout(() => {
          setCreatingCustomerMP(null);
        }, 1000);
      })
      .catch((error) => {
        error = new Error();
        setCreatingCustomerMP("failed");

        setTimeout(() => {
          setCreatingCustomerMP(null);
        }, 1000);
      });
  };

  // getMPCreatedForCustomer
  const getMPCreatedForCustomer = (userId) => {
    if (!userId || userId === null || userId === "") return;

    setCustomerMPLoading("processing");

    const method = "get",
      url = `${BASE_URL}orders/cart/menuplan/admin?page=1&branchId=${branchId}&userId=${userId}`;

    // get Orders
    axios({ url, method, headers })
      .then((result) => {
        setCustomerMPs(result.data.data.items);
        setCustomerMPLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setCustomerMPLoading("failed");
        setCustomerMPs([]);
      });
  };

  return (
    <CustomerContext.Provider
      value={{
        customerLoading,
        customers,
        searchCustomers,
        executeOnce,
        filterCustomers,
        customer,
        gettingCustomer,
        getCustomer,
        createCustomerMP,
        creatingCustomerMP,
        clearData,
        getMPCreatedForCustomer,
        customerMPs,
        customerMPLoading,
        clearDateAndTime,
      }}
    >
      {props.children}
    </CustomerContext.Provider>
  );
}
