import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import ViewInactiveMealPlans from "../../../components/adminComponents/MealPlanMGT/ViewMealPlans/InActivePlans";

export default function ViewInactiveMP() {
  return (
    <>
      <DashboardFrame
        pageHeading="Manage Inactive Meal Plans"
        dashboardContent={<ViewInactiveMealPlans />}
      />
    </>
  );
}
