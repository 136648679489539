import React, { useContext } from "react";
import { RecipeContext } from "../../../../ContextAPI/RecipeContext";
import { getTopingsDetails } from "../HelperFunctions";

export default function RecipeDetails({ isRecipeOrder }) {
  const { recipeDetails } = useContext(RecipeContext);
  const recipeReceived = isRecipeOrder
    ? recipeDetails.RecipeOrder.Recipe
    : recipeDetails.Recipe;

  return (
    <div id="recipe_details">
      <Detail
        id="recipe_details_name"
        title="Recipe title"
        body={recipeReceived.mealName}
      />
      <Detail title="Recipe description" body={recipeReceived.description} />
      <Detail
        title="Recipe toppings"
        body={getTopingsDetails(JSON.parse(recipeReceived.toppins))}
      />
      <Detail title="Key ingredients" body={recipeReceived.ingredients} />
      <Detail
        title="Special instructions"
        body={recipeReceived.specialInstructions}
      />
    </div>
  );
}

export function Detail({ title, body, id }) {
  return (
    <section id={id}>
      <p>{title}</p>
      <h3>{body}</h3>
    </section>
  );
}
