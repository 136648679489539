import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import EachDayBody from "./EachDayBody";
import moment from "moment";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";
import { getDailyMeals } from "../../../../helpers/MenuPlanHelper";
import EachDayHeader from "./EachDayHeader";

export default function EachDay(props) {
  // context
  const { savedMPItems } = useContext(HelpersContext);

  const dayDate = moment(props.day).format("(Do MMMM, YYYY)");

  const filtered = getDailyMeals(dayDate, savedMPItems);

  return (
    <Grid container id="each_day">
      {/* date */}
      <EachDayHeader day={props.day} />

      <Grid item xs={12} sm={12} md={12} lg={12} id="each_day_body">
        <EachDayBody dayMeals={filtered} />
      </Grid>
    </Grid>
  );
}
