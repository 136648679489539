import React from "react";
import SpaceInBetweenLayout from "../../../helpers/SpaceInBetweenLayout";
import { Grid } from "@material-ui/core";
import moment from "moment";

export default function PriceAndPayementDetails(props) {
  const order = props.order;
  const menuItemOrders = props.order.menuitemorders;

  return (
    <>
      <Grid container className="price_details">
        <SpaceInBetweenLayout
          textA="Subtotal"
          textB={"₦" + menuItemOrders.subTotal}
        />

        <SpaceInBetweenLayout textA="Discount" textB="₦0.00" />

        <Grid container className="total">
          <SpaceInBetweenLayout
            textA="Total"
            textB={"₦" + menuItemOrders.total}
          />
        </Grid>
      </Grid>

      {/* payment */}
      <Grid container className="payment_details">
        <SpaceInBetweenLayout
          textA="Payment status:"
          textB={order.paymentStatus}
        />

        <SpaceInBetweenLayout
          textA="Paid via:"
          textB={menuItemOrders.paymentMethod}
        />
      </Grid>

      <section className="other_details">
        <h3>No refund or return of item after purchase.</h3>

        <p>Printed On: {moment().format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
      </section>
    </>
  );
}
