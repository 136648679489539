// external imports
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
}

const BASE_URL = getBaseUrl();

// create InventoryCategory context to manage InventoryCategory here
export const InventoryCategoryContext = createContext();

export default function InventoryCategoryContextProvider(props) {
  // set initial state
  const [inventoryCategoryLoading, setLoading] = useState(false);
  const [inventoryCategories, setInventoryCategory] = useState([]);
  const [showCategories, setShowCategories] = useState(null);

  // make API call to get all the InventoryCategory
  useEffect(() => {
    setLoading(true);

    const method = "get",
      url = `${BASE_URL}inventorycategories`;

    // get InventoryCategory
    axios({ url, method, headers })
      .then((result) => {
        setInventoryCategory(result.data.data);
        setLoading(false);
      })
      .catch((error) => {
        error = new Error();
      });
  }, []);

  // addInventoryCategory
  const addInventoryCategory = (newCategory) => {
    setLoading("processing");

    // get config for API call
    const method = "post",
      url = `${BASE_URL}inventorycategories`,
      data = {
        name: newCategory.toLowerCase(),
        decription: "",
      };
    // make API call
    axios({ url, method, headers, data })
      .then((r) => {
        setLoading("successful");
        alert(
          newCategory.toUpperCase() + " was added successfully. Continue..."
        );
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
        alert(
          newCategory.toUpperCase() +
            " couldn't be added. It may already exist. It might also be a network issue"
        );
      });
  };

  return (
    <InventoryCategoryContext.Provider
      value={{
        inventoryCategoryLoading,
        inventoryCategories,
        addInventoryCategory,
        showCategories,
        setShowCategories,
      }}
    >
      {props.children}
    </InventoryCategoryContext.Provider>
  );
}
