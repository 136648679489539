import { Grid } from "@material-ui/core";
import React from "react";
import NumberFormaterPrefix from "../NumberFormaterPrefix";
import { getTotalWithoutFilter } from "../OrderHelpers";

export default function TotalFigures(props) {
  const filteredResult = props.filteredResult;
  const orders = props.orders;
  const filteredTotalPrice = props.filteredTotalPrice;

  return (
    <>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
        lg={4}
        className="choosen_range filter_forms"
      >
        <p>
          TOTAL NUMBER{" "}
          <span>{filteredResult ? filteredResult.length : orders.length}</span>
        </p>
        <p>
          TOTAL AMOUNT{" "}
          <span>
            {
              <NumberFormaterPrefix
                value={
                  filteredTotalPrice > 0
                    ? filteredTotalPrice
                    : getTotalWithoutFilter(orders)
                }
              />
            }
          </span>
        </p>
      </Grid>
    </>
  );
}
