import React, { useState } from "react";
import { Button, Grid, TextField, InputAdornment } from "@material-ui/core/";
import { AiOutlinePlus } from "react-icons/ai";

export default function CreateVoucherForm() {
  // initial states
  let [numberOfAddBenefitInput, setNumberOfAddBenefitInput] = useState(0);

  // add more space for more Add benefit Input
  const moreAddBenefitInputArray = [];

  for (let moreInput = 0; moreInput < numberOfAddBenefitInput; moreInput--) {

    if (moreAddBenefitInputArray.length < 4) {
      moreAddBenefitInputArray.push(
        <TextField variant="outlined" fullWidth label="0 of 18 words" />
      );
    }
    
  }

  return (
    <main id="create_voucher">
      <form className="form_frame">
        <Grid container>
          {/* Name */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h4>Name</h4>
            <TextField variant="outlined" fullWidth />
          </Grid>

          <h4>Add Benefits</h4>

          {/* Add Benefits */}
          <Grid item xs={12} sm={12} md={12} lg={12} id="add_benefits">
            <p>1 of 4 benefit(s) added</p>

            <TextField variant="outlined" fullWidth label="0 of 18 words" />

            {moreAddBenefitInputArray}

            <section id="add_btn">
              <Button
                onClick={() =>
                  setNumberOfAddBenefitInput(numberOfAddBenefitInput++)
                }
              >
                <span>
                  <AiOutlinePlus />
                </span>
                Add
              </Button>
            </section>
          </Grid>

          {/* Amount */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h4>Amount</h4>
            <TextField
              fullWidth
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">NGN</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {/* button */}
        <section className="form-btn">
          <Button>Create</Button>
        </section>
      </form>
    </main>
  );
}
