import React from "react";
import CreateStaffForm from "../../components/adminComponents/CreateStaff/CreateStaffForm";
import DashboardFrame from "../../components/adminComponents/DashboardFrame";

export default function CreateStaff() {
  return (
    <>
      <DashboardFrame pageHeading="Staff" dashboardContent={<CreateStaffForm />} />
    </>
  );
}
