import React, { useContext } from "react";
import RecipeTable from "../../../pages/admin/CustomerRecipe/RicepeTable";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { sortOrders, tableOptions } from "../../helpers/OrderHelpers";
import TableContent from "../../helpers/Table/TableContent";
import { RecipeContext } from "../../../ContextAPI/RecipeContext";
import Actions from "../CustomerRecipe/ActionsMenu/Actions";
import Menu from "../CustomerRecipe/ActionsMenu/Menu";
import NumberFormaterPrefix from "../../helpers/NumberFormaterPrefix";

function Active() {
  // context
  const { recipeOrders, recipeLoading } = useContext(RecipeContext);

  // separate order according to status
  let acceptedOrders = sortOrders(recipeOrders, "Accepted", "RECIPE");
  let processingOrders = sortOrders(recipeOrders, "Processing", "RECIPE");
  let readyOrders = sortOrders(recipeOrders, "Ready", "RECIPE");

  const orders = [
    ...acceptedOrders,
    ...processingOrders,
    ...readyOrders,
  ];

  // table headings
  const columns = [
    "",
    "S/N",
    "ADDRESS",
    "CUSTOMER NAME",
    "CUSTOMER’S NUMBER",
    "ITEM  TITLE",
    "ORDER ID",
    "QUANTITY",
    "ORDER STATUS",
    "DATE OF REQUEST",
    "TIME OF REQUEST",
    "DATE OF DELIVERY",
    "TIME OF DELIVERY",
    "AMOUNT",
    "PAYMENT METHOD",
    "PAYMENT STATUS",
    "PAYMENT GATEWAY",
    "COLLECTION METHOD",
  ];

  // extract data for the table rows
  const extractedOrders = orders.map((order, index) => {
    const recipeOrder = order.RecipeOrder;
    const customer = recipeOrder.orderedByDetail;
    const payment = recipeOrder.Payments[0];
    const dateCreated = order.createdAt;

    return [
      <Actions
        viewDetails={
          <Menu
            link={"/view-recipe-order"}
            details={order}
            tableType="COMPLETED"
          />
        }
        showStatusBtn={true}
        showKitchen={true}
        order={order}
      />,
      index + 1,
      recipeOrder.deliveryAddress,
      customer.fullName,
      customer.phoneNumber,
      order.RecipeOrder.Recipe.mealName,
      order.id,
      order.quantity,
      order.status,
      moment(dateCreated).format("L"),
      moment(dateCreated).format("HH:mm:ss"),
      moment(order.deliveryDate).format("L"),
      order.deliveryTime,
      <NumberFormaterPrefix value={recipeOrder.amount} />,
      payment ? payment.paymentMethod : "",
      payment ? payment.status : "",
      payment ? payment.gateWayType : "",
      recipeOrder.deliveryOption,
    ];
  });

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter" id="pos_filter">
        {/* today */}
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{orders.length}</span>
          </p>
        </Grid>
      </Grid>

      {/* set table */}
      <TableContent
        loading={recipeLoading}
        receivedData={orders}
        heading="Active Order"
        tableData={extractedOrders}
        columns={columns}
        tableOptions={tableOptions}
      />
    </main>
  );
}

const ActiveRecipeOrder = () => {
  return (
    <>
      <RecipeTable
        table={<Active />}
        title="Active recipe orders"
        pageHeading="Recipe Orders"
      />
    </>
  );
};

export default ActiveRecipeOrder;
