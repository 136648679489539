import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import SpaceInBetweenLayout from "../../../helpers/SpaceInBetweenLayout";

export default function BasicDetails({ order }) {
  const recipeOrder = order.RecipeOrder;
  const customer = recipeOrder.orderedByDetail;

  return (
    <>
      <Grid container className="order_details_top dashed_bottom">
        {/* basic details */}
        <SpaceInBetweenLayout
          textA="Recipent’s name:"
          textB={
            recipeOrder.friendName ? recipeOrder.friendName : customer.fullName
          }
        />

        <SpaceInBetweenLayout
          textA="Recipent’s number:"
          textB={
            recipeOrder.friendPhoneNumber
              ? recipeOrder.friendPhoneNumber
              : customer.phoneNumber
          }
        />

        <SpaceInBetweenLayout textA="Order ID:" textB={order.id} />

        <SpaceInBetweenLayout
          textA="Date of order:"
          textB={moment(order.createdAt).format("Do MMM, YYYY")}
        />

        <SpaceInBetweenLayout
          textA="Time of order:"
          textB={moment(order.createdAt).format("LTS")}
        />

        <SpaceInBetweenLayout textA="Order status:" textB={order.status} />

        <SpaceInBetweenLayout
          textA="Payment status:"
          textB={
            recipeOrder.Payments.length ? recipeOrder.Payments[0].status : ""
          }
        />
      </Grid>
    </>
  );
}
