import React, { useContext } from "react";
import { Button, Grid } from "@material-ui/core/";
import MenuPlanAddMeal from "./MenuPlanAddMeal";
import { MenuPlanContext } from "../../../ContextAPI/MenuPlanContext";

export default function MenuPlanEachTimeOfDay(props) {
  const { menuPlanLoading } = useContext(MenuPlanContext);
  const periodOfDay = props.periodOfDay;
  const row = props.row;
  const day = props.day;
  const allDetails = props.allDetails;
  let itemName;
  let itemPrice;
  let deliveryTimes;

  // this will be used to identify the added meal
  const dayId = periodOfDay + row + day;

  // get the meal for this period of the day
  for (const meal of allDetails) {
    if (dayId === meal.id) {
      itemName = meal.itemName;
      itemPrice = meal.itemPrice;
      deliveryTimes = meal.data.deliveryTimes;
    }
  }

  return (
    <>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        {/* hide add meal popup if a meal item has been added for that period of the day */}
        <section className="add_meal_btn_container">
          <MenuPlanAddMeal
            periodOfDay={periodOfDay}
            row={row}
            day={day}
            planData={props.planData}
            submissionDate={props.submissionDate}
            allDetails={allDetails}
            setAllDetails={props.setAllDetails}
          />
        </section>

        {/* show meal that has been added with feedback */}
        <section className="selected_meal" title={deliveryTimes}>
          {menuPlanLoading === "processing" ? (
            <p>
              <i>
                <b>Processing...</b>
              </i>
            </p>
          ) : menuPlanLoading === "successful" ? (
            <>
              <p align="left">{itemName}</p>

              <Button>{itemPrice} NGN</Button>
            </>
          ) : (
            ""
          )}
        </section>
      </Grid>
    </>
  );
}
