import React, { useContext } from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { MPManagementContext } from "../../../ContextAPI/MPManagementContext";
import MPOrderActions from "./MPOrderActions";
import { tableOptions } from "../../helpers/OrderHelpers";
import StartAndEndDates from "../../helpers/Table/StartAndEndDates";
import TableContent from "../../helpers/Table/TableContent";
import NextAndPrevButtons from "../../helpers/Table/NextAndPrevButtons";
import NumberFormaterPrefix from "../../helpers/NumberFormaterPrefix";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import TotalFigure from "./TotalFigure";

export default function MPOrdersMGT({ orders, group }) {
  // context
  const { getMenuPlanByDate, executeOnce, MPOrdersLoading } =
    useContext(MPManagementContext);
  const { filteredResult, setFilteredResult } = useContext(HelpersContext);

  const columns = [
    "S/N",
    "MORE ACTIONS",
    "ADDRESS",
    "CUSTOMER NAME",
    "CUSTOMER NUMBER",
    "CUSTOMIZED NAME",
    "Plan name",
    "ITEM NAME",
    "QUANTITY",
    "ORDER ID",
    "DATE OF REQUEST",
    "Time of Request",
    "DATE OF DELIVERY",
    "TIME OF DELIVERY",
    "Amount",
    "Payment Status",
    "CUSTOMER EMAIL",
    "COLLECTION METHOD",
    "ORDER STATUS",
    "Created by",
  ];

  // extract data for the table rows
  let extractedOrders;
  if (orders.length > 0) {
    extractedOrders = orders.map((order, index) => {
      const deliveryTime = order.deliveryTime;

      return [
        index + 1,
        <MPOrderActions order={order} />,
        order.deliveryAddress,
        order.fullName,
        order.phoneNumber,
        order.orderName,
        order.name,
        order.itemName,
        deliveryTime.quantity,
        order.orderRef,
        moment(order.createdAt).format("L"),
        moment(order.createdAt).format("HH:mm:ss"),
        moment(order.deliveryDate).format("L"),
        deliveryTime.time,
        <NumberFormaterPrefix
          value={parseFloat(deliveryTime.quantity) * parseFloat(order.amount)}
        />,
        order.paymentStatus,
        order.email,
        order.deliveryOption,
        deliveryTime.status,
        order.addedByAdmin ? "Admin" : "User",
      ];
    });
  }

  // get total of filtered result
  tableOptions.onFilterChange = (
    changedColumn,
    filterList,
    type,
    changedColumnIndex,
    displayData
  ) => {
    setFilteredResult(displayData);
  };

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter" id="pos_filter">
        {/* today */}
        <TotalFigure filteredResult={filteredResult} orders={orders} />

        <Grid item xs={2} sm={2} md={2} lg={2} className="filter_text">
          <p>Filter by:</p>
        </Grid>

        {/* filter options */}
        <StartAndEndDates
          filterFunction={getMenuPlanByDate}
          executeOnce={executeOnce}
        />

        {/* pagination */}
        <NextAndPrevButtons />
      </Grid>

      {/* set table */}
      <TableContent
        loading={MPOrdersLoading}
        receivedData={orders}
        heading={`${group} Menu Plan Orders`}
        tableData={extractedOrders}
        columns={columns}
        tableOptions={tableOptions}
      />
    </main>
  );
}
