import React from "react";
import { Grid } from "@material-ui/core";
import HalfRowLayout from "../../../helpers/HalfRowLayout";
import NumberFormaterSuffix from "../../../helpers/NumberFormaterSuffix";

export default function IncomingOrderPaymentAndDelivery(props) {
  const order = props.order;
  const area = props.area;
  const menuItems = order.menuitemorders;
  const NGN = "NGN";

  return (
    <>
      <Grid container id="payment_and_delivery">
        <Grid item xs={5} sm={5} md={5} lg={5} id="payment_details">
          <Grid container>
            <HalfRowLayout className="text_key" text="Total Amount:" />
            <HalfRowLayout
              text={<NumberFormaterSuffix value={menuItems.total} />}
            />

            <HalfRowLayout className="text_key" text="Sub-Total:" />
            <HalfRowLayout
              text={<NumberFormaterSuffix value={menuItems.subTotal} />}
            />

            <HalfRowLayout className="text_key" text="Delivery fee:" />
            <HalfRowLayout text={area ? area.amount + " " + NGN : ""} />

            <HalfRowLayout className="text_key" text="Payment status:" />
            <HalfRowLayout text={order.paymentStatus} />

            <HalfRowLayout className="text_key" text="Payment method:" />
            <HalfRowLayout text={menuItems.paymentMethod} />
          </Grid>
        </Grid>

        <div className="vertical_rule"></div>

        <Grid item xs={5} sm={5} md={5} lg={5} id="delivery_details">
          <Grid container>
            <HalfRowLayout className="text_key" text="Delivery method:" />
            <HalfRowLayout text={menuItems.deliveryOption} />

            <HalfRowLayout className="text_key" text="Area:" />
            <HalfRowLayout text={area ? area.address : ""} />

            <HalfRowLayout className="text_key" text="Address:" />
            {}
            <HalfRowLayout text={menuItems.deliveryAddress} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
