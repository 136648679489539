import React, { useContext, useEffect, useState } from "react";
import { Grid, Hidden } from "@material-ui/core/";
import SeePendingRequests from "./SeePendingRequests";
import AddToCart from "./AddToCart";
import MenuItemPopup from "./MenuItemPopup";
import { MenuItemContext } from "../../../ContextAPI/MenuItemContext";
import LoadingFeedback from "../../helpers/LoadingFeedback";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import POSHeader from "./POSHeader";

export default function POSHome() {
  // context
  const { menuItemLoading, menuItems, getMenuItems } = useContext(MenuItemContext);
  const { searchResult, search, page } = useContext(HelpersContext);

  // states
  const [cartItems, setCartItems] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [tableDetails, setTableDetails] = useState(null);
  const [showCart, setShowCart] = useState(false);

  // effect
  useEffect(() => {
    getMenuItems(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div id="pos_order">
      <Grid container>
        <Hidden smDown={showCart ? true : false}>
          <Grid
            item
            xs={showCart ? 4 : 12}
            sm={showCart ? 4 : 12}
            md={showCart ? 8 : 12}
            lg={showCart ? 8 : 12}
          >
            <POSHeader
              showCart={showCart}
              cartItems={cartItems}
              setShowCart={setShowCart}
              menuItems={menuItems}
            />

            <main>
              {/* popup to show all pending requests */}
              <SeePendingRequests />

              {/* headings */}
              <section>
                <h1>
                  Select From The Meal Items (
                  {menuItems && menuItems.length ? menuItems.length : ""}){" "}
                </h1>

                {searchResult && searchResult.length > 0 ? (
                  <p>
                    <i>Search Result({searchResult.length})</i>
                  </p>
                ) : (
                  ""
                )}
              </section>

              {/* view all menu items here */}
              <Grid container id="pos_menu_items">
                {/* display menu items here */}
                {searchResult && searchResult.length > 0 ? (
                  searchResult.map((result) => (
                    <MenuItemPopup
                      menuItem={result}
                      cartItems={cartItems}
                      setCartItems={setCartItems}
                      customer={customer}
                      showCart={showCart}
                    />
                  ))
                ) : search && searchResult && !searchResult.length ? (
                  "No Result Found!"
                ) : menuItemLoading === "processing" ? (
                  <LoadingFeedback message="Searching For Menu Items..." />
                ) : menuItemLoading === "found" && menuItems.length > 0 ? (
                  menuItems.map((result) => (
                    <MenuItemPopup
                      menuItem={result}
                      cartItems={cartItems}
                      setCartItems={setCartItems}
                      customer={customer}
                      showCart={showCart}
                    />
                  ))
                ) : menuItemLoading === "found" && menuItems.length <= 0 ? (
                  <LoadingFeedback message="Search Completed But No Menu Item Found" />
                ) : menuItemLoading === "failed" ? (
                  <LoadingFeedback message="An Error Occured. Check your Connection" />
                ) : (
                  ""
                )}
              </Grid>
            </main>
          </Grid>
        </Hidden>

        {showCart ? (
          <Grid item xs={12} sm={12} md={4} lg={4} id="add_to_cart_container">
            <AddToCart
              cartItems={cartItems}
              setCartItems={setCartItems}
              customer={customer}
              setCustomer={setCustomer}
              tableDetails={tableDetails}
              setTableDetails={setTableDetails}
              showCart={showCart}
              setShowCart={setShowCart}
            />
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
}
