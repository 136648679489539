import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import { NotificationContext } from "../../../ContextAPI/NotificationContext";
import Notification from "../../../pages/admin/Notification/Notification";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import Body from "./Body";
import CustomerSearchCheckbox from "./CustomerSearchCheckbox";
import Toggle from "./Toggle";

function Content() {
  const { toggle, setToggle, show, setShow } = useContext(HelpersContext);

  // clear the storage if it is not coming from the create and earn page
  const URL = document.referrer;
  if (!URL.split("/").includes("view-create-and-earn-details")) {
    sessionStorage.clear();
  }

  // get details from session storage
  const createAndEarn = JSON.parse(sessionStorage.getItem("CreateAndEarn"));

  const user = createAndEarn ? createAndEarn.user : "";

  console.log(createAndEarn);

  return (
    <>
      <main id="notification">
        {/* search */}
        <Grid container id="search_bar">
          {createAndEarn && createAndEarn.fromCreateAndEarn ? (
            <p>{user.fullName + " " + user.phoneNumber}</p>
          ) : (
            <>
              <Toggle toggle={toggle} setToggle={setToggle} label="Show" />

              {toggle ? <CustomerSearchCheckbox /> : ""}

              <Toggle toggle={show} setToggle={setShow} label="Send To All" />
            </>
          )}
        </Grid>

        {/* body */}
        <Body createAndEarn={createAndEarn} />
      </main>
    </>
  );
}

export default function Send() {
  const { sending } = useContext(NotificationContext);

  return (
    <>
      <Notification content={<Content />} />

      {/* feedback popup */}
      <FeedbackPopup condition={sending} additionalMessage="Sending..." />
    </>
  );
}
