import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core/";

export default function ViewAnIncomingOrderDishTable(props) {
  // props
  const channel = props.channel;
  const menuItem = props.menuItem;
  const preferences = JSON.parse(menuItem.Cart.preferences);
  const originalItem = menuItem.Cart.MenuItem;
  const menuItemInventories = originalItem.MenuitemInventories;

  console.log(menuItem);

  // addons
  let addons = [];

  if (channel === "POS") {
    addons = JSON.parse(menuItem.Cart.addons);
  } else {
    addons = JSON.parse(JSON.parse(menuItem.Cart.addons));
  }

  return (
    <Grid container id="dish_details">
      <h2>
        {originalItem.itemName} <span>(x{menuItem.Cart.quantity})</span>
      </h2>

      <Grid item xs={12} sm={12} md={12} lg={12} className="dish_table">
        <TableContainer key={menuItem.id}>
          <Table aria-label="a dense table">
            {/* table head */}
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Unit(s)</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Preference</TableCell>
                <TableCell>Special Instructions</TableCell>
                <TableCell>Note</TableCell>
              </TableRow>
            </TableHead>

            {/* table body */}
            <TableBody>
              <TableRow key={menuItem.id}>
                <TableCell>
                  {/* main items */}
                  {menuItemInventories &&
                    menuItemInventories.length &&
                    menuItemInventories.map((item) => (
                      <li>{item.Inventory.itemName}</li>
                    ))}

                  {/* addons */}
                  {addons && addons.length
                    ? addons.map((addon) => (
                        <li>
                          {addon.isExtra ? "Extra " + addon.name : addon.name}{" "}
                        </li>
                      ))
                    : ""}
                </TableCell>

                <TableCell id="qty">
                  {/* main quantity */}
                  {menuItemInventories &&
                    menuItemInventories.length &&
                    menuItemInventories.map((item) => (
                      <li>{parseFloat(item.quantity)} unit(s)</li>
                    ))}

                  {/* addons quantity */}
                  {addons && addons.length
                    ? addons.map((addon) => (
                        <li>
                          {parseFloat(addon.originalQuantity) *
                            parseFloat(addon.quantity)}{" "}
                          unit(s)
                        </li>
                      ))
                    : ""}
                </TableCell>

                <TableCell>
                  {/* main items price */}
                  {menuItemInventories &&
                    menuItemInventories.length &&
                    menuItemInventories.map((item) => (
                      <li>{parseFloat(item.price).toFixed(2)} NGN </li>
                    ))}

                  {/* addons price */}
                  {addons && addons.length
                    ? addons.map((addon) => (
                        <li>{parseFloat(addon.totalPrice).toFixed(2)} NGN </li>
                      ))
                    : ""}
                </TableCell>

                <TableCell>
                  {preferences && preferences.length
                    ? preferences.map((preference) => (
                        <li>
                          {channel === "POS"
                            ? preference.Preference.name
                            : preference.name}{" "}
                          <span>
                            (
                            {parseFloat(
                              channel === "POS"
                                ? preference.Preference.unitPrice
                                : preference.unitPrice
                            ).toFixed(2)}
                            ) NGN
                          </span>{" "}
                        </li>
                      ))
                    : ""}
                </TableCell>

                {/* specialInstruction */}
                <TableCell align="center" style={{ width: 300 }}>
                  {menuItem.Cart.specialInstruction}
                </TableCell>

                {/* note */}
                <TableCell align="center" style={{ width: 300 }}>
                  {menuItem.Cart.note}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <p>
            This Item has
            {addons.length ? " Addons" : " NO Addons"}{" "}
            {preferences.length ? " Preferences" : " NO Preferences"}{" "}
          </p>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
