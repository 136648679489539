import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "165px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function EachAdminSideMenu(props) {
  const classes = useStyles();
  const links = props.links;
  const navIcon = props.navIcon;

  return (
    <>
      <List>
        <ListItem button>
          {/* menu icon */}
          {navIcon ? (
            <ListItemIcon id="side_nav_icon">{navIcon}</ListItemIcon>
          ) : (
            ""
          )}

          <div className={classes.root} id="each_admin_side_menu">
            <Accordion>
              {/* outer menu header*/}
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {props.navTitle}
                </Typography>
              </AccordionSummary>

              {/* outer menu body */}
              {links &&
                links.length &&
                links.map((link) => (
                  <AccordionDetails>
                    <Link
                      onClick={() => localStorage.clear()}
                      href={link[1]}
                      color="inherit"
                    >
                      {link[0]}
                    </Link>
                  </AccordionDetails>
                ))}

              {/* inner menu */}
              {props.innerMenu}
            </Accordion>
          </div>
        </ListItem>
      </List>
    </>
  );
}
