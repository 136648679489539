import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion, Grid } from "@material-ui/core/";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { HelpersContext } from "../../../../../ContextAPI/HelpersContext";
import MenuItemWithCheckBox from "../SelectMT/MenuItemWithCheckBox";
import { getAllDates, stringifyDate } from "../../../../helpers/MenuPlanHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function EachDayBodyPreview() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* morning */}
      <EachDayBodyContent period="Morning" />

      {/* afternoon */}
      <EachDayBodyContent period="Afternoon" />

      {/* evening */}
      <EachDayBodyContent period="Evening" />
    </div>
  );
}

function EachDayBodyContent(props) {
  const classes = useStyles();

  const period = props.period;

  // context
  const {
    MPSelectedMenuItem,
    dateRange,
    selectedPOD,
    morningRanges,
    afternoonRanges,
    eveningRanges,
  } = useContext(HelpersContext);

  useEffect(() => {
    for (const item of MPSelectedMenuItem) {
      item.dateRange = stringifyDate(getAllDates(dateRange));
      item.selectedPOD = selectedPOD;
      item.selectedTimeRange = [
        ...morningRanges,
        ...afternoonRanges,
        ...eveningRanges,
      ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Accordion>
        {/* header */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{period}</Typography>
        </AccordionSummary>

        {/* body */}
        <AccordionDetails>
          <Grid container>
            {selectedPOD.includes(period) ? (
              <Grid item xs={12} sm={12} md={12} lg={12} id="select_mt_body">
                <Grid container id="select_menu_item_checkbox">
                  {MPSelectedMenuItem &&
                    MPSelectedMenuItem.length &&
                    MPSelectedMenuItem.map((item) => (
                      <MenuItemWithCheckBox menuItem={item} mode="view" />
                    ))}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <h1>You haven’t added any meal yet.</h1>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
