import React, { useContext, useState } from "react";
import {
  TextField,
  Grid,
  InputAdornment,
  FormControl,
  Select,
  Button,
} from "@material-ui/core/";
import { MenuItemCategoryContext } from "../../../ContextAPI/MenuItemCategoryContext";
// import { MenuItemContext } from "../../../ContextAPI/MenuItemContext";
import SearchItems from "../MPCategoryMGT/SearchItems";
import NextAndPrevButtons from "../../helpers/Table/NextAndPrevButtons";
// import { HelpersContext } from "../../../ContextAPI/HelpersContext";

export default function POSFilter(props) {
  // context
  const { menuItemCategories } = useContext(MenuItemCategoryContext);
  // const { filterMenuItems } = useContext(MenuItemContext);
  // const { setSearch, setSearchResult } = useContext(HelpersContext);

  // props
  const menuItems = props.menuItems;

  // useState
  const [category, setCategory] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [menuItemType, setMenuItemType] = useState("");

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="filter_forms"
        id="pos_filter"
      >
        {/* searsch field */}
        <SearchItems item={menuItems} />

        {/* categories */}
        <FormControl variant="outlined">
          <Select
            native
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Categories</option>
            {menuItemCategories &&
              menuItemCategories.length > 0 &&
              menuItemCategories.map((category) => (
                <option
                  key={category.id}
                  title={category.description}
                  value={category.name}
                >
                  {category.name.toUpperCase()}
                </option>
              ))}
          </Select>
        </FormControl>

        {/* Combination */}
        <FormControl variant="outlined">
          <Select
            native
            value={menuItemType}
            onChange={(e) => setMenuItemType(e.target.value)}
          >
            <option value="">Combination</option>
            <option value="Single Meal">Single Meal</option>
            <option value="Combo dish">Combo dish</option>
            <option value="Both">Both</option>
          </Select>
        </FormControl>

        {/* Minimum Price */}
        <TextField
          variant="outlined"
          placeholder="Minimum Price"
          name="minPrice"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">NGN</InputAdornment>,
          }}
        />

        {/* Maximum Price */}
        <TextField
          variant="outlined"
          placeholder="Maximum Price"
          name="maxPrice"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">NGN</InputAdornment>,
          }}
        />

        {/* apply filter */}
        <Button
          onClick={
            () => alert("Currently Out of Use")
            // filterMenuItems({
            //   category,
            //   minPrice,
            //   maxPrice,
            //   menuItemType,
            //   setSearch,
            //   setSearchResult,
            // })
          }
        >
          Apply
        </Button>

        {/* clear filter */}
        <Button
          onClick={
            () => (window.location.href = window.location.href.split("#")[0])
            // filterMenuItems({
            //   category: "",
            //   minPrice: "",
            //   maxPrice: "",
            //   menuItemType: "",
            //   setSearch,
            //   setSearchResult,
            // })
          }
        >
          Clear
        </Button>

        <NextAndPrevButtons />
      </Grid>
    </>
  );
}
