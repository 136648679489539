import React from "react";
import { MdCancel } from "react-icons/md";

export default function Heading({ closePopup }) {
  return (
    <>
      <div className="item_popup_header">
        <section id="title_name">
          Adjust <span>Delivery details</span> and <span>Meal quantity </span>
          for the <br /> selected meal(s).
        </section>

        <section id="cancel_icon" onClick={() => closePopup()}>
          <MdCancel />
        </section>
      </div>
    </>
  );
}
