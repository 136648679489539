import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import Verification from "../../../components/adminComponents/Payment/Verification";

export default function PaymentVerification() {
  return (
    <>
      <DashboardFrame
        pageHeading="Payment Verification"
        dashboardContent={<Verification />}
      />
    </>
  );
}
