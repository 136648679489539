// external imports
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  Select,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core/";

// internal imports
import { ScheduleContext } from "../../../../ContextAPI/ScheduleContext";

export default function Schedule() {
  // context
  const { addSchedule } = useContext(ScheduleContext);

  // set initial states
  const [isActive, setIsActive] = useState(true);
  const [periodOfDay, setPeriodOfDay] = useState(null);
  const [upperTimeLimit, setUpperTimeLimit] = useState(null);
  const [lowerTimeLimit, setLowerTimeLimit] = useState(null);

  // yup schema for validation
  const schema = yup.object().shape({
    periodOfDay: yup.string().required(),
    upperTimeLimit: yup.string().required(),
    lowerTimeLimit: yup.string().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    addSchedule(data, isActive);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="form_frame"
      id="schedule_settings"
    >
      <h1>Set time range</h1>

      <Grid container>
        {/* Period of the day */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <h4>Period of the day</h4>
          <FormControl variant="outlined" fullWidth>
            <Select
              native
              name="periodOfDay"
              error={!!errors.periodOfDay}
              helperText={errors.periodOfDay ? errors.periodOfDay.message : ""}
              inputRef={register}
              value={periodOfDay}
              onChange={(e) => setPeriodOfDay(e.target.value)}
            >
              <option value={null}>Select Period of Day</option>
              <option value="Morning">Morning</option>
              <option value="Afternoon">Afternoon</option>
              <option value="Evening">Evening</option>
            </Select>
          </FormControl>
        </Grid>

        {/* Select Time Range */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <h4>Select Time Range</h4>
          <Grid container>
            {/* lower limit */}
            <Grid item xs={6} sm={6} md={6} lg={6}>
              {/* <p>Set lower limit</p> */}
              <TextField
                fullWidth
                label="Set Lower limit"
                variant="outlined"
                type="time"
                name="lowerTimeLimit"
                error={!!errors.lowerTimeLimit}
                helperText={
                  errors.lowerTimeLimit ? errors.lowerTimeLimit.message : ""
                }
                inputRef={register}
                value={lowerTimeLimit}
                onChange={(e) => setLowerTimeLimit(e.target.value)}
              />
            </Grid>

            {/* upper limit */}
            <Grid item xs={6} sm={6} md={6} lg={6}>
              {/* <p>Set upper limit</p> */}
              <TextField
                fullWidth
                label="Set Upper limit"
                variant="outlined"
                type="time"
                name="upperTimeLimit"
                error={!!errors.upperTimeLimit}
                helperText={
                  errors.upperTimeLimit ? errors.upperTimeLimit.message : ""
                }
                inputRef={register}
                value={upperTimeLimit}
                onChange={(e) => setUpperTimeLimit(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* set isActive */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl component="fieldset" className="switch_input">
            <FormGroup aria-label="DisplayOnApp" row>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive}
                    color="primary"
                    onChange={(e) => setIsActive(e.target.checked)}
                  />
                }
                label="isActive"
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      {/* button */}
      <section className="form-btn">
        <Button type="submit">Create</Button>
      </section>
    </form>
  );
}
