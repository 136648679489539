import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import AddMPDetailsForm from "../../../components/adminComponents/MPDetails/AddMPDetails/AddMPDetailsForm";

export default function AddMPDetails(props) {
  const planDetails = props.location.state;

  return (
    <>
      <DashboardFrame
        pageHeading="Menu Plan Details"
        dashboardContent={<AddMPDetailsForm planDetails={planDetails} />}
      />
    </>
  );
}
