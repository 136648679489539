import React from "react";
import DashboardFrame from "../../../../components/adminComponents/DashboardFrame";
import ActiveOrderTable from "../../../../components/adminComponents/Order/ActiveOrder/ActiveOrderTable";
import SettingsFrame from "../../../../components/adminComponents/Settings/SettingsFrame";

export default function ActiveOrder() {
  return (
    <main className="table_page_frame">
      <DashboardFrame
        pageHeading="Order"
        dashboardContent={
            <SettingsFrame
              firstLabel="Active"
              firstContent={<ActiveOrderTable />}
            />
          }
      />
    </main>
  );
}
