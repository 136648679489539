import React, { useContext } from "react";
import { RecipeContext } from "../../../../ContextAPI/RecipeContext";
import { Detail } from "../../CustomerRecipe/ViewAndUpdateDetails/RecipeDetails";

export default function FormDetails() {
  const { recipeDetails, recipeTableType } = useContext(RecipeContext);

  return (
    <div id="survey_form_details">
      <Detail
        title="1.  How many adults are involved?"
        body={recipeDetails.totalAdult}
      />

      {recipeTableType === "COMPLETED" ? (
        ""
      ) : (
        <Detail
          title="2.  How many children are involved?"
          body={recipeDetails.totalChildren}
        />
      )}

      <Detail
        title="3.  What meals are you looking to plan for?"
        body={recipeDetails.mealCategory.split(",").join(", ")}
      />

      <Detail
        title="4. What type of meals do you prefer?"
        body={recipeDetails.preferedFood}
      />

      <Detail
        title="5.  Any health condition we should be aware of to help in planning the meal?"
        body={recipeDetails.healthConditions}
      />

      <Detail
        title="6.  Any food not tolerated (e.g. pepper, sugar, salt, spices, fries, oil, vegetables, fruits etc.) by any of the users?"
        body={recipeDetails.foodNotTolerated}
      />

      <Detail
        title="7.  Can you list any food or foods you/ your group strongly dislike and do not want in your plan?"
        body={recipeDetails.dislikeFood}
      />

      <Detail
        title="8.  Any food(s) you especially enjoy or would like to include more of in your diet?"
        body={recipeDetails.preferedFood}
      />

      <Detail
        title="9.  Anything else you want us to know for the meal planning please?"
        body={recipeDetails.otherInfo}
      />

      <Detail
        title="10.  How many persons are involved?"
        body={recipeDetails.howManyPersonsInvolved}
      />
    </div>
  );
}
