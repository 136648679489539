import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import HalfRowLayout from "../../../helpers/HalfRowLayout";
import moment from "moment";
import CancelMPOrder from "../CancelMPOrder";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import MPOrderKitchen from "../PrintSlips/MPOrderKitchen";
import MPOrderReceipt from "../PrintSlips/MPOrderReceipt";
import { getMPStatus } from "../../../helpers/MenuPlanHelper";
import StatusBtn from "../StatusBtn";

export default function TimeDetails({ order }) {
  // context
  const { roles, deliveryDetails } = useContext(HelpersContext);

  const status = getMPStatus(deliveryDetails, order.deliveryTime);

  return (
    <>
      <Grid container id="order_and_time_details">
        <Grid item xs={5} sm={5} md={5} lg={5} id="order_details">
          <Grid container>
            <HalfRowLayout className="text_key" text="ORDER NAME:" />
            <HalfRowLayout text={order.orderName} />

            <HalfRowLayout className="text_key" text="ORDER ID:" />
            <HalfRowLayout text={order.orderRef} />

            <HalfRowLayout className="text_key" text="CUSTOMER NAME:" />
            <HalfRowLayout text={order.fullName} />
            <HalfRowLayout className="text_key" text="CUSTOMER NUMBER:" />
            <HalfRowLayout text={order.phoneNumber} />
            <HalfRowLayout className="text_key" text="CUSTOMER E-MAIL:" />
            <HalfRowLayout text={order.email} />

            <HalfRowLayout className="text_key" text="DATE OF DELIVERY:" />
            <HalfRowLayout text={order.deliveryDate.split("T")[0]} />

            <HalfRowLayout className="text_key" text="TIME OF DELIVERY:" />
            <HalfRowLayout text={order.deliveryTime.time} /> 

            <HalfRowLayout className="text_key" text="TIME DELIVERED:" />
            <HalfRowLayout text="" />

            <HalfRowLayout className="text_key" text="RECIPIENT’S NAME:" />
            <HalfRowLayout text={order.friendName} />

            <HalfRowLayout className="text_key" text="RECIPIENT’S NUMBER:" />
            <HalfRowLayout text={order.friendPhoneNumber} />

            <HalfRowLayout className="text_key" text="STATUS:" />
            <HalfRowLayout text={status} />

            <HalfRowLayout className="text_key" text="CHANNEL:" />
            <HalfRowLayout text={order.orderChannel} />
          </Grid>
        </Grid>

        <div className="vertical_rule"></div>

        {/* timing details */}
        <Grid item xs={5} sm={5} md={5} lg={5} id="time_details">
          <Grid container>
            {/* date_time_container */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="date_time_container"
            >
              <span>{moment(order.createdAt).format("L")}</span>
              <span>{moment(order.createdAt).format("LTS")}</span>
            </Grid>

            {/* slips */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="date_time_container"
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MPOrderKitchen order={order} status={status} />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MPOrderReceipt order={order} />
              </Grid>
            </Grid>

            {/* time_until_delivery */}
            <Grid container id="until_delivery">
              <Grid item xs={6} sm={6} md={6} lg={6} id="until_delivery_time">
                <p>00:00:00</p>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} id="until_delivery_text">
                <p>(Until Delivery)</p>
              </Grid>
            </Grid>

            {/* status button */}
            {status === "Cancelled" || status === "Delivered" ? (
              ""
            ) : (
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} id="delivery_btn">
                  {roles.includes("Chef 2") ? (
                   <StatusBtn order={order} />
                  ) : (
                    ""
                  )}

                  {/* cancel order here */}
                  {roles.includes("Manager 2") ? (
                    <CancelMPOrder order={order} />
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
