import React, { useContext } from "react";
import RecipeTable from "../../../pages/admin/CustomerRecipe/RicepeTable";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { sortOrders, tableOptions } from "../../helpers/OrderHelpers";
import TableContent from "../../helpers/Table/TableContent";
import { RecipeContext } from "../../../ContextAPI/RecipeContext";
import Actions from "./ActionsMenu/Actions";
import Menu from "./ActionsMenu/Menu";

function PendingTable() {
  // context
  const { recipes, recipeLoading } = useContext(RecipeContext);

  // seperate order according to status
  let pendingOrders = sortOrders(recipes, "PENDING", "RECIPE");

  // table headings
  const columns = [
    "",
    "S/N",
    "CUSTOMER NAME",
    "CUSTOMER’S NUMBER",
    "ORDER ID",
    "RECIPE TITLE",
    "NO. OF DELIVERIES",
    "DATE OF REQUEST",
    "TIME OF REQUEST",
    "STATUS",
  ];

  // extract data for the table rows
  const extractedOrders = pendingOrders.map((order, index) => {
    const customer = order.orderedByDetail;

    return [
      <Actions
        viewDetails={<Menu link={"/view-recipe-details"} details={order} />}
      />,
      index + 1,
      customer.fullName,
      customer.phoneNumber,
      order.id,
      order.Recipe.mealName,
      order.RecipeOrderDeliveryDates.length,
      moment(order.createdAt).format("L"),
      moment(order.createdAt).format("HH:mm:ss"),
      order.status,
    ];
  });

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter" id="pos_filter">
        {/* today */}
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{pendingOrders.length}</span>
          </p>
        </Grid>
      </Grid>

      {/* set table */}
      <TableContent
        loading={recipeLoading}
        receivedData={pendingOrders}
        heading="Pending Order"
        tableData={extractedOrders}
        columns={columns}
        tableOptions={tableOptions}
      />
    </main>
  );
}

const Pending = () => {
  return (
    <>
      <RecipeTable
        table={<PendingTable />}
        title="Pending"
        pageHeading="Recipe requests"
      />
    </>
  );
};

export default Pending;
