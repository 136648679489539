import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";

export default function Notification({ content }) {
  return (
    <>
      <DashboardFrame pageHeading="Send Notification" dashboardContent={content} />
    </>
  );
}
