// external imports
import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";
import { HelpersContext } from "./HelpersContext";
import { restructureMPData } from "../components/helpers/MenuPlanHelper";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
}

const BASE_URL = getBaseUrl();
const BASE_URL_2 = getBaseUrl(2);

// create MPManagement context to manage MPManagement here
export const MPManagementContext = createContext();

export default function MPManagementContextProvider(props) {
  const { setDeliveryDetails, setFilteredResult } = useContext(HelpersContext);

  // set initial state
  const [MPOrders, setMPOrders] = useState([]);
  const [MPOrdersLoading, setMPOrdersLoading] = useState(null);
  const [userMPFeedBack, setUserMPFeedBack] = useState(null);
  const [userMPOrders, setUserMPOrders] = useState([]);
  const [userOrdersLoading, setUserOrdersLoading] = useState(null);
  const [executeOnce, setExecuteOnce] = useState(true);
  const [MPChanging, setMPChanging] = useState(null);
  const [MPDetails, setMPDetails] = useState([]);
  const [gettingMPDetails, setGettingMPDetails] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [gettingTime, setGettingTime] = useState([]);

  // get all orders for order management
  const getMenuPlanByDate = (presentDay, nextDay, page) => {
    setMPOrdersLoading("processing");

    const method = "get",
      url = `${BASE_URL_2}orders/menuplan/getOrdersByDateV1/?dateFrom=${
        presentDay.split("T")[0]
      }&dateTo=${nextDay.split("T")[0]}&page=${page}`;

    // get Orders
    axios({ url, method, headers })
      .then((result) => {
        // breakdown items based on delivery time
        const MPData = restructureMPData(result.data.data.items);

        setFilteredResult(null);
        setMPOrders(MPData);
        setMPOrdersLoading("found");

        // stop another execution
        setExecuteOnce(false);
      })
      .catch((error) => {
        error = new Error();
        setMPOrdersLoading("failed");

        // stop another execution
        setExecuteOnce(false);
      });
  };

  // get menu plan for a user
  const getMenuPlanByUser = (userId) => {
    if (!userId || userId === null || userId === "")
      return setUserMPFeedBack("You have not selected a customer");

    setUserOrdersLoading("processing");

    const method = "get",
      url = `${BASE_URL}orders/menuplan/detail?page=1&userId=${userId}`;

    // get Orders
    axios({ url, method, headers })
      .then((result) => {
        setUserMPOrders(result.data.data.items);
        setUserOrdersLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setUserOrdersLoading("failed");
        setUserMPOrders([]);
      });
  };

  // change MP order status
  const changeMPOrderStatus = (orderId, status, basketId) => {
    setMPChanging("processing");

    // config
    const method = "put",
      url = `${BASE_URL}orders/changePlanOrderDetailStatus/`,
      data = {
        basketId,
        orderId,
        status,
      };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setMPChanging("successful");

        setTimeout(() => {
          setMPChanging(null);
        }, 1000);
      })
      .catch((error) => {
        error = new Error();
        setMPChanging("failed");

        setTimeout(() => {
          setMPChanging(null);
        }, 1000);
      });
  };

  // getMenuPlanById
  const getMenuPlanMealsById = (id) => {
    if (!id)
      return alert("Invalid Menu Plan. Please Cross Check or Select Another");

    setGettingMPDetails("processing");

    const method = "get",
      url = `${BASE_URL}menuplans/${id}`;

    // get Orders
    axios({ url, method, headers })
      .then((result) => {
        setMPDetails(result.data.data.data.MenuPlanDetails);
        setGettingMPDetails("found");
      })
      .catch((error) => {
        error = new Error();
        setGettingMPDetails("failed");
        setMPDetails(null);
      });
  };

  // getMenuPlanById
  const getUniqueMPMealsById = (id) => {
    setGettingMPDetails("processing");

    const method = "get",
      url = `${BASE_URL_2}menuplans/detail/menuplanid?menuplanId=${id}`;

    // get Orders
    axios({ url, method, headers })
      .then((result) => {
        setMPDetails(result.data.data.data);
        setGettingMPDetails("found");
      })
      .catch((error) => {
        error = new Error();
        setGettingMPDetails("failed");
        setMPDetails(null);
      });
  };

  // getMPItemsTimes
  const getMPItemsTimes = (menuplanId, menuItemId, date) => {
    setGettingTime("processing");

    const method = "get",
      url = `${BASE_URL_2}menuplans/detail/plan_date_time?menuplanId=${menuplanId}&menuItemId=${menuItemId}&planDate=${date}`;

    // get Orders
    axios({ url, method, headers })
      .then((result) => {
        const timesArray = [];
        const timeRanges = result.data.data.data;

        // structure the time ranges retrieved
        for (const range of timeRanges) {
          const id = range + date;
          timesArray.push({ id, range, quantity: 1, date });
        }

        setAvailableTimes(timesArray);

        setGettingTime("found");
      })
      .catch((error) => {
        error = new Error();
        setGettingTime("failed");
        setAvailableTimes(null);
      });
  };

  // changeMealDTstatus
  const changeMealDTstatus = (
    createdBy,
    orderId,
    basketId,
    deliveryTime,
    time,
    status,
    closePopup
  ) => {
    setMPChanging("processing");
    const deliveryTimeCopy = deliveryTime;

    for (const item of deliveryTime) {
      if (time === item.time) {
        item.status = status;
      }
    }

    // config
    const method = "put",
      url = `${BASE_URL}orders/basket/`,
      data = {
        basketId,
        orderId,
        createdBy,
        deliveryTime: JSON.stringify(deliveryTime),
      };

    // make API call
    axios({ url, method, headers, data })
      .then((result) => {
        setMPChanging("successful");

        setDeliveryDetails(deliveryTime);

        let statuses = [];
        for (const item of deliveryTime) {
          statuses.push(item.status);
        }

        setMPChanging("successful");
        closePopup(false);

        setTimeout(() => {
          setMPChanging(null);
        }, 1000);
      })
      .catch((error) => {
        error = new Error();
        
        setDeliveryDetails(deliveryTimeCopy);

        setTimeout(() => {
          setMPChanging(null);
        }, 1000);
      });
  };

  return (
    <MPManagementContext.Provider
      value={{
        MPOrdersLoading,
        MPOrders,
        getMenuPlanByUser,
        userMPFeedBack,
        userMPOrders,
        userOrdersLoading,
        getMenuPlanByDate,
        executeOnce,
        MPChanging,
        changeMPOrderStatus,
        MPDetails,
        gettingMPDetails,
        getMenuPlanMealsById,
        getUniqueMPMealsById,
        availableTimes,
        gettingTime,
        getMPItemsTimes,
        changeMealDTstatus,
      }}
    >
      {props.children}
    </MPManagementContext.Provider>
  );
}
