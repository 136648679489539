import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import POSFilter from "../../CreatePOSOrder/POSFilter";
import POSFilterDrawer from "../../CreatePOSOrder/POSFilterDrawer";
import MealItem from "../ViewMenuItems/MealItem";
import LoadingFeedback from "../../../helpers/LoadingFeedback";

export default function MealItems({
  items,
  searchResult,
  search,
  gettingAllMT,
}) {
  return (
    <main id="view_mwnu_items">
      {/* filter */}
      <Grid container className="top_filter">
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{items.length}</span>
          </p>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

        {/* filter section */}
        <Grid item xs={12} sm={12} md={9} lg={9}>
          {/* filter options for xs and sm */}
          <Hidden mdUp>
            <POSFilterDrawer menuItems={items} />
          </Hidden>

          {/* filter options for md and lg */}
          <Hidden smDown>
            <POSFilter menuItems={items} />
          </Hidden>
        </Grid>
      </Grid>

      {/* menu items */}
      <Grid container id="meal_items">
        {/* display menu items here */}
        {searchResult && searchResult.length > 0 ? (
          searchResult.map((result) => <MealItem menuItem={result} />)
        ) : search && searchResult && !searchResult.length ? (
          "No Result Found!"
        ) : gettingAllMT === "processing" ? (
          <LoadingFeedback message="Searching For Menu Items..." />
        ) : gettingAllMT === "found" && items.length > 0 ? (
          items.map((result) => <MealItem menuItem={result} />)
        ) : gettingAllMT === "found" && items.length <= 0 ? (
          <LoadingFeedback message="Search Completed But No Menu Item Found" />
        ) : gettingAllMT === "failed" ? (
          <LoadingFeedback message="An Error occurred. There might be no items available. Alse, Check your Connection" />
        ) : (
          ""
        )}
      </Grid>
    </main>
  );
}
