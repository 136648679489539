import moment from "moment";

// get the number of days in a selected range
const getDaysCount = (dateRange) => {
  // get the amount of days selected
  return moment(dateRange[0].endDate).diff(
    moment(dateRange[0].startDate),
    "days"
  );
};

// getAllDates
const getAllDates = (dateRange) => {
  const rangeDiff = getDaysCount(dateRange);

  // get all the dates of days selected
  const allDays = [];
  for (let day = 0; day <= rangeDiff; day++) {
    allDays.push(moment(dateRange[0].startDate).add(day, "d")._d);
  }

  return allDays;
};

// getDatesSelected
const getDatesSelected = (startDate, endDate) => {
  // get the amount of days selected
  const rangeDiff = moment(endDate).diff(moment(startDate), "days");

  // get all the dates of days selected
  const allDays = [];
  for (let day = 0; day <= rangeDiff; day++) {
    allDays.push(moment(startDate).add(day, "d")._d);
  }

  return allDays;
};

// convert the date to strings for comparism purpose
const stringifyDate = (dates) => {
  let stringifiedDates = [];
  for (const day of dates) {
    stringifiedDates.push(moment(day).format("(Do MMMM, YYYY)").toString());
  }

  return stringifiedDates;
};

// seperate the schedules according to period of the day
const filterSchedulesByTimeOfDay = (schedules) => {
  let morningTime = [];
  let afternoonTime = [];
  let eveningTime = [];

  for (const schedule of schedules) {
    // Morning
    if (schedule.planType === "Morning") {
      morningTime.push(schedule);
    }

    // Afternoon
    if (schedule.planType === "Afternoon") {
      afternoonTime.push(schedule);
    }

    // Evening
    if (schedule.planType === "Evening") {
      eveningTime.push(schedule);
    }
  }

  return { morningTime, afternoonTime, eveningTime };
};

// convert time limits to 12 hours
const timeLimitConvertion = (timeLimit) => {
  const eachSchedule = timeLimit.split("-");

  return (
    moment(eachSchedule[0].trim(), "HH:mm").format("h:mm A") +
    " - " +
    moment(eachSchedule[1].trim(), "HH:mm").format("h:mm A")
  );
};

// add the selected time range to array
const addTimeRange = (
  checked,
  value,
  setSelectedTimeRange,
  selectedTimeRange
) => {
  if (checked) {
    setSelectedTimeRange([...selectedTimeRange, value]);
  }

  if (!checked) {
    setSelectedTimeRange(selectedTimeRange.filter((time) => time !== value));
  }
};

// collect period of days selected
const collectPOD = (status, period, selected, setSelected) => {
  // checked
  if (status) {
    setSelected([...selected, period]);
  }

  // uncheked
  if (!status) {
    setSelected(selected.filter((item) => item !== period));
  }
};

// toggle showing of content
const toggleShowContent = (show, setShow) => {
  if (show === false) {
    setShow(true);
  } else {
    setShow(false);
  }
};

// collect menu items
const collectMenuItems = (status, menuItem, selected, setSelected, id) => {
  // checked
  if (status) {
    setSelected([...selected, menuItem]);
  }

  // uncheked
  if (!status) {
    setSelected(selected.filter((item) => item.id !== id));
  }
};

// get ids
const getIds = (items) => {
  let ids = [];
  for (const item of items) {
    ids = [...ids, item.id];
  }

  return [...new Set(ids)];
};

// get plantypes (i.e. period of day) and their time ranges
const getPlantypes = (morning, afternoon, evening) => {
  let plantypes = [];

  if (morning.length) {
    plantypes.push({ type: "Morning", time: morning.toString() });
  }

  if (afternoon.length) {
    plantypes.push({ type: "Afternoon", time: afternoon.toString() });
  }

  if (evening.length) {
    plantypes.push({ type: "Evening", time: evening.toString() });
  }

  return plantypes;
};

// get the meals selected for a day for preview after submission
const getDailyMeals = (day, meals) => {
  if (!meals.length) return [];

  let filtered = [];

  for (const meal of meals) {
    if (meal.dateRange.includes(day)) {
      filtered.push(meal);
    }
  }

  return filtered;
};

// get meals for a given period of day
const getPODMeals = (filteredMeals, period) => {
  if (!filteredMeals.length) return [];

  let PODMeals = [];
  for (const meal of filteredMeals) {
    if (meal && meal.selectedPOD.includes(period)) {
      PODMeals.push(meal);
    }
  }

  return PODMeals;
};

// get meal item time ranges
const getTimeRanges = (id, selectedItems) => {
  if (!selectedItems.length) return;

  let result;
  for (const item of selectedItems) {
    if (item.id === id) {
      result = item.selectedTimeRange.join(" || ");
    }
  }

  return result;
};

// get unique items
const getUniqueItems = (ids, items) => {
  // filter the selectedInventories to remove duplicates
  let filtered = [];
  for (const item of items) {
    if (ids.includes(item.id)) {
      filtered.push(item);
      ids.shift();
    }
  }

  return filtered;
};

// setup meal plans meals for editing
const MPEditMealsSetup = (
  MPDetails,
  setSavedMPItems,
  history,
  setMPAddMealRedirecting
) => {
  setMPAddMealRedirecting("processing");
  let retrieved = [];

  // setup the retrieved meal plan details to look like how it is when adding
  // this will make it possible to be represented for the users
  if (MPDetails.length) {
    for (const item of MPDetails) {
      let currentItem = item.MenuItem;
      currentItem.dateRange = [moment(item.plandate).format("(Do MMMM, YYYY)")];
      currentItem.selectedPOD = [item.plantype];
      currentItem.selectedTimeRange = [item.deliveryTime];

      retrieved.push(currentItem);
    }

    setSavedMPItems(retrieved);

    setMPAddMealRedirecting("successful");
    history.push("/add-menu-plan-info");
  } else {
    setMPAddMealRedirecting("successful");
    history.push("/add-menu-plan-info");
  }
};

// getQuantity
const getQuantity = (times, deliveryTime) => {
  for (const time of times) {
    if (time.time === deliveryTime) {
      return time.quantity;
    }
  }
};

// restructure MP data
const restructureMPData = (data) => {
  const retrieved = [];

  for (const item of data) {
    const temp = [];

    for (const deliveryTime of JSON.parse(item.deliveryTimeJson)) {
      item.deliveryTime = deliveryTime;
      temp.push({ ...item });
    }
    retrieved.push(...temp);
  }

  return retrieved;
};

const sortByStatus = (data, status) => {
  let retrieved = [];

  for (const item of data) {
    if (item.deliveryTime.status.toLowerCase() === status.toLowerCase()) {
      retrieved.push(item);
    }
  }

  return retrieved;
};

const getMPStatus = (allDeliveryDetails, deliverytime) => {
  let status = "";

  for (const detail of allDeliveryDetails) {
    if (detail.time === deliverytime.time) {
      status = detail.status;
    }
  }

  return status;
};

// filter menu plans
const filterPlans = (items, status) => {
  return items.filter((item) => item.isactivated === status);
};

export {
  getAllDates,
  filterSchedulesByTimeOfDay,
  addTimeRange,
  timeLimitConvertion,
  collectPOD,
  toggleShowContent,
  collectMenuItems,
  getIds,
  getPlantypes,
  stringifyDate,
  getDailyMeals,
  getPODMeals,
  getTimeRanges,
  getDaysCount,
  getUniqueItems,
  getDatesSelected,
  MPEditMealsSetup,
  getQuantity,
  restructureMPData,
  sortByStatus,
  getMPStatus,
  filterPlans,
};
