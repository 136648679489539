// external imports
import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core/";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { VscHistory } from "react-icons/vsc";
import {
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@material-ui/core/";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

// internal imports
import { InventoryContext } from "../../../ContextAPI/InventoryContext";
import {
  handleInventoryPriceChange,
  resetTable,
  removeSelectedItem,
  reduceQty,
  increaseQty,
  toggleShowPriceInput,
  getInventoryDescription,
  clearAddons,
  setUpAddon,
  toggleItems,
  toggleShowItems,
} from "../../helpers/MenuItemHelper";
import { GiCancel } from "react-icons/gi";
import AddPreference from "../CreateMenuItem/CreateMenuForm/AddPreference";
import { MenuItemPreferenceContext } from "../../../ContextAPI/MenuItemPreferenceContext";
import { MenuItemCategoryContext } from "../../../ContextAPI/MenuItemCategoryContext";
import { MenuItemContext } from "../../../ContextAPI/MenuItemContext";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import AddCategory from "../CreateMenuItem/CreateMenuForm/AddCategory";
import {
  processInventory,
  processAddons,
  extractPreferences,
  extractCategories,
} from "../../helpers/EditMenuItemHelper";
import { EditMenuItemContext } from "../../../ContextAPI/EditMenuItemContext";
import SubmitMenuItem from "../CreateMenuItem/SubmitMenuItem";
import { searchItems } from "../MPCategoryMGT/HelperFunction";
import ImageInput from "../MPCategoryMGT/ImageInput";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";

export default function EditMenuItemForm(props) {
  const menuItem = props.menuItem;

  // context
  const { inventories } = useContext(InventoryContext);
  const { menuItemPreferences } = useContext(MenuItemPreferenceContext);
  const { menuItemCategories } = useContext(MenuItemCategoryContext);
  const {
    getMenuItemInventories,
    menuItemInventories,
    getMenuItemAddons,
    getMenuItemPreferences,
    addons,
    preferences,
  } = useContext(MenuItemContext);
  const {
    updateAddons,
    addonsUpadating,
    preferencesUpdating,
    updatePreferences,
    updatingMenuItem,
    editMenuItem,
  } = useContext(EditMenuItemContext);
  const { imgBase64 } = useContext(HelpersContext);

  // useeffect
  useEffect(() => {
    getMenuItemInventories(menuItem.id);
    getMenuItemAddons(menuItem.id);
    getMenuItemPreferences(menuItem.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // setup form fields
  const totalPrice = menuItem.amount;
  const selectedInventories = processInventory(menuItemInventories);
  const selectedAddons = processAddons(addons);
  const minedPreferences = extractPreferences(menuItemPreferences, preferences);
  const retrievedCategories = menuItem.MenuItemCategories;
  const minedCategories = extractCategories(
    menuItemCategories,
    retrievedCategories
  );

  // state
  const [itemName, setItemName] = useState(menuItem.itemName);
  const [description, setDescription] = useState(menuItem.description);
  const [caption, setCaption] = useState(menuItem.caption);
  const [availability, setAvailability] = useState(
    menuItem.available.toString()
  );
  const [specialOffer, setSpecialOffer] = useState(menuItem.specialOffer);
  const [addon, setAddon] = useState(null);
  const [itemCategory, setItemCategory] = useState(null);
  const [preference, setPreference] = useState(null);
  const [showInitialAddonPriceInput, setShowInitialAddonPriceInput] =
    useState(false);
  const [showOtherAddonPriceInput, setShowOtherAddonPriceInput] =
    useState(false);
  const [initialaddonPriceInputIndex, setInitialAddonPriceInputIndex] =
    useState(null);
  const [otherAddonPriceInputIndex, setOtherAddonPriceInputIndex] =
    useState(null);
  const [initialExtra, setInitialExtra] = useState([]);
  const [otherExtra, setOtherExtra] = useState([]);
  const [selectedPreference, setSelectedPreference] = useState([]);
  const [selectedItemCategory, setSelectedItemCategory] = useState([]);
  const [showPreferences, setShowPreferences] = useState(false);
  const [showItemCategories, setShowItemCategories] = useState(false);
  const [displayOnApp, setDisplayOnApp] = useState(menuItem.activated);

  const [filteredPreferences, setFilteredPreferences] = useState([]);
  const [filteredItemCategory, setFilteredItemCategory] = useState([]);
  const [filteredAddonsInventory, setFilteredAddonsInventory] = useState([]);

  // yup schema for validation
  const schema = yup.object().shape({
    itemName: yup.string().required(),
    description: yup.string().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    editMenuItem(
      data,
      selectedItemCategory,
      imgBase64,
      availability,
      specialOffer,
      displayOnApp,
      menuItem.id
    );
  };

  // submitAddons
  const submitAddons = () => {
    updateAddons(menuItem.id, initialExtra, otherExtra);
  };

  // submitPreferences
  const submitPreferences = () => {
    updatePreferences(menuItem.id, selectedPreference);
  };

  return (
    <>
      <form id="create_menu_item" className="form_frame">
        <h1>Edit Menu Item</h1>

        <Grid container>
          {/* section 1 */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ImageInput isEdit={true} initialImage={menuItem.imageUrl} />

            {/* item name */}
            <h4>Item Name</h4>
            <TextField
              fullWidth
              variant="outlined"
              name="itemName"
              error={!!errors.itemName}
              helperText={errors.itemName ? errors.itemName.message : ""}
              inputRef={register}
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h4>Description</h4>
            <TextField
              multiline
              rows={7}
              variant="outlined"
              fullWidth
              name="description"
              error={!!errors.description}
              helperText={errors.description ? errors.description.message : ""}
              inputRef={register}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>

          {/* Select Inventory item */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h4>Selected Inventory item(s)</h4>
            {/* selected Item Options */}
            <div className="form-table">
              {/* first segment */}
              <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                  {/* table head */}
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Item</TableCell>
                      <TableCell align="center">Quality</TableCell>
                      <TableCell align="center">Total Price</TableCell>
                      <TableCell align="center">Extra</TableCell>
                    </TableRow>
                  </TableHead>

                  {/* table body for initially selected inventories. These are no longer editable */}
                  <TableBody>
                    {menuItemInventories &&
                      menuItemInventories.length > 0 &&
                      menuItemInventories.map((inventory, index) => (
                        <TableRow key={inventory.Inventory.id}>
                          {/* name */}
                          <TableCell align="center">
                            {inventory.Inventory.itemName} <br />{" "}
                            <span className="sub_price">
                              (
                              {parseFloat(inventory.price) /
                                parseFloat(inventory.quantity)}{" "}
                              NGN)
                            </span>
                          </TableCell>

                          {/* qty */}
                          <TableCell align="center" id="qty">
                            <span>{inventory.quantity}</span>
                          </TableCell>

                          {/* price */}
                          <TableCell align="center">
                            <p>{inventory.price} NGN</p>
                          </TableCell>

                          {/* extra */}
                          <TableCell align="center">
                            {inventory.extra ? "YES" : "NO"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>

                  {/* table footer */}
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Sum Total</TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">{totalPrice} NGN</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </div>
          </Grid>

          {/* Create Add-ons */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h4>Create Add-ons</h4>
            <TextField
              variant="outlined"
              fullWidth
              name="addon"
              error={!!errors.addon}
              helperText={errors.addon ? errors.addon.message : ""}
              inputRef={register}
              value={addon}
              onChange={(e) => setAddon(e.target.value)}
              onKeyUp={(e) =>
                searchItems(
                  e.target.value,
                  inventories,
                  setFilteredAddonsInventory
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            {/* Select Inventory item dropdown */}
            <div className="form-dropdown">
              <h5>{addon}</h5>

              {/* list of search result */}
              {filteredAddonsInventory &&
                filteredAddonsInventory.length > 0 &&
                filteredAddonsInventory.map((result) => (
                  <p
                    key={result.id}
                    title={getInventoryDescription(
                      result.itemName,
                      inventories
                    )}
                    onClick={() =>
                      setUpAddon(
                        result.itemName,
                        setAddon,
                        otherExtra,
                        inventories,
                        setOtherExtra,
                        initialExtra,
                        selectedInventories,
                        selectedAddons
                      )
                    }
                  >
                    <VscHistory />
                    <span>{result.itemName.toUpperCase()}</span>
                  </p>
                ))}
            </div>

            <div className="form-table">
              {/* first segment */}
              <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                  {/* table head */}
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className="remove-btn">
                        <Button
                          onClick={() =>
                            clearAddons(setInitialExtra, setOtherExtra)
                          }
                        >
                          <GiCancel />
                        </Button>
                      </TableCell>
                      <TableCell align="center">Item</TableCell>
                      <TableCell align="center">Quality</TableCell>
                      <TableCell align="center">Price</TableCell>
                    </TableRow>
                  </TableHead>

                  {/* table body for initially selected addons. These are no longer editable */}
                  <TableBody>
                    {addons &&
                      addons.length > 0 &&
                      addons.map((addon) => (
                        <TableRow key={addon.id}>
                          <TableCell></TableCell>
                          {/* name */}
                          <TableCell align="center">
                            {addon.isExtra
                              ? "Extra " + addon.Inventory.itemName
                              : addon.Inventory.itemName}{" "}
                            <br />{" "}
                            <span className="sub_price">
                              (
                              {parseFloat(addon.price) /
                                parseFloat(addon.quantity)}{" "}
                              NGN)
                            </span>
                          </TableCell>

                          {/* qty */}
                          <TableCell align="center" id="qty">
                            <span>{addon.quantity}</span>
                          </TableCell>

                          {/* price */}
                          <TableCell align="center">
                            <p>{addon.price} NGN</p>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>

                  {/* table body for addons coming from the inventory above*/}
                  <TableBody>
                    {initialExtra &&
                      initialExtra.length > 0 &&
                      initialExtra.map((inventory, index) => (
                        <TableRow key={inventory.id}>
                          <TableCell align="center" className="remove-btn">
                            <Button
                              onClick={() =>
                                alert(
                                  `Turn Off the ${inventory.name.toUpperCase()}'s Extra Button above`
                                )
                              }
                            >
                              <GiCancel />
                            </Button>
                          </TableCell>

                          <TableCell align="center">
                            Extra {inventory.name} <br />{" "}
                            <span className="sub_price">
                              ({inventory.price} NGN)
                            </span>
                          </TableCell>

                          <TableCell align="center" id="qty">
                            {/* subtract */}
                            <span
                              className="span_btn"
                              onClick={() =>
                                reduceQty(
                                  null,
                                  index,
                                  initialExtra,
                                  setInitialExtra
                                )
                              }
                            >
                              <AiOutlineMinus />
                            </span>
                            <span>{inventory.quantity}</span>
                            {/* add */}
                            <span
                              className="span_btn"
                              onClick={() =>
                                increaseQty(
                                  null,
                                  index,
                                  initialExtra,
                                  setInitialExtra
                                )
                              }
                            >
                              <AiOutlinePlus />
                            </span>
                          </TableCell>

                          <TableCell align="center">
                            {/* we are using the index === priceInputIndex to ensure that only one selected inventory is affected */}
                            {showInitialAddonPriceInput &&
                            index === initialaddonPriceInputIndex ? (
                              <>
                                <span className="sub_price">
                                  {inventory.totalPrice} NGN
                                </span>
                                <br />
                                <input
                                  type="text"
                                  name={`initiaExtraPrice-${index}`}
                                  placeholder="Tab to Exit"
                                  title="Click outside to Exit"
                                  autoFocus
                                  onBlur={(e) =>
                                    toggleShowPriceInput(
                                      e,
                                      index,
                                      showInitialAddonPriceInput,
                                      setShowInitialAddonPriceInput,
                                      setInitialAddonPriceInputIndex
                                    )
                                  }
                                  onChange={(e) =>
                                    handleInventoryPriceChange(
                                      e,
                                      index,
                                      initialExtra
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <p
                                title="Click to Edit"
                                onClick={(e) =>
                                  toggleShowPriceInput(
                                    e,
                                    index,
                                    showInitialAddonPriceInput,
                                    setShowInitialAddonPriceInput,
                                    setInitialAddonPriceInputIndex
                                  )
                                }
                              >
                                {inventory.totalPrice} NGN
                              </p>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                  {/* table body for addons coming from the addons search itself*/}
                  <TableBody>
                    {otherExtra &&
                      otherExtra.length > 0 &&
                      otherExtra.map((inventory, index) => (
                        <TableRow key={inventory.id}>
                          <TableCell align="center" className="remove-btn">
                            <Button
                              onClick={() =>
                                removeSelectedItem(
                                  index,
                                  otherExtra,
                                  setOtherExtra
                                )
                              }
                            >
                              <GiCancel />
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {inventory.name} <br />{" "}
                            <span className="sub_price">
                              ({inventory.price} NGN)
                            </span>
                          </TableCell>
                          <TableCell align="center" id="qty">
                            {/* subtract */}
                            <span
                              className="span_btn"
                              onClick={() =>
                                reduceQty(
                                  null,
                                  index,
                                  otherExtra,
                                  setOtherExtra
                                )
                              }
                            >
                              <AiOutlineMinus />
                            </span>
                            <span>{inventory.quantity}</span>
                            {/* add */}
                            <span
                              className="span_btn"
                              onClick={() =>
                                increaseQty(
                                  null,
                                  index,
                                  otherExtra,
                                  setOtherExtra
                                )
                              }
                            >
                              <AiOutlinePlus />
                            </span>
                          </TableCell>

                          <TableCell align="center">
                            {/* we are using the index === otherAddonPriceInputIndex to ensure that only one selected inventory is affected */}
                            {showOtherAddonPriceInput &&
                            index === otherAddonPriceInputIndex ? (
                              <>
                                <span className="sub_price">
                                  {inventory.totalPrice} NGN
                                </span>
                                <br />
                                <input
                                  type="text"
                                  name={`otherExtraPrice-${index}`}
                                  placeholder="Tab to Exit"
                                  title="Click outside to Exit"
                                  autoFocus
                                  onBlur={(e) =>
                                    toggleShowPriceInput(
                                      e,
                                      index,
                                      showOtherAddonPriceInput,
                                      setShowOtherAddonPriceInput,
                                      setOtherAddonPriceInputIndex
                                    )
                                  }
                                  onChange={(e) =>
                                    handleInventoryPriceChange(
                                      e,
                                      index,
                                      otherExtra
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <p
                                title="Click to Edit"
                                onClick={(e) =>
                                  toggleShowPriceInput(
                                    e,
                                    index,
                                    showOtherAddonPriceInput,
                                    setShowOtherAddonPriceInput,
                                    setOtherAddonPriceInputIndex
                                  )
                                }
                              >
                                {inventory.totalPrice} NGN
                              </p>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            {/* update addons button */}
            <Button onClick={() => submitAddons()}>
              {addonsUpadating ? addonsUpadating : "Update Addons"}{" "}
            </Button>

            {/* feedback */}
          </Grid>

          {/* preference */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <section id="menu_category_input">
              <h4>Create Preference</h4>
              <Grid container>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <TextField
                    variant="outlined"
                    placeholder="Search Preference"
                    name="preference"
                    fullWidth
                    error={!!errors.preference}
                    helperText={
                      errors.preference ? errors.preference.message : ""
                    }
                    inputRef={register}
                    value={preference}
                    onChange={(e) => setPreference(e.target.value)}
                    onKeyUp={(e) =>
                      searchItems(
                        e.target.value,
                        minedPreferences,
                        setFilteredPreferences,
                        true
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() =>
                            toggleShowItems(showPreferences, setShowPreferences)
                          }
                        >
                          <KeyboardArrowDownIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* add button */}
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <AddPreference
                    preferenceName={preference}
                    selectedPreference={selectedPreference}
                    setSelectedPreference={setSelectedPreference}
                  />
                </Grid>
              </Grid>

              {/* preference dropdown */}
              <div className="form-dropdown">
                <FormControl component="fieldset">
                  <FormGroup>
                    {showPreferences ? (
                      <>
                        {minedPreferences &&
                          minedPreferences.length > 0 &&
                          minedPreferences.map((result) => (
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    fullWidth
                                    name={result}
                                    color="primary"
                                    onChange={(e) =>
                                      toggleItems(
                                        e.target.checked,
                                        result,
                                        selectedPreference,
                                        setSelectedPreference
                                      )
                                    }
                                  />
                                }
                                label={result.name.toUpperCase()}
                              />
                              <span className="preference_price">
                                ({result.unitPrice} NGN)
                              </span>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        {filteredPreferences &&
                          filteredPreferences.length > 0 &&
                          filteredPreferences.map((result, index) => (
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    fullWidth
                                    name={result}
                                    color="primary"
                                    onChange={(e) =>
                                      toggleItems(
                                        e.target.checked,
                                        result,
                                        selectedPreference,
                                        setSelectedPreference
                                      )
                                    }
                                  />
                                }
                                label={result.name.toUpperCase()}
                              />
                              <span className="preference_price">
                                ({result.unitPrice} NGN)
                              </span>
                            </>
                          ))}
                      </>
                    )}
                  </FormGroup>
                </FormControl>
              </div>

              {/* preference table */}
              <div className="form-table">
                {/* first segment */}
                <TableContainer component={Paper}>
                  <Table aria-label="a dense table">
                    {/* table head */}
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Price</TableCell>
                        <TableCell align="center" className="remove-btn">
                          <Button
                            onClick={() =>
                              resetTable(
                                setSelectedPreference,
                                setPreference,
                                null
                              )
                            }
                          >
                            <GiCancel />
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {/* retrieved and uneditable preferences */}
                    <TableBody>
                      {preferences &&
                        preferences.length > 0 &&
                        preferences.map((item) => (
                          <TableRow key={item.Preference.id}>
                            {/* name */}
                            <TableCell align="center">
                              {item.Preference.name}
                            </TableCell>

                            {/* price */}
                            <TableCell align="center">
                              {item.Preference.unitPrice} NGN
                            </TableCell>

                            {/* remove */}
                            <TableCell
                              align="center"
                              className="remove-btn"
                            ></TableCell>
                          </TableRow>
                        ))}
                    </TableBody>

                    {/* table body */}
                    <TableBody>
                      {selectedPreference &&
                        selectedPreference.length > 0 &&
                        selectedPreference.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCell align="center">{item.name}</TableCell>
                            <TableCell align="center">
                              {item.unitPrice} NGN
                            </TableCell>
                            <TableCell align="center" className="remove-btn">
                              <Button
                                onClick={() =>
                                  removeSelectedItem(
                                    index,
                                    selectedPreference,
                                    setSelectedPreference,
                                    null,
                                    null
                                  )
                                }
                              >
                                <GiCancel />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </section>

            {/* update preference button */}
            <Button onClick={() => submitPreferences()}>
              {preferencesUpdating ? preferencesUpdating : "Update Preference"}
            </Button>
          </Grid>

          {/* Add catgory */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <section id="menu_category_input">
              <h4>Add Category</h4>
              <Grid container>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <TextField
                    variant="outlined"
                    placeholder="Search Item Category"
                    name="itemCategory"
                    fullWidth
                    error={!!errors.itemCategory}
                    helperText={
                      errors.itemCategory ? errors.itemCategory.message : ""
                    }
                    inputRef={register}
                    value={itemCategory}
                    onChange={(e) => setItemCategory(e.target.value)}
                    onKeyUp={(e) =>
                      searchItems(
                        e.target.value,
                        minedCategories,
                        setFilteredItemCategory,
                        true
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() =>
                            toggleShowItems(
                              showItemCategories,
                              setShowItemCategories
                            )
                          }
                        >
                          <KeyboardArrowDownIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* add button */}
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <AddCategory
                    itemCategoryName={itemCategory}
                    selectedItemCategory={selectedItemCategory}
                    setSelectedItemCategory={setSelectedItemCategory}
                  />
                </Grid>
              </Grid>

              {/* category dropdown */}
              <div className="form-dropdown">
                <FormControl component="fieldset">
                  <FormGroup>
                    {showItemCategories ? (
                      <>
                        {minedCategories &&
                          minedCategories.length > 0 &&
                          minedCategories.map((result, index) => (
                            <>
                              <FormControlLabel
                                title={result.description}
                                control={
                                  <Checkbox
                                    fullWidth
                                    name={result}
                                    color="primary"
                                    onChange={(e) =>
                                      toggleItems(
                                        e.target.checked,
                                        result,
                                        selectedItemCategory,
                                        setSelectedItemCategory
                                      )
                                    }
                                  />
                                }
                                label={result.name.toUpperCase()}
                              />
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        {filteredItemCategory &&
                          filteredItemCategory.length > 0 &&
                          filteredItemCategory.map((result, index) => (
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    fullWidth
                                    name={result}
                                    color="primary"
                                    onChange={(e) =>
                                      toggleItems(
                                        e.target.checked,
                                        result,
                                        selectedItemCategory,
                                        setSelectedItemCategory
                                      )
                                    }
                                  />
                                }
                                label={result.name.toUpperCase()}
                              />
                            </>
                          ))}
                      </>
                    )}
                  </FormGroup>
                </FormControl>
              </div>

              {/* category table */}
              <div className="form-table">
                {/* first segment */}
                <TableContainer component={Paper}>
                  <Table aria-label="a dense table">
                    {/* table head */}
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center" className="remove-btn">
                          <Button
                            onClick={() =>
                              resetTable(
                                setSelectedItemCategory,
                                setItemCategory,
                                null
                              )
                            }
                          >
                            <GiCancel />
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {/* initially selected categories. Not Editable */}
                    <TableBody>
                      {retrievedCategories &&
                        retrievedCategories.length > 0 &&
                        retrievedCategories.map((item) => (
                          <TableRow
                            key={item.ItemCategory.id}
                            title={item.ItemCategory.description}
                          >
                            <TableCell align="center">
                              {item.ItemCategory.name}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        ))}
                    </TableBody>

                    {/* table body */}
                    <TableBody>
                      {selectedItemCategory &&
                        selectedItemCategory.length > 0 &&
                        selectedItemCategory.map((item, index) => (
                          <TableRow key={item.id} title={item.description}>
                            <TableCell align="center">{item.name}</TableCell>
                            <TableCell align="center" className="remove-btn">
                              <Button
                                onClick={() =>
                                  removeSelectedItem(
                                    index,
                                    selectedItemCategory,
                                    setSelectedItemCategory,
                                    null,
                                    null
                                  )
                                }
                              >
                                <GiCancel />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </section>
          </Grid>

          {/* add caption */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <section>
              <h4>Add Caption</h4>
              <TextField
                fullWidth
                variant="outlined"
                name="caption"
                error={!!errors.caption}
                helperText={errors.caption ? errors.caption.message : ""}
                inputRef={register}
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </section>
          </Grid>

          {/* Availability */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <section>
              <h4>Availability Status</h4>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="availabilty"
                  name="availabilty"
                  value={availability}
                  onChange={(e) => setAvailability(e.target.value)}
                >
                  {/* unavailable */}
                  <FormControlLabel
                    value={"false"}
                    control={<Radio color="primary" />}
                    label="Unavailable"
                  />

                  {/* Available */}
                  <FormControlLabel
                    value={"true"}
                    control={<Radio color="primary" />}
                    label="Available"
                  />
                </RadioGroup>
              </FormControl>
            </section>
          </Grid>

          {/* Special offer */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <section className="switch_input">
              <br />
              <FormControl component="fieldset">
                <FormGroup aria-label="specialOffer" row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={specialOffer}
                        color="primary"
                        onChange={(e) => setSpecialOffer(e.target.checked)}
                      />
                    }
                    label="Special Offer"
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
            </section>
          </Grid>

          {/* Display on App */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl component="fieldset" className="switch_input">
              <FormGroup aria-label="DisplayOnApp" row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={displayOnApp}
                      color="primary"
                      onChange={(e) => setDisplayOnApp(e.target.checked)}
                    />
                  }
                  label="Display on App"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>

        {/* feedback popup */}
        <FeedbackPopup
          condition={updatingMenuItem}
          additionalMessage="Returning..."
        />

        <section className="form-btn">
          <SubmitMenuItem label="Update" submitMT={handleSubmit(onSubmit)} />
        </section>
      </form>
    </>
  );
}
