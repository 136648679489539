import { Button, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";

export default function NextAndPrevButtons() {
  const { page, setPage } = useContext(HelpersContext);

  return (
    <>
      <Grid item xs={2} sm={2} md={2} lg={2} className="pagination_btns">
        {/* prev */}
        <Button
          onClick={() => {
            page > 1 ? setPage(page - 1) : alert("You Can't go below Page 1.");
          }}
        >
          Prev
        </Button>

        <p>Page:</p>
        <p>{page}</p>
        
        {/* next */}
        <Button onClick={() => setPage(page + 1)}>Next</Button>
      </Grid>
    </>
  );
}
