import React, { useContext } from "react";
import RecipeTable from "../../../pages/admin/CustomerRecipe/RicepeTable";
import { RecipeContext } from "../../../ContextAPI/RecipeContext";
import GeneralInfo from "./view/GeneralInfo";
import FormDetails from "./view/FormDetails";
import SubmitButton from "../CustomerMP/Create/itemPopup/SubmitButton";
import { SurveyContext } from "../../../ContextAPI/SurveyContext";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import { getDeliveryDays } from "./HelperFunction";
import SelectedDT from "./view/SelectedDT";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";

function Content() {
  // context
  const { roles } = useContext(HelpersContext);
  const { recipeDetails, recipeTableType } = useContext(RecipeContext);
  const { changeStatus } = useContext(SurveyContext);
  const deliveryDateTime = JSON.parse(recipeDetails.deliveryTime);

  const days = getDeliveryDays(deliveryDateTime);

  return (
    <>
      {recipeDetails ? (
        <>
          <div id="view_update_customer_recipe_details">
            <GeneralInfo />
            <FormDetails />

            <SelectedDT days={days} times={deliveryDateTime} />

            {recipeTableType === "COMPLETED" ? (
              ""
            ) : roles.includes("Create Plan for Customer") ? (
              <SubmitButton
                label={"Process Survey"}
                submitFunction={() =>
                  changeStatus(
                    recipeDetails.id,
                    "COMPLETED",
                    recipeDetails.User
                  )
                }
              />
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        "Details Not Set!"
      )}
    </>
  );
}

const ViewSurvey = () => {
  const { changing } = useContext(SurveyContext);

  return (
    <>
      <RecipeTable table={<Content />} title="Details" />

      {/* feedback popup */}
      <FeedbackPopup condition={changing} additionalMessage="Redirecting..." />
    </>
  );
};

export default ViewSurvey;
