import React from "react";
import CreateMenuItemForm from "../../components/adminComponents/CreateMenuItem/CreateMenuItemForm";
import DashboardFrame from "../../components/adminComponents/DashboardFrame";

export default function CreateMenuItem() {
  return (
    <>
      <DashboardFrame
        pageHeading="Menu Item"
        dashboardContent={<CreateMenuItemForm />}
      />
    </>
  );
}
