import { Button } from "@material-ui/core";
import React from "react";

export default function SubmitButton({
  label,
  submitFunction,
  altClass,
  icon,
}) {
  return (
    <>
      <section className={altClass ? "alt_class" : "form-btn"}>
        <Button onClick={() => submitFunction()}>
          {<span>{icon}</span>}
          {<span>{label}</span>}
        </Button>
      </section>
    </>
  );
}
