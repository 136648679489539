import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import LogoAnimation from "../../helpers/LogoAnimation";

export default function Content(props) {
  const history = useHistory();

  return (
    <Grid container id="mp_retrieving_meals">
      {/* message */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        id="message"
        className="form-btn"
      >
        <h1>{props.message}</h1>

        <h3>{props.reason}</h3>

        {/* display menu plans here */}
        <Button onClick={() => history.push("/dashboard")}>
          Return to Dashboard
        </Button>
      </Grid>

      {/* loader section */}
      <Grid item xs={12} sm={12} md={12} lg={12} id="loader">
        <LogoAnimation
          widthDivider="2"
          heightDivider="2"
          speed={0.01}
          imgSRC={null}
          color={"#05944f"}
          wireframe={true}
        />
      </Grid>
    </Grid>
  );
}
