import { InputAdornment, TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import { searchItems } from "./HelperFunction";
import SearchIcon from "@material-ui/icons/Search";

export default function SearchItems(props) {
  // context
  const { setSearchResult, setSearch, search } = useContext(HelpersContext);

  return (
    <>
      <TextField
        variant="outlined"
        placeholder="Search Item By Name..."
        name="search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyUp={(e) =>
          searchItems(e.target.value, props.item, setSearchResult, props.isName)
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}
