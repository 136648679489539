import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from "@material-ui/core";
import React from "react";

export default function Toggle({ toggle, setToggle, label }) {
  return (
    <>
      <Grid item xs={2} sm={2} md={1} lg={1} id="select_all">
        <FormControl component="fieldset" className="switch_input">
          <FormGroup aria-label="selectAll" row>
            <FormControlLabel
              control={
                <Switch
                  checked={toggle}
                  color="primary"
                  onChange={(e) => setToggle(e.target.checked)}
                />
              }
              label={label}
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </Grid>
    </>
  );
}
