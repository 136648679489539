import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { tableOptions } from "../../helpers/OrderHelpers";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import TableContent from "../../helpers/Table/TableContent";
import RecipeTable from "../../../pages/admin/CustomerRecipe/RicepeTable";
import { CustomerContext } from "../../../ContextAPI/CustomerContext";
import CustomerSearch from "../MenuPlanManagement/CustomerMPOrder/CustomerSearch";

function CreatedMPTable() {
  const { getMPCreatedForCustomer, customerMPs, customerMPLoading } =
    useContext(CustomerContext);
  const { customer } = useContext(HelpersContext);

  const columns = [
    "",
    "S/N",
    "STAFF’S NAME",
    "STAFF’S NUMBER",
    "DATE OF CREATION",
    "TIME OF CREATION",
    "NO. OF MEALS",
    "NO. OF DELIVERIES",
    "SUBSCRIPTION DURATION",
    "AMOUNT",
  ];

  // extract data for the table rows
  let extractedOrders;
  if (customerMPs.length > 0) {
    extractedOrders = customerMPs.map((order, index) => {
      return [
        "",
        index + 1,
        "",
        "",
        moment(order.createdAt).format("L"),
        moment(order.createdAt).format("LTS"),
        1,
        JSON.parse(order.deliveryTime).length,
        "SUBSCRIPTION DURATION",
        order.amount,
      ];
    });
  }

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter">
        {/* today */}
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{customerMPs.length}</span>
          </p>
        </Grid>

        <Grid item xs={2} sm={2} md={2} lg={2} className="filter_text"></Grid>

        {/* search */}
        <CustomerSearch getOrders={getMPCreatedForCustomer} />
      </Grid>

      {/* set table */}
      <TableContent
        loading={customerMPLoading}
        receivedData={customerMPs}
        heading={customer}
        tableData={extractedOrders}
        columns={columns}
        tableOptions={tableOptions}
      />
    </main>
  );
}

const CreatedMP = () => {
  return (
    <>
      <RecipeTable
        table={<CreatedMPTable />}
        title="Customer MP"
        pageHeading="Created meal plans"
      />
    </>
  );
};

export default CreatedMP;
