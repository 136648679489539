import { Grid } from "@material-ui/core";
import React from "react";

export default function TotalFigure({ filteredResult, orders }) {
  return (
    <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
      <p>
        All
        <span>{filteredResult ? filteredResult.length : orders.length}</span>
      </p>
    </Grid>
  );
}
