import React from "react";
import { Grid } from "@material-ui/core";

export default function ReceiptItemsDetails(props) {
  const menuItems = props.menuItems;

  return (
    <Grid container className="cart_item_details">
      {menuItems &&
        menuItems.length &&
        menuItems.map((item) => (
          <>
            <Grid item xs={1} sm={1} md={1} lg={1} id="qty">
              <p>({item.Cart.quantity}x)</p>
            </Grid>
            <Grid item xs={7} sm={7} md={7} lg={7}>
              <p>{item.Cart.MenuItem.itemName.toUpperCase()}</p>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} align="right">
              <p>₦{item.Cart.amount}</p>
            </Grid>
          </>
        ))}
        </Grid>
  );
}
