import React from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import ViewMealPlansContent from "../../../components/adminComponents/MealPlanMGT/ViewMealPlansContent";

export default function ViewMealPlans() {
  return (
    <>
      <DashboardFrame
        pageHeading="Manage Meal Plans"
        dashboardContent={<ViewMealPlansContent />}
      />
    </>
  );
}
