import React, { useRef } from "react";
import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ReactToPrint from "react-to-print";
import PrintoutHeaderWithIMG from "../../Order/IncomingOrder/PrintoutHeaderWithIMG";
import BasicDetails from "./BasicDetails";
import DeliveryDetails from "./DeliveryDetails";
import DeliveryAddress from "./DeliveryAddress";
import MealDetail from "./MealDetails";
import PriceAndDate from "./PriceAndDate";

export default function KitchenSlip({ order }) {
  const componentRef = useRef();

  return (
    <div>
      {/* button to trigger the printing */}
      <ReactToPrint
        trigger={() => <MenuItem>Kitchen Slip</MenuItem>}
        content={() => componentRef.current}
      />

      {/* component to be printed hidden from users */}
      <div style={{ display: "none" }}>
        <KitchenSlipContent order={order} ref={componentRef} />
      </div>
    </div>
  );
}

class KitchenSlipContent extends React.PureComponent {
  render() {
    const order = this.props.order;
    const recipeOrder = this.props.order.RecipeOrder;

    return (
      <>
        <main className="kitchen_slips">
          <PrintoutHeaderWithIMG />

          <Grid container className="order_details dashed_bottom">
            {/* basic details */}
            <BasicDetails order={order} />

            {/* delivery details */}
            <DeliveryDetails order={order} />

            {/* delivery address */}
            <DeliveryAddress order={order} />
          </Grid>

          {/* recipe details */}
          <MealDetail order={order} />

          {/* price and date */}
          <PriceAndDate total={recipeOrder.amount} />
        </main>
      </>
    );
  }
}
