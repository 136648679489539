// searchItem searches the inventories
const searchItem = (searchData, allInventories) => {
  // discontinue if there is no search yet
  if (searchData === null) return;

  // empty the previous search array if any
  const searchResult = [];
  const data = searchData.toLowerCase();

  // loop through the categories
  for (const inventory of allInventories) {
    //   convert to lower case
    const inventoryName = inventory.itemName.toLowerCase();
    // check if the search word or character matches
    if (
      [...inventoryName].includes(data) ||
      inventoryName === data ||
      inventoryName.split(" ").includes(data)
    ) {
      // add any match to the searchResult
      if (!searchResult.includes(inventoryName)) {
        searchResult.push(inventoryName);
      }
    }
  }

  return searchResult;
};

// get total price of the selectd inventories
const getTotalPrice = (selectedInventories, setTotalPrice) => {
  let total = 0;
  for (const inventory of selectedInventories) {
    // multiply the unit price by the quantity
    const inventoryByQuantityPrice =
      parseFloat(inventory.quantity) * parseFloat(inventory.price);

    // get the total price of the selected invenory
    total = parseFloat(total) + parseFloat(inventoryByQuantityPrice);
  }
  setTotalPrice(total);
};

// returnAllSelectedInventories helps to force the UI to update
const returnAllSelectedInventories = (
  index,
  selectedInventories,
  setSelectedInventories
) => {
  const result = selectedInventories.filter(() => index !== null);

  setSelectedInventories(result);
};

// setUpInventory adds an addon (initiaExtra or otherExtra) to the table when clicked
const setUpAddon = (
  selectedInventory,
  setAddon,
  otherExtra,
  inventories,
  setOtherExtra,
  initialExtra,
  selectedInventories,
  selectedAddons
) => {
  // terminate process if addon already exist
  for (const extra of initialExtra) {
    if (selectedInventory.toLowerCase() === extra.name.toLowerCase())
      return alert(`${extra.name.toUpperCase()} already exist in the Table`);
  }

  for (const inventory of selectedInventories) {
    if (selectedInventory.toLowerCase() === inventory.name.toLowerCase())
      return alert(
        `${inventory.name.toUpperCase()} already exist in the Table`
      );
  }

  if (selectedAddons) {
    for (const addon of selectedAddons) {
      if (selectedInventory.toLowerCase() === addon.name.toLowerCase())
        return alert(`${addon.name.toUpperCase()} already exist in the Table`);
    }
  }

  // add addon to the table
  setUpInventory(
    selectedInventory,
    setAddon,
    otherExtra,
    inventories,
    setOtherExtra
  );
};

// setUpInventory adds an inventory to the table when clicked
const setUpInventory = (
  selectedInventory,
  setInventory,
  selectedInventories,
  inventories,
  setSelectedInventories,
  setTotalPrice
) => {
  // terminate process if addon already exist
  for (const extra of selectedInventories) {
    if (selectedInventory.toLowerCase() === extra.name.toLowerCase())
      return alert(`${extra.name.toUpperCase()} already exist in the Table`);
  }

  // set the search field to the clicked inventory
  setInventory(selectedInventory);

  // let the selectedInventoriesArray collect the initial value of the selectedInventories
  // this is so that the former data will not be lost
  let selectedInventoriesArray = selectedInventories;

  // find full details of the selectedInventory
  for (const inventory of inventories) {
    if (selectedInventory.toLowerCase() === inventory.itemName.toLowerCase()) {
      // collect the data of that inventory
      const newInventory = {
        id: inventory.id,
        name: inventory.itemName,
        price: inventory.unitPrice,
        totalPrice: inventory.unitPrice,
        quantity: "1",
        extra: false,
      };

      selectedInventoriesArray.push(newInventory);
    }
  }

  // this is just to ensure that duplication never happens
  returnUniqueInventories(selectedInventories, setSelectedInventories);

  // get the total price
  if (setTotalPrice) {
    getTotalPrice(selectedInventories, setTotalPrice);
  }
};

// handleInventoryPriceChange manipulates each unitPrice with its total price when entered and returns the sum total
const handleInventoryPriceChange = (
  e,
  index,
  selectedInventories,
  setTotalPrice
) => {
  // selected inventory
  const inventory = selectedInventories[index];

  inventory.totalPrice = e.target.value;
  inventory.price =
    parseFloat(inventory.totalPrice) / parseFloat(inventory.quantity);

  // get the total price
  if ((selectedInventories, setTotalPrice)) {
    getTotalPrice(selectedInventories, setTotalPrice);
  }
};

// clearSelectedInventories deletes all inventories already in the table
const resetTable = (setSelectedInventories, setInventory, setTotalPrice) => {
  setSelectedInventories([]);

  if (setInventory) {
    setInventory("");
  }

  if (setTotalPrice) {
    setTotalPrice(0);
  }
};

// removeSelectedInventory deletes an inventory when selected from the table
const removeSelectedItem = (
  index,
  selectedInventories,
  setSelectedInventories,
  setTotalPrice,
  totalPrice
) => {
  // filter out the inventory with the given index
  const result = selectedInventories.filter(
    (inventory) => index !== selectedInventories.indexOf(inventory)
  );

  // subtract the removed price from the total time
  if (totalPrice && setTotalPrice) {
    setTotalPrice(
      parseFloat(totalPrice) - parseFloat(selectedInventories[index].totalPrice)
    );
  }

  setSelectedInventories(result);
};

// reduce quantity of an inventory when clicked
const reduceQty = (
  e,
  index,
  selectedInventories,
  setSelectedInventories,
  setTotalPrice
) => {
  if (e) {
    e.preventDefault();
  }

  let inventory = selectedInventories[index];

  // terminate process if the quantity is one
  if (
    inventory.quantity === parseFloat(1) ||
    inventory.quantity === 1 ||
    inventory.quantity === "1"
  )
    return;

  inventory.quantity = parseFloat(inventory.quantity) - 1;
  inventory.totalPrice =
    parseFloat(inventory.price) * parseFloat(inventory.quantity);

  // do this in order to update the SelectedInventories on the page
  returnAllSelectedInventories(
    index,
    selectedInventories,
    setSelectedInventories
  );

  if (selectedInventories && setTotalPrice) {
    getTotalPrice(selectedInventories, setTotalPrice);
  }
};

// increase quantity of an inventory when clicked
const increaseQty = (
  e,
  index,
  selectedInventories,
  setSelectedInventories,
  setTotalPrice
) => {
  if (e) {
    e.preventDefault();
  }

  let inventory = selectedInventories[index];

  inventory.quantity = parseFloat(inventory.quantity) + 1;
  inventory.totalPrice =
    parseFloat(inventory.price) * parseFloat(inventory.quantity);

  // do this in order to update the SelectedInventories on the page
  returnAllSelectedInventories(
    index,
    selectedInventories,
    setSelectedInventories
  );

  if (selectedInventories && setTotalPrice) {
    getTotalPrice(selectedInventories, setTotalPrice);
  }
};

// returnUniqueInventories filteres through a list of selected inventories and remove duplicates
const returnUniqueInventories = (
  selectedInventoriesArray,
  setSelectedInventories
) => {
  // filter the search to include only uinque values
  // get all the unique IDs of the selectedInventoriesArray
  let selectedInventoriesIds = [];
  for (const inventory of selectedInventoriesArray) {
    if (!selectedInventoriesIds.includes(inventory.id)) {
      selectedInventoriesIds.push(inventory.id);
    }
  }

  // filter the selectedInventories to remove duplicates
  let filteredSelectedInventories = [];
  for (const inventory of selectedInventoriesArray) {
    if (selectedInventoriesIds.includes(inventory.id)) {
      filteredSelectedInventories.push(inventory);
      selectedInventoriesIds.shift();
    }
  }

  setSelectedInventories(filteredSelectedInventories);
};

// adjustExtra adds extra of a selected inventory to the addon
const adjustExtra = (
  index,
  selectedInventories,
  setInitialExtra,
  initialExtra
) => {
  const inventory = selectedInventories[index];

  if (inventory.extra === false) {
    inventory.extra = true;

    // collect the data of that inventory
    const newInventory = {
      id: inventory.id,
      name: inventory.name,
      price: inventory.price,
      totalPrice: inventory.price,
      quantity: "1",
    };

    initialExtra.push(newInventory);

    // this is just to ensure that duplication never happens
    returnUniqueInventories(initialExtra, setInitialExtra);

    returnAllSelectedInventories(index, initialExtra, setInitialExtra);
  } else {
    inventory.extra = false;
    const inventoryId = inventory.id;

    // remove the inventory
    const result = initialExtra.filter((inv) => inv.id !== inventoryId);
    setInitialExtra(result);
  }
};

// toggleShowPriceInput is used to show a particular inventory total price input when clicked
const toggleShowPriceInput = (
  e,
  index,
  showPriceInput,
  setShowPriceInput,
  setPriceInputIndex
) => {
  e.preventDefault();

  if (index === null) return;

  if (showPriceInput) {
    setShowPriceInput(false);
    setPriceInputIndex(null);
  } else {
    setShowPriceInput(true);
    setPriceInputIndex(index);
  }
};

// getInventoryDescription returns a particular inventory's description
const getInventoryDescription = (inventoryName, inventories) => {
  for (const inventory of inventories) {
    if (inventoryName.toLowerCase() === inventory.itemName.toLowerCase()) {
      return inventory.description;
    }
  }
};

// clearAddons deletes all selected addons
const clearAddons = (setInitialExtra, setOtherExtra) => {
  setInitialExtra([]);
  setOtherExtra([]);
};

// search Preference or categories
const lookUpItems = (searchData, allData) => {
  // discontinue if there is no search yet
  if (searchData === null || allData === []) return;

  // empty the previous search array if any
  const searchResult = [];
  const data = searchData.toLowerCase();

  // loop through all Preferences
  for (const item of allData) {
    //   convert to lower case
    const itemName = item.name.toLowerCase();
    // check if the search word or character matches
    if (
      [...itemName].includes(data) ||
      itemName === data ||
      itemName.split(" ").includes(data)
    ) {
      // add any match to the searchResult
      if (!searchResult.includes(item)) {
        searchResult.push(item);
      }
    }
  }

  return searchResult;
};

// toggleItems is used to add or remove a preference or category from the table
const toggleItems = (
  value,
  currentPreference,
  selectedPreference,
  setSelectedPreference,
  order
) => {
  // add preference
  if (value === true) {
    // add the current item to the array
    if (order) {
      // use here if you are handling POS order
      selectedPreference.push({
        id: currentPreference.id,
        name: currentPreference.Inventory.itemName,
        price: currentPreference.price,
        totalPrice: currentPreference.price,
        originalQuantity: currentPreference.quantity,
        quantity: 1,
        isExtra: currentPreference.isExtra,
      });
    } else {
      selectedPreference.push(currentPreference);
    }

    // this ensures that there is no duplicate preferences and auto updates the ui
    returnUniqueInventories(selectedPreference, setSelectedPreference);
  }

  // remove preference
  if (value === false) {
    const result = selectedPreference.filter(
      (item) => item.id !== currentPreference.id
    );

    setSelectedPreference(result);
  }
};

// toggleShowItems is used to show or hide items
const toggleShowItems = (
  showPreferences,
  setShowPreferences,
  setDiscountAmount,
  setDiscountPercent,
  setOldPrice,
  setNewPrice
) => {
  if (showPreferences === false) {
    setShowPreferences(true);
  } else {
    setShowPreferences(false);

    // reset values
    if (setDiscountAmount && setDiscountPercent && setOldPrice && setNewPrice) {
      setDiscountAmount(0);
      setDiscountPercent(0);
      setOldPrice(0);
      setNewPrice(0);
    }
  }
};

// validate the image to check if an image is okay
const validateImage = (imageUrl, setImageCheck) => {
  if (!imageUrl) {
    setImageCheck("empty");
  }

  if (imageUrl) {
    // check if what is entered is an image
    if (imageUrl.type.split("/")[0] === "image") {
      // check if the image is not more than 70kb
      if (imageUrl.size <= 71680) {
        setImageCheck(false);
      } else {
        setImageCheck("setSize");
      }
    } else {
      setImageCheck("setType");
    }
  }
};

// applyDiscount to discount the total price
const applyDiscount = (
  discountAmount,
  discountPercent,
  setOldPrice,
  totalPrice,
  setNewPrice,
  discount
) => {
  if (parseFloat(discountAmount) > parseFloat(totalPrice)) return;

  let percentage = discountPercent / 100;

  // terminate operation if percentage or discount isn't valid
  if (!percentage || percentage <= 0 || !discount) return;

  // setup backup
  setOldPrice(totalPrice);

  // subtract the percentage from the sum total
  setNewPrice(totalPrice - parseFloat(totalPrice) * parseFloat(percentage));
};

// filter menu items
const filterItems = (items, status) => {
  return items.filter((item) => item.activated === status);
};

export {
  searchItem,
  setUpInventory,
  handleInventoryPriceChange,
  resetTable,
  removeSelectedItem,
  reduceQty,
  increaseQty,
  adjustExtra,
  toggleShowPriceInput,
  getInventoryDescription,
  clearAddons,
  setUpAddon,
  lookUpItems,
  toggleItems,
  returnUniqueInventories,
  toggleShowItems,
  validateImage,
  applyDiscount,
  returnAllSelectedInventories,
  filterItems,
};
