import { Button, Grid } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import { MPManagementContext } from "../../../../ContextAPI/MPManagementContext";
import { useHistory } from "react-router-dom";
import { MPEditMealsSetup } from "../../../helpers/MenuPlanHelper";
import LogoAnimation from "../../../helpers/LogoAnimation";
import FeedbackPopup from "../../../helpers/FeedbackPopup";
import SelectMT from "../AddMPDetails/SelectMT/SelectMT";

export default function RetrievingMPDetailsContent(props) {
  const planDetails = props.planDetails;
  const history = useHistory();

  // context
  const {
    setMPBasicInfo,
    setSavedMPItems,
    MPAddMealRedirecting,
    setMPAddMealRedirecting,
  } = useContext(HelpersContext);
  const { MPDetails, gettingMPDetails, getMenuPlanMealsById } =
    useContext(MPManagementContext);

  useEffect(() => {
    // set basic details for meal plan retrieved
    setMPBasicInfo({
      planName: planDetails.name,
      menuPlan: planDetails,
    });

    // get the meals in the plan
    getMenuPlanMealsById(planDetails.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container id="mp_retrieving_meals">
      {/* login form */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        id="message"
        className="form-btn"
      >
        {/* Plan Name */}
        <h1>{planDetails.name.toUpperCase()}</h1>

        {/* No of Items found */}
        <h3>
          <span>{MPDetails ? MPDetails.length : ""}</span> Items found
        </h3>

        {/* Add Items */}
        <SelectMT useMenuItem={true} />

        {/* display menu plans here */}
        <Button
          onClick={() =>
            gettingMPDetails === "found"
              ? MPEditMealsSetup(
                  MPDetails,
                  setSavedMPItems,
                  history,
                  setMPAddMealRedirecting
                )
              : alert(
                  "Plan Data Not Retrieved. Please Wait, Refresh Or Return Back Later."
                )
          }
        >
          {gettingMPDetails === "processing"
            ? "Getting The Meals Already In This Plan For You. Please Wait..."
            : gettingMPDetails === "found"
            ? "View Meals"
            : gettingMPDetails === "failed"
            ? "An Error Occured. There might be no items available. Also, Check your Connection"
            : ""}
        </Button>

        <Button
          onClick={() => {
            window.location.href = "/view-menu-plans";
          }}
        >
          Return To Meal Plans
        </Button>
      </Grid>

      {/* loader section */}
      <Grid item xs={12} sm={12} md={12} lg={12} id="loader">
        <LogoAnimation
          widthDivider="2"
          heightDivider="2"
          speed={0.01}
          imgSRC={null}
          color={"#05944f"}
          wireframe={true}
        />
      </Grid>

      {/* feedback popup */}
      <FeedbackPopup
        condition={MPAddMealRedirecting}
        additionalMessage="Redirecting..."
      />
    </Grid>
  );
}
