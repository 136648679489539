import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";

export default function EachDayHeader(props) {
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} id="each_day_header">
        <h1>{moment(props.day).format("dddd")}</h1>
        <p>{moment(props.day).format("(Do MMMM, YYYY)")}</p>
      </Grid>
    </>
  );
}
