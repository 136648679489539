import { Grid } from "@material-ui/core";
import React from "react";
import Info from "./Info";
import TimingAndStatus from "./TimingAndStatus";

export default function GeneralInfo() {
  return (
    <>
      <Grid container id="general_info">
        <Grid item xs={10} sm={10} md={5} lg={5}>
          <Info />
        </Grid>

        <div className="vertical_rule"></div>

        {/* timing details */}
        <Grid item xs={5} sm={5} md={5} lg={5} id="time_details">
          <TimingAndStatus />
        </Grid>
      </Grid>
    </>
  );
}
