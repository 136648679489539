import React from "react";
import DashboardFrame from "../../../../components/adminComponents/DashboardFrame";
import CustomerMPOrderTable from "../../../../components/adminComponents/MenuPlanManagement/CustomerMPOrder/CustomerMPOrderTable";
import SettingsFrame from "../../../../components/adminComponents/Settings/SettingsFrame";

export default function CustomerMPOrder() {
  return (
    <main className="table_page_frame">
      <DashboardFrame
        pageHeading="Menu Plan Order Management"
        dashboardContent={
          <SettingsFrame
            firstLabel="Customer's Orders"
            firstContent={<CustomerMPOrderTable />}
          />
        }
      />
    </main>
  );
}
