import { Grid, TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SubmitMenuItem from "../CreateMenuItem/SubmitMenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import { NotificationContext } from "../../../ContextAPI/NotificationContext";
import Input from "./Input";

export default function Body({ createAndEarn }) {
  const {
    description,
    setDescription,
    selectedCustomers,
    setTitle,
    title,
    setTopic,
    topic,
    show,
  } = useContext(HelpersContext);
  const { sendNotification, sendToAll } = useContext(NotificationContext);

  const schema = yup.object().shape({
    topic: yup.string().required(),
    title: yup.string().required(),
    description: yup.string().required(),
  });

  // get what we need from useform()
  const { register, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <Grid container id="body">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {show ? (
          <Input
            placeholder={"Write a topic..."}
            name={"topic"}
            error={errors.topic}
            register={register}
            value={topic}
            setValue={setTopic}
          />
        ) : (
          ""
        )}

        <Input
          placeholder={"Write a title..."}
          name={"title"}
          error={errors.title}
          register={register}
          value={title}
          setValue={setTitle}
          autoFocus={true}
        />

        <TextField
          placeholder="Write a message..."
          multiline
          rows={15}
          variant="outlined"
          fullWidth
          name="description"
          error={!!errors.description}
          helperText={errors.description ? errors.description.message : ""}
          inputRef={register}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        {/* button */}
        <section className="form-btn">
          {selectedCustomers &&
          selectedCustomers.length &&
          description !== "" &&
          title !== "" ? (
            <SubmitMenuItem label="Send" submitMT={sendNotification} />
          ) : (
            ""
          )}

          {createAndEarn ? (
            <SubmitMenuItem label="Send" submitMT={sendNotification} />
          ) : (
            ""
          )}

          {/* send to all */}
          {show && topic !== "" && description !== "" && title !== "" ? (
            <SubmitMenuItem label="Send to All" submitMT={sendToAll} />
          ) : (
            ""
          )}

          {/* refresh page */}
          {createAndEarn ? (
            ""
          ) : (
            <h1
              onClick={() =>
                (window.location.href = window.location.href.split("#")[0])
              }
            >
              <DeleteIcon style={{ fontSize: 50 }} />
            </h1>
          )}
        </section>
      </Grid>
    </Grid>
  );
}
