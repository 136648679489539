import React from "react";
import DashboardFrame from "../../../../components/adminComponents/DashboardFrame";
import ActiveMPOrdersTable from "../../../../components/adminComponents/MenuPlanManagement/ActiveMPOrders/ActiveMPOrdersTable";
import SettingsFrame from "../../../../components/adminComponents/Settings/SettingsFrame";

export default function ActiveMPOrders() {
  return (
    <main className="table_page_frame">
      <DashboardFrame
        pageHeading="Menu Plan Order"
        dashboardContent={
          <SettingsFrame
            firstLabel="Active Orders"
            firstContent={<ActiveMPOrdersTable />}
          />
        }
      />
    </main>
  );
}
