// external imports
import React, { createContext, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};
let branchId = null;

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
  branchId = staff.branchId;
}

const BASE_URL = getBaseUrl();

// create MenuItem context to manage MenuItem here
export const MenuItemContext = createContext();

export default function MenuItemContextProvider(props) {
  // set initial state
  const [menuItemLoading, setLoading] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);
  const [addons, setAddons] = useState([]);
  const [addonsLoading, setAddonsLoading] = useState(false);
  const [preferences, setPreferences] = useState([]);
  const [preferencesLoading, setPreferencesLoading] = useState(false);
  const [menuItemInventories, setmenuItemInventories] = useState(null);
  const [allMenuItems, setAllMenuItems] = useState([]);
  const [gettingAllMT, setGettingAllMT] = useState(false);

  // make API call to get only activated and available MenuItems
  const getMenuItems = (page) => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}menuitems?branchId=${branchId}&page=${page}`;

    // get MenuItems
    axios({ url, method, headers })
      .then((result) => {
        setMenuItems(result.data.data.items);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  }

  // make API call to get both activated, deactivated, unavailable and available MenuItems
  const getAllMenuItems = (page) => {
    setGettingAllMT("processing");

    const method = "get",
      url = `${BASE_URL}menuitems/all?branchId=${branchId}&page=${page}`;

    // get MenuItems
    axios({ url, method, headers })
      .then((result) => {
        setAllMenuItems(result.data.data.items);
        setGettingAllMT("found");
      })
      .catch((error) => {
        error = new Error();
        setGettingAllMT("failed");
      });
  }

  // AddMenuItem
  const addMenuItem = (
    inputs,
    selectedInventories,
    selectedPreference,
    selectedItemCategory,
    initialExtra,
    otherExtra,
    imageUrl,
    availability,
    specialOffer,
    displayOnApp
  ) => {
    setLoading("processing");

    // validate image
    if (!imageUrl) return alert("No Image Selected");

    // check for empty arrays
    if (selectedInventories.length <= 0 || selectedItemCategory.length <= 0)
      return alert("You must select atleast one Inventory and one category");

    // process data
    // selected invenories
    let inventories = [];
    for (const inventory of selectedInventories) {
      const newInventory = {
        id: inventory.id,
        price: parseFloat(inventory.totalPrice),
        quantity: parseFloat(inventory.quantity),
        extra: inventory.extra,
      };

      inventories.push(newInventory);
    }

    // itemCategories
    let itemCategories = [];
    for (const category of selectedItemCategory) {
      itemCategories.push(category.id);
    }

    // preferences
    let preferences = [];
    for (const preference of selectedPreference) {
      preferences.push(preference.id);
    }

    // addons
    // initialExtra
    let addons = [];
    for (const addon of initialExtra) {
      const newAddon = {
        id: addon.id,
        price: parseFloat(addon.totalPrice),
        quantity: parseFloat(addon.quantity),
      };

      addons.push(newAddon);
    }

    // otherExtra
    for (const addon of otherExtra) {
      const newAddon = {
        id: addon.id,
        price: parseFloat(addon.totalPrice),
        quantity: parseFloat(addon.quantity),
      };

      addons.push(newAddon);
    }

    // options
    // set the data
    const options = {
      method: "post",
      url: `${BASE_URL}menuitems`,
      headers,
      data: {
        itemName: inputs.itemName,
        imageUrl,
        inventories,
        itemCategories,
        description: inputs.description,
        caption: inputs.caption,
        discount: parseFloat(inputs.discountAmount),
        discountPercent: parseFloat(inputs.discountPercent),
        activated: displayOnApp,
        available: availability,
        specialOffer,
        addons,
        preferences,
      },
    };

    // make API call
    axios(options)
      .then(() => {
        setLoading("successful");

        setTimeout(() => {
          window.location.href = window.location.href.split("#")[0];
        }, 3000);
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  // filterByCategory
  const filterByCategory = (
    id,
    categotyName,
    setSelectedCategory,
    setShowCategories,
    setSearch
  ) => {
    setSearch("");
    setSelectedCategory(categotyName);
    setShowCategories(false);

    setLoading("searching");

    const method = "get",
      url = `${BASE_URL}menuitems/search?categoryId=${id}&page=1`;

    // get MenuItems
    axios({ url, method, headers })
      .then((result) => {
        setFilteredMenuItems(result.data.data.items);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading(false);
      });
  };

  // filter by price, category and menuItemType
  const filterMenuItems = (data) => {
    // clear the search field and seaerch result if any
    // this is so that the filter result can be applied
    data.setSearch("");
    data.setSearchResult(null);

    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}menuitems/filter?branchId=${branchId}&page=1&category=${data.category}&minPrice=${data.minPrice}&maxPrice=${data.maxPrice}&menuItemType=${data.menuItemType}`;

    // get MenuItems
    axios({ url, method, headers })
      .then((result) => {
        setMenuItems(result.data.data.items);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  // get addons for a particular menu item
  const getMenuItemAddons = (id) => {
    setAddonsLoading("processing");

    const method = "get",
      url = `${BASE_URL}menuitems/${id}/addons`;

    // get addons
    axios({ url, method, headers })
      .then((result) => {
        setAddons(result.data.data);
        setAddonsLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setAddonsLoading("failed");
      });
  };

  // get Preferences for a particular menu item
  const getMenuItemPreferences = (id) => {
    setPreferencesLoading("processing");

    const method = "get",
      url = `${BASE_URL}menuitems/${id}/preferences`;

    // get preferences
    axios({ url, method, headers })
      .then((result) => {
        setPreferences(result.data.data);
        setPreferencesLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setPreferencesLoading("failed");
      });
  };

  // get a single menu item
  const getMenuItemInventories = (id) => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}menuitems/${id}/inventories`;

    // get MenuItem
    axios({ url, method, headers })
      .then((result) => {
        setmenuItemInventories(result.data.data);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  return (
    <MenuItemContext.Provider
      value={{
        menuItemLoading,
        menuItems,
        addMenuItem,
        filterByCategory,
        filteredMenuItems,
        filterMenuItems,
        setMenuItems,
        addons,
        getMenuItemAddons,
        addonsLoading,
        getMenuItemPreferences,
        preferences,
        preferencesLoading,
        getMenuItemInventories,
        menuItemInventories,
        allMenuItems,
        gettingAllMT,
        getAllMenuItems,
        getMenuItems
      }}
    >
      {props.children}
    </MenuItemContext.Provider>
  );
}
