// external imports
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};
let branchId = null;

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
  branchId = staff.branchId;
}

const BASE_URL = getBaseUrl();

// create MenuPlanCategory context to manage MenuPlanCategory here
export const MenuPlanCategoryContext = createContext();

export default function MenuPlanCategoryContextProvider(props) {
  // set initial state
  const [menuPlanCategoryLoading, setLoading] = useState(false);
  const [menuPlanCategories, setMenuPlanCategory] = useState([]);
  const [updatingCategory, setUpdatingCategory] = useState(false);

  // make API call to get all the MenuPlanCategory
  useEffect(() => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}menuplans/categories/${branchId}`;

    // get MenuPlanCategory
    axios({ url, method, headers })
      .then((result) => {
        setMenuPlanCategory(result.data.data.data.rows);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  }, []);

  // Add Category
  const addMenuPlanCategory = (inputs, imageUrl, setCategory) => {
    setLoading("processing");

    // options
    const options = {
      method: "post",
      url: `${BASE_URL}menuplans/category`,
      headers,
      data: {
        name: inputs.name,
        branchId: staff.branchId,
        imageurl: imageUrl,
      },
    };

    // make API call
    axios(options)
      .then(() => {
        setCategory(inputs.name);

        setLoading("successful");
        alert("Successful!");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
        alert("Failed! There may be a duplicate or a network lag");
      });
  };

  // return value if it is passed
  const isValue = (value) => {
    if (value) {
      return value;
    }
  };

  // editmpcategory
  const editMPCategory = (input) => {
    setUpdatingCategory("processsing");

    const method = "put",
      url = `${BASE_URL}menuplans/category`,
      data = {
        id: isValue(input.id),
        name: isValue(input.name),
        imageurl: isValue(input.imgBase64),
        description: isValue(input.description),
      };

    axios({ method, url, data, headers })
      .then(() => {
        setUpdatingCategory("successful");

        window.location.href = window.location.href.split("#")[0];
      })
      .catch((error) => {
        error = new Error();
        setUpdatingCategory("failed");
      });
  };

  return (
    <MenuPlanCategoryContext.Provider
      value={{
        menuPlanCategoryLoading,
        menuPlanCategories,
        addMenuPlanCategory,
        updatingCategory,
        editMPCategory,
      }}
    >
      {props.children}
    </MenuPlanCategoryContext.Provider>
  );
}
