import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Cookies from "universal-cookie";
import LogoAnimation from "../../helpers/LogoAnimation";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import NoPermissionsFound from "../../../pages/admin/PageNotFound/NoPermissionsFound";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");

export default function DashboardLPContent() {
  const { roles } = useContext(HelpersContext);

  return (
    <div id="dashboard-body-content">
      <Typography variant="h4" gutterBottom>
        Hi, {staff.firstName} &#128075;
      </Typography>

      <Typography paragraph>Mange your system with full aunthencity</Typography>

      <section id="welcome_image">
        {roles && roles.length ? (
          <LogoAnimation
            widthDivider="2"
            heightDivider="2"
            speed={0.01}
            imgSRC={null}
            color={"#05944f"}
            wireframe={true}
          />
        ) : (
          <NoPermissionsFound />
        )}
      </section>
    </div>
  );
}
