import React from "react";
import { Grid } from "@material-ui/core";

export default function SpaceInBetweenLayout(props) {
  return (
    <Grid container className={props.mainClass}>
      {/* left */}
      <Grid item xs={6} sm={6} md={6} lg={6} id={props.idA} >
        {props.childrenA}
        <p>{props.textA}</p>
      </Grid>

      {/* right */}
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        id={props.idB}
        align="right"
      >
        {props.childrenB}
        <p>{props.textB}</p>
      </Grid>
    </Grid>
  );
}
