import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import POSFilter from "./POSFilter";
import { FaFilter } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";

export default function POSFilterDrawer(props) {
  const [state, setState] = React.useState(false);

  const toggleDrawer = (update) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(update);
  };

  return (
    <div id="pos_filter_drawer">
      {/* drawer trigger */}
      <Button onClick={toggleDrawer(true)}>
        <span>Filter</span> <FaFilter />
      </Button>

      {/* drawer body */}
      <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
        {/* heading */}
        <section id="pos_filter_drawer_header">
          <h2>Filter Menu Items</h2>

          <aside onClick={toggleDrawer(false)}>
            <h2>
              <IoCloseSharp />
            </h2>
          </aside>
        </section>

        {/* content */}
        <POSFilter menuItems={props.menuItems} />
      </Drawer>
    </div>
  );
}
