import { TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { HelpersContext } from "../../../../../../ContextAPI/HelpersContext";
import NumberFormaterPrefix from "../../../../../helpers/NumberFormaterPrefix";
import AgreeToTerms from "./AgreeToTerms";

export default function Modify() {
  const { agreed, modifyDescription, setModifyDescription, modifiedPrice } =
    useContext(HelpersContext);

  return (
    <section id="modify">
      <header>
        <div>MODIFY MEAL</div>

        <div>
          <AgreeToTerms />
        </div>
      </header>

      {agreed ? (
        <>
          <main>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              name="description"
              placeholder="Leave a note (e.g Extra sauce, less spice)"
              value={modifyDescription}
              onChange={(e) => setModifyDescription(e.target.value)}
            />
          </main>

          <div id="price">
            <p>Price</p>
            <p>
              <NumberFormaterPrefix value={modifiedPrice} />
            </p>
          </div>
        </>
      ) : (
        ""
      )}
    </section>
  );
}
