import { TextField } from "@material-ui/core";
import React from "react";

export default function Input({
  placeholder,
  name,
  error,
  register,
  value,
  setValue,
  autoFocus,
}) {
  return (
    <>
      <TextField
        placeholder={placeholder}
        variant="outlined"
        fullWidth
        name={name}
        error={!!error}
        helperText={error ? error.message : ""}
        inputRef={register}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoFocus={autoFocus}
      />
    </>
  );
}
