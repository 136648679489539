import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Grid, TextField, InputAdornment } from "@material-ui/core/";
import { UtilsContext } from "../../../../ContextAPI/UtilsContext";
import FeedbackPopup from "../../../helpers/FeedbackPopup";

export default function DeliveryArea() {
  // context
  const { addDeliveryArea, addingDA } = useContext(UtilsContext);

  // initial states
  const [area, setArea] = useState("");
  const [deliveryFee, setDeliveryFee] = useState("");
  const [addedDeliveryArea, setAddedDeliveryArea] = useState([]);

  // yup schema for validation
  const schema = yup.object().shape({
    area: yup.string().required(),
    deliveryFee: yup.number().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    addDeliveryArea(
      data,
      addedDeliveryArea,
      setAddedDeliveryArea,
      setArea,
      setDeliveryFee
    );
  };

  return (
    <form id="delivery_area" onSubmit={handleSubmit(onSubmit)}>
      <main>
        <section>
          {/* area_delivery_fee_heading */}
          <Grid container id="area_delivery_fee_heading">
            {/* area */}
            <Grid item xs={6} sm={6} md={6} lg={6} className="area">
              <h4>Area</h4>
            </Grid>

            {/* delivery */}
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <h4>Delivery Fee</h4>
            </Grid>
          </Grid>

          {/* area_delivery_fee_body */}
          <Grid container id="area_delivery_fee_body">
            {/* area */}
            <Grid item xs={6} sm={6} md={6} lg={6} className="area">
              <TextField
                fullWidth
                variant="outlined"
                name="area"
                error={!!errors.area}
                helperText={errors.area ? errors.area.message : ""}
                inputRef={register}
                value={area}
                onChange={(e) => setArea(e.target.value)}
              />
            </Grid>

            {/* delivery */}
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                variant="outlined"
                name="deliveryFee"
                error={!!errors.deliveryFee}
                helperText={
                  errors.deliveryFee ? errors.deliveryFee.message : ""
                }
                inputRef={register}
                value={deliveryFee}
                onChange={(e) => setDeliveryFee(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">NGN</InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* button */}
            <Grid item xs={2} sm={2} md={2} lg={2} className="form-btn">
              <Button type="submit">Create</Button>
            </Grid>
          </Grid>

          {/* feedback popup */}
          <FeedbackPopup
            condition={addingDA}
            additionalMessage="Page Reloads in a moment..."
          />
        </section>

        {addedDeliveryArea.length ? (
          <div>
            <h4>You Just Added The Following Delivery Area(s):</h4>
          </div>
        ) : (
          ""
        )}

        {/* Delivery Area that has just been added preview */}
        <section id="added_delivery_area">
          {addedDeliveryArea && addedDeliveryArea.length ? (
            addedDeliveryArea.map((deliveryArea) => (
              <AreaInput deliveryArea={deliveryArea} />
            ))
          ) : (
            <p>Delivery Areas Just Added Will Show Here Below </p>
          )}
        </section>
      </main>
    </form>
  );
}

// area input
function AreaInput(props) {
  const deliveryArea = props.deliveryArea;

  return (
    <>
      <Grid container id="area_delivery_fee_body">
        {/* area */}
        <Grid item xs={6} sm={6} md={6} lg={6} className="area">
          <TextField
            fullWidth
            variant="outlined"
            disabled
            value={deliveryArea.address}
          />
        </Grid>

        {/* delivery fee */}
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <TextField
            fullWidth
            variant="outlined"
            disabled
            value={deliveryArea.amount}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">NGN</InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* button */}
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          className="form-btn"
          id="disabled_btn"
        >
          <Button disabled>Added</Button>
        </Grid>
      </Grid>
    </>
  );
}
