import React from "react";
import CreateMenuPlanForm from "../../../components/adminComponents/CreateMenuPlan/CreateMenuPlanForm";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";

export default function CreateMenuPlan() {
  return (
    <>
      <DashboardFrame
        pageHeading="Menu Plan"
        dashboardContent={<CreateMenuPlanForm />}
      />
    </>
  );
}
