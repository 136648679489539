// external imports
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";

// local imports
import { returnUniqueInventories } from "../components/helpers/MenuItemHelper";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
}

const BASE_URL = getBaseUrl();

// create MenuItemPreference context to manage MenuItemPreference here
export const MenuItemPreferenceContext = createContext();

export default function MenuItemPreferenceContextProvider(props) {
  // set initial state
  const [menuItemPreferenceLoading, setLoading] = useState(false);
  const [menuItemPreferences, setMenuItemPreferences] = useState([]);

  // make API call to get all the MenuItemPreferences
  useEffect(() => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}menuitems/preferences`;

    // get MenuItemPreferences
    axios({ url, method, headers })
      .then((result) => {
        setMenuItemPreferences(result.data.data.rows);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  }, []);

  // Add Preference
  const addPreference = (data, selectedPreference, setSelectedPreference) => {
    setLoading("processing");

    // get config for API call
    const method = "post",
      url = `${BASE_URL}menuitems/preferences`;

    // make API call
    axios({ url, method, headers, data })
      .then((result) => {
        // get the newly added preference details
        const item = result.data.data;
        const newPreference = {
          id: item.id,
          name: item.name,
          unitPrice: item.unitPrice,
        };

        // add the newPreference to the table
        selectedPreference.push(newPreference);
        // this ensures that there are no duplicates and forces the newPreference to update the UI
        returnUniqueInventories(selectedPreference, setSelectedPreference);

        alert("Successful!")
      })
      .catch((error) => {
        error = new Error();
        alert("Failed! There may be a duplicate or a network lag");
      });
  };

  return (
    <MenuItemPreferenceContext.Provider
      value={{ menuItemPreferenceLoading, menuItemPreferences, addPreference }}
    >
      {props.children}
    </MenuItemPreferenceContext.Provider>
  );
}
