import React, { useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { CgMoreVertical } from "react-icons/cg";
import { useHistory } from "react-router-dom";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import { MPManagementContext } from "../../../ContextAPI/MPManagementContext";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import MPOrderKitchen from "./PrintSlips/MPOrderKitchen";
import MPOrderReceipt from "./PrintSlips/MPOrderReceipt";
import { getMPStatus } from "../../helpers/MenuPlanHelper";

export default function MPOrderActions({ order }) {
  // context
  const { MPChanging } = useContext(MPManagementContext);
  const { deliveryDetails, setDeliveryDetails } = useContext(HelpersContext);

  useEffect(() => {
    setDeliveryDetails(JSON.parse(order.deliveryTimeJson));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const status = getMPStatus(deliveryDetails, order.deliveryTime);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    // hold the current menu plan order
    localStorage.setItem("MenuPlanOrder", JSON.stringify(order));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <CgMoreVertical />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* see all details of an order */}
        <MenuItem onClick={() => history.push("/view-a-customer-mp-order")}>
          View Details
        </MenuItem>

        <MPOrderKitchen order={order} status={status} />

        <MPOrderReceipt order={order} />
      </Menu>

      {/* feedback popup */}
      <FeedbackPopup
        condition={MPChanging}
        additionalMessage="Refresh To See Update..."
      />
    </div>
  );
}
