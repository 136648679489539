import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core/";
import { GoCalendar } from "react-icons/go";
import MenuPlanCalendar from "./MenuPlanCalendar";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function MyMenuPlanCalendar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper" : undefined;

  return (
    <div>
      {/* popover button */}
      <Button aria-describedby={id} onClick={handleClick}>
        <GoCalendar />
        <span>Calendar</span>
      </Button>

      {/* popover frame */}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            {/* content */}
            <div className={classes.paper}>
                <MenuPlanCalendar />
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
