import React from "react";
import { Button, Grid, Hidden } from "@material-ui/core";
import AdminLoginForm from "../../components/adminComponents/adminLogin/AdminLoginForm";
import Cookies from "universal-cookie";
import Logout from "../../components/helpers/Logout";
import LogoAnimation from "../../components/helpers/LogoAnimation";

const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");

export default function AdminLogin() {
  return (
    <>
      <Grid container id="admin_login">
        {/* Welcome section */}
        <Grid item xs={12} sm={12} md={6} lg={6} id="welcome_message">
          <Hidden smDown>
            <LogoAnimation
              widthDivider="2"
              heightDivider="2"
              speed={0.01}
              imgSRC="https://res.cloudinary.com/byterestaurant/image/upload/v1638041535/ooma%20web/ooma_logo_3_hjbssd.png"
            />
          </Hidden>
          <div>
            <h1>Welcome!</h1>
            {staff ? (
              <p>You are Logged In Already...</p>
            ) : (
              <p>Enter Your login details.</p>
            )}
          </div>
        </Grid>

        {/* login form */}
        <Grid item xs={12} sm={12} md={6} lg={6} id="login_form">
          {staff ? (
            <section id="login_redirect">
              Go To <Button href="/dashboard"> Dashboard</Button> Or <Logout />
            </section>
          ) : (
            <AdminLoginForm />
          )}
        </Grid>
      </Grid>
    </>
  );
}
