import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { CgMoreVertical } from "react-icons/cg";
import { MenuItem } from "@material-ui/core";

export default function Actions({ recipe }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <CgMoreVertical />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            localStorage.setItem("createAndEarnRecipe", JSON.stringify(recipe));
            window.location.href = "/view-create-and-earn-details";
          }}
        >
          View
        </MenuItem>
        <MenuItem>Print</MenuItem>
      </Menu>
    </div>
  );
}
