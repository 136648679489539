import React, { useContext, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core/";
import Slide from "@material-ui/core/Slide";
import MealItem from "../../MenuItemsMGT/ViewMenuItems/MealItem";
import ItemPopupAccordion from "./itemPopup/AddonsPreferences/ItemPopupAccordion";
import Modify from "./itemPopup/Modify/Modify";
import SelectDays from "./itemPopup/DateAndTimeMGT/SelectDays";
import Heading from "./itemPopup/Heading";
import SelectedTime from "./itemPopup/SelectedTime";
import { doNothing, getItemTotal, getMonthsInPlan } from "../HelperFunctions";
import { HelpersContext } from "../../../../ContextAPI/HelpersContext";
import { MenuItemContext } from "../../../../ContextAPI/MenuItemContext";
import SubmitMenuItem from "../../CreateMenuItem/SubmitMenuItem";
import { CustomerContext } from "../../../../ContextAPI/CustomerContext";
import CustomerSearch from "../../MenuPlanManagement/CustomerMPOrder/CustomerSearch";
import NumberFormaterSuffix from "../../../helpers/NumberFormaterSuffix";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ItemPopup({ menuItem }) {
  // context
  const { setMonthsInItem, setPage, customerId } = useContext(HelpersContext);
  const { clearData } = useContext(CustomerContext);

  // states
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");

    // get the list months in a plan and clear the data from previous item if any
    getMonthsInPlan(menuItem.availableDays, setMonthsInItem);
    clearData();
  };

  const handleClose = () => {
    setOpen(false);
    setPage(1);
    clearData();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={2} className="item_popup">
      <section onClick={handleClickOpen()}>
        <MealItem menuItem={menuItem} isView={true} isPlan={true} />
      </section>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        disableBackdropClick={true}
        TransitionComponent={Transition}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <Heading closePopup={handleClose} isFinalClose={true} />
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* show the search customer widget if not selected yet */}
            {customerId ? (
              <Content menuItem={menuItem} closePopup={handleClose} />
            ) : (
              <CustomerSearch getOrders={doNothing} />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

function Content({ menuItem, closePopup }) {
  // context
  const {
    agreed,
    customerMPTotalPrice,
    setCustomerMPTotalPrice,
    modifiedPrice,
    customerMPAddons,
    customerMPPreferences,
    setCustomerMPPreview,
    customerMPPreview,
    itemsBeforePreview,
  } = useContext(HelpersContext);

  const {
    addons,
    addonsLoading,
    getMenuItemAddons,
    preferences,
    preferencesLoading,
    getMenuItemPreferences,
  } = useContext(MenuItemContext);

  const { clearData } = useContext(CustomerContext);

  // add to Preview
  const addToPreview = () => {
    setCustomerMPPreview([...customerMPPreview, ...itemsBeforePreview]);

    clearData();

    closePopup();
  };

  // get all addons and preferences here
  useEffect(() => {
    getMenuItemAddons(menuItem.menuitemid);
    getMenuItemPreferences(menuItem.menuitemid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get the total price
  useEffect(() => {
    const total = getItemTotal(
      customerMPAddons,
      customerMPPreferences,
      menuItem.amount
    );

    const otherPrice = agreed ? modifiedPrice : 0;

    setCustomerMPTotalPrice(parseFloat(total) + parseFloat(otherPrice));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerMPAddons, customerMPPreferences, agreed]);

  return (
    <>
      <main id="adjust_meal_item_details">
        {/* Item name and price */}
        <section id="meal_title">
          <h1>{menuItem.itemName}</h1>
          <p>
            <NumberFormaterSuffix value={customerMPTotalPrice} />
          </p>
        </section>

        {/* Addons */}
        <ItemPopupAccordion
          content="ADD-ONS"
          items={addons}
          loading={addonsLoading}
        />

        {/* preferences */}
        <ItemPopupAccordion
          content="PREFERENCE"
          items={preferences}
          loading={preferencesLoading}
        />

        {/* modify meal with instructions */}
        <Modify />

        {/* show days available. Pick days and time of choice */}
        <SelectDays
          availableDays={menuItem.availableDays}
          menuItem={menuItem}
        />

        {/* show selected time */}
        <SelectedTime menuItem={menuItem} />

        {/* add to plan button */}
        <section className="form-btn">
          <SubmitMenuItem label={"Add to plan"} submitMT={addToPreview} />
        </section>
      </main>
    </>
  );
}
