import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Button, Grid } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import AvailabilityAndDisplay from "./AvailabilityAndDisplay";
import NumberFormaterSuffix from "../../../helpers/NumberFormaterSuffix";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

export default function MealItem(props) {
  const classes = useStyles();
  const isPlan = props.isPlan;
  const menuItem = props.menuItem;
  const isView = props.isView;
  const history = useHistory();

  return (
    <Grid
      item
      xs={!isView ? 6 : 12}
      sm={!isView ? 4 : 12}
      md={!isView ? 3 : 12}
      lg={!isView ? 3 : 12}
      className={isPlan ? "customer_meal_items" : ""}
    >
      <Card className={classes.root} key={menuItem.id}>
        <CardActionArea>
          {/* item image */}
          <CardMedia
            component="img"
            alt={menuItem.itemName}
            height="100"
            image={menuItem.imageUrl}
            title={menuItem.description}
          />

          {/* item name */}
          <CardContent title={menuItem.itemName}>
            <Typography variant="body2" color="textSecondary" component="p">
              {menuItem.itemName} <br />
              <NumberFormaterSuffix value={menuItem.amount} />
            </Typography>
          </CardContent>
        </CardActionArea>

        {/* price */}
        <CardActions title={menuItem.description}>
          {isView ? (
            <NumberFormaterSuffix value={menuItem.amount} />
          ) : (
            <>
              <Button onClick={() => history.push("/edit-menu-item", menuItem)}>
                <span className="emoji_style">&#9997;</span>
              </Button>

              {/* availability and display on app */}
              <AvailabilityAndDisplay
                item={menuItem}
                url={`menuitems/${menuItem.id}`}
              />
            </>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
}
