import React, { useRef } from "react";
import SpaceInBetweenLayout from "../../../helpers/SpaceInBetweenLayout";
import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ReactToPrint from "react-to-print";
import ReceiptItemsDetails from "./ReceiptItemsDetails";
import PriceAndPayementDetails from "./PriceAndPayementDetails";
import PrintoutHeader from "./PrintoutHeader";

export default function Receipt(props) {
  const componentRef = useRef();
  const order = props.order;
  const customer = props.customer;
  
  return (
    <div>
      {/* button to trigger the printing */}
      <ReactToPrint
        trigger={() => <MenuItem>Receipt</MenuItem>}
        content={() => componentRef.current}
      />

      {/* component to be printed hidden from users */}
      <div style={{ display: "none" }}>
        {order.orderChannel === "POS" ? (
          <POSReceiptContent
            order={order}
            ref={componentRef}
            customer={customer}
          />
        ) : (
          <AppReceiptContent
            area={props.area}
            order={order}
            ref={componentRef}
            customer={customer}
          />
        )}
      </div>
    </div>
  );
}

class POSReceiptContent extends React.PureComponent {
  render() {
    const order = this.props.order;
    const menuItems = this.props.order.menuitemorders.MenuItemOrderDetails;
    let customer;
    if (this.props.customer) {
      customer = this.props.customer;
    } else {
      customer = order.orderBy;
    }

    return (
      <>
        <main className="receipt">
          <PrintoutHeader total={this.props.order.menuitemorders.total} />

          {/* order details */}
          <Grid container className="order_details">
            <SpaceInBetweenLayout textA={order.orderName} textB="" />

            <SpaceInBetweenLayout textA="Order ID:" textB={order.orderRef} />

            <SpaceInBetweenLayout
              textA="No. of items:"
              textB={menuItems.length}
            />
          </Grid>

          <Grid container className="waiter">
            <SpaceInBetweenLayout textA="Waiter" textB={customer.fullName} />
          </Grid>

          {/* cart details */}
          <ReceiptItemsDetails menuItems={menuItems} />

          {/* price and payment*/}
          <PriceAndPayementDetails order={order} />
        </main>
      </>
    );
  }
}

class AppReceiptContent extends React.PureComponent {
  render() {
    const order = this.props.order;
    const menuItems = order.menuitemorders.MenuItemOrderDetails;
    let customer;
    if (this.props.customer) {
      customer = this.props.customer;
    } else {
      customer = order.orderBy;
    }

    const otherDetails = order.menuitemorders;

    return (
      <>
        <main className="receipt">
          <PrintoutHeader total={this.props.order.menuitemorders.total} />

          {/* order details */}
          <Grid container className="order_details">
            <SpaceInBetweenLayout
              textA="Customer’s name:"
              textB={customer.fullName}
            />

            <SpaceInBetweenLayout
              textA="Customer’s number:"
              textB={customer.phoneNumber}
            />

            <SpaceInBetweenLayout
              textA="Recipent’s name:"
              textB={otherDetails.friendName}
            />

            <SpaceInBetweenLayout
              textA="Recipent’s number:"
              textB={otherDetails.friendPhoneNumber}
            />
            <SpaceInBetweenLayout textA="Order ID:" textB={order.orderRef} />

            <SpaceInBetweenLayout
              textA="No. of items:"
              textB={menuItems.length}
            />

            <SpaceInBetweenLayout
              textA="Delivery area:"
              textB={this.props.area ? this.props.area.address : ""}
            />
          </Grid>

          <Grid container id="delivery_address">
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <h3>
                <span>Delivery Address</span>
              </h3>
              <h3>{otherDetails.deliveryAddress}</h3>
            </Grid>
          </Grid>

          {/* cart details */}
          <ReceiptItemsDetails menuItems={menuItems} />

          {/* price and payment*/}
          <PriceAndPayementDetails order={order} />
        </main>
      </>
    );
  }
}
