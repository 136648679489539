import React from "react";
import POSHome from "../../components/adminComponents/CreatePOSOrder/POSHome";
import DashboardFrame from "../../components/adminComponents/DashboardFrame";

export default function CreatePOSOrder() {
  return (
    <>
      <DashboardFrame pageHeading="POS" dashboardContent={<POSHome />} />
    </>
  );
}
