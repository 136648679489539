import React, { useContext } from "react";
import { MPManagementContext } from "../../../../ContextAPI/MPManagementContext";
import { sortByStatus } from "../../../helpers/MenuPlanHelper";
import MPOrdersMGT from "../MPOrdersMGT";

export default function PendingMPOrdersTable() {
  // context
  const { MPOrders } = useContext(MPManagementContext);

  let pendingOrders = sortByStatus(MPOrders, "Pending");

  const orders = [...pendingOrders];

  console.log(MPOrders);

  return (
    <>
      <MPOrdersMGT orders={orders} group={"Pending"} />
    </>
  );
}
