import React, { useContext, useEffect } from "react";
import RecipeTable from "../../../pages/admin/CustomerRecipe/RicepeTable";
import GeneralInfo from "./View/GeneralInfo";
import UpdateForm from "../CustomerRecipe/ViewAndUpdateDetails/Form/UpdateForm";
import { RecipeContext } from "../../../ContextAPI/RecipeContext";
import PaymentAndDelivery from "../CustomerRecipe/ViewAndUpdateDetails/PaymentAndDelivery";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";

function Content() {
  // context
  const { recipeDetails } = useContext(RecipeContext);
  const { setOrderStatus } = useContext(HelpersContext);
  const isRecipeOrder = true;

  useEffect(() => {
    setOrderStatus(recipeDetails.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {recipeDetails ? (
        <>
          <div id="view_update_customer_recipe_details">
            <GeneralInfo />
            <UpdateForm isRecipeOrder={isRecipeOrder} />
            <PaymentAndDelivery isRecipeOrder={isRecipeOrder} />
          </div>
        </>
      ) : (
        "Details Not Set!"
      )}
    </>
  );
}

const ViewRecipeOrder = () => {
  const { changing } = useContext(RecipeContext);
  return (
    <>
      <RecipeTable table={<Content />} title="Details" />

      {/* feedback popup */}
      <FeedbackPopup condition={changing} additionalMessage="Redirecting..." />
    </>
  );
};

export default ViewRecipeOrder;
