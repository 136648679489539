import React, { useContext } from "react";
import RecipeTable from "../../../pages/admin/CustomerRecipe/RicepeTable";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { sortOrders, tableOptions } from "../../helpers/OrderHelpers";
import TableContent from "../../helpers/Table/TableContent";
import { RecipeContext } from "../../../ContextAPI/RecipeContext";
import Actions from "./ActionsMenu/Actions";
import Menu from "./ActionsMenu/Menu";
import NumberFormaterPrefix from "../../helpers/NumberFormaterPrefix";

function CompletedTable() {
  // context
  const { recipes, recipeLoading } = useContext(RecipeContext);
  
  // seperate order according to status
  let completedOrders = sortOrders(recipes, "READY TO PAY", "RECIPE");
  let paidOrders = sortOrders(recipes, "PAID", "RECIPE");

  const orders = [...completedOrders, ...paidOrders]

  // table headings
  const columns = [
    "",
    "S/N",
    "STAFF NAME",
    "STAFF NUMBER",
    "CUSTOMER NAME",
    "CUSTOMER’S NUMBER",
    "ORDER ID",
    "RECIPE TITLE",
    "NO. OF DELIVERIES",
    "DATE OF REQUEST",
    "TIME OF REQUEST",
    "DATE OF PRICING",
    "TIME OF PRICING",
    "AMOUNT",
    "PAYMENT METHOD",
    "PAYMENT STATUS",
    "PAYMENT GATEWAY",
    "STATUS",
  ];

  // extract data for the table rows
  const extractedOrders = orders.map((order, index) => {
    const staff = order.staffDetail ? order.staffDetail : {};
    const customer = order.orderedByDetail;
    const payment = order.Payments[0];
    const dateCreated = order.createdAt;
    const dateUpdated = order.updatedAt;

    return [
      <Actions
        viewDetails={
          <Menu
            link={"/view-recipe-details"}
            details={order}
            tableType="COMPLETED"
          />
        }
      />,
      index + 1,
      staff.fullName,
      staff.phoneNumber,
      customer.fullName,
      customer.phoneNumber,
      order.id,
      order.Recipe.mealName,
      order.RecipeOrderDeliveryDates.length,
      moment(dateCreated).format("L"),
      moment(dateCreated).format("HH:mm:ss"),
      moment(dateUpdated).format("L"),
      moment(dateUpdated).format("HH:mm:ss"),
      <NumberFormaterPrefix value={order.amount} />,
      payment ? payment.paymentMethod : "",
      payment ? payment.status : "",
      payment ? payment.gateWayType : "",
      order.status,
    ];
  });

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter" id="pos_filter">
        {/* today */}
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{orders.length}</span>
          </p>
        </Grid>
      </Grid>

      {/* set table */}
      <TableContent
        loading={recipeLoading}
        receivedData={orders}
        heading="Completed Order"
        tableData={extractedOrders}
        columns={columns}
        tableOptions={tableOptions}
      />
    </main>
  );
}

const Completed = () => {
  return (
    <>
      <RecipeTable
        table={<CompletedTable />}
        title="Completed"
        pageHeading="Recipe requests"
      />
    </>
  );
};

export default Completed;
