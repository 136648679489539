import React, { useContext } from "react";
import EachAdminSideMenu from "./AdminSideMenu/EachAdminSideMenu";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PersonIcon from "@material-ui/icons/Person";
import Divider from "@material-ui/core/Divider";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CategoryIcon from "@material-ui/icons/Category";
import SettingsIcon from "@material-ui/icons/Settings";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EmojiFoodBeverageIcon from "@material-ui/icons/EmojiFoodBeverage";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import NotificationsIcon from "@material-ui/icons/Notifications";
import KitchenIcon from "@material-ui/icons/Kitchen";
import PaymentIcon from '@material-ui/icons/Payment';

export default function AdminSideMenu() {
  const { roles } = useContext(HelpersContext);

  return (
    <>
      <Divider />

      {/* pos */}
      {roles.includes("Waiter") ? (
        <EachAdminSideMenu
          navIcon={<CreditCardIcon />}
          navTitle="POS"
          links={[["Place Order", "/pos"]]}
        />
      ) : (
        ""
      )}

      {/* instant order */}
      {roles.includes("Order management 1") ? (
        <EachAdminSideMenu
          navIcon={<LocalMallIcon />}
          navTitle="Order"
          links={[
            ["Pending", "/incoming-order"],
            ["Active", "/active-order"],
          ]}
        />
      ) : (
        ""
      )}

      {/* recipe */}
      {roles.includes("Recipe Request Management 1") ? (
        <EachAdminSideMenu
          navIcon={<DoubleArrowIcon />}
          navTitle="Recipe"
          links={[
            ["Pending", "/pending-recipe"],
            ["Completed", "/completed-recipe"],
          ]}
        />
      ) : (
        ""
      )}

      {/* recipe order */}
      {roles.includes("Order Management 3") ? (
        <EachAdminSideMenu
          navIcon={<LocalMallIcon />}
          navTitle="Recipe Order"
          links={[
            ["Pending", "/pending-recipe-order-management"],
            ["Active", "/recipe-order-management"],
            ["History", "/history-recipe-order-management"],
          ]}
        />
      ) : (
        ""
      )}

      {/* create and Earn recipe */}
      {roles.includes("Admin 2") ? (
        <EachAdminSideMenu
          navIcon={<KitchenIcon />}
          navTitle="Create and Earn"
          links={[["View Requests", "/create-and-earn"]]}
        />
      ) : (
        ""
      )}

      {/* meal plan order */}
      {roles.includes("Order management 1") ||
      roles.includes("Survey Management") ||
      roles.includes("Create Plan for Customer") ? (
        <EachAdminSideMenu
          navIcon={<ScheduleIcon />}
          navTitle="MP Order"
          links={
            roles.includes("Order management 1") &&
            roles.includes("Survey Management") &&
            roles.includes("Create Plan for Customer")
              ? [
                  ["Pending", "/menu-plan-pending-orders"],
                  ["Active", "/menu-plan-active-orders"],
                  ["History", "/menu-plan-history-orders"],

                  ["Survey", "/survey-management"],
                ]
              : roles.includes("Order management 1")
              ? [
                  ["Pending", "/menu-plan-pending-orders"],
                  ["Active", "/menu-plan-active-orders"],
                  ["History", "/menu-plan-history-orders"],
                ]
              : roles.includes("Survey Management") ||
                roles.includes("Create Plan for Customer")
              ? [["Survey", "/survey-management"]]
              : ""
          }
        />
      ) : (
        ""
      )}

      {/* sales */}
      {roles.includes("Cashier 1") ? (
        <EachAdminSideMenu
          navIcon={<ReceiptIcon />}
          navTitle="Sales"
          links={[
            ["Instant Order", "/instant-order-sales"],
            ["Menu Plan Order", "/meal-plan-order-sales"],
          ]}
        />
      ) : (
        ""
      )}

      {/* payment */}
      {roles.includes("Cashier 1") ? (
        <EachAdminSideMenu
          navIcon={<PaymentIcon />}
          navTitle="Payments"
          links={[["Verify", "/payment-verification"]]}
        />
      ) : (
        ""
      )}

      {roles.includes("Chef 1") ? (
        <>
          {/* Menu Item */}
          <EachAdminSideMenu
            navIcon={<FastfoodIcon />}
            navTitle="Menu Item"
            links={[
              ["Create", "/create-menu-item"],
              ["View", "/menu-items-management"],
              ["Inactive", "/inactive-menu-items-management"],
            ]}
          />

          {/* Menu Plan */}
          <EachAdminSideMenu
            navIcon={<MenuBookIcon />}
            navTitle="MP Menu"
            links={[
              ["Upload meals", "/create-menu-plan"],
              ["View", "/view-menu-plans"],
              ["Inactive", "/inactive-view-menu-plans"],
            ]}
          />

          {/* Category MGT */}
          <EachAdminSideMenu
            navIcon={<CategoryIcon />}
            navTitle="Category MGT"
            links={[
              ["Menu Item", "#"],
              ["Menu Plan", "/view-menu-plans-categories"],
            ]}
          />
        </>
      ) : (
        ""
      )}

      {/* Customer Menu Plan */}
      {roles.includes("Create Plan for Customer") ? (
        <EachAdminSideMenu
          navIcon={<EmojiFoodBeverageIcon />}
          navTitle="Meal Plan"
          links={[
            ["Create", "/create-customer-meal-plan"],
            ["View", ""],
          ]}
        />
      ) : (
        ""
      )}

      {/* inventory */}
      {roles.includes("Holding 1") ||
      roles.includes("Holding 2") ||
      roles.includes("Holding 3") ? (
        <EachAdminSideMenu
          navIcon={<StoreMallDirectoryIcon />}
          navTitle="Inventory"
          links={[
            ["Create", "/create-inventory"],
            ["View", "/manage-inventory"],
          ]}
        />
      ) : (
        ""
      )}

      {/* staff */}
      {roles.includes("Chef 1") ? (
        <EachAdminSideMenu
          navIcon={<PersonIcon />}
          navTitle="Staff"
          links={[
            ["Create Staff", "/create-staff"],
            ["Create Role", "/create-role"],
          ]}
        />
      ) : (
        ""
      )}

      {/* customer mgt */}
      {roles.includes("Customer Service 1") ||
      roles.includes("Order management 2") ? (
        <EachAdminSideMenu
          navIcon={<PeopleAltIcon />}
          navTitle="Customer"
          links={[
            ["Sign Up", "/view-customers"],
            ["Instant Order", "/customer-orders"],
            ["Menu Plan Order", "/menu-plan-customer-orders"],
          ]}
        />
      ) : (
        ""
      )}

      <Divider />

      {/* settings */}
      {roles.includes("Admin 1") ? (
        <EachAdminSideMenu
          navIcon={<SettingsIcon />}
          navTitle="Settings"
          links={[
            ["Profile", "/profile-setting"],
            ["Order", "/order-and-schedule"],
            ["Delivery Address", "/branch-setup"],
          ]}
          innerMenu={
            <EachAdminSideMenu
              navTitle="Branch"
              links={[
                ["Create", "/create-branch"],
                ["Manage Branches", "#"],
              ]}
            />
          }
        />
      ) : (
        ""
      )}

      {/* settings */}
      {roles.includes("Admin 2") ? (
        <EachAdminSideMenu
          navIcon={<NotificationsIcon />}
          navTitle="Notification"
          links={[["Send", "/send-notification"]]}
        />
      ) : (
        ""
      )}
    </>
  );
}
