// toggleShowCategories
const toggleShowCategories = (showCategories, setShowCategories) => {
  if (showCategories === null) {
    setShowCategories(true);
  } else {
    setShowCategories(null);
  }
};

// updateCategory
const updateCategory = (
  category,
  setInventoryCategory,
  showCategories,
  setShowCategories,
  setFilteredCategory
) => {
  setInventoryCategory(category);
  toggleShowCategories(showCategories, setShowCategories);
  setFilteredCategory([])
};

export {
  toggleShowCategories,
  updateCategory,
};
