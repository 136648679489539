import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useContext } from "react";
import { RecipeContext } from "../../../../ContextAPI/RecipeContext";
import HalfRowLayout from "../../../helpers/HalfRowLayout";
import SubmitButton from "../../CustomerMP/Create/itemPopup/SubmitButton";
import { BiPrinter } from "react-icons/bi";

export default function GeneralInfo() {
  const { recipeDetails, recipeTableType } = useContext(RecipeContext);
  const customer = recipeDetails.orderedByDetail;
  const dateCreated = recipeDetails.createdAt;
  const dateUpdated = recipeDetails.updatedAt;
  const staff = recipeDetails.staffDetail;

  return (
    <>
      <Grid container id="general_info">
        <Grid item xs={10} sm={10} md={6} lg={6}>
          <Grid container>
            <HalfRowLayout className="text_key" text="CUSTOMER NAME:" />
            <HalfRowLayout text={customer.fullName} />

            <HalfRowLayout className="text_key" text="CUSTOMER NUMBER:" />
            <HalfRowLayout text={customer.phoneNumber} />

            <HalfRowLayout className="text_key" text="ORDER ID:" />
            <HalfRowLayout text={recipeDetails.id} />

            <HalfRowLayout className="text_key" text="COMPLETED BY:" />
            <HalfRowLayout
              text={staff ? `${staff.fullName} -- ${staff.phoneNumber}` : ""}
            />

            <HalfRowLayout className="text_key" text="STATUS:" />
            <HalfRowLayout text={recipeDetails.status} />

            <HalfRowLayout className="text_key" text="DATE OF REQUEST:" />
            <HalfRowLayout text={moment(dateCreated).format("L")} />

            <HalfRowLayout className="text_key" text="TIME OF REQUEST:" />
            <HalfRowLayout text={moment(dateCreated).format("LTS")} />

            <HalfRowLayout className="text_key" text="DATE OF PRICING:" />
            <HalfRowLayout
              text={
                dateUpdated && recipeTableType === "COMPLETED"
                  ? moment(dateUpdated).format("L")
                  : ""
              }
            />

            <HalfRowLayout className="text_key" text="TIME OF PRICING:" />
            <HalfRowLayout
              text={
                dateUpdated && recipeTableType === "COMPLETED"
                  ? moment(dateUpdated).format("LTS")
                  : ""
              }
            />

            <HalfRowLayout className="text_key" text="TOTAL DELIVERIES:" />
            <HalfRowLayout
              text={recipeDetails.RecipeOrderDeliveryDates.length}
            />

            <HalfRowLayout className="text_key" text="RECIPIENT’S NAME:" />
            <HalfRowLayout text={recipeDetails.friendName} />

            <HalfRowLayout className="text_key" text="RECIPIENT’S NUMBER:" />
            <HalfRowLayout text={recipeDetails.friendPhoneNumber} />
          </Grid>
        </Grid>

        <Grid item xs={2} sm={2} md={6} lg={6} align="right">
          <div className="print_btn">
            <SubmitButton
              icon={<BiPrinter />}
              label="Print page"
              altClass={"alt_class"}
              submitFunction={() => window.print()}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
