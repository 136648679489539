const imgToBase64 = (imageUrl, setUploadPercentage, setImgBase64) => {
  setUploadPercentage(0);

  if (imageUrl) {
    // instantiate new FIleReader Class
    let imageData = new FileReader();

    // set the path of the image
    imageData.readAsDataURL(imageUrl);

    // when the path is set, make the API call
    imageData.onload = (e) => {
      // set the data
      setImgBase64(e.target.result);

      // get the loaded image and total image
      const { loaded, total } = e;

      // get the percentage by multiplying the loaded by 100
      let percent = Math.floor((loaded * 100) / total);

      // keep showing progress report while it is not completed yet
      if (percent < 100) setUploadPercentage(percent);
    };
  }
};

// search Items
const searchItems = (searchString, items, setSearchResult, isName) => {
  // clear search result if the search field is empty
  if (searchString === "") {
    setSearchResult([]);
  }

  // discontinue if there is no search yet
  if (searchString === null || searchString === "" || items === []) return;

  // empty the previous search array if any
  setSearchResult([]);
  let results = [];

  // create a regular expression pattern for the search string
  const pattern = new RegExp(searchString, "gi");

  // loop through all items
  for (const item of items) {
    // setup the item name depending on how the item is named
    let itemName;
    if (isName) {
      itemName = item.name;
    } else {
      itemName = item.itemName;
    }

    // check if the search word or character matches
    if (pattern.test(itemName)) {
      results.push(item);
    }
  }

  setSearchResult(results);
};

export { imgToBase64, searchItems };
