import React, { useContext } from "react";
import RecipeTable from "../../../pages/admin/CustomerRecipe/RicepeTable";
import GeneralInfo from "./ViewAndUpdateDetails/GeneralInfo";
import UpdateForm from "./ViewAndUpdateDetails/Form/UpdateForm";
import SelectedDT from "./ViewAndUpdateDetails/DeliveryTime/SelectedDT";
import { Price } from "./ViewAndUpdateDetails/Price";
import { RecipeContext } from "../../../ContextAPI/RecipeContext";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import PaymentAndDelivery from "./ViewAndUpdateDetails/PaymentAndDelivery";
import SubmitMenuItem from "../CreateMenuItem/SubmitMenuItem";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";

function Content() {
  // context
  const { recipeDetails, updateRecipePrice, recipeTableType } =
    useContext(RecipeContext);
  const { roles } = useContext(HelpersContext);

  return (
    <>
      {recipeDetails ? (
        <>
          <div id="view_update_customer_recipe_details">
            <GeneralInfo />
            <UpdateForm />
            <SelectedDT />
            <Price />
            {recipeTableType === "COMPLETED" ? (
              <PaymentAndDelivery />
            ) : roles.includes("Recipe Request Management 2") ? (
              <section className="form-btn">
                <SubmitMenuItem
                  label="Submit"
                  submitMT={() => updateRecipePrice(recipeDetails.recipeId)}
                />
              </section>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        "Details Not Set!"
      )}
    </>
  );
}

const ViewAndUpdateDetails = () => {
  // context
  const { updatingPrice } = useContext(RecipeContext);

  return (
    <>
      <RecipeTable table={<Content />} title="Details" />

      {/* feedback popup */}
      <FeedbackPopup
        condition={updatingPrice}
        additionalMessage="Redirecting..."
      />
    </>
  );
};

export default ViewAndUpdateDetails;
