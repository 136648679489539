// external imports
import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";
import { HelpersContext } from "./HelpersContext";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");

let headers = {};
let branchId = null;

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
  branchId = staff.branchId;
}

const BASE_URL_2 = getBaseUrl(2);

// create CreateAndEarn context to manage CreateAndEarn here
export const CreateAndEarnContext = createContext();

export default function CreateAndEarnContextProvider(props) {
  const { currentCreateAndEarnRecipe, setCurrentCreateAndEarnRecipe } =
    useContext(HelpersContext);

  // set initial state
  const [changing, setChanging] = useState(null);
  const [updating, setUpdating] = useState(null);
  const [createAndEarnRecipes, setCreateAndEarnRecipes] = useState([]);

  // get all CreateAndEarn status
  const getAllCreateAndEarn = () => {
    setChanging("processing");

    // config
    const method = "get",
      url = `${BASE_URL_2}recipe-earn?branchId=${branchId}`;

    // make API call
    axios({ url, method, headers })
      .then((result) => {
        setCreateAndEarnRecipes(result.data.data.items);
        setChanging("found");
      })
      .catch((error) => {
        error = new Error();
        setChanging("failed");
        setChanging(false);
      });
  };

  // update createAndEarn
  const updateCreateAndEarnRecipe = (recipeId, status, closePopup) => {
    setUpdating("processing");

    const method = "put",
      url = `${BASE_URL_2}recipe-earn/${recipeId}`,
      data = {
        adminFeedBack: "Handled",
        status,
      };

    // get Recipes
    axios({ url, method, headers, data })
      .then(() => {
        currentCreateAndEarnRecipe.status = status;

        setCurrentCreateAndEarnRecipe(currentCreateAndEarnRecipe);
        setUpdating("successful");

        if (closePopup) {
          closePopup(false);
        }
        
        setTimeout(() => {
          setChanging(null);
        }, 1000);
      })
      .catch((error) => {
        error = new Error();
        setUpdating("failed");

        setTimeout(() => {
          setChanging(null);
        }, 1000);
      });
  };

  useEffect(() => {
    getAllCreateAndEarn();
  }, []);

  return (
    <CreateAndEarnContext.Provider
      value={{
        changing,
        createAndEarnRecipes,
        updating,
        updateCreateAndEarnRecipe,
      }}
    >
      {props.children}
    </CreateAndEarnContext.Provider>
  );
}
