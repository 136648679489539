import React from "react";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core/";
import InputAdornment from "@material-ui/core/InputAdornment";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export default function CreatePromotionForm() {
  return (
    <main id="create_promotion">
      <form className="form_frame">
        <Grid container>
          {/* Image */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h4>Upload Photo</h4>
            <TextField variant="outlined" type="file" />
          </Grid>

          {/* Name */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <h4>Name</h4>
            <TextField variant="outlined" fullWidth />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} className="category-input">
            <h4>Add Sub Category</h4>
            <TextField
              variant="outlined"
              placeholder="Search Sub Category"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyboardArrowDownIcon />
                  </InputAdornment>
                ),
              }}
            />

            <span>
              <Button>Add</Button>
            </span>

            {/* sub category dropdown */}
            <div className="form-dropdown">
              <p>Vegah</p>
              <p>Vegetable</p>
              <p>Vegah</p>
              <p>Vegetable</p>
            </div>
          </Grid>

          {/* Display on App */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl component="fieldset" className="switch_input">
              <FormGroup aria-label="DisplayOnApp" row>
                <FormControlLabel
                  value="DisplayOnApp"
                  control={<Switch color="primary" />}
                  label="Display on App"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>

        {/* button */}
        <section className="form-btn">
          <Button>Create</Button>
        </section>
      </form>
    </main>
  );
}
