import { MenuItem } from "@material-ui/core";
import React, { useContext } from "react";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import { RecipeContext } from "../../../ContextAPI/RecipeContext";

export default function StatusBtn({ order, location }) {
  // context
  const { changeRecipeDTstatus } = useContext(RecipeContext);
  const { roles, orderStatus } = useContext(HelpersContext);
  const status = location === "onTable" ? order.status : orderStatus;

  return (
    <>
      <section className="btn_blue">
        {roles.includes("Chef 3") ? (
          status === "Delivered" || status === "Cancelled" ? (
            ""
          ) : (
            <MenuItem
              onClick={() =>
                changeRecipeDTstatus(
                  order.id,
                  status === "Pending" || status === "PENDING"
                    ? "Accepted"
                    : status === "Accepted"
                    ? "Processing"
                    : status === "Processing"
                    ? "Ready"
                    : status === "Ready"
                    ? "Delivered"
                    : ""
                )
              }
            >
              {status === "Pending" || status === "PENDING"
                ? "Accept"
                : status === "Accepted"
                ? "Begin Processing"
                : status === "Processing"
                ? "Ready"
                : status === "Ready"
                ? "Deliver"
                : "Done"}
            </MenuItem>
          )
        ) : (
          ""
        )}
      </section>
    </>
  );
}
