// external imports
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core/";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { VscHistory } from "react-icons/vsc";
import {
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@material-ui/core/";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

// internal imports
import AddCategory from "./CreateMenuForm/AddCategory";
import { InventoryContext } from "../../../ContextAPI/InventoryContext";
import {
  setUpInventory,
  handleInventoryPriceChange,
  resetTable,
  removeSelectedItem,
  reduceQty,
  increaseQty,
  adjustExtra,
  toggleShowPriceInput,
  getInventoryDescription,
  clearAddons,
  setUpAddon,
  toggleItems,
  toggleShowItems,
  applyDiscount,
} from "../../helpers/MenuItemHelper";
import { GiCancel } from "react-icons/gi";
import AddPreference from "./CreateMenuForm/AddPreference";
import { MenuItemPreferenceContext } from "../../../ContextAPI/MenuItemPreferenceContext";
import { MenuItemCategoryContext } from "../../../ContextAPI/MenuItemCategoryContext";
import { MenuItemContext } from "../../../ContextAPI/MenuItemContext";
import FeedbackPopup from "../../helpers/FeedbackPopup";
import SubmitMenuItem from "./SubmitMenuItem";
import ImageInput from "../MPCategoryMGT/ImageInput";
import { HelpersContext } from "../../../ContextAPI/HelpersContext";
import { searchItems } from "../MPCategoryMGT/HelperFunction";

export default function CreateMenuItemForm() {
  // context
  const { inventories } = useContext(InventoryContext);
  const { menuItemPreferences } = useContext(MenuItemPreferenceContext);
  const { menuItemCategories } = useContext(MenuItemCategoryContext);
  const { menuItemLoading, addMenuItem } = useContext(MenuItemContext);
  const { imgBase64 } = useContext(HelpersContext);

  // setup form fields
  const [itemName, setItemName] = useState(null);
  const [description, setDescription] = useState(null);
  const [caption, setCaption] = useState(null);
  const [discount, setDiscount] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [availability, setAvailability] = useState(true);
  const [specialOffer, setSpecialOffer] = useState(null);
  const [inventory, setInventory] = useState(null);
  const [addon, setAddon] = useState(null);
  const [itemCategory, setItemCategory] = useState(null);
  let [totalPrice, setTotalPrice] = useState(0);
  const [oldPrice, setOldPrice] = useState(0);
  const [newPrice, setNewPrice] = useState(0);
  const [preference, setPreference] = useState(null);
  const [selectedInventories, setSelectedInventories] = useState([]);
  const [showPriceInput, setShowPriceInput] = useState(false);
  const [priceInputIndex, setPriceInputIndex] = useState(null);
  const [showInitialAddonPriceInput, setShowInitialAddonPriceInput] =
    useState(false);
  const [showOtherAddonPriceInput, setShowOtherAddonPriceInput] =
    useState(false);
  const [initialaddonPriceInputIndex, setInitialAddonPriceInputIndex] =
    useState(null);
  const [otherAddonPriceInputIndex, setOtherAddonPriceInputIndex] =
    useState(null);
  const [initialExtra, setInitialExtra] = useState([]);
  const [otherExtra, setOtherExtra] = useState([]);
  const [selectedPreference, setSelectedPreference] = useState([]);
  const [selectedItemCategory, setSelectedItemCategory] = useState([]);
  const [showPreferences, setShowPreferences] = useState(false);
  const [showItemCategories, setShowItemCategories] = useState(false);
  const [displayOnApp, setDisplayOnApp] = useState(true);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [filteredPreferences, setFilteredPreferences] = useState([]);
  const [filteredItemCategory, setFilteredItemCategory] = useState([]);
  const [filteredAddonsInventory, setFilteredAddonsInventory] = useState([]);

  // yup schema for validation
  const schema = yup.object().shape({
    itemName: yup.string().required(),
    description: yup.string().required(),
  });

  // get what we need from useform()
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // execute here when form is submitted
  const onSubmit = (data) => {
    addMenuItem(
      data,
      selectedInventories,
      selectedPreference,
      selectedItemCategory,
      initialExtra,
      otherExtra,
      imgBase64,
      availability,
      specialOffer,
      displayOnApp
    );
  };

  return (
    <>
      <form id="create_menu_item" className="form_frame">
        <h1>Create Menu Item</h1>

        <Grid container>
          {/* section 1 */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {/* menu Image */}
            <h4>Menu Image</h4>
            <ImageInput />

            {/* item name */}
            <h4>Item Name</h4>
            <TextField
              fullWidth
              variant="outlined"
              name="itemName"
              error={!!errors.itemName}
              helperText={errors.itemName ? errors.itemName.message : ""}
              inputRef={register}
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h4>Description</h4>
            <TextField
              multiline
              rows={7}
              variant="outlined"
              fullWidth
              name="description"
              error={!!errors.description}
              helperText={errors.description ? errors.description.message : ""}
              inputRef={register}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>

          {/* Select Inventory item */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {discount ? (
              <h4 id="inventory_notice">Turn off Discount to make changes!</h4>
            ) : (
              <>
                <h4>Select Inventory item</h4>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="inventory"
                  error={!!errors.inventory}
                  helperText={errors.inventory ? errors.inventory.message : ""}
                  inputRef={register}
                  value={inventory}
                  onChange={(e) => setInventory(e.target.value)}
                  onKeyUp={(e) =>
                    searchItems(
                      e.target.value,
                      inventories,
                      setFilteredInventory
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                {/* Select Inventory item dropdown */}
                <div className="form-dropdown">
                  <h5>{inventory}</h5>

                  {/* list of search result */}
                  {filteredInventory &&
                    filteredInventory.length > 0 &&
                    filteredInventory.map((result) => (
                      <p
                        key={result.id}
                        title={getInventoryDescription(
                          result.itemName,
                          inventories
                        )}
                        onClick={() =>
                          setUpInventory(
                            result.itemName,
                            setInventory,
                            selectedInventories,
                            inventories,
                            setSelectedInventories,
                            setTotalPrice
                          )
                        }
                      >
                        <VscHistory />
                        <span>{result.itemName.toUpperCase()}</span>
                      </p>
                    ))}
                </div>
              </>
            )}

            {/* selected Item Options */}
            <div className="form-table">
              {/* first segment */}
              <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                  {/* table head */}
                  <TableHead>
                    <TableRow>
                      {discount ? (
                        ""
                      ) : (
                        <TableCell align="center" className="remove-btn">
                          <Button
                            onClick={() =>
                              resetTable(
                                setSelectedInventories,
                                setInventory,
                                setTotalPrice
                              )
                            }
                          >
                            <GiCancel />
                          </Button>
                        </TableCell>
                      )}
                      <TableCell align="center">Item</TableCell>
                      <TableCell align="center">Quality</TableCell>
                      <TableCell align="center">Total Price</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>

                  {/* table body */}
                  <TableBody>
                    {selectedInventories &&
                      selectedInventories.length > 0 &&
                      selectedInventories.map((inventory, index) => (
                        <TableRow key={inventory.id}>
                          {discount ? (
                            ""
                          ) : (
                            <TableCell align="center" className="remove-btn">
                              <Button
                                onClick={() =>
                                  removeSelectedItem(
                                    index,
                                    selectedInventories,
                                    setSelectedInventories,
                                    setTotalPrice,
                                    totalPrice
                                  )
                                }
                              >
                                <GiCancel />
                              </Button>
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {inventory.name} <br />{" "}
                            <span className="sub_price">
                              ({inventory.price} NGN)
                            </span>
                          </TableCell>
                          <TableCell align="center" id="qty">
                            {/* subtract */}
                            {discount ? (
                              ""
                            ) : (
                              <span
                                className="span_btn"
                                onClick={() =>
                                  reduceQty(
                                    null,
                                    index,
                                    selectedInventories,
                                    setSelectedInventories,
                                    setTotalPrice
                                  )
                                }
                              >
                                <AiOutlineMinus />
                              </span>
                            )}

                            <span>{inventory.quantity}</span>
                            {/* add */}

                            {discount ? (
                              ""
                            ) : (
                              <span
                                className="span_btn"
                                onClick={() =>
                                  increaseQty(
                                    null,
                                    index,
                                    selectedInventories,
                                    setSelectedInventories,
                                    setTotalPrice
                                  )
                                }
                              >
                                <AiOutlinePlus />
                              </span>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {/* we are using the index === priceInputIndex to ensure that only one selected inventory is affected */}
                            {showPriceInput &&
                            index === priceInputIndex &&
                            discount === false ? (
                              <>
                                <span className="sub_price">
                                  {inventory.totalPrice} NGN
                                </span>
                                <br />
                                <input
                                  type="text"
                                  name={`inventoryPrice-${index}`}
                                  placeholder="Tab to Exit"
                                  title="Click outside to Exit"
                                  autoFocus
                                  onBlur={(e) =>
                                    toggleShowPriceInput(
                                      e,
                                      index,
                                      showPriceInput,
                                      setShowPriceInput,
                                      setPriceInputIndex
                                    )
                                  }
                                  onChange={(e) =>
                                    handleInventoryPriceChange(
                                      e,
                                      index,
                                      selectedInventories,
                                      setTotalPrice
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <p
                                title={
                                  discount
                                    ? "Turn off Discount to edit"
                                    : "Click to Edit"
                                }
                                onClick={(e) =>
                                  toggleShowPriceInput(
                                    e,
                                    index,
                                    showPriceInput,
                                    setShowPriceInput,
                                    setPriceInputIndex
                                  )
                                }
                              >
                                {inventory.totalPrice} NGN
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <FormControl
                              component="fieldset"
                              className="switch_input"
                            >
                              <FormGroup aria-label="extra" row>
                                <FormControlLabel
                                  name={`inventoryExtra-${index}`}
                                  onChange={(e) =>
                                    adjustExtra(
                                      index,
                                      selectedInventories,
                                      setInitialExtra,
                                      initialExtra
                                    )
                                  }
                                  control={<Switch color="primary" />}
                                  label="Extra"
                                  labelPlacement="start"
                                />
                              </FormGroup>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>

                  {/* table footer */}
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Sum Total</TableCell>
                      <TableCell align="center"></TableCell>
                      {discount ? "" : <TableCell align="center"></TableCell>}
                      <TableCell align="center">{totalPrice} NGN</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </div>
          </Grid>

          {/* Create Add-ons */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h4>Create Add-ons</h4>
            <TextField
              variant="outlined"
              fullWidth
              name="addon"
              error={!!errors.addon}
              helperText={errors.addon ? errors.addon.message : ""}
              inputRef={register}
              value={addon}
              onChange={(e) => setAddon(e.target.value)}
              onKeyUp={(e) =>
                searchItems(
                  e.target.value,
                  inventories,
                  setFilteredAddonsInventory
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            {/* Select Inventory item dropdown */}
            <div className="form-dropdown">
              <h5>{addon}</h5>

              {/* list of search result */}
              {filteredAddonsInventory &&
                filteredAddonsInventory.length > 0 &&
                filteredAddonsInventory.map((result) => (
                  <p
                    key={result.id}
                    title={getInventoryDescription(
                      result.itemName,
                      inventories
                    )}
                    onClick={() =>
                      setUpAddon(
                        result.itemName,
                        setAddon,
                        otherExtra,
                        inventories,
                        setOtherExtra,
                        initialExtra,
                        selectedInventories
                      )
                    }
                  >
                    <VscHistory />
                    <span>{result.itemName.toUpperCase()}</span>
                  </p>
                ))}
            </div>

            <div className="form-table">
              {/* first segment */}
              <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                  {/* table head */}
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className="remove-btn">
                        <Button
                          onClick={() =>
                            clearAddons(setInitialExtra, setOtherExtra)
                          }
                        >
                          <GiCancel />
                        </Button>
                      </TableCell>
                      <TableCell align="center">Item</TableCell>
                      <TableCell align="center">Quality</TableCell>
                      <TableCell align="center">Price</TableCell>
                    </TableRow>
                  </TableHead>

                  {/* table body for addons coming from the inventory above*/}
                  <TableBody>
                    {initialExtra &&
                      initialExtra.length > 0 &&
                      initialExtra.map((inventory, index) => (
                        <TableRow key={inventory.id}>
                          <TableCell align="center" className="remove-btn">
                            <Button
                              onClick={() =>
                                alert(
                                  `Turn Off the ${inventory.name.toUpperCase()}'s Extra Button above`
                                )
                              }
                            >
                              <GiCancel />
                            </Button>
                          </TableCell>

                          <TableCell align="center">
                            Extra {inventory.name} <br />{" "}
                            <span className="sub_price">
                              ({inventory.price} NGN)
                            </span>
                          </TableCell>

                          <TableCell align="center" id="qty">
                            {/* subtract */}
                            <span
                              className="span_btn"
                              onClick={() =>
                                reduceQty(
                                  null,
                                  index,
                                  initialExtra,
                                  setInitialExtra
                                )
                              }
                            >
                              <AiOutlineMinus />
                            </span>
                            <span>{inventory.quantity}</span>
                            {/* add */}
                            <span
                              className="span_btn"
                              onClick={() =>
                                increaseQty(
                                  null,
                                  index,
                                  initialExtra,
                                  setInitialExtra
                                )
                              }
                            >
                              <AiOutlinePlus />
                            </span>
                          </TableCell>

                          <TableCell align="center">
                            {/* we are using the index === priceInputIndex to ensure that only one selected inventory is affected */}
                            {showInitialAddonPriceInput &&
                            index === initialaddonPriceInputIndex ? (
                              <>
                                <span className="sub_price">
                                  {inventory.totalPrice} NGN
                                </span>
                                <br />
                                <input
                                  type="text"
                                  name={`initiaExtraPrice-${index}`}
                                  placeholder="Tab to Exit"
                                  title="Click outside to Exit"
                                  autoFocus
                                  onBlur={(e) =>
                                    toggleShowPriceInput(
                                      e,
                                      index,
                                      showInitialAddonPriceInput,
                                      setShowInitialAddonPriceInput,
                                      setInitialAddonPriceInputIndex
                                    )
                                  }
                                  onChange={(e) =>
                                    handleInventoryPriceChange(
                                      e,
                                      index,
                                      initialExtra
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <p
                                title="Click to Edit"
                                onClick={(e) =>
                                  toggleShowPriceInput(
                                    e,
                                    index,
                                    showInitialAddonPriceInput,
                                    setShowInitialAddonPriceInput,
                                    setInitialAddonPriceInputIndex
                                  )
                                }
                              >
                                {inventory.totalPrice} NGN
                              </p>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                  {/* table body for addons coming from the addons search itself*/}
                  <TableBody>
                    {otherExtra &&
                      otherExtra.length > 0 &&
                      otherExtra.map((inventory, index) => (
                        <TableRow key={inventory.id}>
                          <TableCell align="center" className="remove-btn">
                            <Button
                              onClick={() =>
                                removeSelectedItem(
                                  index,
                                  otherExtra,
                                  setOtherExtra
                                )
                              }
                            >
                              <GiCancel />
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {inventory.name} <br />{" "}
                            <span className="sub_price">
                              ({inventory.price} NGN)
                            </span>
                          </TableCell>
                          <TableCell align="center" id="qty">
                            {/* subtract */}
                            <span
                              className="span_btn"
                              onClick={() =>
                                reduceQty(
                                  null,
                                  index,
                                  otherExtra,
                                  setOtherExtra
                                )
                              }
                            >
                              <AiOutlineMinus />
                            </span>
                            <span>{inventory.quantity}</span>
                            {/* add */}
                            <span
                              className="span_btn"
                              onClick={() =>
                                increaseQty(
                                  null,
                                  index,
                                  otherExtra,
                                  setOtherExtra
                                )
                              }
                            >
                              <AiOutlinePlus />
                            </span>
                          </TableCell>

                          <TableCell align="center">
                            {/* we are using the index === otherAddonPriceInputIndex to ensure that only one selected inventory is affected */}
                            {showOtherAddonPriceInput &&
                            index === otherAddonPriceInputIndex ? (
                              <>
                                <span className="sub_price">
                                  {inventory.totalPrice} NGN
                                </span>
                                <br />
                                <input
                                  type="text"
                                  name={`otherExtraPrice-${index}`}
                                  placeholder="Tab to Exit"
                                  title="Click outside to Exit"
                                  autoFocus
                                  onBlur={(e) =>
                                    toggleShowPriceInput(
                                      e,
                                      index,
                                      showOtherAddonPriceInput,
                                      setShowOtherAddonPriceInput,
                                      setOtherAddonPriceInputIndex
                                    )
                                  }
                                  onChange={(e) =>
                                    handleInventoryPriceChange(
                                      e,
                                      index,
                                      otherExtra
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <p
                                title="Click to Edit"
                                onClick={(e) =>
                                  toggleShowPriceInput(
                                    e,
                                    index,
                                    showOtherAddonPriceInput,
                                    setShowOtherAddonPriceInput,
                                    setOtherAddonPriceInputIndex
                                  )
                                }
                              >
                                {inventory.totalPrice} NGN
                              </p>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>

          {/* preference */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <section id="menu_category_input">
              <h4>Create Preference</h4>
              <Grid container>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <TextField
                    variant="outlined"
                    placeholder="Search Preference"
                    name="preference"
                    fullWidth
                    error={!!errors.preference}
                    helperText={
                      errors.preference ? errors.preference.message : ""
                    }
                    inputRef={register}
                    value={preference}
                    onChange={(e) => setPreference(e.target.value)}
                    onKeyUp={(e) =>
                      searchItems(
                        e.target.value,
                        menuItemPreferences,
                        setFilteredPreferences,
                        true
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() =>
                            toggleShowItems(showPreferences, setShowPreferences)
                          }
                        >
                          <KeyboardArrowDownIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* add button */}
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <AddPreference
                    preferenceName={preference}
                    selectedPreference={selectedPreference}
                    setSelectedPreference={setSelectedPreference}
                  />
                </Grid>
              </Grid>

              {/* preference dropdown */}
              <div className="form-dropdown">
                <FormControl component="fieldset">
                  <FormGroup>
                    {showPreferences ? (
                      <>
                        {menuItemPreferences &&
                          menuItemPreferences.length > 0 &&
                          menuItemPreferences.map((result, index) => (
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    fullWidth
                                    name={result}
                                    color="primary"
                                    onChange={(e) =>
                                      toggleItems(
                                        e.target.checked,
                                        result,
                                        selectedPreference,
                                        setSelectedPreference
                                      )
                                    }
                                  />
                                }
                                label={result.name.toUpperCase()}
                              />
                              <span className="preference_price">
                                ({result.unitPrice} NGN)
                              </span>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        {filteredPreferences &&
                          filteredPreferences.length > 0 &&
                          filteredPreferences.map((result, index) => (
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    fullWidth
                                    name={result}
                                    color="primary"
                                    onChange={(e) =>
                                      toggleItems(
                                        e.target.checked,
                                        result,
                                        selectedPreference,
                                        setSelectedPreference
                                      )
                                    }
                                  />
                                }
                                label={result.name.toUpperCase()}
                              />
                              <span className="preference_price">
                                ({result.unitPrice} NGN)
                              </span>
                            </>
                          ))}
                      </>
                    )}
                  </FormGroup>
                </FormControl>
              </div>

              {/* preference table */}
              <div className="form-table">
                {/* first segment */}
                <TableContainer component={Paper}>
                  <Table aria-label="a dense table">
                    {/* table head */}
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Price</TableCell>
                        <TableCell align="center" className="remove-btn">
                          <Button
                            onClick={() =>
                              resetTable(
                                setSelectedPreference,
                                setPreference,
                                null
                              )
                            }
                          >
                            <GiCancel />
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                      {selectedPreference &&
                        selectedPreference.length > 0 &&
                        selectedPreference.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCell align="center">{item.name}</TableCell>
                            <TableCell align="center">
                              {item.unitPrice} NGN
                            </TableCell>
                            <TableCell align="center" className="remove-btn">
                              <Button
                                onClick={() =>
                                  removeSelectedItem(
                                    index,
                                    selectedPreference,
                                    setSelectedPreference,
                                    null,
                                    null
                                  )
                                }
                              >
                                <GiCancel />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </section>
          </Grid>

          {/* Add catgory */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <section id="menu_category_input">
              <h4>Add Category</h4>
              <Grid container>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <TextField
                    variant="outlined"
                    placeholder="Search Item Category"
                    name="itemCategory"
                    fullWidth
                    error={!!errors.itemCategory}
                    helperText={
                      errors.itemCategory ? errors.itemCategory.message : ""
                    }
                    inputRef={register}
                    value={itemCategory}
                    onChange={(e) => setItemCategory(e.target.value)}
                    onKeyUp={(e) =>
                      searchItems(
                        e.target.value,
                        menuItemCategories,
                        setFilteredItemCategory,
                        true
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() =>
                            toggleShowItems(
                              showItemCategories,
                              setShowItemCategories
                            )
                          }
                        >
                          <KeyboardArrowDownIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* add button */}
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <AddCategory
                    itemCategoryName={itemCategory}
                    selectedItemCategory={selectedItemCategory}
                    setSelectedItemCategory={setSelectedItemCategory}
                  />
                </Grid>
              </Grid>

              {/* category dropdown */}
              <div className="form-dropdown">
                <FormControl component="fieldset">
                  <FormGroup>
                    {showItemCategories ? (
                      <>
                        {menuItemCategories &&
                          menuItemCategories.length > 0 &&
                          menuItemCategories.map((result, index) => (
                            <>
                              <FormControlLabel
                                title={result.description}
                                control={
                                  <Checkbox
                                    fullWidth
                                    name={result}
                                    color="primary"
                                    onChange={(e) =>
                                      toggleItems(
                                        e.target.checked,
                                        result,
                                        selectedItemCategory,
                                        setSelectedItemCategory
                                      )
                                    }
                                  />
                                }
                                label={result.name.toUpperCase()}
                              />
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        {filteredItemCategory &&
                          filteredItemCategory.length > 0 &&
                          filteredItemCategory.map((result, index) => (
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    fullWidth
                                    name={result}
                                    color="primary"
                                    onChange={(e) =>
                                      toggleItems(
                                        e.target.checked,
                                        result,
                                        selectedItemCategory,
                                        setSelectedItemCategory
                                      )
                                    }
                                  />
                                }
                                label={result.name.toUpperCase()}
                              />
                            </>
                          ))}
                      </>
                    )}
                  </FormGroup>
                </FormControl>
              </div>

              {/* category table */}
              <div className="form-table">
                {/* first segment */}
                <TableContainer component={Paper}>
                  <Table aria-label="a dense table">
                    {/* table head */}
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center" className="remove-btn">
                          <Button
                            onClick={() =>
                              resetTable(
                                setSelectedItemCategory,
                                setItemCategory,
                                null
                              )
                            }
                          >
                            <GiCancel />
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                      {selectedItemCategory &&
                        selectedItemCategory.length > 0 &&
                        selectedItemCategory.map((item, index) => (
                          <TableRow key={item.id} title={item.description}>
                            <TableCell align="center">{item.name}</TableCell>
                            <TableCell align="center" className="remove-btn">
                              <Button
                                onClick={() =>
                                  removeSelectedItem(
                                    index,
                                    selectedItemCategory,
                                    setSelectedItemCategory,
                                    null,
                                    null
                                  )
                                }
                              >
                                <GiCancel />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </section>
          </Grid>

          {/* Discount */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <section id="discount_input">
              <br />
              <FormControl component="fieldset" className="switch_input">
                <FormGroup aria-label="Discount" row>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e) =>
                          toggleShowItems(
                            discount,
                            setDiscount,
                            setDiscountAmount,
                            setDiscountPercent,
                            setOldPrice,
                            setNewPrice
                          )
                        }
                      />
                    }
                    label="Discount"
                    labelPlacement="start"
                  />
                  {discountAmount > totalPrice ? (
                    <p id="discount_warning">
                      Discount is more than Price. Discount will not be applied!
                    </p>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </FormControl>

              {discount ? (
                <Grid container>
                  <br />
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    {/* amount */}
                    <TextField
                      disabled={totalPrice <= 0 ? true : false}
                      variant="outlined"
                      name="discountAmount"
                      error={!!errors.discountAmount}
                      helperText={
                        errors.discountAmount
                          ? errors.discountAmount.message
                          : ""
                      }
                      inputRef={register}
                      value={discountAmount}
                      onChange={(e) => setDiscountAmount(e.target.value)}
                      onKeyUp={(e) =>
                        setDiscountPercent(
                          (parseFloat(e.target.value) * 100) /
                            parseFloat(totalPrice)
                        )
                      }
                      onBlur={() =>
                        applyDiscount(
                          discountAmount,
                          discountPercent,
                          setOldPrice,
                          totalPrice,
                          setNewPrice,
                          discount
                        )
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">NGN</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  {/* percentage */}
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TextField
                      disabled={totalPrice <= 0 ? true : false}
                      variant="outlined"
                      name="discountPercent"
                      error={!!errors.discountPercent}
                      helperText={
                        errors.discountPercent
                          ? errors.discountPercent.message
                          : ""
                      }
                      inputRef={register}
                      value={discountPercent}
                      onChange={(e) => setDiscountPercent(e.target.value)}
                      onKeyUp={(e) =>
                        setDiscountAmount(
                          (parseFloat(totalPrice) *
                            parseFloat(e.target.value)) /
                            100
                        )
                      }
                      onBlur={() =>
                        applyDiscount(
                          discountAmount,
                          discountPercent,
                          setOldPrice,
                          totalPrice,
                          setNewPrice,
                          discount
                        )
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </section>
          </Grid>

          {/* price */}
          {discount ? (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <section>
                <h4>Price</h4>

                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    {/* old price */}
                    <TextField
                      disabled
                      variant="outlined"
                      label="Old"
                      name="oldPrice"
                      error={!!errors.oldPrice}
                      helperText={
                        errors.oldPrice ? errors.oldPrice.message : ""
                      }
                      inputRef={register}
                      value={oldPrice}
                      onChange={(e) => setOldPrice(oldPrice)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">NGN</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  {/* new price */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      disabled
                      variant="outlined"
                      label="New"
                      name="newPrice"
                      error={!!errors.newPrice}
                      helperText={
                        errors.newPrice ? errors.newPrice.message : ""
                      }
                      inputRef={register}
                      value={newPrice}
                      onChange={(e) => setNewPrice(newPrice)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">NGN</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </section>
            </Grid>
          ) : (
            ""
          )}

          {/* add caption */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <section>
              <h4>Add Caption</h4>
              <TextField
                fullWidth
                variant="outlined"
                name="caption"
                error={!!errors.caption}
                helperText={errors.caption ? errors.caption.message : ""}
                inputRef={register}
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </section>
          </Grid>

          {/* Availability */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <section>
              <h4>Availability Status</h4>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="availabilty"
                  name="availabilty"
                  value={availability}
                  onChange={(e) => setAvailability(e.target.value)}
                >
                  {/* unavailable */}
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" />}
                    label="Unavailable"
                  />

                  {/* Available */}
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" />}
                    label="Available"
                  />
                </RadioGroup>
              </FormControl>
            </section>
          </Grid>

          {/* Special offer */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <section className="switch_input">
              <br />
              <FormControl component="fieldset">
                <FormGroup aria-label="specialOffer" row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={specialOffer}
                        color="primary"
                        onChange={(e) => setSpecialOffer(e.target.checked)}
                      />
                    }
                    label="Special Offer"
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
            </section>
          </Grid>

          {/* Display on App */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl component="fieldset" className="switch_input">
              <FormGroup aria-label="DisplayOnApp" row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={displayOnApp}
                      color="primary"
                      onChange={(e) => setDisplayOnApp(e.target.checked)}
                    />
                  }
                  label="Display on App"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>

        {/* feedback popup */}
        <FeedbackPopup
          condition={menuItemLoading}
          additionalMessage="Page Reloads in a moment..."
        />

        {/* button */}
        <section className="form-btn">
          <SubmitMenuItem label="Create" submitMT={handleSubmit(onSubmit)} />
        </section>
      </form>
    </>
  );
}
