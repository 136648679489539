import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { OrderContext } from "../../../../ContextAPI/OrderContext";
import moment from "moment";
import OrderActions from "../OrderActions";
import { sortOrders, tableOptions } from "../../../helpers/OrderHelpers";
import StartAndEndDates from "../../../helpers/Table/StartAndEndDates";
import NextAndPrevButtons from "../../../helpers/Table/NextAndPrevButtons";
import TableContent from "../../../helpers/Table/TableContent";

export default function IncomingOrderTable() {
  // context
  const { allOrders, orderLoading, filterOrders, executeOnce } =
    useContext(OrderContext);

  // seperate order according to status
  let pendingOrders = sortOrders(allOrders, "Pending");
  
  // table headings
  const columns = [
    "S/N",
    "MORE ACTIONS",
    "AREA",
    "ADDRESS",
    "ORDER NAME",
    "ORDER ID",
    "CUSTOMER NAME",
    "CUSTOMER NUMBER",
    "NO. OF ITEMS",
    "DATE OF ORDER",
    "TIME OF REQUEST",
    "TIME OF DELIVERY",
    "Amount",
    "Payment Method",
    "Payment Gate Way",
    "Payment Status",
    "CHANNEL",
    "DELIVERY METHOD",
  ];

  // extract data for the table rows
  const extractedOrders = pendingOrders.map((order, index) => {
    const menuItemOrders = order.menuitemorders;
    const deliveryArea = menuItemOrders.DeliveryAddress;
    const menuItemOrderDetails = menuItemOrders.MenuItemOrderDetails;
    const paymentDetails = order.Payments;
    const orderBy = order.orderBy;

    return [
      index + 1,
      <OrderActions order={order} />,
      deliveryArea ? deliveryArea.address : "",
      menuItemOrders.deliveryAddress,
      order.orderName,
      order.orderRef,
      orderBy.fullName,
      orderBy.phoneNumber,
      menuItemOrderDetails.length,
      moment(order.createdAt).format("L"),
      moment(order.createdAt).format("HH:mm:ss"),
      moment(menuItemOrders.deliveryTime).format("HH:mm:ss") !== "Invalid date"
        ? moment(menuItemOrders.deliveryTime).format("HH:mm:ss")
        : menuItemOrders.deliveryTime,
      menuItemOrders.total,
      menuItemOrders.paymentMethod,
      paymentDetails && paymentDetails.length
        ? paymentDetails[0].gateWayType
        : "",
      order.paymentStatus,
      order.orderChannel,
      order.menuitemorders.deliveryOption,
    ];
  });

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter" id="pos_filter">
        {/* today */}
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{pendingOrders.length}</span>
          </p>
        </Grid>

        {/* filter options */}
        <StartAndEndDates
          filterFunction={filterOrders}
          executeOnce={executeOnce}
        />

        {/* pagination */}
        <NextAndPrevButtons />
      </Grid>

      {/* set table */}
      <TableContent
        loading={orderLoading}
        receivedData={pendingOrders}
        heading="Pending Order"
        tableData={extractedOrders}
        columns={columns}
        tableOptions={tableOptions}
      />
    </main>
  );
}
