import React, { useContext, useEffect, useRef } from "react";
import SpaceInBetweenLayout from "../../../helpers/SpaceInBetweenLayout";
import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ReactToPrint from "react-to-print";
import MPItemDetail from "./MPItemDetail";
import PrintoutHeaderWithIMG from "../../Order/IncomingOrder/PrintoutHeaderWithIMG";
import { timeLimitConvertion } from "../../../helpers/MenuPlanHelper";
import moment from "moment";
import PriceAndDate from "../../Order/IncomingOrder/PriceAndDate";
import { UtilsContext } from "../../../../ContextAPI/UtilsContext";

export default function MPOrderKitchen({ order, status }) {
  const componentRef = useRef();

  // context
  const { getArea, area, utilsLoading } = useContext(UtilsContext);

  useEffect(() => {
    getArea(order.deliveryAddId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* button to trigger the printing */}
      <ReactToPrint
        trigger={() => <MenuItem>Kitchen Slip</MenuItem>}
        content={() => componentRef.current}
      />

      {/* component to be printed hidden from users */}
      <div style={{ display: "none" }}>
        <KitchenSlipContent
          order={order}
          ref={componentRef}
          area={area}
          utilsLoading={utilsLoading}
          status={status}
        />
      </div>
    </div>
  );
}

class KitchenSlipContent extends React.PureComponent {
  render() {
    const order = this.props.order;
    const deliveryTime = order.deliveryTime;
    const quantity = deliveryTime.quantity;
    const area = this.props.area;
    const utilsLoading = this.props.utilsLoading;
    const status = this.props.status;

    return (
      <>
        <main className="kitchen_slips">
          <PrintoutHeaderWithIMG />

          {/* order details */}
          <Grid container className="order_details dashed_bottom">
            <Grid container className="order_details_top dashed_bottom">
              {/* basic details */}
              <SpaceInBetweenLayout
                textA="Recipent’s name:"
                textB={order.friendName ? order.friendName : order.fullName}
              />

              <SpaceInBetweenLayout
                textA="Recipent’s number:"
                textB={
                  order.friendPhoneNumber
                    ? order.friendPhoneNumber
                    : order.phoneNumber
                }
              />

              <SpaceInBetweenLayout
                textA="Recipient’s Email:"
                textB={order.email}
              />

              <SpaceInBetweenLayout textA="Order ID:" textB={order.orderRef} />

              <SpaceInBetweenLayout textA="No. of items:" textB={"1"} />

              <SpaceInBetweenLayout
                textA="Date of order:"
                textB={moment(order.createdAt).format("Do MMM, YYYY")}
              />

              <SpaceInBetweenLayout
                textA="Time of order:"
                textB={moment(order.createdAt).format("LTS")}
              />

              <SpaceInBetweenLayout textA="Order status:" textB={status} />

              <SpaceInBetweenLayout
                textA="Payment status:"
                textB={order.paymentStatus}
              />
            </Grid>

            {/* delivery details */}
            <Grid container className="order_details_down">
              <SpaceInBetweenLayout
                textA="Delivery area:"
                textB={
                  utilsLoading === "processing" ? (
                    <i>Fetching Area</i>
                  ) : utilsLoading === "found" && area ? (
                    area.address
                  ) : utilsLoading === "failed" ? (
                    ""
                  ) : (
                    ""
                  )
                }
              />

              <SpaceInBetweenLayout
                textA="Date of delivery:"
                textB={moment(order.deliveryDate).format("Do MMM, YYYY")}
              />

              <SpaceInBetweenLayout
                textA="Time of delivery:"
                textB={timeLimitConvertion(deliveryTime.time)}
              />

              <SpaceInBetweenLayout
                textA="Delivery method:"
                textB={order.deliveryOption}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              id="delivery_address"
              align="center"
            >
              <h3>
                <b>Delivery Address</b>
              </h3>
              <p>{order.deliveryAddress}</p>
            </Grid>
          </Grid>

          {/* cart details */}
          <MPItemDetail order={order} quantity={quantity} />

          {/* price and date */}
          <PriceAndDate
            order={order}
            total={parseFloat(quantity) * parseFloat(order.amount)}
          />
        </main>
      </>
    );
  }
}
