import React from "react";
import CreateRoleForm from "../../components/adminComponents/CreateRole/CreateRoleForm";
import DashboardFrame from "../../components/adminComponents/DashboardFrame";

export default function CreateRole() {
  return (
    <>
      <DashboardFrame pageHeading="" dashboardContent={<CreateRoleForm />} />
    </>
  );
}
