import React from "react";
import ViewAMPOrderFrame from "../ViewAMPOrderFrame";

export default function ViewACustomerMPOrderContent() {
  return (
    <>
      <ViewAMPOrderFrame />
    </>
  );
}
