// external imports
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");

let headers = {};
let branchId = null;

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
  branchId = staff.branchId;
}

const BASE_URL_2 = getBaseUrl(2);

// create Survey context to manage Survey here
export const SurveyContext = createContext();

export default function SurveyContextProvider(props) {
  // set initial state
  const [surveyLoading, setLoading] = useState(null);
  const [surveys, setSurveys] = useState([]);
  const [changing, setChanging] = useState(null);

  // make API call to get all the Surveys
  useEffect(() => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL_2}mealsurvey?branchId=${branchId}`;

    // get Orders
    axios({ url, method, headers })
      .then((result) => {
        setSurveys(result.data.data.items);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
        setSurveys([]);
      });
  }, []);

  // update survey status
  const changeStatus = (id, status, user) => {
    setChanging("processing");

    // config
    const method = "put",
      url = `${BASE_URL_2}mealsurvey/${id}`,
      data = {
        status,
      };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setChanging("successful");

        localStorage.clear();
        localStorage.setItem("surveyId", id);
        localStorage.setItem("user", JSON.stringify(user));

        // redirect to create plan for user
        window.location.href = "/create-customer-meal-plan";
      })
      .catch((error) => {
        error = new Error();
        setChanging("failed");
        setChanging(false);
      });
  };

  return (
    <SurveyContext.Provider
      value={{ surveyLoading, surveys, changing, changeStatus }}
    >
      {props.children}
    </SurveyContext.Provider>
  );
}
