// external imports
import React, { createContext, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};
let branchId = null;

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
  branchId = staff.branchId;
}

const BASE_URL = getBaseUrl();

// create Utils context to manage Utils here
export const UtilsContext = createContext();

export default function UtilsContextProvider(props) {
  // set initial state
  const [utilsLoading, setLoading] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [banks, setBanks] = useState([]);
  const [submissionError, setSubmissionError] = useState("");
  const [addingDA, setAddingDA] = useState(null);
  const [area, setArea] = useState(null);
  const [payment, setPayment] = useState(null);
  const [loadingPay, setLoadingPay] = useState(false);
  const [verifying, setVerifying] = useState(false);

  // get all banks
  const getBanks = () => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}/utils/banks`;

    // get Utilss
    axios({ url, method, headers })
      .then((result) => {
        setBanks(result.data.data.rows);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  // add bank
  const addBank = (data, setSearch, setSelectedBank, setShowAllBank) => {
    setLoading("processing");
    setSelectedBank(null);
    setSearch(null);

    // get config for API call
    const method = "post",
      url = `${BASE_URL}/utils/banks`;

    // make API call
    axios({ url, method, headers, data })
      .then((result) => {
        const bankDetails = result.data.data;
        setSearch(bankDetails.accountName);
        setSelectedBank(bankDetails);
        setShowAllBank(false);

        alert("Successful!");
      })
      .catch((error) => {
        error = new Error();
        alert("Failed! There may be a duplicate or a network lag");
      });
  };

  // make payment
  const makePayment = (paymentData) => {
    const paymentMethod = paymentData.paymentMethod;
    let posRef = paymentData.POSRef;

    // setup bankId
    let bankId;
    if (paymentData.selectedBank) {
      bankId = paymentData.selectedBank.id;
    } else {
      bankId = "";
    }

    // setup cash
    if (paymentMethod === "CASH") {
      posRef = "";
      bankId = "";
    }

    // setup POS
    if (paymentMethod === "POS" && !paymentData.POSRef) {
      return setSubmissionError("You hav not entered POS Reference");
    }
    if (paymentMethod === "POS" && paymentData.POSRef) {
      posRef = paymentData.POSRef;
      bankId = "";
    }

    // transfer
    if (paymentMethod === "TRANSFER" && !paymentData.selectedBank) {
      return setSubmissionError("You hav not selected any bank");
    }
    if (paymentMethod === "TRANSFER" && paymentData.selectedBank) {
      posRef = "";
      bankId = paymentData.selectedBank.id;
    }

    setIsSubmitting("processing");
    // get config for API call
    const method = "post",
      url = `${BASE_URL}payments/pos`,
      data = {
        amount: paymentData.order.menuitemorders.total,
        branchId,
        orderId: paymentData.order.id,
        paymentMethod: paymentData.paymentMethod,
        posRef,
        bankId,
        currency: "NGN",
      };

    // make API call
    axios({ url, method, headers, data })
      .then((result) => {
        setIsSubmitting("successful");

        setTimeout(() => {
          setIsSubmitting(false);
          paymentData.closePopup(false);
        }, 3000);
      })
      .catch((error) => {
        error = new Error();
        setIsSubmitting("failed");
      });
  };

  // Add DeliveryArea
  const addDeliveryArea = (
    input,
    addedDeliveryArea,
    setAddedDeliveryArea,
    setArea,
    setDeliveryFee
  ) => {
    setAddingDA("processing");

    // get config for API call
    const method = "post",
      url = `${BASE_URL}utils/deliveryaddress`,
      data = {
        amount: parseFloat(input.deliveryFee),
        address: input.area,
      };

    // get the newly added DeliveryArea details
    const item = data;

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setAddedDeliveryArea([...addedDeliveryArea, item]);
        setArea("");
        setDeliveryFee("");

        setAddingDA("successful");

        // return feedback to null
        setTimeout(() => {
          setAddingDA(null);
        }, 1000);
      })
      .catch((error) => {
        error = new Error();

        setAddingDA("failed");

        // return feedback to null
        setTimeout(() => {
          setAddingDA(null);
        }, 1000);
      });
  };

  // get area
  const getArea = (id) => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}utils/deliveryaddress/${id}`;

    // get Utilss
    axios({ url, method, headers })
      .then((result) => {
        setArea(result.data.data);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  };

  // get payment
  const getPayment = (id, type) => {
    setLoadingPay("processing");

    const method = "get",
      url = `${BASE_URL}payments/orders/${id}?orderType=${type}`;

    // get Utils
    axios({ url, method, headers })
      .then((result) => {
        setPayment(result.data.data);
        setLoadingPay("found");
      })
      .catch((error) => {
        error = new Error();
        setLoadingPay("failed");
      });
  };

    // get payment
    const getPaymentByRef = (ref) => {
      setLoadingPay("processing");
  
      const method = "get",
        url = `${BASE_URL}payments/${ref}`;
  
      // get Utils
      axios({ url, method, headers })
        .then((result) => {
          console.log(result);
          setPayment(result.data.data);
          setLoadingPay("found");
        })
        .catch((error) => {
          error = new Error();
          setLoadingPay("failed");
        });
    };

  // verify payment
  const verifyPayment = (paymentRef) => {
    setVerifying("processing");

    // get config for API call
    const method = "get",
      url = `${BASE_URL}payments/verify/${paymentRef}`;

    // make API call
    axios({ url, method, headers })
      .then((result) => {
        setVerifying("successful");

        // return feedback to null
        setTimeout(() => {
          setVerifying(null);
        }, 1000);
      })
      .catch((error) => {
        error = new Error();

        setVerifying("failed");

        // return feedback to null
        setTimeout(() => {
          setVerifying(null);
        }, 1000);
      });
  };

  return (
    <UtilsContext.Provider
      value={{
        utilsLoading,
        banks,
        getBanks,
        addBank,
        makePayment,
        submissionError,
        isSubmitting,
        addDeliveryArea,
        addingDA,
        getArea,
        area,
        payment,
        loadingPay,
        getPayment,
        verifyPayment,
        verifying,
        getPaymentByRef
      }}
    >
      {props.children}
    </UtilsContext.Provider>
  );
}
