// external imports
import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getIds, getOrderedFor } from "../components/helpers/POSOrderHelper";
import { getBaseUrl } from "../Configurations/ContextConfig";
import { HelpersContext } from "./HelpersContext";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};
let branchId = null;
let staffId = null;

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
  branchId = staff.branchId;

  staffId = staff.id;
}

const BASE_URL = getBaseUrl();

// create Order context to manage Order here
export const OrderContext = createContext();

export default function OrderContextProvider(props) {
  // context
  const { presentDay, nextDay } = useContext(HelpersContext);

  // set initial state
  const [orderLoading, setLoading] = useState(null);
  const [orderByIdLoading, setOrderByIdLoading] = useState(null);
  const [staffOrders, setOrders] = useState([]);
  const [order, setOrder] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [isCreating, setIsCreating] = useState(null);
  const [changing, setChanging] = useState(null);
  const [executeOnce, setExecuteOnce] = useState(true);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [loadingCustomerOrder, setLoadingCustomerOrder] = useState(null);

  // get all orders made by a staff for the day
  useEffect(() => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}orders/menuitem/getOrdersByUserAndDate?page=1&userId=${staffId}&dateFrom=${presentDay}&dateTo=${nextDay}`;

    // get Orders
    axios({ url, method, headers })
      .then((result) => {
        setOrders(result.data.data.items);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get all orders for order management
  const filterOrders = (startDate, endDate, page) => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL}orders/menuitem/getOrdersByDate/?dateFrom=${startDate}&dateTo=${endDate}&page=${page}`;

    // get Orders
    axios({ url, method, headers })
      .then((result) => {
        setAllOrders(result.data.data.items);
        setLoading("found");

        // stop another execution
        setExecuteOnce(false);
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");

        // stop another execution
        setExecuteOnce(false);
      });
  };

  // add Order
  const createOrder = (orderData) => {
    const tableDetails = orderData.tableDetails;
    const cartItems = orderData.cartItems;
    const total = orderData.cartTotal;
    const cartIds = getIds(cartItems);
    const orderedFor = getOrderedFor(orderData.customer);

    // terminate process if cart is empty
    if (!cartItems.length || total <= 0 || total <= "0")
      return alert("You do not have any item in your cart!");

    // terminate if no table is selected
    if (!tableDetails) return alert("You Have not selected a Table!");

    // get the order name
    // we are ensuring that even if the Form for table details is bypassed somehow, the data will not be corrupted so if Take Out is selected, we will not concatinate the strings
    let orderName;
    if (tableDetails.tableName === "Take Out") {
      orderName = tableDetails.tableName;
    } else {
      orderName = tableDetails.tableName + tableDetails.tableSide;
    }

    setIsCreating("processing");

    // get config for API call
    const method = "post",
      url = `${BASE_URL}orders/menuItem/`,
      data = {
        isMenuPlan: false,
        branchId,
        subTotal: total,
        total,
        paymentMethod: "CASH",
        paymentType: "FullPayment",
        deliveryCharge: 0,
        orderName,
        orderedFor,
        orderChannel: "POS",
        paymentStatus: "Not Paid",
        cartIds: cartIds.toString(),
      };

    // Create the order here
    axios({ url, method, headers, data })
      .then(() => {
        setIsCreating("successful");

        window.location.href = window.location.href.split("#")[0];
      })
      .catch((error) => {
        error = new Error();
        setIsCreating("failed");
      });
  };

  // get an order by id
  const getOrderById = (orderDetails) => {
    setOrderByIdLoading("processing");

    const method = "get",
      url = `${BASE_URL}orders/byId?orderId=${orderDetails.id}&isMenuplan=${orderDetails.isMenuPlan}`;

    // get MenuItem
    axios({ url, method, headers })
      .then((result) => {
        setOrder(result.data.data);
        setOrderByIdLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setOrderByIdLoading("failed");
      });
  };

  // change order status
  const changeOrderStatus = (orderId, status, closePopup) => {
    setChanging("processing");

    if (closePopup) {
      closePopup(false);
    }

    // config
    const method = "put",
      url = `${BASE_URL}/orders/changeOrderStatus/`,
      data = {
        isMenuPlan: false,
        orderId,
        status,
      };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setChanging("successful");

        setTimeout(() => {
          setChanging(null);
        }, 1000);
      })
      .catch((error) => {
        error = new Error();
        setChanging("failed");

        setTimeout(() => {
          setChanging(null);
        }, 1000);
      });
  };

  // get menu item order by customer
  const getCustomersMTOrders = (userId, page) => {
    if (!userId || userId === null || userId === "") return;

    setLoadingCustomerOrder("processing");

    const method = "get",
      url = `${BASE_URL}orders/menuitem/detail?page=${page}&userId=${userId}`;

    // get Orders
    axios({ url, method, headers })
      .then((result) => {
        setCustomerOrders(result.data.data.items);
        setLoadingCustomerOrder("found");
      })
      .catch((error) => {
        error = new Error();
        setLoadingCustomerOrder("failed");
        setCustomerOrders([]);
      });
  };

  return (
    <OrderContext.Provider
      value={{
        orderLoading,
        changing,
        staffOrders,
        createOrder,
        allOrders,
        filterOrders,
        getOrderById,
        order,
        isCreating,
        changeOrderStatus,
        orderByIdLoading,
        executeOnce,
        customerOrders,
        loadingCustomerOrder,
        getCustomersMTOrders,
        setLoadingCustomerOrder,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
}
