import React from "react";
import CreateBranchForm from "../../../components/adminComponents/Settings/Branch/CreateBranchForm";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import SettingsFrame from "../../../components/adminComponents/Settings/SettingsFrame";

export default function CreateBranch() {
  return (
    <>
      <DashboardFrame
        pageHeading="Settings"
        dashboardContent={
          <SettingsFrame
            firstLabel="Create Branch"
            firstContent={<CreateBranchForm />}
          />
        }
      />
    </>
  );
}
