import React, { useContext } from "react";
import DashboardFrame from "../../../components/adminComponents/DashboardFrame";
import Order from "../../../components/adminComponents/Settings/OrderAndSchedule/Order";
import Schedule from "../../../components/adminComponents/Settings/OrderAndSchedule/Schedule";
import SettingsFrame from "../../../components/adminComponents/Settings/SettingsFrame";
import { ScheduleContext } from "../../../ContextAPI/ScheduleContext";
import FeedbackPopup from "../../../components//helpers/FeedbackPopup";

export default function OrderAndSchedule() {
  const { scheduleLoading } = useContext(ScheduleContext);

  return (
    <>
      {/* feedback popup */}
      <FeedbackPopup
        condition={scheduleLoading}
        additionalMessage="Page Reloads in a moment..."
      />

      <DashboardFrame
        pageHeading="Settings"
        dashboardContent={
          <SettingsFrame
            firstLabel="Order"
            secondLabel="Schedule"
            firstContent={<Order />}
            secondContent={<Schedule />}
          />
        }
      />
    </>
  );
}
