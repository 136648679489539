import React from "react";
import { Grid } from "@material-ui/core";
import { Detail } from "../../CustomerRecipe/ViewAndUpdateDetails/RecipeDetails";
import { getTopingsDetails } from "../../CustomerRecipe/HelperFunctions";

export default function MealDetail({ order }) {
  const recipeReceived = order.RecipeOrder.Recipe;
  return (
    <>
      <Grid container className="cart_item_details" key={order.id}>
        {/* menu item name */}
        <Grid item xs={12} sm={12} md={12} lg={12} id="cart_item_heading">
          <>
            <h4>({order.quantity}x)</h4> <h4>{recipeReceived.mealName}</h4>
          </>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} id="item_details">
          <Detail title="Description" body={recipeReceived.description} />
          <Detail title="Key Ingredients:" body={recipeReceived.ingredients} />
          <Detail
            title="Toppings:"
            body={getTopingsDetails(JSON.parse(recipeReceived.toppins))}
          />
          <Detail
            title="Special Instructions::"
            body={recipeReceived.specialInstructions}
          />
        </Grid>
      </Grid>
    </>
  );
}
