import React, { useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import HalfRowLayout from "../../../helpers/HalfRowLayout";
import NumberFormaterSuffix from "../../../helpers/NumberFormaterSuffix";
import { UtilsContext } from "../../../../ContextAPI/UtilsContext";

export default function PaymentAndDelivery({ order }) {
  // context
  const { getArea, area, utilsLoading, getPayment } = useContext(UtilsContext);

  useEffect(() => {
    getArea(order.deliveryAddId);
    getPayment(order.orderId, "ORDER");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container id="payment_and_delivery">
        <Grid item xs={5} sm={5} md={5} lg={5} id="payment_details">
          <Grid container>
            <HalfRowLayout className="text_key" text="Total Amount:" />
            <HalfRowLayout
              text={<NumberFormaterSuffix value={order.total} />}
            />

            <HalfRowLayout className="text_key" text="Sub-Total:" />
            <HalfRowLayout
              text={
                <NumberFormaterSuffix
                  value={parseFloat(order.amount) * parseFloat(order.deliveryTime.quantity)}
                />
              }
            />

            <HalfRowLayout className="text_key" text="Delivery fee:" />
            <HalfRowLayout
              text={<NumberFormaterSuffix value={order.deliveryCharge} />}
            />

            <HalfRowLayout className="text_key" text="Payment status:" />
            <HalfRowLayout text={order.paymentStatus} />

            <HalfRowLayout className="text_key" text="Payment method:" />
            <HalfRowLayout text={order.paymentMethod} />

            <HalfRowLayout className="text_key" text="Payment Gateway:" />
            <HalfRowLayout text="" />
          </Grid>
        </Grid>

        <div className="vertical_rule"></div>

        <Grid item xs={5} sm={5} md={5} lg={5} id="delivery_details">
          <Grid container>
            <HalfRowLayout className="text_key" text="Delivery method:" />
            <HalfRowLayout text={order.deliveryOption} />

            <HalfRowLayout className="text_key" text="Area:" />
            <HalfRowLayout
              text={
                utilsLoading === "processing" ? (
                  <i>Fetching Area</i>
                ) : utilsLoading === "found" && area ? (
                  area.address
                ) : utilsLoading === "failed" ? (
                  ""
                ) : (
                  ""
                )
              }
            />

            <HalfRowLayout className="text_key" text="Address:" />

            <HalfRowLayout text={order.deliveryAddress} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
