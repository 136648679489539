import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useContext } from "react";
import { RecipeContext } from "../../../../ContextAPI/RecipeContext";
import HalfRowLayout from "../../../helpers/HalfRowLayout";

export default function Info() {
  const { recipeDetails } = useContext(RecipeContext);
  const recipeOrder = recipeDetails.RecipeOrder;
  const customer = recipeOrder.orderedByDetail;
  const dateCreated = recipeDetails.createdAt;
  const dateUpdated = recipeDetails.updatedAt;
  const staff = recipeOrder.staffDetail;

  return (
    <>
      {" "}
      <Grid container>
        <HalfRowLayout className="text_key" text="CUSTOMER NAME:" />
        <HalfRowLayout text={customer.fullName} />

        <HalfRowLayout className="text_key" text="CUSTOMER NUMBER:" />
        <HalfRowLayout text={customer.phoneNumber} />

        <HalfRowLayout className="text_key" text="ORDER ID:" />
        <HalfRowLayout text={recipeDetails.id} />

        <HalfRowLayout className="text_key" text="QUANTITY:" />
        <HalfRowLayout text={recipeDetails.quantity} />

        <HalfRowLayout className="text_key" text="CHANNEL:" />
        <HalfRowLayout text={recipeDetails.orderChannel} />

        <HalfRowLayout className="text_key" text="COMPLETED BY:" />
        <HalfRowLayout
          text={staff ? `${staff.fullName} -- ${staff.phoneNumber}` : ""}
        />

        <HalfRowLayout className="text_key" text="DATE OF REQUESTED:" />
        <HalfRowLayout text={moment(dateCreated).format("L")} />

        <HalfRowLayout className="text_key" text="TIME OF REQUESTED:" />
        <HalfRowLayout text={moment(dateCreated).format("LTS")} />

        <HalfRowLayout className="text_key" text="DATE OF PRICING:" />
        <HalfRowLayout text={moment(dateUpdated).format("L")} />

        <HalfRowLayout className="text_key" text="TIME OF PRICING:" />
        <HalfRowLayout text={moment(dateUpdated).format("LTS")} />

        <HalfRowLayout className="text_key" text="STATUS:" />
        <HalfRowLayout text={recipeDetails.status} />

        <HalfRowLayout className="text_key" text="DATE OF DELIVERY:" />
        <HalfRowLayout text={moment(recipeDetails.deliveryDate).format("L")} />

        <HalfRowLayout className="text_key" text="TIME OF DELIVERY:" />
        <HalfRowLayout text={recipeDetails.deliveryTime} />

        <HalfRowLayout className="text_key" text="TIME OF DELIVERED:" />
        <HalfRowLayout text={""} />

        <HalfRowLayout className="text_key" text="RECIPIENT’S NAME:" />
        <HalfRowLayout
          text={
            recipeOrder.friendName ? recipeOrder.friendName : customer.fullName
          }
        />

        <HalfRowLayout className="text_key" text="RECIPIENT’S NUMBER:" />
        <HalfRowLayout
          text={
            recipeOrder.friendPhoneNumber
              ? recipeOrder.friendPhoneNumber
              : customer.phoneNumber
          }
        />
      </Grid>
    </>
  );
}
