// external imports
import React, { createContext, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let headers = {};

if (staff) {
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
}

const BASE_URL = getBaseUrl();

// create MenuItem context to manage MenuItem here
export const EditMenuItemContext = createContext();

export default function EditMenuItemContextProvider(props) {
  // set initial state
  const [addonsUpadating, setAddonsUpadating] = useState(null);
  const [preferencesUpdating, setPreferencesUpdating] = useState(null);
  const [updatingMenuItem, setUpdatingMenuItem] = useState(null);
  const [updatingAvailability, setUpdatingAvailability] = useState(null);

  //   updateAddons
  const updateAddons = (id, initialExtra, otherExtra) => {
    if (!otherExtra.length) return alert("You have not selected Any Addons");

    // addons
    // initialExtra
    let addons = [];
    for (const addon of initialExtra) {
      const newAddon = {
        id: addon.id,
        price: parseFloat(addon.totalPrice),
        quantity: parseFloat(addon.quantity),
      };

      addons.push(newAddon);
    }

    // otherExtra
    for (const addon of otherExtra) {
      const newAddon = {
        id: addon.id,
        price: parseFloat(addon.totalPrice),
        quantity: parseFloat(addon.quantity),
      };

      addons.push(newAddon);
    }

    setAddonsUpadating("processing");

    // config
    const method = "put",
      url = `${BASE_URL}/menuitems/${id}/addons`,
      data = { addons };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setAddonsUpadating("successful");

        setTimeout(() => {
          setAddonsUpadating(null);
        }, 2000);
      })
      .catch((error) => {
        error = new Error();
        setAddonsUpadating("failed");

        setTimeout(() => {
          setAddonsUpadating(null);
        }, 2000);
      });
  };

  //   updatePreferences
  const updatePreferences = (id, selectedPreferences) => {
    if (!selectedPreferences.length)
      return alert("You have not selected Any preference");

    // preferences
    let preferences = [];
    for (const preference of selectedPreferences) {
      preferences.push(preference.id);
    }

    setPreferencesUpdating("processing");

    // config
    const method = "put",
      url = `${BASE_URL}/menuitems/${id}/preferences`,
      data = { preferences };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setPreferencesUpdating("successful");

        setTimeout(() => {
          setPreferencesUpdating(null);
        }, 2000);
      })
      .catch((error) => {
        error = new Error();
        setPreferencesUpdating("failed");

        setTimeout(() => {
          setPreferencesUpdating(null);
        }, 2000);
      });
  };

  //   edit menu item
  const editMenuItem = (
    inputs,
    selectedItemCategory,
    imageUrl,
    availability,
    specialOffer,
    displayOnApp,
    id
  ) => {
    setUpdatingMenuItem("processing");

    // configuration for Axios API call
    let options = {
      method: "put",
      url: `${BASE_URL}menuitems/${id}`,
      headers,
    };

    // process data
    // itemCategories
    let itemCategories = [];
    for (const category of selectedItemCategory) {
      itemCategories.push(category.id);
    }

    // set availability to boolean
    let available;
    if (availability === "true") {
      available = true;
    } else {
      available = false;
    }

    // process the image if an image was selected
    if (imageUrl) {
      options.data = {
        itemName: inputs.itemName,
        imageUrl,
        itemCategories,
        description: inputs.description,
        caption: inputs.caption,
        activated: displayOnApp,
        available,
        specialOffer,
      };

      editMenuItemAPICall(options);
    } else {
      // set the data for when no new image is selected
      options.data = {
        itemName: inputs.itemName,
        itemCategories,
        description: inputs.description,
        caption: inputs.caption,
        activated: displayOnApp,
        available,
        specialOffer,
      };

      editMenuItemAPICall(options);
    }
  };

  // API call for edit menu item
  const editMenuItemAPICall = (options) => {
    axios(options)
      .then(() => {
        setUpdatingMenuItem("successful");

        window.location.href = "/menu-items-management";
      })
      .catch((error) => {
        error = new Error();
        setUpdatingMenuItem("failed");
      });
  };

  // update menu item availability
  const updateAvailability = (
    state,
    itemId,
    updateToggleButton,
    type,
    url,
    isCategory
  ) => {
    setUpdatingAvailability("processing");

    let id;
    if (isCategory) {
      id = itemId;
    }

    // configuration for Axios API call
    let options = {
      method: "put",
      url: `${BASE_URL}${url}`,
      headers,
      data:
        type === "availability"
          ? {
              id,
              available: state,
            }
          : {
              id,
              activated: state,
            },
    };

    // API call
    axios(options)
      .then(() => {
        setUpdatingAvailability("successful");
        updateToggleButton(state);

        setTimeout(() => {
          setUpdatingAvailability(null);
        }, 1000);
      })
      .catch((error) => {
        error = new Error();
        setUpdatingAvailability("failed");

        setTimeout(() => {
          setUpdatingAvailability(null);
        }, 1000);
      });
  };

  return (
    <EditMenuItemContext.Provider
      value={{
        addonsUpadating,
        updateAddons,
        preferencesUpdating,
        updatePreferences,
        updatingMenuItem,
        editMenuItem,
        updateAvailability,
        updatingAvailability,
      }}
    >
      {props.children}
    </EditMenuItemContext.Provider>
  );
}
