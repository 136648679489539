// external imports
import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../Configurations/ContextConfig";
import { HelpersContext } from "./HelpersContext";

// initialization
const cookies = new Cookies();
const staff = cookies.get("AGU-ANAGHI-ERI-AGU");
let branchId = null;
let headers = {};
let staffId = null;

if (staff) {
  branchId = staff.branchId;
  staffId = staff.id;
  headers = {
    Authorization: `Bearer ${staff.token}`,
  };
}

const BASE_URL_2 = getBaseUrl(2);

// create Recipe context to manage Recipe here
export const RecipeContext = createContext();

export default function RecipeContextProvider(props) {
  // context
  const { setOrderStatus } = useContext(HelpersContext);

  // set initial state
  const [recipeLoading, setLoading] = useState(null);
  const [recipes, setRecipes] = useState([]);
  const [recipe, setRecipe] = useState(null);
  const [fetchingRecipe, setFetchingRecipe] = useState(false);
  const [recipeDetails, setRecipeDetails] = useState("");
  const [updatingPrice, setUpdatingPrice] = useState(false);
  const [recipeTableType, setRecipeTableType] = useState("");
  const [recipeOrders, setRecipeOrders] = useState([]);
  const [changing, setChanging] = useState(null);

  // form states
  const [recipeTitle, setRecipeTitle] = useState("");
  const [recipeDescription, setRecipeDescription] = useState("");
  const [recipeToppings, setRecipeToppings] = useState("");
  const [keyIngredients, setKeyIngredients] = useState("");
  const [instructions, setInstructions] = useState("");
  const [amount, setAmount] = useState(null);

  // make API call to get all the Recipes
  useEffect(() => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL_2}recipes/orders?branchId=${branchId}`;

    // get Recipes
    axios({ url, method, headers })
      .then((result) => {
        setRecipes(result.data.data.rows);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  }, []);

  // make API call to get all the Recipes orders by delivery time
  useEffect(() => {
    setLoading("processing");

    const method = "get",
      url = `${BASE_URL_2}recipes/deliverytime?branchId=${branchId}`;

    // get Recipes
    axios({ url, method, headers })
      .then((result) => {
        setRecipeOrders(result.data.data.rows);
        setLoading("found");
      })
      .catch((error) => {
        error = new Error();
        setLoading("failed");
      });
  }, []);

  // changeRecipeDTstatus
  const changeRecipeDTstatus = (DTId, status) => {
    setChanging("processing");

    // config
    const method = "put",
      url = `${BASE_URL_2}recipes/deliverytime/${DTId}`,
      data = {
        status,
      };

    // make API call
    axios({ url, method, headers, data })
      .then(() => {
        setChanging("successful");

        setOrderStatus(status);

        setTimeout(() => {
          setChanging(null);
        }, 1000);
      })
      .catch((error) => {
        error = new Error();
        setChanging("failed");

        setTimeout(() => {
          setChanging(null);
        }, 1000);
      });
  };

  // get an order
  const getRecipeOrder = (recipeId) => {
    setFetchingRecipe("processing");

    const method = "get",
      url = `${BASE_URL_2}recipes/${recipeId}/order`;

    // get Recipes
    axios({ url, method, headers })
      .then((result) => {
        setRecipe(result.data.data);
        setFetchingRecipe("found");
      })
      .catch((error) => {
        error = new Error();
        setFetchingRecipe("failed");
      });
  };

  // update price
  const updateRecipePrice = (recipeId) => {
    setUpdatingPrice("processing");

    const method = "put",
      url = `${BASE_URL_2}recipes/${recipeId}/order`,
      data = {
        amount,
        staff: staffId,
      };

    // get Recipes
    axios({ url, method, headers, data })
      .then(() => {
        setUpdatingPrice("successful");

        // redirect user to the pending recipe page
        window.location.href = "/pending-recipe";
      })
      .catch((error) => {
        error = new Error();
        setUpdatingPrice("failed");
      });
  };

  return (
    <RecipeContext.Provider
      value={{
        recipeLoading,
        recipes,
        recipe,
        fetchingRecipe,
        getRecipeOrder,
        recipeDetails,
        setRecipeDetails,
        recipeTitle,
        setRecipeTitle,
        recipeDescription,
        setRecipeDescription,
        recipeToppings,
        setRecipeToppings,
        keyIngredients,
        setKeyIngredients,
        instructions,
        setInstructions,
        updateRecipePrice,
        updatingPrice,
        amount,
        setAmount,
        recipeTableType,
        setRecipeTableType,
        recipeOrders,
        changeRecipeDTstatus,
        changing,
      }}
    >
      {props.children}
    </RecipeContext.Provider>
  );
}
