import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core/";
import MenuPlanAddMealSearchOption from "./MenuPlanAddMealSearchOption";
import { ScheduleContext } from "../../../ContextAPI/ScheduleContext";
import {
  filterSchedulesByTimeOfDay,
  addTimeRange,
  timeLimitConvertion,
} from "../../helpers/MenuPlanHelper";
import { MenuPlanContext } from "../../../ContextAPI/MenuPlanContext";
import moment from "moment";

export default function MenuPlanTimeLimits(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const menuItem = props.menuItem;

  const handleClickOpen = () => () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      {/* popup button */}
      <section
        id="menu_plan_time_limit_btn"
        onClick={handleClickOpen()}
        title={menuItem.description}
      >
        <MenuPlanAddMealSearchOption
          menuItem={menuItem}
          periodOfDay={props.periodOfDay}
          row={props.row}
          day={props.day}
        />
      </section>

      {/* popup body */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Select all Time Limits{" "}
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {/* content */}
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <MenuPlanTimeLimitsBody
              menuItem={menuItem}
              periodOfDay={props.periodOfDay}
              row={props.row}
              day={props.day}
              planData={props.planData}
              submissionDate={props.submissionDate}
              allDetails={props.allDetails}
              setAllDetails={props.setAllDetails}
              closePlanTimeLimit={handleClose}
              toggleAddMeal={props.toggleAddMeal}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function MenuPlanTimeLimitsBody(props) {
  // context
  const { schedules } = useContext(ScheduleContext);
  const { AddMenuPlanDetail } = useContext(MenuPlanContext);

  // useState
  const [selectedTimeRange, setSelectedTimeRange] = useState([]);

  // props
  const menuItem = props.menuItem;
  const periodOfDay = props.periodOfDay;
  const row = props.row;
  const day = props.day;
  const planData = props.planData;
  const submissionDate = props.submissionDate;
  const allDetails = props.allDetails;
  const setAllDetails = props.setAllDetails;

  const handleSubmit = (e) => {
    e.preventDefault();

    // terminate if no time range is selcted
    if (selectedTimeRange <= 0)
      return alert("You Must Select At Least One Time Range");

    AddMenuPlanDetail(
      selectedTimeRange,
      menuItem,
      periodOfDay,
      row,
      day,
      planData,
      submissionDate,
      allDetails,
      setAllDetails
    );

    // close the popups
    props.toggleAddMeal(false);
    props.closePlanTimeLimit(false);
  };

  // seperate the schedules according to period of the day
  const filteredSchedules = filterSchedulesByTimeOfDay(schedules, moment);
  const morningTime = filteredSchedules.morningTime;
  const afternoonTime = filteredSchedules.afternoonTime;
  const eveningTime = filteredSchedules.eveningTime;

  return (
    <form onSubmit={handleSubmit}>
      <FormControl component="fieldset">
        {/* display time according to the period of day being worked upon */}
        {periodOfDay === "Morning" ? (
          <>
            {/* Morning */}
            <FormLabel component="legend">Morning</FormLabel>
            {morningTime &&
              morningTime.length > 0 &&
              morningTime.map((result) => (
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value={result.timeRange}
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e) =>
                          addTimeRange(
                            e.target.checked,
                            e.target.value,
                            setSelectedTimeRange,
                            selectedTimeRange
                          )
                        }
                      />
                    }
                    label={timeLimitConvertion(result.timeRange, moment)}
                    labelPlacement="end"
                  />
                </FormGroup>
              ))}
          </>
        ) : periodOfDay === "Afternoon" ? (
          <>
            {/* Afternoon */}
            <FormLabel component="legend">Afternoon</FormLabel>
            {afternoonTime &&
              afternoonTime.length > 0 &&
              afternoonTime.map((result) => (
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value={result.timeRange}
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e) =>
                          addTimeRange(
                            e.target.checked,
                            e.target.value,
                            setSelectedTimeRange,
                            selectedTimeRange
                          )
                        }
                      />
                    }
                    label={timeLimitConvertion(result.timeRange, moment)}
                    labelPlacement="end"
                  />
                </FormGroup>
              ))}
          </>
        ) : (
          <>
            {/* Evening */}
            <FormLabel component="legend">Evening</FormLabel>
            {eveningTime &&
              eveningTime.length > 0 &&
              eveningTime.map((result) => (
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value={result.timeRange}
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e) =>
                          addTimeRange(
                            e.target.checked,
                            e.target.value,
                            setSelectedTimeRange,
                            selectedTimeRange
                          )
                        }
                      />
                    }
                    label={timeLimitConvertion(result.timeRange, moment)}
                    labelPlacement="end"
                  />
                </FormGroup>
              ))}
          </>
        )}
      </FormControl>

      <section className="form-btn">
        <Button type="submit">Create</Button>
      </section>
    </form>
  );
}
