import React, { useContext, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, TextField, InputAdornment } from "@material-ui/core";
import LoadingFeedback from "../../../helpers/LoadingFeedback";
import { CustomerContext } from "../../../../ContextAPI/CustomerContext";
import CustomerSummary from "./CustomerSummary";
import SearchIcon from "@material-ui/icons/Search";
import NextAndPrevButtons from "../../../helpers/Table/NextAndPrevButtons";
import StartAndEndDates from "../../../helpers/Table/StartAndEndDates";

export default function ViewCustomersTable() {
  // context
  const {
    customerLoading,
    customers,
    searchCustomers,
    executeOnce,
    filterCustomers,
  } = useContext(CustomerContext);

  // states
  const [search, setSearch] = useState("");

  // table headings
  const columns = [
    "S/N",
    "NAME",
    "PHONE NUMBER",
    "SIGN UP DATE",
    "EMAIL",
    "SUMMARY",
  ];

  // extract data for the table rows
  let extractedCustomers = [];
  if (customers.length) {
    extractedCustomers = customers.map((customer, index) => {
      return [
        index + 1,
        customer.fullName,
        customer.phoneNumber,
        customer.createdAt.split("T")[0],
        customer.email,
        <CustomerSummary customer={customer} />,
      ];
    });
  }

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "100%",
    tableBodyMaxHeight: "400px",
    jumpToPage: true,
  };

  return (
    <main className="main_table_frame">
      {/* Sort */}
      <Grid container className="top_filter" id="pos_filter">
        {/* today */}
        <Grid item xs={1} sm={1} md={1} lg={1} className="choosen_range">
          <p>
            All <span>{customers.length}</span>
          </p>
        </Grid>

        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          className="filter_forms"
          id="customer_search"
        >
          {/* searsch field */}
          <TextField
            variant="outlined"
            placeholder="Search Menu"
            name="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={(e) => searchCustomers(search)}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1} className="filter_text">
          <p>Filter by Date:</p>
        </Grid>

        {/* filter options */}
        <StartAndEndDates
          filterFunction={filterCustomers}
          executeOnce={executeOnce}
        />

        {/* pagination */}
        <NextAndPrevButtons />
      </Grid>

      {/* set table */}
      {customerLoading === "processing" ? (
        <LoadingFeedback message="Searching For Customers..." />
      ) : customerLoading === "found" && customers.length > 0 ? (
        <MUIDataTable
          title={"Customers Found"}
          data={extractedCustomers}
          columns={columns}
          options={options}
        />
      ) : customerLoading === "found" && customers.length <= 0 ? (
        <LoadingFeedback message="Search Completed - No Customers Found" />
      ) : customerLoading === "failed" ? (
        <LoadingFeedback message="An Error Occured. There May Be no Customers for this DATE RANGE." />
      ) : (
        <MUIDataTable
          title={"Customers Found"}
          data={extractedCustomers}
          columns={columns}
          options={options}
        />
      )}
    </main>
  );
}
