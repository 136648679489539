import { TextField } from "@material-ui/core";
import React from "react";

const Input = ({
  title,
  isTextArea,
  textAreaCount,
  value,
  placeholder,
  width,
  inputSetter
}) => {
  return (
    <>
      <h4>{title}</h4>
      <TextField
        multiline={isTextArea}
        rows={textAreaCount}
        placeholder={placeholder}
        fullWidth={width ? width : true}
        variant="outlined"
        name="name"
        // error={!!errors.name}
        // helperText={errors.name ? errors.name.message : ""}
        // inputRef={register}
        value={value}
        onChange={(e) => inputSetter(e.target.value)}
      />
    </>
  );
};

export default Input;
