import React from "react";
import DashboardFrame from "../../components/adminComponents/DashboardFrame";
import DashboardLPContent from "../../components/adminComponents/DashboardLP/DashboardLPContent";

export default function DashboardLP() {
  return (
    <>
      <DashboardFrame dashboardContent={<DashboardLPContent />} />
    </>
  );
}
