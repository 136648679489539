import React from "react";
import { Grid, LinearProgress } from "@material-ui/core/";

export default function ImageUploadProgress(props) {
  return (
    <>
      {props.progress > 0 ? (
        <Grid id="progress_bar" container>
          <Grid item xs={11} sm={11} md={11} lg={11}>
            <LinearProgress
              variant="buffer"
              value={props.progress}
              valueBuffer={10}
            />
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1}>
            <p>{props.progress}%</p>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
}
